import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Col } from '../../base/Grid';
import Link from '../../base/Link';
import Typography from '../../base/Typography';

export const FooterSection = styled.section`
  padding-top: ${rem(32)};
`;

export const FafaCol = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    let marginTop = rem(32);
    if (largeUp) {
      marginTop = 0;
    }

    return css`
      margin-top: ${marginTop};

      ${largeUp
        && `
        margin-top: 0;
      `}
      @media print {
        max-width: 100%;
        flex: 0 100%;
      }
    `;
  }}
`;

export const FafaTypography = styled(Typography)`
  ${({ theme }) => css`
    display: inline-block;
    margin-right: ${rem(16)};

    ${theme.largeUp()
      && `
      display: block;
      margin-right: 0;
    `}
  `}
`;

export const FafaLink = styled(Link)`
  display: inline-block;
`;
