import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../../base/Typography';
import { Col } from '../../../base/Grid/grid';

export const ProductResourcesViewContainer = styled.div`
  /**  Hidden initially, then shown via javascript */
  visibility: hidden;
  height: 0;
`;

export const ColProductResourcesStyled = styled(Col)`
  ${({ theme }) => {
    let marginBottom = rem(40);

    if (theme.mediumUp()) {
      marginBottom = rem(30);
    }

    return css`
      margin-bottom: ${marginBottom};
      :last-child {
        margin-bottom: 0;
      }
    `;
  }}
`;

export const TypographyResourcesViewHeadingStyled = styled(Typography)`
  && {
    padding-bottom: ${rem(20)};
  }
}
`;
