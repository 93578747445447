import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../base/Typography';
import CheckmarkBoldIcon from '../../icons/utility/CheckmarkBold';

export const ULStyled = styled.ul`
  ${({ disableBottomPadding }) => css`
    padding: 0 0 0 ${rem(40)};
    ${disableBottomPadding
      && css`
        margin-bottom: 0;
      `}
  `}
`;

export const LIStyled = styled.li`
  list-style: none;
  position: relative;
`;

export const BulletStyled = styled('span')`
  ${({ theme }) => {
    const smallUp = theme.smallUp();
    const mediumUp = theme.mediumUp();

    return css`
      position: absolute;
      left: ${rem(-32)};
      top: 8px;
      ${smallUp && `
        left: ${rem(-40)};
        top: ${rem(10)};
      `}
      ${mediumUp && ''}

    ::before {
      width: ${rem(5)};
      height: ${rem(5)};
      ${smallUp && `
      width: ${rem(7)};
      height: ${rem(7)};
    `}
      position: absolute;
      content: "";
      border-radius: 50%;
      background: ${theme.colors.neutral.gray29};
      display: inline-block;
    }
    `;
  }};
`;

export const NumberedStyled = styled('span')`
${({ theme, index }) => {
    const smallUp = theme.smallUp();
    const mediumUp = theme.mediumUp();

    return css`
    position: absolute;
    left: ${rem(-32)};
    top: ${rem(-1)};
    color: ${theme.colors.primary.blue};
    font-family: 'tandelle';
    font-size: 1.5rem;
    line-height: 1.75rem;

    ${smallUp && `
      font-size: 1.75rem;
      line-height: 2rem;
      top: ${rem(-3)};
    `}

    ${mediumUp && `
      left: ${rem(-40)};
      font-size: 2.25rem;
      line-height: 2.5rem;
    `}
    ::before {
      top: -0.1rem;
      width: ${rem(2)};
      height: ${rem(2)};
      position: absolute;
      box-shadow: 0.8rem 1.2rem 0 0.07rem ${theme.colors.cta.yellow};
      ${smallUp && `
        box-shadow: 0.87rem 1.45rem 0 0.07rem ${theme.colors.cta.yellow};
      `}
      ${mediumUp && `
        box-shadow: 1.01rem 1.81rem 0 0.11rem ${theme.colors.cta.yellow};
      `}
      content: '${index + 1}';
    }
  `;
  }};
`;

export const IconStyled = styled(CheckmarkBoldIcon)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    let top = 2;
    if (largeUp) {
      top = 5;
    } else if (mediumUp) {
      top = 5;
    }

    return css`
      position: absolute;
      padding-right: 0.5rem;
      /* These top/left values will need to be adjusted once we throw the correct checkmark icon in. */
      top: ${rem(top)};
      left: ${rem(-40)};
    `;
  }}
`;

export const TypographyListItemHeader = styled(Typography)`
  padding-bottom: ${rem(10)};
`;
