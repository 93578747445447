import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Typography from '../../../base/Typography';

export const SectionStyled = styled.section`
  padding-top: 2rem;
  padding-bottom: 2rem;
  .nmx-col {
    @media print {
      max-width: 100%;
      flex: 0 100%;
    }
  }
`;

export const CopyrightTypographyStyled = styled(Typography)`
  && {
    line-height: ${rem(30)};
  }
`;

export const CopyrightSmallTypographyStyled = styled(Typography)`
  && {
    font-size: 80%;
  }
`;
