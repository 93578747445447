import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Col } from '../../../base/Grid';
import Link from '../../../base/Link';
import Picture from '../../../base/Picture';
import Typography from '../../../base/Typography';

export const PhotoColStyled = styled(Col)`
  && {
    ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      margin-bottom: ${mediumUp ? 0 : rem(24)};
      padding-right: ${mediumUp && rem(32)};
      display: ${!mediumUp && 'flex'};
      ${!mediumUp
        && `
        justify-content: center;
      `}
    `;
  }
}
  }
`;

export const ContentColStyled = styled(Col)`
  && {
    ${({ theme }) => css`
      padding-left: ${theme.mediumUp() && rem(16)};
    `}
  }
`;

export const TypographyHeadlineStyled = styled(Typography)`
  && {
    ${({ theme }) => css`
      text-align: ${theme.mediumUp() ? 'left' : 'center'};
    `}
  }
`;

export const DivButtonContainer = styled.div`
  && {
    ${({ theme }) => css`
      text-align: ${theme.mediumDown() ? 'center' : 'left'};
      ${theme.smallDown()
        && css`
          button {
            width: 100%;
            max-width: 100%;
          }
        `}
      margin-top: ${rem(12)};
    `}
  }
`;

export const PictureFRPhotoStyled = styled(Picture)`
  ${({ theme, variant }) => css`
    display: block;
    max-width: ${(variant === 'secondary') ? rem(220) : rem(270)};
    float: ${theme.mediumUp() ? 'right' : 'none'};
  `
}
`;

export const ButtonLinkStyled = styled(Link)`
  ${({ theme }) => css`
    ${theme.smallDown()
      && `
        width: 100%;
        max-width: none;
      `}
  `}
`;
