import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import Colors from '../../theme/colors';
import Typography from '../../base/Typography';
import { Row } from '../../base/Grid';

import {
  AnchorArticleContainerStyled,
  ArticleDynamicCalloutStyled,
  ButtonDynamicCalloutBannerStyled,
  ButtonDynamicCalloutOverlayStyled,
  ColContentContainerStyled,
  ColImageContainerStyled,
  DivBannerImageContainerStyled,
  DivCardImageStyled,
  DivContentContainerStyled,
  DivDynamicCalloutBannerStyled,
  DivDynamicCalloutCardStyled,
  DynamicCalloutCardContainer,
  IconVisualLinkStyled,
  MetadataStyled,
  SpanArticleFlagStyled,
  TypographyArticleFlagStyled,
  TypographyCardStyled,
  TypographyVisualLinkStyled,
} from './styles';

const buttonTextDefault = 'Get started';
const jsRedirect = (urlPath) => {
  window.location.href = urlPath;
};

export const DynamicCalloutComponent = ({
  actionId,
  assistiveText,
  buttonText,
  disableLazyLoad,
  flag,
  image,
  metadata,
  orientation,
  outGoingLink,
  tag,
  title,
  url,
  visualLink,
}) => {
  // Adding appropriate color based on the flag prop. Primary is default.
  const flagPrimary = Colors.accent.lightBlue2;
  const flagSecondary = Colors.cta.yellow;
  // If we add more colors, we will need to update this expression
  const flagColor = flag === 'secondary' ? flagSecondary : flagPrimary;

  // card CTA orientation
  if (orientation === 'card') {
    return (
      <DivDynamicCalloutCardStyled>
        <ArticleDynamicCalloutStyled>
          <AnchorArticleContainerStyled
            href={url}
            id={actionId}
            data-tracking-id={actionId}
            ariaLabel={assistiveText}
            {...(outGoingLink && {
              target: '_blank',
              rel: 'nofollow noopener noreferrer',
            })}>
            <SpanArticleFlagStyled flagColor={flagColor}>
              <TypographyArticleFlagStyled
                component="p"
                color={Colors.neutral.black}
                weight={500}
                disableBottomPadding>
                {tag}
              </TypographyArticleFlagStyled>
            </SpanArticleFlagStyled>
            <DivCardImageStyled
              image={image}
              style={
                disableLazyLoad ? { backgroundImage: `url(${image})` } : {}
              }
              data-bg={`url(${image})`} /* If a non URL image is passed in, how should we handle setting the data-bg? */
              className={`${!disableLazyLoad && 'lazy'}`}
            />
            <DynamicCalloutCardContainer className="js-dynamic-callout-card-content-container">
              {!!metadata && (
                <MetadataStyled
                  className="nmx-no-print"
                  component="time"
                  variant="h6"
                  color={Colors.neutral.gray41}
                  weight={300}
                  uppercase>
                  {metadata}
                </MetadataStyled>
              )}
              <TypographyCardStyled
                component="h3"
                variant="h4"
                color={Colors.neutral.gray29}
                weight={500}
                hasLink={visualLink}>
                {title}
              </TypographyCardStyled>
              {!!visualLink && (
                <TypographyVisualLinkStyled
                  className="nmx-no-print"
                  component="p"
                  color={Colors.cta.blue}
                  weight={500}
                  disableBottomPadding>
                  {visualLink}
                  <IconVisualLinkStyled />
                </TypographyVisualLinkStyled>
              )}
            </DynamicCalloutCardContainer>
          </AnchorArticleContainerStyled>
        </ArticleDynamicCalloutStyled>
      </DivDynamicCalloutCardStyled>
    );
  }

  return (
    <DivDynamicCalloutBannerStyled>
      <Row align="center">
        <ColImageContainerStyled
          xsmall={12}
          small={5}
          medium={6}
          large={5}
          nested>
          <DivBannerImageContainerStyled
            data-bg={image}
            style={disableLazyLoad ? { backgroundImage: `url(${image})` } : {}}
            className={`${!disableLazyLoad && 'lazy'}`}
            image={image}
          />
        </ColImageContainerStyled>
        <ColContentContainerStyled
          xsmall={12}
          small={7}
          medium={6}
          large={7}>
          <DivContentContainerStyled>
            <Typography
              component="h2"
              variant="h3"
              color={Colors.neutral.gray29}
              disableBottomPadding>
              {title}
            </Typography>
            <ButtonDynamicCalloutBannerStyled
              role="link"
              variant="tertiary"
              id={`${actionId}-button`}
              data-tracking-id={`${actionId}-button`}
              aria-label={assistiveText}
              onClick={() => {
                jsRedirect(url);
              }}>
              {buttonText || buttonTextDefault}
            </ButtonDynamicCalloutBannerStyled>
          </DivContentContainerStyled>
        </ColContentContainerStyled>
      </Row>
      {/* Clickable overlay for the banner variation */}
      <ButtonDynamicCalloutOverlayStyled
        role="link"
        id={actionId}
        data-tracking-id={actionId}
        aria-label={assistiveText}
        onClick={() => {
          jsRedirect(url);
        }}
      />
    </DivDynamicCalloutBannerStyled>
  );
};

DynamicCalloutComponent.propTypes = {
  actionId: PropTypes.string.isRequired,
  assistiveText: PropTypes.string,
  buttonText: PropTypes.string,
  disableLazyLoad: PropTypes.bool,
  flag: PropTypes.string,
  image: PropTypes.string.isRequired,
  /** Optional Duration prop (TODO: sync with video DurationStyled naming convention) */
  metadata: PropTypes.string,
  orientation: PropTypes.string,
  outGoingLink: PropTypes.bool,
  tag: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  visualLink: PropTypes.string,
};

DynamicCalloutComponent.defaultProps = { disableLazyLoad: false };

export default withTheme(DynamicCalloutComponent);
