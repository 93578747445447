import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchService from '@nmx/utils/dist/services/SearchService/Frontend';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import Session from '@nmx/utils/dist/utilities/frontend/session';
import EntityCard from './EntityCard';
import DefaultCard from './DefaultCard';
import resolveFrPhoto from '../../../assets/js/utils/resolveFrPhoto.util';
import CrcCard from '../DynamicAgentCard/CrcCard';
import SectionContainer from '../SectionContainer';

const AdvisorConnectComponent = ({
  id,
  agentObject,
  backgroundVariant,
  checkCrc,
  config,
  defaultCardProps,
  removeDefaultCardForLeads,
  disableLazyLoad,
}) => {
  const [isEnsemble, setIsEnsemble] = useState(false);
  // if agentObject is passed in, it takes precedence over everything
  const [agentData, setAgentData] = useState(agentObject);
  const [ensembleData, setEnsembleData] = useState();
  const [crcData, setCrcData] = useState({});
  const [isCrcDataValid, setIsCrcDataValid] = useState(false);

  const getStandardizedTitle = (agentObj) => {
    if (agentObj.type === 'MD') {
      return 'Managing Director';
    }

    return agentObj.title;
  };

  const transformAgentData = (agentObj) => ({
    title: getStandardizedTitle(agentObj),
    address: {
      street: agentObj.street,
      building: agentObj.building,
      city: agentObj.city,
      state: agentObj.state,
      zip: agentObj.zip,
      zipLastFour: agentObj.zipLastFour,
    },
    name: agentObj.fullName,
    phone: agentObj.selectedPhone1 && agentObj.selectedPhone1.Number,
    slug: `/financial/advisor/${agentObj.slug}/`,
    imgSrc: resolveFrPhoto(agentObj),
  });

  const transformEnsembleData = (ensembleObj) => ({
    address: {
      street: ensembleObj.street,
      building: ensembleObj.building,
      city: ensembleObj.city,
      state: ensembleObj.state,
      zip: ensembleObj.zip,
      zipLastFour: ensembleObj.zipLastFour,
    },
    name: ensembleObj.name,
    phone: ensembleObj.primaryPhone,
    slug: ensembleObj.url,
    imgSrc: ensembleObj.imageUrl || '/life-and-money/assets/images/ensemble-default.jpg',
  });
  const setCRCValues = () => {
    // Check for CRC value in query string
    const crcQueryParam = (Location.getQueryParam('crc') || '').substring(0, 4);
    if (crcQueryParam === 'true' || crcQueryParam === 'True') {
      const crc = true;
      const phone = Location.getQueryParam('no_phone');
      const city = Location.getQueryParam('no_city');

      setCrcData({
        crc,
        phone,
        city,
      });

      setIsCrcDataValid(crc && phone && city);
    }
  };

  const defaultCardNeedsToBeRemoved = () => {
    if (typeof localStorage !== 'undefined') {
      const localStorageVar = localStorage.getItem('leadConfirmedUser');
      const queryParam = (Location.getQueryParam('cta') || '').substring(0, 4);

      if (queryParam === 'none' || localStorageVar === 'true') {
        // If the user is a lead, set the localStorage var
        localStorage.setItem('leadConfirmedUser', true);

        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    // check special params to see if visitor is orphan
    setCRCValues();

    // if agentObject is passed in, it takes precedence over agentNumber from the Session
    let agentId = Session.get('agentNumber');

    if (agentId) {
      // prepend leading zero if only 5 digits (if greater than 6, and is leading zero, truncate the first zero)
      agentId = agentId.toString().padStart(6, '0');

      while ((agentId.length > 6) && (agentId.charAt(0) === '0')) {
        agentId = agentId.substring(1);
      }
    }

    // check for query params and ensure agentObject is undefined
    if (agentId && !agentObject) {
      SearchService.getTeams({
        agentNumber: agentId,
        isEnsemble: true,
      }).then((rsp) => {
        setIsEnsemble(rsp.data.length === 1);
        setEnsembleData(rsp.data[0]);
      });

      SearchService.getFrs({ agentNumber: agentId }).then((rsp) => {
        // rsp.data will be an [] if no data is returned
        setAgentData(rsp.data[0]);
      });
    }
  }, []);

  if (isEnsemble && ensembleData) {
    return (
      <SectionContainer
        cardType='ensemble'
        variant={backgroundVariant}
        moduleName="FR-card-fafa-module"
        moduleVariation="A">
        <EntityCard
          config={config}
          backgroundVariant={backgroundVariant}
          dataObject={transformEnsembleData(ensembleData)} />
      </SectionContainer>
    );
  }

  // // Crcs are orphans, they don't have an agent anymore. So show the city and office phone number instead...
  if (checkCrc && isCrcDataValid) {
    return <CrcCard city={crcData.city} phone={crcData.phone} />;
  }

  if (agentData) {
    return (
      <SectionContainer cardType='agent' variant={backgroundVariant} moduleName="FR-card-fafa-module" moduleVariation="A">
        <EntityCard
          config={config}
          backgroundVariant={backgroundVariant}
          dataObject={transformAgentData(agentData)} />
      </SectionContainer>
    );
  }

  // /*
  //   we're about to show the DefaultCard, but HOLLLLLL UP.
  //   We may need this entire section to disappear.
  //   If business wants us to hide this for visitors meets certain criteria
  // */
  if (removeDefaultCardForLeads && defaultCardNeedsToBeRemoved()) {
    return false;
  }

  return (
    <SectionContainer
      cardType='default'
      variant={backgroundVariant}
      id={id}
      moduleName='FR-card-fafa-module'
      moduleVariation='A'>
      <DefaultCard
        backgroundVariant={backgroundVariant}
        headline={defaultCardProps.headline}
        desktopWebpSrc={defaultCardProps.desktopWebpSrc.toString()}
        imageOnTop={defaultCardProps.imageOnTop}
        imageSrc={defaultCardProps.imageSrc.toString()}
        imageVariant={defaultCardProps.imageVariant}
        mobileImageSrc={defaultCardProps.mobileImageSrc.toString()}
        mobileWebpSrc={defaultCardProps.mobileWebpSrc.toString()}
        cta={defaultCardProps.cta}
        text={defaultCardProps.text}
        subText={defaultCardProps.subText}
        disableLazyLoad={disableLazyLoad}
      />
    </SectionContainer>
  );
};

AdvisorConnectComponent.propTypes = {
  id: PropTypes.string.isRequired,
  backgroundVariant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quarternary']),
  checkCrc: PropTypes.bool,
  defaultCardProps: PropTypes.shape({
    backgroundVariant: 'secondary',
    headline: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    desktopWebpSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    imageSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    imageVariant: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    mobileImageSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    mobileWebpSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    cta: PropTypes.shape({
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
      link: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
    }),
    subText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  removeDefaultCardForLeads: PropTypes.bool,
  agentObject: PropTypes.shape({
    id: PropTypes.string,
    agentNumber: PropTypes.string,
    fullName: PropTypes.string,
    type: PropTypes.string, // MP, MD, etc
    street: PropTypes.string,
    building: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    zipLastFour: PropTypes.string,
    designations: PropTypes.array,
    selectedPhone1: PropTypes.object,
    briefBio: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    photo: PropTypes.string,
    gender: PropTypes.string,
  }),
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  disableLazyLoad: PropTypes.bool,
};

AdvisorConnectComponent.defaultProps = {
  checkCrc: false,
  defaultCardProps: {},
  removeDefaultCardForLeads: false,
  disableLazyLoad: false,
};

export default AdvisorConnectComponent;
