import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../../base/Grid';
// template components
import SectionContainer from '../SectionContainer';
import Typography from '../../base/Typography';

import {
  ButtonStyled,
  ColContentStyled,
  ColDesktopStatCardContainer,
  DivMobileStatCardContainer,
  DivStatCardStyled,
  LinkStyled,
  PictureCardImageStyled,
  TypographyCardHeaderStyled,
  TypographyContentHeaderStyled,
} from './styles';

export const StatModuleComponent = ({
  cardHeader,
  cardHeaderAriaLabel,
  cardSubText,
  cardSubTextAriaLabel,
  disableBottomPadding,
  headline,
  image,
  imageAlt,
  location,
  subText,
  // Button props
  buttonAriaLabel,
  buttonId,
  buttonUrl,
  buttonText,
  // Link props
  linkAriaLabel,
  linkId,
  linkUrl,
  linkText,
}) => {
  const getStatCard = () => (
    <DivStatCardStyled>
      {image && (
        <PictureCardImageStyled
          lazyLoad={false}
          src={image}
          alt={imageAlt}
          fullWidth={true}
        />
      )}
      {cardHeader && (
        <TypographyCardHeaderStyled
          component="h3"
          aria-label={cardHeaderAriaLabel}>
          {cardHeader}
        </TypographyCardHeaderStyled>
      )}
      {cardSubText && (
        <Typography
          component="p"
          align="center"
          aria-label={cardSubTextAriaLabel || ''}
          disableBottomPadding>
          {cardSubText}
        </Typography>
      )}
    </DivStatCardStyled>
  );

  return (
    <SectionContainer
      id={`section-${location}-stat-module`}
      variant="tertiary"
      moduleName="stat-module"
      moduleVariation="A"
      disableBottomPadding={disableBottomPadding}>
      <Row align="left">
        <ColContentStyled
          xsmall={12}
          medium={6}
          large={8}>
          <TypographyContentHeaderStyled component="h2">
            {headline}
          </TypographyContentHeaderStyled>
          {subText}
          {/* ONLY DISPLAY FOR MOBILE */}
          <DivMobileStatCardContainer
            buttonText={buttonText}
            linkText={linkText}>
            {getStatCard()}
          </DivMobileStatCardContainer>
          {buttonText && (
            <ButtonStyled
              variant='secondary'
              role='link'
              ariaLabel={buttonAriaLabel}
              id={buttonId}
              onClick={() => {
                window.location = buttonUrl;
              }}>
              {buttonText}
            </ButtonStyled>
          )}
          {linkText && (
            <LinkStyled
              variant='secondary'
              id={linkId}
              ariaLabel={linkAriaLabel}
              href={linkUrl}>
              {linkText}
            </LinkStyled>
          )}
        </ColContentStyled>
        <ColDesktopStatCardContainer medium={6} large={4}>
          {/* ONLY DISPLAY FOR TABLET AND DESKTOP */}
          {getStatCard()}
        </ColDesktopStatCardContainer>
      </Row>
    </SectionContainer>
  );
};

StatModuleComponent.propTypes = {
  cardHeader: PropTypes.any,
  cardHeaderAriaLabel: PropTypes.any,
  cardSubtext: PropTypes.any,
  cardSubTextAriaLabel: PropTypes.any,
  disableBottomPadding: PropTypes.bool,
  headline: PropTypes.any.isRequired,
  image: PropTypes.any,
  imageAlt: PropTypes.any,
  location: PropTypes.string.isRequired,
  subText: PropTypes.any.isRequired,
  // button props
  buttonAriaLabel: PropTypes.any,
  buttonId: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonText: PropTypes.any,
  // Link props
  linkAriaLabel: PropTypes.any,
  linkId: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.any,
};

export default StatModuleComponent;
