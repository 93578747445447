/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Typography from '../Typography';

export const AssistiveText = styled(Typography)`
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  margin: 0;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  white-space: nowrap;
`;
