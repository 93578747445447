import React from 'react';

import GeneralDisclaimer from '../GeneralDisclaimer';
import { Row } from '../../../base/Grid';
import Typography from '../../../base/Typography';

import { DisclaimerSectionStyled, CustomDisclaimerColStyled } from './styles';

const DisclaimerSection = ({ isAmp, disclaimerFootnotes, customDisclaimers, disableDisclaimerTopBorder }) => {
  const CHARACTER_COUNT_THRESHOLD = 100;
  const combinedCustomFootnotes = [...disclaimerFootnotes || [], ...customDisclaimers || []];
  let combinedCustomFootnotesCharacterCount = 0;
  combinedCustomFootnotes.forEach((combinedCustomFootnote) => {
    combinedCustomFootnotesCharacterCount += String(combinedCustomFootnote.props.children).length;
  });

  return (
    <DisclaimerSectionStyled
      disableDisclaimerTopBorder={disableDisclaimerTopBorder} data-nosnippet
    >
      {(combinedCustomFootnotes && combinedCustomFootnotes.length > 0)
        ? <Row>
          <CustomDisclaimerColStyled disclaimerFootnotes={disclaimerFootnotes} customDisclaimers={customDisclaimers} isAmp={isAmp} combinedCustomFootnotesCharacterCount={combinedCustomFootnotesCharacterCount} characterCountThreshold={CHARACTER_COUNT_THRESHOLD} {... (combinedCustomFootnotesCharacterCount > CHARACTER_COUNT_THRESHOLD) ? { } : { medium: 6 }}>
            {combinedCustomFootnotes.map((combinedCustomFootnote, index) => (
              <Typography
                component='p'
                disableBottomPadding={true}
                key={`custom-disclaimer-footnote-${index}`}
              >
                <Typography component='small'>
                  {combinedCustomFootnote}
                </Typography>
              </Typography>
            ))}
          </CustomDisclaimerColStyled>
        </Row>
        : ('')}
      <Row align='center'>
        <GeneralDisclaimer disclaimerFootnotes={disclaimerFootnotes} customDisclaimers={customDisclaimers} isAmp={isAmp} />
      </Row>
    </DisclaimerSectionStyled>
  );
};

export default DisclaimerSection;
