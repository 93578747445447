import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Theme } from '../../components';

function standardizeTagName(tagName) {
  return tagName.trim().toLowerCase();
}
function mountToNode(node, component, withTheme) {
  const props = {};
  for (let index = 0; index < node.attributes.length; index += 1) {
    const attribute = node.attributes[index];
    props[attribute.name] = attribute.value;
  }
  if (Object.prototype.hasOwnProperty.call(props, 'container')) {
    throw new Error(
      `'container' cannot be use as an attribute for ${node.tagName}`,
    );
  }
  if (Object.prototype.hasOwnProperty.call(props, 'children')) {
    throw new Error(
      `'children' cannot be use as an attribute for ${node.tagName}`,
    );
  }
  props.container = node;
  props.children = Array.prototype.slice.call(node.childNodes, 0);
  if (withTheme) {
    ReactDOM.render(<ThemeProvider theme={Theme.render(typeof window !== 'undefined' ? window.innerWidth : 480, typeof window !== 'undefined' ? window.innerHeight : 900)}>{React.createElement(component, props)}</ThemeProvider>, node);
  } else {
    ReactDOM.render(React.createElement(component, props), node);
  }
}
export default (tagName = '', component = undefined, withTheme = false) => {
  Array.prototype.slice
    .call(document.querySelectorAll(standardizeTagName(tagName)), 0)
    .forEach((node) => {
      mountToNode(node, component, withTheme);
    });
};
