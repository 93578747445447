import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  AnchorDocumentLinkStyled,
  ColIconStyled,
  ColLinkStyled,
  ColPictureStyled,
  DivDocumentLinkStyled,
  DivImageContainerStyled,
  RowStyled,
  TypographyLinkTextStyled,
} from './styles';
import YoutubeIcon from '../../icons/social/Youtube';

export const DocumentLinkComponent = ({
  buttonAriaLabel,
  buttonId,
  children,
  className,
  hasIcon,
  iconVariant: RenderIconVariant,
  outGoingLink,
  picSrc,
  targetUrl,
}) => {
  const documentLinkText = useRef();
  const addHoverState = () => {
    documentLinkText.current.classList.add('hover-state-active');
  };
  const removeHoverState = () => {
    documentLinkText.current.classList.remove('hover-state-active');
  };

  return (
    <DivDocumentLinkStyled
      className={className}
      hasIcon={hasIcon}>
      <AnchorDocumentLinkStyled
        id={buttonId}
        href={targetUrl}
        ariaLabel={buttonAriaLabel}
        onMouseOver={addHoverState}
        onMouseOut={removeHoverState}
        onFocus={addHoverState}
        onBlur={removeHoverState}
        {...(outGoingLink && {
          target: '_blank',
          rel: 'nofollow noopener noreferrer',
        })}>
        <RowStyled>
          {hasIcon && (
            <ColIconStyled
              xsmall={4}
              large={3}>
              <RenderIconVariant />
            </ColIconStyled>
          )}
          {picSrc && (
            <ColPictureStyled xsmall={4}>
              <DivImageContainerStyled
                lazyLoad={false}
                variant='primary'
                bgImage={picSrc}>
              </DivImageContainerStyled>
            </ColPictureStyled>
          )}
          <ColLinkStyled xsmall={8}>
            <TypographyLinkTextStyled
              weight={500}
              ref={documentLinkText}>
              {children}
            </TypographyLinkTextStyled>
          </ColLinkStyled>
        </RowStyled>
      </AnchorDocumentLinkStyled>
    </DivDocumentLinkStyled>
  );
};

DocumentLinkComponent.propTypes = {
  buttonAriaLabel: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  /** optional additional className */
  className: PropTypes.string,
  hasIcon: PropTypes.bool,
  iconVariant: PropTypes.any,
  outGoingLink: PropTypes.bool,
  picSrc: PropTypes.any,
  targetUrl: PropTypes.string.isRequired,
};

DocumentLinkComponent.defaultProps = {
  iconVariant: YoutubeIcon,
  hasIcon: true,
};

export default DocumentLinkComponent;
