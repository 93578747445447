export default function (fr = {}) {
  const photoUrl = (fr.photo || '').toString().trim();

  if (/^https?:\/\//gi.test(photoUrl)) {
    return photoUrl;
  }

  const gender = (fr.gender || 'm').toString().trim().toLowerCase();
  if (['m', 'male'].indexOf(gender) !== -1) {
    return '/assets/images/dude.jpg';
  }
  return '/assets/images/dudette.jpg';
}
