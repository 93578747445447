import React from 'react';
import PropTypes from 'prop-types';
import { PhotoCol } from './styles';
import { Row, Col } from '../../../base/Grid';
import FrPicture from './FrPicture';
import Typography from '../../../base/Typography';
import Button from '../../../base/Button';

const DefaultCard = ({ defaultImage, defaultHeadline, defaultCta, defaultText, defaultSecondaryText }) => {
  const content = (
    <>
      <Typography component='h3' weight={200}>
        {defaultHeadline}
      </Typography>
      <Typography component='p' weight={200}>
        {defaultText}
      </Typography>
      {defaultSecondaryText
      && <Typography component='p' weight={500}>
        {defaultSecondaryText}
      </Typography>}
      <Button className='nmx-uppercase' onClick={() => { window.location.href = '/find-a-financial-advisor/'; } }>{defaultCta}</Button>
    </>
  );

  return defaultImage ? (
    <Row align="center">
      <PhotoCol xsmall={12} medium={3}>
        {/*
          I thought about moving the width 100% into the styled component itself,
          but left it out in case we have a scenario where we don't want the width to be 100%
        */}
        <FrPicture defaultImage={defaultImage} alt='picture of a financial representative' styles={{
          width: '100%',
          height: 'auto',
        }} />
      </PhotoCol>
      <Col xsmall={12} mediumOffset={1} medium={7}>
        {content}
      </Col>
    </Row>
  ) : (
    <Row styles={{ textAlign: 'center' }}>
      <Col xsmall={12} small={10} smallOffset={1} medium={8} mediumOffset={2}>
        {content}
      </Col>
    </Row>
  );
};

DefaultCard.propTypes = {
  defaultCta: PropTypes.string,
  defaultHeadline: PropTypes.string,
  defaultImage: PropTypes.string,
  defaultText: PropTypes.string,
  defaultSecondaryText: PropTypes.string,
};

DefaultCard.defaultProps = {
  defaultText: 'Our advisors are here to give you more of the information you want, and the knowledge you never knew you needed. To get to your next goal, and the next.',
  defaultCta: "Let's Talk",
  defaultHeadline: 'Take the next step.',

};

export default DefaultCard;
