/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import xhr from 'xhr';
import ProfileOutlineIcon from '../../../../icons/utility/ProfileOutline';

import { ButtonStyled, FwmButtonStyled, DivStyled, DivLogoutFramesStyled } from './styles';

export const NMLoginButtonComponent = (props) => {
  const { variant } = props;
  const isFwm = variant === 'fwm';

  const [iframesLoading, setIframesLoading] = useState(false);
  const [userLogoutUrls, setUserLogoutUrls] = useState(null);
  const loginButtonComponent = useRef();

  useEffect(() => {
    xhr({
      method: 'GET',
      uri: props.config.services.userLogoutUrls,
    }, (err, resp, body) => {
      if (typeof body !== 'undefined') {
        setUserLogoutUrls(JSON.parse(body));
      }
    });
  }, []);

  // If the user is logged into any other NM services, clicking the login button will log them out.
  const LogoutFrames = () => {
    if (userLogoutUrls) {
      const nmisLogout = userLogoutUrls.nmisLogoutUrl;
      // const urls = Object.values(this.state.userLogoutUrls.urls); // experimental feature Object.values unsupported in IE11
      const urls = Object.keys(userLogoutUrls.urls).map((item) => userLogoutUrls.urls[item]); // ie11 fix // TODO: revert after NM stops supporting IE11
      const randomizeUrl = `?${window.crypto.getRandomValues(new Uint32Array(1))}${window.crypto.getRandomValues(new Uint32Array(1))}`;
      const iframeTitle = 'Logout Window Content';
      urls.forEach((key, i) => {
        urls[i] += randomizeUrl;
      });

      return (
        <DivLogoutFramesStyled className='nmx-logout-iframe-container'>
          {urls.map((url) => (
            <iframe key={url} src={url} title={iframeTitle} />
          ))}
          <iframe src={nmisLogout} title={iframeTitle} />
        </DivLogoutFramesStyled>
      );
    }
    return false;
  };

  const openModal = () => {
    setIframesLoading(true);
    document.getElementById('nmx-client-login').classList.add('is-active');
    document.body.classList.add('modal-is-open');
    document.getElementById('nmx-client-login-username').focus(); // TODO: come back and reexamine which field gets focus (vs modal)

    // TODO: this login-button/login-component correlation can be streamlined...
    // all of the component logic should live in the same component as button
    // TODO: confirm Adobe tracking

    // Google analytics tracking
    window.gtag('event', 'send', {
      send_to: props.config.public.googleAnalyticsTrackingId,
      event_category: 'Log in Link',
      event_action: 'Click',
      event_label: 'Log in Link Click',
    });
  };

  return (
    <>
      {isFwm ? (
        <FwmButtonStyled id='nmx-login-open-button' onClick={openModal}>Client log in</FwmButtonStyled>
      ) : (
        <DivStyled className='nm-login-button' ref={loginButtonComponent}>
          <ButtonStyled className='nmx-login-open-button nmx-nav-link nmx-nav-link-utility' id='nmx-login-open-button' aria-label='Open login modal' onClick={openModal}>
            <ProfileOutlineIcon />Log in
          </ButtonStyled>
        </DivStyled>
      )}
      {iframesLoading && (
        <>{LogoutFrames()}</>
      )}
    </>
  );
};

NMLoginButtonComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    public: PropTypes.shape({ googleAnalyticsTrackingId: PropTypes.string }).isRequired,
    services: PropTypes.shape({ userLogoutUrls: PropTypes.string.isRequired }).isRequired,
  }),
};

NMLoginButtonComponent.defaultProps = {
  config: {
    public: { googleAnalyticsTrackingId: '<%=googleAnalyticsTrackingId%>' },
    services: { userLogoutUrls: '<%=userLogoutUrls%>' },
  },
};

export default NMLoginButtonComponent;
