import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../form/TextField';

export const PhoneInputComponent = React.forwardRef(({
  value,
  onBlur,
  onChange,
  onKeyUp,
  error,
  errorMessage,
  isPcg,
}, ref) => (
  <TextField
    id="in-page-lead-form-phone-input"
    label='Phone number'
    ref={ref}
    name='phone'
    value={value}
    type='tel'
    error={error}
    errorMessage={errorMessage}
    onChange={onChange}
    onBlur={onBlur}
    onKeyUp={onKeyUp}
    required={true}
    customTheme={'leads'}
    maxLength={14}
    isPcg={isPcg} />
));

PhoneInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
};

export default PhoneInputComponent;
