/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Col } from '../../../base/Grid';
import { getColorForName } from '../../../theme/colors';

const yellow = getColorForName('color-system-cta-yellow');

export const PhotoCol = styled(Col)`
  ${({ theme }) => css`
      && {
        padding-left: ${theme.mediumUp()
    ? 0
    : rem(16)};
        margin-bottom: ${theme.mediumUp()
    ? 0
    : rem(24)};
      }
    `}
`;

export const StyledImg = styled.img`
  ${({ theme, photoWidth }) => css`
      width: ${(theme.smallDown())
    ? '100%'
    : photoWidth
      ? `${photoWidth}%`
      : 'auto'};
    `}
`;

export const AgentCardWrapper = styled.div`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(32)};
`;

export const FormCol = styled(Col)`
${({ theme }) => css`
    ${theme.mediumUp()
    ? `
      padding-left: 2rem;
      border-left: 1px solid ${yellow};
      margin-top: 0;
    `
    : `
      margin-top: 2rem;
    `}
    `}
`;
