import React from 'react';
import PropTypes from 'prop-types';

// components
import AssistiveText from '../../base/AssistiveText';
// images
import ibd from './logos/ibd.png';
import ibdDarkTheme from './logos/ibd-dark-theme.png';
import nerdwallet from './logos/nerdwallet.png';
import nerdwalletDarkTheme from './logos/nerdwallet-dark-theme.png';
// data
// import AccoladesData from './accoladesData'; // TODO: build in accolades array of robust accolades/credential options

import {
  CredentialContainer,
  CredentialFooter,
  CredentialHeadline,
  DescriptionStyled,
} from './styles';

const getCredentialLogo = (credential) => {
  // TODO: would be nice if these logos were svg and deliverable by an Icon-like component
  if (credential === 'ibd') {
    return (
      <img alt='Investors Business Daily logo' className='nmx-credential-logo nmx-credential-logo__ibd' src={ibd} />
    );
  }
  if (credential === 'ibd-dark-theme') {
    return (
      <img alt='Investors Business Daily logo' className='nmx-credential-logo nmx-credential-logo__ibd' src={ibdDarkTheme} />
    );
  }
  if (credential === 'nerdwallet') {
    return (
      <img alt='NerdWallet logo' className='nmx-credential-logo' src={nerdwallet} />
    );
  }
  if (credential === 'nerdwallet-dark-theme') {
    return (
      <img alt='NerdWallet logo' className='nmx-credential-logo' src={nerdwalletDarkTheme} />
    );
  }
  return false;
};

export const CredentialCalloutComponent = ({
  className,
  credential,
  description,
  descriptionAriaLabel,
  footer,
  headline,
  headlineAriaLabel,
  headlineAssistiveSupplement,
  isCredentialCitable,
  sectionTheme,
}) => (
  <CredentialContainer
    className={className}
    as={isCredentialCitable ? 'blockquote' : 'div'}>
    <CredentialHeadline
      className='nmx-credential-callout-header'
      component='h3'
      weight={300}
      ariaLabel={headlineAriaLabel}
      disableBottomPadding
      sectionTheme={sectionTheme}>
      {headline}
      {headlineAssistiveSupplement
      && <AssistiveText text={headlineAssistiveSupplement} component='span' />
      }
    </CredentialHeadline>
    <DescriptionStyled
      {...descriptionAriaLabel && { ariaLabel: descriptionAriaLabel }}
      sectionTheme={sectionTheme} credential={credential}>
      {description}
    </DescriptionStyled>
    {credential && getCredentialLogo(credential)}
    {footer && (
      <CredentialFooter>
        {footer}
      </CredentialFooter>
    )}
  </CredentialContainer>
);

CredentialCalloutComponent.propTypes = {
  /** optional additional className */
  className: PropTypes.string,
  /** optional credential prop to toggle logo */ /** TODO: this Component could use some sustainable naming convention rework */
  credential: PropTypes.oneOf(['ibd', 'ibd-dark-theme', 'nerdwallet', 'nerdwallet-dark-theme']),
  /** required credential description */
  description: PropTypes.any.isRequired,
  /** optional description ariaLabel: used to properly add human legibility to description fragment */
  descriptionAriaLabel: PropTypes.any,
  /** blockquote footer */
  footer: PropTypes.any,
  /** required large-font credential text */
  headline: PropTypes.string.isRequired,
  /** required headlineAriaLabel, used to fulfill human-legibility for screen readers */
  headlineAriaLabel: PropTypes.string.isRequired,
  /** optional headlineAssistiveSupplement, used to fulfill hum-legibility for HTMl5 Outline */
  headlineAssistiveSupplement: PropTypes.string,
  /** required isCredentialCitable prop used for citable content */
  isCredentialCitable: PropTypes.bool.isRequired,
  /** required section theme, light/dark will determine content styling   */
  sectionTheme: PropTypes.oneOf(['light', 'dark']).isRequired,
};

CredentialCalloutComponent.defaultProps = { sectionTheme: 'light' };

export default CredentialCalloutComponent;
