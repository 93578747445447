import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from '../../../base/Grid';
import Typography from '../../../base/Typography';

import {
  PhotoColStyled,
  ContentColStyled,
  TypographyHeadlineStyled,
  DivButtonContainer,
  PictureFRPhotoStyled,
  ButtonLinkStyled,
} from './styles';

const DefaultCard = ({
  backgroundVariant,
  desktopWebpSrc,
  headline,
  imageSrc,
  imageVariant,
  mobileImageSrc,
  mobileWebpSrc,
  cta,
  text,
  subText,
  disableLazyLoad,
}) => {
  const contents = <>
    <TypographyHeadlineStyled component='h2'>
      {headline}
    </TypographyHeadlineStyled>
    {/* TODO: ensure defaultText can handle html being passed in or maybe a react component? */}
    <Typography component='p'>
      {text}
    </Typography>
    {subText && (
      <Typography component='p' weight={400}>
        {subText}
      </Typography>
    )}
    {cta
      && (
        <DivButtonContainer>
          <ButtonLinkStyled
            id="advisor-card-connect-button"
            variant={backgroundVariant === 'primary'
              ? 'buttonPrimary'
              : 'buttonSecondary'}
            href={cta.link}
            {...cta.ariaLabel && { 'aria-label': cta.ariaLabel }}>
            {cta.text}
          </ButtonLinkStyled>
        </DivButtonContainer>
      )}
  </>;

  return imageSrc
    ? (
      <Row align="center">
        <PhotoColStyled xsmall={12} medium={4}>
          <PictureFRPhotoStyled
            src={imageSrc}
            mobileSrc={mobileImageSrc}
            mobileWebpSrc={mobileWebpSrc}
            desktopWebpSrc={desktopWebpSrc}
            alt={'Picture of a financial representative'}
            lazyLoad={!disableLazyLoad}
            width="200"
            height="100%"
            variant={imageVariant}
          />
        </PhotoColStyled>
        <ContentColStyled xsmall={12} medium={8}>
          {contents}
        </ContentColStyled>
      </Row>
    )
    : (
      <Row styles={{ textAlign: 'center' }}>
        <Col xsmall={12} small={10} medium={8}>
          {contents}
        </Col>
      </Row>
    );
};

DefaultCard.propTypes = {
  backgroundVariant: PropTypes.string,
  desktopWebpSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  headline: PropTypes.string,
  imageOnTop: PropTypes.bool,
  imageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imageVariant: PropTypes.string,
  mobileImageSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  mobileWebpSrc: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  cta: PropTypes.shape({
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    link: PropTypes.string.isRequired,
  }),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disableLazyLoad: PropTypes.bool,
};

DefaultCard.defaultProps = {
  desktopWebpSrc: '/assets/images/frs/leo-tucker-desktop-v7.webp',
  headline: 'Take the next step.',
  imageSrc: '/assets/images/frs/leo-tucker-desktop-v7.jpg',
  mobileImageSrc: '/assets/images/frs/leo-tucker-mobile-v6.jpg',
  mobileWebpSrc: '/assets/images/frs/leo-tucker-mobile-v6.webp',
  text: 'Our advisors are here to give you more of the information you want, and the knowledge you never knew you needed. To get to your next goal, and the next.',
  disableLazyLoad: false,
};

export default DefaultCard;
