import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const DivProgressBarContainerStyled = styled.div`
  height:${rem(6)};
  overflow:hidden;
`;

export const ProgressStyled = styled.progress`
  ${({ bgColor, fillColor }) => css`
      width: 100%;
      background-color: ${bgColor};
      ::-webkit-progress-bar {
        background-color: ${bgColor};
      }
      ::-webkit-progress-value {
        background-color: ${fillColor};
        transition: all 1s;
      }
  `}
`;

