import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Container } from '../../base/Grid';
import { GeneralSection } from './styles';

export const SectionContainerComponent = React.forwardRef(
  ({
    children,
    className,
    disableBottomPadding,
    disableTopPadding,
    id,
    moduleName,
    moduleVariation,
    variant,
  }, ref) => {
    const classNames = {};
    classNames[className] = className;
    const compiledId = `${id}-section-container`;

    return (
      <GeneralSection
        id={compiledId}
        variant={variant}
        disableBottomPadding={disableBottomPadding}
        disableTopPadding={disableTopPadding}
        className={classnames(classNames, className)}
        data-module={`${compiledId}:${moduleName}:${moduleVariation}`}
        ref={ref}>
        <Container>
          {children}
        </Container>
      </GeneralSection>
    );
  },
);

SectionContainerComponent.propTypes = {
  /** Children components placed inside the section */
  children: PropTypes.any.isRequired,
  /** Custom class name to add to Type. */
  className: PropTypes.string,
  /** Disable the default bottom padding for section */
  disableBottomPadding: PropTypes.bool,
  /** Disable the default top padding for section */
  disableTopPadding: PropTypes.bool,
  /** Id for the section */
  id: PropTypes.string.isRequired,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** optional moduleVariation, TODO: is this being used? */
  moduleVariation: PropTypes.string,
  /** optional section theme  */
  // sectionTheme: PropTypes.oneOf(['light', 'dark']),
  /** optional section light theme background color */
  // sectionLightThemeBackgroundColor: PropTypes.oneOf(['light1', 'light2', 'light3']),
  /**  Variant determines the look and feel of the Section */
  variant: PropTypes.string.isRequired,
  // variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
};

SectionContainerComponent.defaultProps = {
  className: null,
  disableBottomPadding: false,
  disableTopPadding: false,
  moduleName: 'unknown',
  moduleVariation: 'A',
  variant: 'primary',
};

export default SectionContainerComponent;
