import React, {
  useEffect,
  useState,
  useRef,
  // Fragment,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

import Session from '@nmx/utils/dist/utilities/frontend/session';
import { withTheme } from 'styled-components';
import debounce from 'lodash/debounce';
import { Row, Col } from '../../base/Grid';
import Button from '../../base/Button';
import AlertStrokeIcon from '../../icons/utility/AlertStroke';
import MoreInfoStrokeIcon from '../../icons/utility/MoreInfoStroke';
import theme from '../../theme';
import DynamicConfig from '../../utils/dynamicConfig';

// styles
import {
  InformationalBanner,
  ContainerDefault,
  DismissButton,
  // DataSeparator,
  DataTextContainer,
  StartAdornment,
  LinkStyled,
  DrawerCaretFlippedIconStyled,
  DrawerCaretIconStyled,
  ButtonStyled,
  Styles,
} from './styles'; // TODO: move styles to Component

const getAdornmentIcon = (icon) => {
  if (icon === 'alert-stroke') {
    return <AlertStrokeIcon
      className="nmx-icon-start-adornment"
      width="18px"
      height="18px"
      style={{ verticalAlign: 'middle' }}
    />;
  } if (icon === 'more-info-stroke') {
    return <MoreInfoStrokeIcon
      className="nmx-icon-start-adornment"
      width="18px"
      height="18px"
      style={{ verticalAlign: 'middle' }}
    />;
  }
  return false;
};

export const BannerModule = ({
  'data-banner-id': bannerId = 'nmx-informational-banner',
  'data-app-name': appName,
  'data-dynamic-config-base-url': dynamicConfigBaseUrl,
  'data-static-config': staticConfig,
}) => {
  const configName
    = appName !== 'nmx-static'
    && appName !== 'nmx-static-fa-profile-pages'
    && appName !== 'nmx-static-articles'
    && appName !== 'coverage-calc'
    && appName !== 'di-calculator'
    && appName !== 'gelateria-pages'
    && appName !== 'retirement-calculator'
      ? 'default'
      : appName;

  // ref is used because event listeners are scoping out the value
  // the resize event calls handleBannerDisplay which would show an empty bannerConfig if this was a state instead of a ref
  const bannerConfig = useRef();
  const bannerEl = useRef(null);
  const dataTextContainerRef = useRef(null);

  const [bannerBackgroundColor, setBannerBackgroundColor] = useState(false); // sets banner background color
  const [bannerDismissed, setBannerDismissed] = useState(false); // when banner is dismissed trigger function to resize stuff
  const [desktopActiveWidow, setDesktopActiveWidow] = useState(false);
  const [drawerStatus, setDrawerStatus] = useState(false);
  const [retrievedBannerConfig, setRetrievedBannerConfig] = useState(false); // triggers render to show banner

  // when the banner is initially rendered, the bannerEl ref shows the wrong height.
  // when we set this to true it re renders the component and seems to fix that issue. probably a better way to do this.
  const [initialBannerRender, setInitialBannerRender] = useState(false);

  const styles = Styles();

  // constants
  const DATA_TEXT_CONTAINER_REF_OUTER_PADDING_THRESHOLD = 90;
  const DEFAULT_BG_COLOR = '#F7FAFC';
  const DEFAULT_HIDE_DISMISS_BUTTON = false;
  const DISMISSED_SESSION_KEY = `${configName}-dismiss-banner`;
  const LISTENER_THROTTLE = 150;
  const SECONDARY_BG_COLOR = theme.colors.accent.lightBlue2;

  const mmCheckMedium = () => typeof document !== 'undefined'
    && window.matchMedia('(min-width: 768px)').matches;
  const mmCheckLarge = () => typeof document !== 'undefined'
    && window.matchMedia('(min-width: 1024px)').matches;

  function bannerIsVisible() {
    return (
      bannerEl.current
      && bannerConfig.current
      && bannerConfig.current.isActive === true
      && !bannerDismissed
    );
  }

  const isDesktopWidow = () => {
    const ctaButton = typeof document !== 'undefined' ? document.querySelector('#nmx-informational-banner-cta-button') : {};
    // don't ref dataTextContainerRef on ie11 text version // devs will see "Cannot read property 'clientWidth' of null" error locally, fyi
    if (
      typeof document !== 'undefined'
      && window.navigator.userAgent.match(/MSIE|Trident/) === null
    ) {
      // if cta-button
      if (bannerConfig.current.variant === 'cta-button') {
        setDesktopActiveWidow(
          dataTextContainerRef.current.offsetWidth
            > window.innerWidth
              - ctaButton.offsetWidth
              - DATA_TEXT_CONTAINER_REF_OUTER_PADDING_THRESHOLD,
        );
      } else {
        setDesktopActiveWidow(dataTextContainerRef.current.offsetWidth < window.innerWidth - DATA_TEXT_CONTAINER_REF_OUTER_PADDING_THRESHOLD);
      }
    }
  };

  function handleBannerDisplay() {
    const headerLogoContainer
      = typeof document !== 'undefined'
        ? document.getElementById('header-logo-heading')
        : {}; // TODO: none of this is really working because we're still using the vars below
    const mobileTriggerOpen
      = typeof document !== 'undefined'
        ? document.getElementById('nav-toggle')
        : {}; // TODO: none of this is really working because we're still using the vars below
    const nmLoginButton
      = typeof document !== 'undefined'
        ? document.querySelector('.nm-login-button')
        : {}; // TODO: none of this is really working because we're still using the vars below
    const mobileNav
      = typeof document !== 'undefined'
        ? document.getElementById('nmx-mobile-nav-container')
        : {}; // TODO: none of this is really working because we're still using the vars below
    const headerContainer
      = typeof document !== 'undefined'
        ? document.getElementById('nmx-header')
        : {}; // TODO: none of this is really working because we're still using the vars below
    const headerBreadcrumbsNavContainer
      = typeof document !== 'undefined'
        ? document.getElementById('nmx-breadcrumbs-container')
        : {}; // TODO: none of this is really working because we're still using the vars below
    // init breadcrumbsNavContainerHeight
    let headerBreadcrumbsNavContainerHeight = 0;

    if (bannerIsVisible() === true) {
      if (mmCheckLarge()) {
        // desktop styles // reset
        if (headerLogoContainer !== null) { // TODO: better way to handle this?
          headerLogoContainer.style.top = 0;
        }
        if (mobileNav !== null) { // TODO: better way to handle this?
          mobileNav.style.top = 0;
        }
        if (nmLoginButton !== null) { // TODO: better way to handle this?
          nmLoginButton.style.top = null; // remove inline style
        }
        if (mobileTriggerOpen !== null) { // TODO: better way to handle this?
          mobileTriggerOpen.style.top = null; // remove inline style
        }
        if (headerBreadcrumbsNavContainer !== null) { // TODO: better way to handle this?
          headerContainer.style.height = `${115 + 53 + bannerEl.current.clientHeight}px`; // desktop nav + breadcrumbs height + infoBanner height // TODO: convert to rems
          headerBreadcrumbsNavContainer.style.top = `${headerContainer.clientHeight - 53}px`; // TODO: convert to rems
        } else {
          headerContainer.style.height = `${115 + bannerEl.current.clientHeight}px`; // desktop nav + infoBanner height // TODO: convert to rems
        }
        if (!initialBannerRender) {
          isDesktopWidow();
        }
      } else {
        // mobile styles // adjust to height of informational banner
        if (nmLoginButton !== null) { // TODO: better way to handle this?
          nmLoginButton.style.top = `${bannerEl.current.clientHeight}px`; // TODO: convert to rems
        }
        if (mobileTriggerOpen !== null) { // TODO: better way to handle this?
          mobileTriggerOpen.style.top = `${bannerEl.current.clientHeight}px`;
        }
        if (headerContainer.classList.contains('is-logo-only')) {
          // TODO: logo-only infoBanner // TODO: logo-only breadcrumbsNav
          mobileNav.style.top = `${bannerEl.current.clientHeight}px`; // banner height
          headerContainer.style.height = `${bannerEl.current.clientHeight}px`; // banner height
        } else {
          mobileNav.style.top = `${headerLogoContainer.clientHeight + bannerEl.current.clientHeight}px`; // height of mobile logo container + banner height // TODO: convert to rems
          // if (initialBannerRender) {
          headerLogoContainer.style.top = `${bannerEl.current.clientHeight}px`; // TODO: convert to rems
          // }
          // TODO: consolidate below into reusable function
          if (headerBreadcrumbsNavContainer !== null) { // TODO: better way to handle this?
            headerBreadcrumbsNavContainerHeight = mmCheckMedium() ? 53 : 40;
          }
          headerContainer.style.height = `${50 + headerBreadcrumbsNavContainerHeight + bannerEl.current.clientHeight}px`; // mobile header + breadcrumbs height + infoBanner height // TODO: convert to rems
          if (headerBreadcrumbsNavContainer !== null) {
            headerBreadcrumbsNavContainer.style.top = `${headerContainer.clientHeight - headerBreadcrumbsNavContainerHeight}px`; // TODO: convert to rems
          }
        }
        // NOTE: only other moving piece not handled here for banner sizing, is #header-logo-heading (headerLogoContainer), handled in nav.header.js
      }
    } else {
      // remove any immediate RE:banner styles
      if (headerLogoContainer !== null) { // TODO: better way to handle this?
        headerLogoContainer.style.top = null;
      }
      if (mobileNav) { // TODO: better way to handle this?
        mobileNav.style.top = null;
      }
      if (mobileTriggerOpen !== null) { // TODO: better way to handle this?
        mobileTriggerOpen.style.top = null;
      }
      if (nmLoginButton !== null) { // TODO: better way to handle this?
        nmLoginButton.style.top = null;
      }
      // if breadcrumbsNav exists, set height
      // TODO: consolidate below into reusable function
      if (headerBreadcrumbsNavContainer) {
        headerBreadcrumbsNavContainerHeight = mmCheckMedium() ? 53 : 40;
      }
      headerContainer.style.height = mmCheckLarge()
        ? (`${115 + headerBreadcrumbsNavContainerHeight}px`) // desktop nav height + breadcrumbs height // TODO: convert to rems
        : (`${50 + headerBreadcrumbsNavContainerHeight}px`); // '3.125rem' = 50px mobile menu height // TODO: convert to rems
      // set breadcrumbsNav position
      if (headerBreadcrumbsNavContainer) {
        headerBreadcrumbsNavContainer.style.top = `${headerContainer.clientHeight - headerBreadcrumbsNavContainerHeight}px`; // TODO: convert to rems
      }
    }
  }

  function setInfoBannerBackgroundStyle(bgStyle) {
    if (bgStyle) {
      // sets primary/secondary background color style // TODO: use elaborate ternary statement here?
      if (bgStyle === 'default') {
        setBannerBackgroundColor(DEFAULT_BG_COLOR);
      } else if (bgStyle === 'secondary') {
        setBannerBackgroundColor(SECONDARY_BG_COLOR);
      } else {
        setBannerBackgroundColor(DEFAULT_BG_COLOR);
      }
    } else {
      setBannerBackgroundColor(DEFAULT_BG_COLOR);
    }
  }

  // do this on mount
  useEffect(() => {
    const headerContainer
      = typeof document !== 'undefined'
        ? document.getElementById('nmx-header')
        : {};
    // used by storybook
    if (staticConfig) {
      bannerConfig.current = staticConfig.informationalBanner;
      setRetrievedBannerConfig(true); // TODO: consolidate
      if (Session.get(DISMISSED_SESSION_KEY) !== undefined) {
        setBannerDismissed(Session.get(DISMISSED_SESSION_KEY));
      }
      setInfoBannerBackgroundStyle(bannerConfig.current.backgroundStyle);
      headerContainer.classList.add('nmx-informational-banner-active');
      return;
    }

    // wrap dynamicConfig in (non) ie11 check
    if (
      typeof document !== 'undefined'
      && window.navigator.userAgent.match(/MSIE|Trident/) === null
    ) {
      // pulled in from dynamic config
      const dynamicConfigUrl = `${dynamicConfigBaseUrl}/${configName}.json`;
      const dynamicConfigSessionKey = `${configName}-dynamic-config`;
      DynamicConfig(dynamicConfigUrl, dynamicConfigSessionKey).then(
        (dynamicConfig) => {
          bannerConfig.current = dynamicConfig.banner; // TODO: informationalBanner
          if (Session.get(DISMISSED_SESSION_KEY) !== undefined) {
            setBannerDismissed(Session.get(DISMISSED_SESSION_KEY));
          }
          setRetrievedBannerConfig(true);
          setInfoBannerBackgroundStyle(bannerConfig.current.backgroundStyle);
        },
      );
      headerContainer.classList.add('nmx-informational-banner-active');
    }
  }, []);

  // do this when bannerDismissed is modified
  useEffect(() => {
    // listen to changes in bannerConfig
    handleBannerDisplay();
  }, [bannerDismissed]);

  // do this on mount and unmount
  // having event listeners causes all kinds of fun stuff which is why bannerConfig uses the useRef hook instead of useState
  // https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // TODO: combine onMount hooks?
    if (typeof document !== 'undefined') {
      // add resize listener on Mount
      window.addEventListener(
        'resize',
        debounce(handleBannerDisplay, LISTENER_THROTTLE),
      );
      return () => {
        // remove resize listener on Unmount
        window.removeEventListener(
          'resize',
          debounce(handleBannerDisplay, LISTENER_THROTTLE),
        );
      };
    }
  }, []);

  // do this on every render (mobile)
  useEffect(() => {
    // when element is fully rendered
    if (bannerIsVisible() && !mmCheckLarge()) {
      handleBannerDisplay();
    }
  });

  const toggleDrawer = () => {
    // ensure mobile header elements are where they're supposed to be
    // add a 50ms delay to this re-render
    // TODO: figure out a better way to handle drawer open re-position, for some reason, height is not exact and needs to be force-updated
    setDrawerStatus(!drawerStatus);
    setTimeout(handleBannerDisplay, 50);
  };

  const handleCtaClick = () => {
    // TODO: probably can consolidate these with some config adjustments // TODO: build into Link Component?
    if (
      typeof document !== 'undefined'
      && bannerConfig.current.data.ctaUrlNewWindow
    ) {
      setTimeout(() => {
        window.open(bannerConfig.current.data.buttonCtaUrl, '_blank');
      }, 100);
    } else {
      window.location = bannerConfig.current.data.buttonCtaUrl;
    }
  };

  // TODO: creative wishlist item to animate banner dismiss (scroll up out of view) // potentially easier to do on desktop only
  function dismissBanner() {
    const headerContainer
      = typeof document !== 'undefined'
        ? document.getElementById('nmx-header')
        : {}; // TODO: none of this is really working because we're still using the vars below
    const headerBreadcrumbsNavContainer
      = typeof document !== 'undefined'
        ? document.getElementById('nmx-breadcrumbs-container')
        : {}; // TODO: none of this is really working because we're still using the vars below
    setBannerDismissed(true);
    Session.set(DISMISSED_SESSION_KEY, true);
    headerContainer.classList.remove('nmx-informational-banner-active');
    // if breadcrumbsNav exists, set height
    // TODO: consolidate below into reusable function
    let headerBreadcrumbsNavContainerHeight = 0;
    if (headerBreadcrumbsNavContainer) {
      headerBreadcrumbsNavContainerHeight = mmCheckMedium() ? 53 : 40;
    }
    headerContainer.style.height = mmCheckLarge()
      ? (`${115 + headerBreadcrumbsNavContainerHeight}px`) // desktop nav height + breadcrumbs height // TODO: convert to rems
      : (`${50 + headerBreadcrumbsNavContainerHeight}px`); // '3.125rem' = 50px mobile menu height // TODO: convert to rems
    // set breadcrumbsNav position
    if (headerBreadcrumbsNavContainer) {
      headerBreadcrumbsNavContainer.style.top = `${headerContainer.clientHeight - headerBreadcrumbsNavContainerHeight}px`; // TODO: convert to rems
    }
  }

  // TODO: has to be a better way to do this
  // doing this because for some reason the height of the bannerEl ref is not correct on the initial load for mobile
  // forcing a re render in this seems to fix the height issue on the initial load for mobile
  const onBannerRefChange = useCallback((node) => {
    setInitialBannerRender(true); // trigger re render to get the correct height of the banner
    bannerEl.current = node;
    handleBannerDisplay(); // set initial rendering
  }, []);

  const onDataTextContainerRefChange = useCallback((node) => {
    dataTextContainerRef.current = node;
    // init set desktopWidow
    if (bannerIsVisible() === true) {
      isDesktopWidow();
    }
  }, []);

  if (
    typeof document !== 'undefined'
    && window.navigator.userAgent.match(/MSIE|Trident/) !== null
  ) {
    //  hard-coded ie11 banner, converted some styled components to inline styles where Styled Components specificity was failing in ie11
    return (
      <InformationalBanner
        id={'nmx-informational-banner'}
        className={'nmx-no-print nmx-informational-banner nmx-informational-banner__text'}
        style={{ backgroundColor: DEFAULT_BG_COLOR }}>
        <div
          className="nmx-container"
          style={{
            maxWidth: '90rem',
            position: 'relative',
            margin: '0 auto',
            background: 'transparent',
          }}>
          <Row>
            <Col nested>
              <Row>
                <Col
                  style={{
                    padding: '0.875rem 2.5rem 0.875rem 3.125rem',
                    textAlign: 'left',
                  }}>
                  <p
                    style={{
                      display: 'inline-block',
                      width: 'auto',
                      fontWeight: 300,
                      fontSize: '1rem',
                      lineHeight: '1.5rem',
                      color: theme.colors.primary.blue,
                    }}>
                    <StartAdornment
                      variant="text"
                      style={{
                        position: 'absolute',
                        marginLeft: '1rem',
                      }}>
                      <AlertStrokeIcon
                        className="nmx-icon-start-adornment"
                        width="18px"
                        height="18px"
                        style={{ verticalAlign: 'middle' }}
                      />
                    </StartAdornment>
                    We noticed you are using Internet Explorer. This browser is
                    no longer supported. For the best experience possible,
                    please use Edge, Chrome, Firefox or Safari.
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        {/* intentionally hide dismissButton for ie11 banner */}
        {/* because we want to persist, and also, the default React functionality not working in ie11 anyway  */}
        {/* <DismissButton onClick={dismissBanner} aria-label="Dismiss Banner" className='nmx-informational-banner__dismiss-button' /> */}
      </InformationalBanner>
    );
  }
  return (
    retrievedBannerConfig
    && bannerConfig.current.isActive === true
    && !bannerDismissed && (
      <InformationalBanner
        id={'nmx-informational-banner'}
        className={`nmx-no-print ${bannerId} ${bannerId}__${
          bannerConfig.current.variant
        }${drawerStatus
          ? ' drawer-is-open'
          : ''}${
          bannerConfig.current.backgroundStyle === 'secondary'
            ? ' secondary-bg'
            : ''
        }`}
        style={{ backgroundColor: bannerBackgroundColor }}
        ref={onBannerRefChange}>
        <ContainerDefault>
          {bannerConfig.current.variant === 'text' && (
            <Row>
              <Col nested>
                <Row>
                  <Col
                    styles={styles.ColInner(
                      bannerConfig.current.startAdornment,
                      bannerConfig.current.hideDismissButton
                        || DEFAULT_HIDE_DISMISS_BUTTON,
                      'text',
                      desktopActiveWidow,
                    )}>
                    <DataTextContainer
                      variant="text"
                      ref={onDataTextContainerRefChange}
                      activeWidow={desktopActiveWidow}>
                      {bannerConfig.current.startAdornment
                        ? (
                          <StartAdornment
                            variant="text"
                            activeWidow={desktopActiveWidow}>
                            {getAdornmentIcon(bannerConfig.current.startAdornment)}
                          </StartAdornment>
                        )
                        : null}
                      {bannerConfig.current.data.text}
                    </DataTextContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {bannerConfig.current.variant === 'text-link' && (
            <Row>
              <Col nested>
                <Row>
                  <Col
                    styles={styles.ColInner(
                      bannerConfig.current.startAdornment,
                      bannerConfig.current.hideDismissButton
                        || DEFAULT_HIDE_DISMISS_BUTTON,
                      'text-link',
                      desktopActiveWidow,
                    )}>
                    <DataTextContainer
                      variant="text-link"
                      ref={onDataTextContainerRefChange}
                      activeWidow={desktopActiveWidow}>
                      {bannerConfig.current.startAdornment
                        ? (
                          <StartAdornment
                            variant="text-link"
                            activeWidow={desktopActiveWidow}>
                            {getAdornmentIcon(bannerConfig.current.startAdornment)}
                          </StartAdornment>
                        )
                        : null}
                      <LinkStyled
                        id={`${bannerId}-link`}
                        variant="secondary"
                        href={bannerConfig.current.data.ctaUrl}
                        aria-label={bannerConfig.current.data.ctaAriaLabel}
                        {...(bannerConfig.current.data.ctaUrlNewWindow && { target: '_blank' })}>
                        {bannerConfig.current.data.text}
                      </LinkStyled>
                    </DataTextContainer>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {bannerConfig.current.variant === 'cta-button' && (
            <Row>
              <Col nested>
                <Row>
                  <Col
                    className="nmx-align-center"
                    styles={styles.ColInner(
                      bannerConfig.current.startAdornment,
                      bannerConfig.current.hideDismissButton
                        || DEFAULT_HIDE_DISMISS_BUTTON,
                      'cta-button',
                      desktopActiveWidow,
                    )}>
                    <DataTextContainer
                      variant="cta-button"
                      ref={onDataTextContainerRefChange}
                      activeWidow={desktopActiveWidow}>
                      {bannerConfig.current.startAdornment
                        ? (
                          <StartAdornment
                            variant="cta-button"
                            activeWidow={desktopActiveWidow}>
                            {getAdornmentIcon(bannerConfig.current.startAdornment)}
                          </StartAdornment>
                        )
                        : null}
                      {/* TODO: get this working again, separate */}
                      {/* {bannerConfig.current.data.text.length > 0
                        && bannerConfig.current.data.text.split('').map((item, index) => (
                          <Fragment key={`${item}${index}`}>
                            {item}
                            {index < bannerConfig.current.data.text.length - 1
                            && (<DataSeparator>&#124;</DataSeparator>)}
                          </Fragment>
                        ))} */}
                      {bannerConfig.current.data.text}
                    </DataTextContainer>
                    <ButtonStyled
                      id={`${bannerId}-cta-button`}
                      aria-label={bannerConfig.current.data.ctaAriaLabel}
                      variant="tertiary"
                      onClick={handleCtaClick}
                      styles={styles.CtaButton(
                        'cta-button',
                        bannerConfig.current.startAdornment,
                      )}
                      buttonVariant={bannerConfig.current.variant}>
                      {bannerConfig.current.data.buttonCtaText}
                    </ButtonStyled>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {bannerConfig.current.variant === 'drawer' && (
            <Row>
              <Col nested>
                <Row>
                  <Col
                    styles={styles.ColInner(
                      bannerConfig.current.startAdornment,
                      bannerConfig.current.hideDismissButton
                        || DEFAULT_HIDE_DISMISS_BUTTON,
                      'drawer',
                      desktopActiveWidow,
                    )}>
                    <DataTextContainer
                      variant="drawer"
                      ref={onDataTextContainerRefChange}
                      activeWidow={desktopActiveWidow}>
                      {bannerConfig.current.startAdornment
                        ? (
                          <StartAdornment
                            variant="drawer"
                            activeWidow={desktopActiveWidow}>
                            {getAdornmentIcon(bannerConfig.current.startAdornment)}
                          </StartAdornment>
                        )
                        : null}
                      <Button
                        id={`${bannerId}-cta-expand-button`}
                        aria-label={bannerConfig.current.data.ctaAriaLabel}
                        variant="quarternary"
                        onClick={toggleDrawer}
                        styles={styles.CtaButton('quarternary')}>
                        {/* TODO: add DOM Parser */}
                        {bannerConfig.current.data.ctaText}
                        {
                          drawerStatus
                            ? <DrawerCaretFlippedIconStyled />
                            : <DrawerCaretIconStyled />
                        }
                      </Button>
                    </DataTextContainer>
                  </Col>
                </Row>
                {drawerStatus && (
                  <Row>
                    <Col
                      styles={styles.ColDrawer()}
                      className="nmx-informational-banner-drawer__drawer-contents">
                      <DataTextContainer variant="drawer-contents">
                        {bannerConfig.current.data.drawerContents.text}{' '}
                        {bannerConfig.current.data.drawerContents.linkUrl
                          && <LinkStyled
                            variant="secondary" // TODO: update for 3.x.x
                            href={
                              bannerConfig.current.data.drawerContents.linkUrl // TODO: update for 3.x.x
                            }
                            {...(bannerConfig.current.data.drawerContents
                              .ctaUrlNewWindow && { target: '_blank' })}
                            linkSection="drawer">
                            {bannerConfig.current.data.drawerContents.linkText}
                          </LinkStyled>
                        }
                      </DataTextContainer>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
        </ContainerDefault>
        {!bannerConfig.current.hideDismissButton && (
          <DismissButton
            onClick={dismissBanner}
            aria-label="Dismiss Banner"
            className="nmx-informational-banner__dismiss-button"
            drawerIsOpen={drawerStatus}
          />
        )}
      </InformationalBanner>
    )
  );
};

BannerModule.propTypes = {
  'data-banner-id': PropTypes.string,
  'data-static-config': PropTypes.object,
  'data-app-name': PropTypes.string,
  'data-dynamic-config-base-url': PropTypes.string,
};

export default withTheme(BannerModule);
