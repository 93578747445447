import React from 'react';
import PropTypes from 'prop-types';
// styles
import { TextFieldStyled } from '../../styles';

export const FirstNameInputComponent = React.forwardRef(({
  value,
  onChange,
  onBlur,
  onClick,
  error,
  errorMessage,
  placeholder,
}, ref) => (
  <TextFieldStyled
    id='suspect-email-capture-form-first-name-input'
    label='First name'
    ref={ref}
    name='firstName'
    value={value}
    error={error}
    errorMessage={errorMessage}
    onChange={onChange}
    onBlur={onBlur}
    onClick={onClick}
    required={true}
    maxLength={100}
    placeholder={placeholder} />
));

FirstNameInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default FirstNameInputComponent;
