import { rem } from 'polished';
import styled, { css } from 'styled-components';
// template components
import Button from '../../base/Button';
import { Col, Row } from '../../base/Grid';
import Form from '../../form/Form';
import SectionContainer from '../../modules/SectionContainer';
import TextField from '../../form/TextField';
import Typography from '../../base/Typography';

export const ButtonStyled = styled(Button)`
  ${({ theme }) => {
    const xlargeDown = theme.xlargeDown();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const between768To1024 = theme.useMediaQuery(
      theme.breakpoints.between(768, 1440),
      { defaultMatches: (theme.pageLoadWindowWidth >= 768 && theme.pageLoadWindowWidth < 1440) },
    );

    return css`
      display: block;
      ${mediumUp && 'min-height: unset;'}
      ${mediumUp && `height: ${rem(40)};`}
      ${mediumUp && `line-height: ${rem(2)};`}
      margin-top: ${largeUp ? rem(24) : 0};
      ${!between768To1024 && 'min-width: unset;'};
      ${!between768To1024 && 'max-width: unset;'};
      ${!between768To1024 && 'width: 100%;'};
      ${xlargeDown && 'margin-left: auto;'};
      ${xlargeDown && 'margin-right: auto;'};
    `;
  }
}`;

// White box inner col
export const ColContainerStyled = styled(Col)`
  padding-right: 0;
  padding-left: 0;
`;

// Form container
export const ColInputContainerStyled = styled(Col)`
  ${({ theme, disableBottomPadding }) => {
    const largeUp = theme.largeUp();
    const smallUp = theme.smallUp();
    let verticalPadding = largeUp ? 0 : rem(20);
    const horizontalPadding = smallUp ? rem(4) : 0;

    if (disableBottomPadding) {
      verticalPadding = 0;
    }

    return css`
      padding: 0 ${horizontalPadding} ${verticalPadding};
    `;
  }
}`;

// White box
export const DivFormContainerStyled = styled.div`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const paddingTop = mediumUp ? rem(60) : rem(50);
    const paddingBottom = mediumUp ? rem(36) : rem(32);
    return css`
      background-color: ${theme.colors.neutral.white};
      padding-top: ${paddingTop};
      padding-bottom: ${paddingBottom};
      height: auto;
      position: relative;
    `;
  }
}`;

// Circle icon container
export const DivIconContainerStyled = styled.div`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    let size = rem(60);

    if (mediumUp) {
      size = rem(70);
    }

    return css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: ${size};
      width: ${size};
      z-index: 0;
      .nmx-icon {
        height: 100%;
        width: 100%;
        margin-top: -24%;
      }
    `;
  }
}`;

// PATTERN STYLES /////////////////
// controls the opacity of both left and right dot pattern svgs
const patternOpacity = 0.25;

export const DivLeftPatternContainerStyled = styled.div`
  ${({ theme }) => {
    const xxlargeUp = theme.xxlargeUp();
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    let minHeight = rem(320); // xsmall default
    let patternOffsetDesktop = rem(-550); // xsmall default

    if (xxlargeUp) {
      patternOffsetDesktop = 0;
    } else if (xlargeUp) {
      patternOffsetDesktop = rem(-100);
    } else if (largeUp) {
      patternOffsetDesktop = rem(-444);
    } else if (mediumUp) {
      patternOffsetDesktop = rem(-575);
    } else if (smallUp) {
      patternOffsetDesktop = rem(-460);
    }

    if (xlargeUp) {
      minHeight = rem(310);
    } else if (mediumUp) {
      minHeight = rem(400);
    }

    return css`
      position: absolute;
      bottom: 0;
      left: ${patternOffsetDesktop};
      width: ${mediumUp ? 'auto' : '100%'};
      opacity: ${patternOpacity + 0.15}; /** Adding compensation */
      z-index: 0;
      .nmx-icon {
        height: 100%; /** was 560 */
        min-height: ${minHeight}; /** adding min-height so dot pattern doesn't scale down on confirmation screen */
        width: auto;
      }
    `;
  }
}`;

export const DivRightPatternContainerStyled = styled.div`
  ${({ theme }) => {
    const xxlargeUp = theme.xxlargeUp();
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    let minHeight = rem(320); // xsmall default
    let patternOffsetDesktop = rem(-10); // xsmall default

    if (xxlargeUp) {
      patternOffsetDesktop = rem(0);
    } else if (xlargeUp) {
      patternOffsetDesktop = rem(-200);
    } else if (largeUp) {
      patternOffsetDesktop = rem(-580);
    } else if (mediumUp) {
      patternOffsetDesktop = rem(-550);
    } else if (smallUp) {
      patternOffsetDesktop = rem(-95);
    }

    if (xlargeUp) {
      minHeight = rem(310);
    } else
    if (mediumUp) {
      minHeight = rem(400);
    }

    return css`
      position: absolute;
      top: 0;
      right: ${patternOffsetDesktop};
      width: ${mediumUp ? 'auto' : '100%'};
      opacity: ${patternOpacity};
      .nmx-icon {
        height: 100%;
        min-height: ${minHeight}; /** adding min-height so dot pattern doesn't scale down on confirmation screen */
        width: auto;
      }
    `;
  }
}`;
// END PATTERN STYLES ////////////////

export const DivLoadingContainerStyled = styled.div`
  width: ${rem(125)};
  margin: 0 auto;
`;

export const FieldsetStyled = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const FormStyled = styled(Form)`
  position: relative;
  z-index: 1;
  .nmx-container {
    padding: 0
  }
`;

export const LegendStyled = styled.legend`
  display: none;
`;

export const RowConfirmationStyled = styled(Row)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
    padding-top: ${mediumUp ? rem(10) : rem(5)};
  `;
  }
}`;

export const SectionContainerStyled = styled(SectionContainer)`
  ${({ theme }) => css`
    && {
      background-color: ${theme.colors.accent2.lightBlue};
      width: 100%;
      position: relative;
      max-width: unset;
      overflow: hidden;
      padding-top: ${rem(35)};
      padding-bottom: ${rem(30)};
      && {
        > .nmx-container {
          max-width: unset;
        }
      }
      .nmxc-label {
        font-weight: 400;
      }
      .nmx-input-error-label {
        padding-bottom: 0;
      }
      input {
        height: ${rem(40)};
        margin-top: ${rem(6)};
      }
      @media print {
        display: none !important;
      }
    }
  `
}`;

// this style gets imported by the individual input files, not the main js file
export const TextFieldStyled = styled(TextField)`
  height: ${rem(55)};
`;

export const TypographyHeaderStyled = styled(Typography)`
  padding-bottom: ${rem(16)};
`;

export const TypographyStyled = styled(Typography)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
    text-align: ${mediumUp ? 'center' : 'left'};
  `;
  }
}`;
