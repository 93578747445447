import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { getColorForName } from '../../theme/colors';

// TODO: convert base Sass styles to styled-components
// TODO: create styled-compoennts styles to handle incoming props (like using theme colors)
// eslint-disable-next-line import/prefer-default-export
export const BaseFont = styled.h1`
  /* If a weight value was provided, override the default */
  ${({ weight }) => weight
    && css`
      && {
        /** TODO: would be nice to only add 1 extra specificity layer, not 2 */
        font-weight: ${weight};
      }
    `}
  /* If a align value was provided, override the default */
  ${({ align }) => align
    && css`
      && {
        text-align: ${align};
      }
    `}
  /* If a uppercase value was provided, override the default */
  ${({ uppercase }) => uppercase
    && css`
      && {
        text-transform: uppercase;
      }
    `}
  /* If a color value was provided, override the default */
  ${({ color }) => color
    && css`
      && {
        color: ${getColorForName(color)};
      }
    `}
  /* If a disableBottomPadding prop was provided, override the default */
  ${({ theme, disableBottomPadding, nextElement, component, variant }) => {
    // media breakpoints
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    // conditional vars
    const visualComponent = variant || component; // if no variant is selected, fallback to the component being used.
    const headingsLargeSet = ['h1', 'h2'];
    const headingsMediumSet = ['h3', 'h4', 'h5', 'h6'];
    // css vars
    let paddingBottom = smallUp ? rem(16) : rem(12); // default

    if (disableBottomPadding) {
      paddingBottom = 0;
    } else if (nextElement === 'graphic') {
      paddingBottom = mediumUp ? rem(40) : rem(24);
    } else if (nextElement === 'button') {
      paddingBottom = rem(24);
    } else if (nextElement === 'typography') {
      // start nested IF statement for typography as next element conditions
      // if ((visualComponent === 'h3') || (visualComponent === 'h4') || (visualComponent === 'h5') || (visualComponent === 'h6')) {
      if (headingsMediumSet.includes(visualComponent)) {
        paddingBottom = mediumUp ? rem(12) : rem(8);
      } else {
        // default for ALL other variants - h1, h2, p, span, small, etc....
        paddingBottom = mediumUp ? rem(16) : rem(12);
      }
    } else if (nextElement === 'link') {
      // start nested IF statement for link as next element conditions
      // if ((visualComponent === 'h3') || (visualComponent === 'h4') || (visualComponent === 'h5') || (visualComponent === 'h6')) {
      if (headingsMediumSet.includes(visualComponent)) {
        paddingBottom = mediumUp ? rem(12) : rem(8);
      } else if (headingsLargeSet.includes(visualComponent)) {
        paddingBottom = mediumUp ? rem(16) : rem(12);
      } else {
        // default for all other variants - h1, h2, p, span, small, etc....
        paddingBottom = rem(24);
      }
    }

    return css`
      padding-bottom: ${paddingBottom};
    `;
  }}
`;
