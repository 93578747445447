/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ValidationErrorLabel from '../ValidationErrorLabel';
import Typography from '../../base/Typography';
import './radio-group.scss';

import {
  DivStyled,
  FieldsetStyled,
  RadioLabelStyled,
  RadioLabelGroupStyled,
  VisualPickerContainerStyled,
  VisualPickerItemContainerStyles,
  VisualPickerOptionStyled,
  VisualPickerOptionContainerStyled,
  VisualPickerOptionTextStyled,
  VisualPickerOptionTextLabelStyled,
} from './styles';

export const RadioComponent = ({ disabled, error, errorMessage, id, label, name, onChange, radioValues, required, selectedRadioValue, variant }) => {
  const [selectedValue, setSelectedValue] = useState(selectedRadioValue);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
    onChange(e);
  };

  if (variant !== 'visualPicker') {
    return <DivStyled className='nmx-form__input' role='radiogroup'>
      {label}{required && <span>&#42;</span>}
      <FieldsetStyled>
        <legend>{label}</legend>
        {
          radioValues.map((radioValue, index) => (
            <RadioLabelGroupStyled aria-label={radioValue.label} key={radioValue.value} index={index}>
              <RadioLabelStyled className='nmx-form__radio-button-label'>{radioValue.label}
                <input
                  aria-checked={radioValue.value === selectedValue}
                  checked={radioValue.value === selectedValue}
                  disabled={disabled}
                  name={name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  type='radio'
                  value={radioValue.value}
                />
              </RadioLabelStyled>
            </RadioLabelGroupStyled>
          ))
        }
        <ValidationErrorLabel error={error} errorMessage={errorMessage} />
      </FieldsetStyled>
    </DivStyled>;
  }
  return <VisualPickerContainerStyled role='radiogroup' className='nmx-visual-picker'>
    <Typography>{label}{required && <span>&#42;</span>}</Typography>
    <VisualPickerItemContainerStyles>
      {
        radioValues.map((radioValue, index) => (
          <VisualPickerOptionStyled aria-label={radioValue.label} key={`${radioValue.value}-${index}`} index={index}>
            <input
              aria-checked={radioValue.value === selectedValue}
              aria-label={radioValue.label}
              checked={radioValue.value === selectedValue}
              id={`${id}-${radioValue.value}`}
              name={name}
              onChange={(e) => {
                handleChange(e);
              }}
              type='radio'
              value={radioValue.value}
              // readOnly // use to style a visual picker without the native radio button
            />
            <VisualPickerOptionContainerStyled>
              <VisualPickerOptionTextLabelStyled
                htmlFor={`${id}-${radioValue.value}`}
                className='nmx-visual-picker__label'
              >
                <VisualPickerOptionTextStyled>
                  {radioValue.label}
                </VisualPickerOptionTextStyled>
              </VisualPickerOptionTextLabelStyled>
            </VisualPickerOptionContainerStyled>
          </VisualPickerOptionStyled>
        ))
      }
    </VisualPickerItemContainerStyles>
    <ValidationErrorLabel error={error} errorMessage={errorMessage} />
  </VisualPickerContainerStyled>;
};

RadioComponent.propTypes = {
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  error: PropTypes.bool,
  /** Message to display when error is true */
  errorMessage: PropTypes.string,
  /** id of radioGroup */
  id: PropTypes.string,
  /** Name applied to input or textarea */
  /** The label placed on the text field */
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  /** String array of radio buttons for group, each string is used as both the display label and value */
  radioValues: PropTypes.array.isRequired,
  required: PropTypes.bool,
  /** optional selected radio value - if this value matches a value option, the radio will be selected */
  selectedRadioValue: PropTypes.string,
  /** distinguish between native and visual picker variants */
  variant: PropTypes.string,
};

RadioComponent.defaultProps = {
  radioValues: [],
  onChange: () => {},
  disabled: false,
  error: false,
  errorMessage: 'Error',
  required: false,
};

export default RadioComponent;
