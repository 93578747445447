import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import SectionContainer from '../SectionContainer';
import ChevronLeftNarrowIcon from '../../icons/utility/ChevronLeftNarrow';
import ChevronRightNarrowIcon from '../../icons/utility/ChevronRightNarrow';
import { Row, Col } from '../../base/Grid';

export const ColArticleContainerStyled = styled(Col)`
  padding: 0;
`;

export const ColDesktopCard = styled(Col)`
  padding: 0 ${rem(16)};
`;

export const SectionContainerStyled = styled(SectionContainer)`
  padding-bottom: ${rem(28)};
`;

// swiper container
export const SwiperCarouselContainerRow = styled(Row)`
  && {
    width: 100%;
    height: 100%;
    padding-bottom: ${rem(32)};
  }
`;

export const SwiperCarouselSwiperSlide = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 ${rem(16)};
`;

// Dot indicators/controls
export const SwiperCarouselSwiperPagination = styled.div`
  ${({ theme }) => css`
    &&& {
      bottom: ${rem(15)};
      .swiper-pagination-bullet-active {
        background-color: ${theme.colors.cta.blue};
      }
    }
  `}
`;

export const SwiperCarouselSwiperNavigation = styled.div`
  ${({ theme, variant }) => {
    let thisLeft;
    let thisRight;
    const smallUp = theme.smallUp();
    if (!smallUp && variant === 'prev') {
      thisLeft = '10%';
    } else if (smallUp && variant === 'prev') {
      thisLeft = '25%';
    }
    if (!smallUp && variant === 'next') {
      thisRight = '10%';
    } else if (smallUp && variant === 'next') {
      thisRight = '25%';
    }
    return css`
    &&& {
      display: block;
      left: ${thisLeft};
      right: ${thisRight};
      top: unset;
      bottom: 0;
      width: ${rem(15)};
      z-index: 11;
      &::after {
        color: ${theme.colors.cta.blue};
        font-size: ${rem(24)};
        visibility: hidden;
      }
    `;
  }}
`;

export const SwiperCarouselSwiperNavigationLeftIcon = styled(ChevronLeftNarrowIcon)`
  ${({ theme }) => css`
    width: ${rem(15)};
    height: ${rem(34)};
    .icon-stroke {
      fill: none; /** TODO: reexamine build-icons to handle svg polyline stroke and fill */
      stroke: ${theme.colors.neutral.gray54};
    }
    :hover {
      .icon-stroke {
        stroke: ${theme.colors.cta.blue};
      }
    }
  `}
`;

export const SwiperCarouselSwiperNavigationRightIcon = styled(ChevronRightNarrowIcon)`
  ${({ theme }) => css`
    width: ${rem(15)};
    height: ${rem(34)};
    .icon-stroke {
      fill: none; /** TODO: reexamine build-icons to handle svg polyline stroke and fill */
      stroke: ${theme.colors.neutral.gray54};
    }
    :hover {
      .icon-stroke {
        stroke: ${theme.colors.cta.blue};
      }
    }
  `}
`;

export const SwiperCarouselSwiperCol = styled(Col)`
  padding: 0;
`;

export const SwiperCarouselSwiperWrapper = styled(Col)`
  padding: 0;
`;

export const ColHeadlineStyled = styled(Col)`
  ${({ theme, subText }) => {
    const mediumUp = theme.mediumUp();
    let paddingBottom = mediumUp ? rem(40) : rem(24); // default with no subText
    if (subText) {
      paddingBottom = mediumUp ? rem(16) : rem(12);
    }
    return css`
    padding-bottom: ${paddingBottom};
  `;
  }
}
`;

export const ColSubtextStyled = styled(Col)`
  ${({ theme }) => css`
    padding-bottom: ${theme.mediumUp() ? rem(40) : rem(24)};
  `}
`;
