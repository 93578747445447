import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonChipStyled,
  Styles,
} from './styles';

// TODO: Expand functionality to chips to make them
// * dismissable (with a close icon)
// * optionally clickable (chips can be used as static filter/info displays, for example.)

export const ChipComponent = ({
  className,
  icon: RenderIcon,
  id,
  onClick,
  text,
}) => {
  const styles = Styles();
  return (
    <ButtonChipStyled
      className={className}
      id={id}
      onClick={onClick}
      type='button'>
      {RenderIcon && (
        <RenderIcon style={styles.IconStyled(!!text)} />
      )}
      {text}
    </ButtonChipStyled>
  );
};

ChipComponent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.any,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default ChipComponent;
