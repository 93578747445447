import React from 'react';
import { Container, Row, Col } from '../../../base/Grid';
import WidowBlock from '../../../base/WidowBlock';
import {
  ContactInfoHeaderTooltip,
  DemographicInfoHeaderTooltip,
  GoalHeaderTooltip,
  PersonalInfoHeaderTooltip,
} from '../tooltips/tooltips';

import {
  DivPaginationContainerStyled,
  RowHeroHeaderStyled,
  SpanNoBreakStyled,
  TypographyHeroHeaderStyled,
  TypographyStyled,
} from './styles';

// Simple JSX components
// Code blocks that are reused on the first 4 views //////
export const embeddedHeaderRow = (ariaHidden, component, isPcg) => (
  <Row>
    <Col>
      <TypographyStyled
        component={component}
        variant='h2'
        aria-hidden={ariaHidden}
        isPcg={isPcg}>
        {isPcg ? (
          <>Get matched with a private client <WidowBlock>group advisor</WidowBlock></>
        ) : (
          <>Get matched with a financial advisor in four <WidowBlock>easy steps.</WidowBlock></>
        )}
      </TypographyStyled>
    </Col>
  </Row>
);

// For FAFA Hero variant headers /////////////
export const heroGoalsHeaderRow = (
  <RowHeroHeaderStyled>
    <Col>
      <TypographyHeroHeaderStyled
        component='h2'
        variant='h3'
        isWelcomeView>
        What&#39;s your biggest financial&nbsp;
        <SpanNoBreakStyled>
          goal?<GoalHeaderTooltip />
        </SpanNoBreakStyled>
      </TypographyHeroHeaderStyled>
      <TypographyHeroHeaderStyled
        isWelcomeView>
        (Choose one)
      </TypographyHeroHeaderStyled>
    </Col>
  </RowHeroHeaderStyled>
);

export const heroDemographicHeaderRow = (
  <RowHeroHeaderStyled>
    <Col>
      <TypographyHeroHeaderStyled
        component='h2'
        variant='h3'>
        Tell us a bit about&nbsp;
        <SpanNoBreakStyled>
          yourself.
          <DemographicInfoHeaderTooltip />
        </SpanNoBreakStyled>
      </TypographyHeroHeaderStyled>
    </Col>
  </RowHeroHeaderStyled>
);

export const heroPersonalInfoHeaderRow = (
  <RowHeroHeaderStyled>
    <Col>
      <TypographyHeroHeaderStyled
        component='h2'
        variant='h3'>
        Who will we be talking&nbsp;
        <SpanNoBreakStyled>
          with? <PersonalInfoHeaderTooltip />
        </SpanNoBreakStyled>
      </TypographyHeroHeaderStyled>
    </Col>
  </RowHeroHeaderStyled>
);

export const heroContactInfoHeaderRow = (
  <RowHeroHeaderStyled>
    <Col>
      <TypographyHeroHeaderStyled
        component='h2'
        variant='h3'>
        How do we get in touch with&nbsp;
        <SpanNoBreakStyled>
          you?
          <ContactInfoHeaderTooltip />
        </SpanNoBreakStyled>
      </TypographyHeroHeaderStyled>
    </Col>
  </RowHeroHeaderStyled>
);

export const getScreenNumber = (x) => (
  <DivPaginationContainerStyled>
    <TypographyStyled
      id={`in-page-lead-form-screen-number-${x}`}
      className='nmx-p'
      component='span'
      weight={400}>
      {x}&#47;4
    </TypographyStyled>
  </DivPaginationContainerStyled>
);

export const errorSubmittingMessage = (
  <Container>
    <Row align='center'>
      <TypographyStyled
        variant='h3'>
        Unable to submit form.
      </TypographyStyled>
    </Row>
  </Container>
);

