// eslint-disable-next-line import/prefer-default-export
export const Styles = () => ({
  image: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    marginBottom: '1rem',
  },
  thankYouText: { marginBottom: '1rem' },
});
