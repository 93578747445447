/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  ToastBox,
  ToastContainer,
  ToastContentContainer,
  ToastContent,
  ToastHeadline,
  ToastIconContainer,
  ToastIcon,
  ToastText,
  TextCloseButton,
  ToggleButton,
  ToggleContent,
  CloseButton,
} from './styles';

// TODO: this Component can be optimized in many places (hooks, refs, remove jsxIf, ErrorBoundaries, move some stylistic elements into CSS, etc),
// use data- attributes (heading, text, storage_var), refactor listeners, consolidate open/close toast functionality
// proper tag markup, css is overcomplicated

let enableListener = true;
export default class NMExpandableToast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupIsActive: false,
      popupExpanded: false,
      mmBreakpoint: this.mmCheck(),
    };

    // component refs
    this.toastContainer = React.createRef();
    this.toastAccordion = React.createRef();
    this.textBlock = React.createRef();
    this.popupBox = React.createRef();
    this.toastToggleBtn = React.createRef();
  }

  mmCheck = () => (typeof window !== 'undefined'
    ? window.matchMedia('(min-width: 768px)').matches
    : false);

  toggleContent = (e) => {
    e.preventDefault();
    // Get computed height of hidden text content and use it to set the height of the accordion when it expands
    this.setState({ popupExpanded: !this.state.popupExpanded }, () => {
      this.openToast();
    });
  }

  openToast = () => {
    this.toastAccordion.current.style.height = `${this.textBlock.current
      .offsetHeight + 60}px`; // text height + close button height
    this.toastContainer.current.style.height = `${this.textBlock.current
      .offsetHeight + 60}px`; // text height + close button height
  }

  hideToast = () => {
    this.setState({ popupIsActive: false });
    // Set data in localStorage so the banner stays hidden for this user
    try {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(this.props.storage_var, false);
      }
    } catch (error) {
      console.error(`localStorage setItem failed with error ${error}`);
    }
  }

  collapsePopup = () => {
    this.setState({ popupExpanded: false });
    this.toastAccordion.current.style.height = '0';
    this.toastContainer.current.style.height = this.mmCheck()
      ? '60px'
      : '50px';
  }

  componentDidMount = () => {
    // Get data from localStorage to see if the user chose to hide the announcement component
    try {
      if (
        typeof localStorage !== 'undefined'
        && localStorage.getItem(this.props.storage_var) === 'false'
      ) {
        this.setState({ popupIsActive: false });
      } else if (
        typeof localStorage !== 'undefined'
        && localStorage.getItem(this.props.storage_var) === null
      ) {
        // if prop is not set, then show expandable toast
        this.setState({ popupIsActive: true });
      }
    } catch (error) {
      console.error(`localStorage getItem failed with error ${error}`);
    }

    // only add event listeners is popup displays
    // TODO: all of these listeners can be built better (listener should call a function)
    window.addEventListener('click', (e) => {
      const { target } = e;
      if (
        !this.popupBox.current.contains(target)
          && this.popupBox.current !== target
      ) {
        // Collapse popup is the user clicks outside on the popup
        this.collapsePopup();
      } else if (!this.state.popupExpanded) {
        // Collapse popup is the user clicks heading button
        if (
          !(
            this.toastToggleBtn.current !== target
              && !this.toastToggleBtn.current.contains(target)
          )
        ) {
          this.collapsePopup(); // TODO: This logic is not efficient, setting popupExpanded
        }
      }
    });

    window.addEventListener('keydown', (e) => {
      const { target } = e;
      // Hide popup if user is focused in or on the element and hits escape
      if (this.popupBox.current.contains(target)) {
        if (e.key === 'Escape') {
          this.hideToast();
        }
      }
    });

    enableListener = true;
    window.addEventListener('resize', () => {
      if (!enableListener) return;
      enableListener = false;
      this.collapsePopup();
      setTimeout(() => {
        enableListener = true;
      }, 300);
    });
  }

  componentWillUnmount = () => {
    // TODO: all of these listeners can be built better
    window.removeEventListener('click', (e) => {
      const { target } = e;
      // Collapse popup is the user clicks outside on the popup
      if (!this.popupBox.contains(target) && this.popupBox !== target) {
        this.collapsePopup();
      }
    });

    window.removeEventListener('keydown', (e) => {
      const { target } = e;
      // Hide popup if user is focused in or on the element and hits escape
      if (this.popupBox.contains(target)) {
        if (e.key === 'Escape') {
          this.hideToast();
        }
      }
    });

    window.removeEventListener('resize', () => {
      if (!enableListener) return;
      enableListener = false;
      this.collapsePopup();
      setTimeout(() => {
        enableListener = true;
      }, 300);
    });
  }

  render() {
    const { heading, text, config } = this.props;
    const { basePath, nmxTemplateVersion } = config;
    const { popupIsActive, popupExpanded } = this.state;
    // If the text props are passed in as an array, map it to the component.
    // If it's a single string, just display the text
    try {
      const textArray = JSON.parse(text);
      this.textEntries = textArray.map((item, index) => (
        <span key={index} className="nm-expandable-toast__text-block">
          {item}
        </span>
      ));
    } catch (error) {
      this.textEntries = (
        <span className="nm-expandable-toast__text-block">{text}</span>
      );
    }

    return (
      <ToastContainer
        className='nmx-no-print'
        hideToast={!popupIsActive}
        aria-hidden={!popupIsActive}>
        <CloseButton
          id="nm-expandable-toast-close-button"
          aria-label="Close notification popup"
          onClick={this.hideToast}>
          &#10005;
        </CloseButton>
        <ToastBox
          ref={this.popupBox}
          aria-modal="true"
          role="dialog">
          <ToastContentContainer
            ref={this.toastContainer}>
            <ToggleButton
              id="nm-expandable-toast-toggle-content-button"
              ref={this.toastToggleBtn}
              onClick={this.toggleContent}>
              <ToastContent isExpanded={popupExpanded}>
                <ToastHeadline>
                  {heading}
                </ToastHeadline>
                <ToastIconContainer
                  aria-label={
                    !popupExpanded
                      ? 'Show more information'
                      : 'Hide additional information'
                  }>
                  <ToastIcon
                    basePath={basePath}
                    nmxTemplateVersion={nmxTemplateVersion}
                    invert={popupExpanded}
                    aria-hidden="true"></ToastIcon>
                </ToastIconContainer>
              </ToastContent>
            </ToggleButton>
            <ToggleContent
              aria-hidden={!popupExpanded}
              ref={this.toastAccordion}>
              <div ref={this.textBlock}>
                <ToastText>{this.textEntries}</ToastText>
                <TextCloseButton
                  id="nm-expandable-toast-text-close-button"
                  aria-label="Close notification popup"
                  onClick={this.hideToast}>
                  close
                </TextCloseButton>
              </div>
            </ToggleContent>
          </ToastContentContainer>
        </ToastBox>
      </ToastContainer>
    );
  }
}

NMExpandableToast.propTypes = {
  /** Config vars used in footer */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
  }).isRequired,
};

NMExpandableToast.defaultProps = { nmxTemplateVersion: '<%=templateVersion%>' };
