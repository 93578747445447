import React from 'react';

import XIcon from '../../../icons/social/X';

import { AStyledX, HeadlineStyled, ListStyled, ListItemStyled, FacebookIconStyled, LinkedinIconStyled, InstagramIconStyled, YoutubeIconStyled } from './styles';

const socialLinks = () => (
  <>
    <HeadlineStyled
      component='h4'
      variant='h5'
      weight={300}
      disableBottomPadding={true}
      aria-label="Footer Social Media Links">
      Connect with us
    </HeadlineStyled>
    <ListStyled inline={true}>
      <ListItemStyled>
        <a
          href="https://www.facebook.com/northwesternmutual"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="Connect with Northwestern Mutual on Facebook">
          <FacebookIconStyled />
        </a>
      </ListItemStyled>
      <ListItemStyled socialIcon='x'>
        <AStyledX
          aria-label="Follow Northwestern Mutual on X"
          href="https://twitter.com/nm_financial/"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          <XIcon />
        </AStyledX>
      </ListItemStyled>
      <ListItemStyled>
        <a
          href="https://www.linkedin.com/company/northwestern-mutual"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="Visit Northwestern Mutual on LinkedIn">
          <LinkedinIconStyled />
        </a>
      </ListItemStyled>
      <ListItemStyled>
        <a
          href="https://instagram.com/northwesternmutual"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="Follow Northwestern Mutual on Instagram">
          <InstagramIconStyled />
        </a>
      </ListItemStyled>
      <ListItemStyled>
        <a
          href="https://www.youtube.com/northwesternmutual"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="Connect with Northwestern Mutual on YouTube">
          <YoutubeIconStyled />
        </a>
      </ListItemStyled>
    </ListStyled>
  </>
);

export default socialLinks;
