/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React from 'react';
import { Row, Col } from '../../../base/Grid';
import Typography from '../../../base/Typography';
import List from '../../../base/List';

import {
  ButtonLinkStyled,
  ListItemStyled,
  LinkStyled,
} from './styles';

const navLinks = ({ basePath }) => (
  <nav
    itemScope
    itemType="https://schema.org/SiteNavigationElement"
    id="nmx-footer-nav">
    <Typography component='h4' className="nmx-assistive-text">Footer Navigation</Typography>
    <Row>
      <Col xsmall={12} small={6} medium={4}>
        <List role="menu">
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/who-we-are/`}>
              About Us
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              target="_blank"
              rel="noopener noreferrer"
              href="https://news.northwesternmutual.com">
              Newsroom
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/careers/`}>
              Careers
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/security-and-privacy/`}>
              Information Protection
            </LinkStyled>
          </ListItemStyled>
        </List>
      </Col>
      <Col xsmall={12} small={6} medium={4}>
        <List role="menu" className="reduced">
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/financial-planning/business-services/`}>
              Business Services
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/podcast/`}>
              Podcast
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              target="_blank"
              rel="noopener noreferrer"
              href={`${basePath}/contact-us/`}>
              Contact Us
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/faq/`}>
              FAQs
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/legal-information/`}>
              Legal Notice
            </LinkStyled>
          </ListItemStyled>
        </List>
      </Col>
      <Col xsmall={12} small={6} smallOffset={6} medium={4} mediumOffset={0}>
        <List role="menu" className="reduced">
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              href={`${basePath}/sitemap/`}>
              Sitemap
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role="none">
            <LinkStyled
              role="menuitem"
              target="_blank"
              rel="noopener noreferrer"
              href={`${basePath}/privacy-notices/`}>
              Privacy Notices
            </LinkStyled>
          </ListItemStyled>
          <ListItemStyled role='none'>
            {/* <!-- OneTrust Cookies Settings button start --> */}
            <ButtonLinkStyled
              variant='quarternary'
              id='ot-sdk-btn' // DO NOT CHANGE
              className='ot-sdk-show-settings nmx-override' // DO NOT CHANGE
              role='menuitem'>
              Do Not Sell or Share My Personal Information
            </ButtonLinkStyled>
            {/* <!-- OneTrust Cookies Settings button end --> */}
          </ListItemStyled>
        </List>
      </Col>
    </Row>
  </nav>
);

export default navLinks;
