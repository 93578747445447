import React from 'react';

import BaseLoading from './styles';

export const LoadingComponent = () => <BaseLoading className="loader" />;

LoadingComponent.propTypes = {};

LoadingComponent.defaultProps = {};

export default LoadingComponent;
