import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import Button from '../../../../base/Button';
import { Container, Col, Row } from '../../../../base/Grid';
import Typography from '../../../../base/Typography';
import Link from '../../../../base/Link';

export const ButtonHideShowPasswordStyled = styled.button`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    // const largeUp = theme.largeUp();
    // const xlargeUp = theme.xlargeUp();
    let thisRight = rem(50);
    if (mediumUp) {
      thisRight = rem(100);
    }

    return css`
      position: absolute;
      right: ${thisRight};
      margin-top: 25px;
      background: none;
      border: none;
      .nmx-icon {
        .icon-fill {
          fill: ${theme.colors.primary.blue};
        }
      }
    `;
  }}
`;

export const ButtonCloseStyled = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: ${rem(42)};
  min-width: unset;
  padding: ${rem(12)};
  border: none;
  float: right;
  background: transparent;
  svg {
    height: ${rem(16)};
    width: ${rem(16)};
  }
  :hover {
    cursor: pointer;
  }
`;

export const ButtonLoginStyled = styled(Button)`
  display: block;
  margin: auto;
  margin-bottom: ${rem(16)};
`;

export const ColFormStyled = styled(Col)`
  margin-bottom: ${rem(16)};
  .nmx-label {
    display: block;
  }
`;

export const ColLoginContentStyled = styled(Col)`
  ${({ theme }) => css`
    padding: ${theme.mediumUp() ? rem(48) : rem(16)}; 
  `}
`;

export const ContainerModalStyled = styled(Container)`
  ${({ theme }) => {
    let width = '100%';
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();

    if (xlargeUp) {
      width = '60%';
    } else if (largeUp) {
      width = '60%';
    } else if (mediumUp) {
      width = '75%';
    }
    return css`
      .is-active {
        display: block;
      }
      padding: 0;
      position: absolute;
      z-index: ${theme.zIndex.modalMain};
      display: none;
      width: ${width};
      max-width: ${xlargeUp ? rem(900) : rem(800)};
      height: ${mediumUp ? 'auto' : '100%'};
      border: 0;
      margin: 0 auto;
      backface-visibility: hidden;
      background-color: ${theme.colors.neutral.white};
      box-shadow: none;
      overflow-y: ${mediumUp ? 'auto' : 'scroll'};
      border-radius: ${mediumUp ? rem(4) : 0};
      min-height: ${mediumUp ? rem(300) : 0};
      ${largeUp && 'overflow: hidden;'}
    `;
  }}
`;

export const DivLoginErrorStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${rem(16)};
    border-left: ${rem(5)} solid ${theme.colors.system.negativeError};
    margin-bottom: ${rem(16)};
    background-color: ${theme.colors.neutral.gray99};

    p {
      flex: 1 0 auto;
    }

    .nmx-icon {
      .icon-fill {
        fill: ${theme.colors.system.negativeError};
      }
      .icon-color {
        fill: ${theme.colors.neutral.white};
      }
    }
   `}
`;

export const DivModalContainerStyled = styled.div`
  position: absolute;
  &.is-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    .nmx-modal {
      display: block;
      @media all and (-ms-high-contrast: none) {
        top: ${rem(50)};
        left: 25%;
      }
    }
    .nmx-modal__overlay {
      display: flex;
      align-items: center;
      margin: auto;
    }
  }
`;

export const DivModalOverlayStyled = styled.div`
  ${({ theme }) => css`
    position: fixed;
    z-index: ${theme.zIndex.modalOverlay};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5); /** TODO: leverage shadow theme (once organized) */
    overflow-y: scroll;
    .is-active {
      display: flex;
      align-items: center;
      margin: auto;
    }
  `}
`;

export const UlLoginLinksStyled = styled.ul`
  display: inline-block;
  li {
    display: inline-block;
    margin: ${rem(8)};
    &:first-child {
      padding-right: ${rem(16)};
      border-right: 1px solid gray; /** TODO: this can be gray.gray */
    }
  }
`;

export const TypographyStyled = styled(Typography)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

export const LinkStyled = styled(Link)`
  font-size: ${rem(14)};
  line-height: ${rem(22)};
`;

export const RowStyled = styled(Row)`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray95};
  `}
`;

export const ColStyledAppDownloadContainer = styled(Col)`
  padding: ${rem(24)} 0 ${rem(16)};
`;

export const ColStyledDownloadLinkContainer = styled(Col)`
  .nmx-icon.badge-appStore {
    max-height: ${rem(40)};
  }
  .nmx-icon.badge-googlePlay {
    max-height: ${rem(60)};
    margin-top: ${rem(-10)};
  }
`;

export const ListItemStyledDownLoadLink = styled.li`
  display: inline-block;
  vertical-align: top;
`;
