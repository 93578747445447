import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { getColorForName } from '../../theme/colors';

const BaseLoading = styled.div`
  @keyframes animate-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }

  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  height: 0;
  padding-bottom: 100%;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    border: ${rem(3)} solid transparent;
    animation-name: animate-spin;
    animation-duration: 1250ms;
    animation-timing-function: cubic-bezier(0.12, 0.33, 0.89, 0.68);
    animation-iteration-count: infinite;
    border-radius: ${rem(1000)};
    /* mobile only, but could just be a weird vw issue */
    border-width: ${rem(3)};
  }

  &::before {
      border-bottom-color: ${getColorForName('color-primary-nm-blue')};
      border-left-color:  ${getColorForName('color-primary-nm-blue')};
      height: 100%;
      width: 100%;
      margin: -50% 0 0 -50%;
  }

  &::after {
    border-top-color: ${getColorForName('color-system-cta-yellow')};
    border-right-color: ${getColorForName('color-system-cta-yellow')};
    animation-direction: reverse;
    /* may need to be adjusted IF the container for the loader is LARGE */
    height: 90%;
    width: 90%;
    margin: -45% 0 0 -45%;
  }
`;

export default BaseLoading;
