import { ThemeContext } from 'styled-components';
import { useContext } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const Styles = () => {
  const theme = useContext(ThemeContext);
  return {
    image: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
      marginBottom: '1rem',
      height: '9.47917vw',
      width: '9.47917vw',
      fill: theme.colors.system.negativeError,
    },
    errorText: { marginBottom: '1rem' },
  };
};

