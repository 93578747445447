const weightThin = 200;
const weightLight = 300;
const weightRegular = 400;
const weightMedium = 500;
const weightSemibold = 600;
// const weightBold = 700; // commented for use later

export const fontFamilySansSerif = "font-family: 'Guardian Sans', 'Helvetica Neue', Helvetica, sans-serif;";

const fontData = {
  guardianSansThin: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Thin',
    style: 'normal',
    weight: weightThin,
  },
  guardianSansThinItalic: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Thin-Italic',
    style: 'italic',
    weight: weightThin,
  },
  guardianSansLight: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Light',
    style: 'normal',
    weight: weightLight,
  },
  guardianSansLightItalic: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Light-Italic',
    style: 'italic',
    weight: weightLight,
  },
  guardianSansRegular: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Regular',
    style: 'normal',
    weight: weightRegular,
  },
  guardianSansRegularItalic: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Regular-Italic',
    style: 'italic',
    weight: weightRegular,
  },
  guardianSansMedium: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Medium',
    style: 'normal',
    weight: weightMedium,
  },
  guardianSansMediumItalic: {
    name: 'Guardian Sans',
    file: 'GuardianSans-Medium-Italic',
    style: 'italic',
    weight: weightMedium,
  },
  guardianSansSemibold: {
    name: 'Guardian Sans',
    file: 'GuardianSans-SemiBold',
    style: 'normal',
    weight: weightSemibold,
  },
  guardianSansSemiboldItalic: {
    name: 'Guardian Sans',
    file: 'GuardianSans-SemiBold-Italic',
    style: 'italic',
    weight: weightSemibold,
  },
  guardianTextSansRegular: {
    name: 'Guardian Sans',
    // Guardian Text Sans
    file: 'GuardianTextSans-Regular-Web',
    style: 'normal',
    weight: weightRegular,
  },
  guardianTextSansMedium: {
    name: 'Guardian Sans',
    // Guardian Text Sans
    file: 'GuardianTextSans-Medium-Web',
    style: 'normal',
    weight: weightMedium,
  },
};

const fontList = [
  'guardianSansThin',
  'guardianSansThinItalic',
  'guardianSansLight',
  'guardianSansLightItalic',
  'guardianSansRegular',
  'guardianSansRegularItalic',
  'guardianSansMedium',
  'guardianSansMediumItalic',
  'guardianSansSemibold',
  'guardianSansSemiboldItalic',
  // Remove these two fonts for nmx-template 2.0 so we align properly with CX and gelato
  // 'guardianTextSansRegular',
  // 'guardianTextSansMedium',
];

// eslint-disable-next-line import/prefer-default-export
export const getFonts = (templateBasePath, nmxTemplateVersion) => fontList.map((font) => {
  // header = h1, h2, h3 etc
  const basePath = `${templateBasePath}/template/assets/${nmxTemplateVersion}/fonts/guardian`;
  const data = fontData[font];
  const {
    name,
    file,
    style,
    weight,
  } = data;

  const fontPath = `${basePath}/${file}`;

  return `
    @font-face {
      font-display: swap;
      font-family: '${name}';
      font-style: ${style};
      font-weight: ${weight};
      src: url('${fontPath}.woff2') format('woff2'),
           url('${fontPath}.woff') format('woff');
    }
    `;
}).join(`
  `);
