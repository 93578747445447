import rem from 'polished/lib/helpers/rem';
import { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import Typography from '../../base/Typography';

export const DivItemContainerStyled = styled.div`
  ${({ theme, variant }) => css`
    border-bottom: 1px solid ${theme.colors.neutral.gray87}; /** TODO: revisit theme grays */
    :first-of-type {
      border-top: 1px solid ${theme.colors.neutral.gray87}; /** TODO: revisit theme grays */
    }

    ${variant === 'secondary'
      && `
      background-color: ${theme.colors.neutral.gray95};
      margin-bottom: 1rem;
      border: 0;
    `}
  `}
`;

export const ButtonContainerStyled = styled.button`
  ${({ theme, variant, isOpen, isFAQ }) => {
    let thisPadding = `${rem(22)} 0`;
    if (variant === 'secondary') {
      thisPadding = rem(22);
    }
    if (variant === 'secondary' && isFAQ) {
      thisPadding = rem(24);
    }
    if (variant === 'secondary' && isFAQ && isOpen === 'true') {
      thisPadding = `${rem(24)} ${rem(24)} ${rem(16)}`;
    }
    return css`
      display: flex;
      position: relative;
      width: 100%;
      padding: ${thisPadding};
      background: inherit;
      border: 0;
      justify-content: flex-start;
      align-items: center;
      color: ${theme.colors.neutral.gray29};

      &.is-accordion-item-expanded {
        ${variant === 'primary'
          && `
          color: ${theme.colors.primary.blue};
          .accordion-toggle-icon {
            color: ${theme.colors.primary.blue};
          }
        `}
      }
      &:hover {
        cursor: pointer;
        color: ${theme.colors.primary.blue};
        .accordion-toggle-icon {
          .icon-fill {
            fill: ${theme.colors.primary.blue};
          }
        }
      }
    `;
  }}
`;

export const DdItemContainer = styled.dd`
  ${({ theme, variant, isFAQ }) => css`
      margin: 0; /** TODO: add to reset for <dd />? */
      padding-bottom: ${rem(22)};
      /** Primary: 31 = 15 (icon width) + 16 left-margin */
      /** ${variant === 'primary'
        && `
        padding-right: ${rem(31)};
      `} */
      /** Secondary: 70 = 24 (button padding) + 30 (icon width) + 16 left-margin */
      /** right padding only active for FAQ (for now) */
      ${variant === 'secondary'
        && `
        padding-right: ${rem(22)};
        padding-left: ${rem(22)};
      `}
      ${variant === 'secondary'
        && isFAQ
        && !theme.mediumUp()
        && `
        padding-right: ${rem(24)};
        padding-bottom: ${rem(24)};
        padding-left: ${rem(24)};
      `}
      ${variant === 'secondary'
        && isFAQ
        && theme.mediumUp()
        && `
        padding-right: ${rem(70)};
        padding-bottom: ${rem(24)};
        padding-left: ${rem(24)};
      `}
    `}
`;

export const DtItemContainer = styled.dt`
  width: 100%;
`;

export const TypographyTitleStyled = styled(Typography)`
  ${({ leftPadding, variant }) => css`
  flex: 1 1 auto;

  ${variant === 'primary'
    && `
    font-size: ${rem(17)};
  `}

  padding-top: ${rem(6)};
  ${leftPadding
    && `
      padding-left: 1rem;
    `}
  `}
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);

  return {
    TitleIconStyled: {
      flexShrink: 0,
      maxWidth: rem(28),
      height: rem(33),
      /** set icon height to 100% mediumUp */
      ...(theme.mediumUp() && { height: '100%' }),
    },
    IconToggleStyled: {
      flexShrink: 0,
      color: theme.colors.cta.blue,
      marginLeft: rem(16),
    },
  };
};
