export default (props) => [
  {
    // we don't want to load chat on mobile so give a prop that doesn't exist
    propAddValue: (('ontouchstart' in window)
    || (navigator.maxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0)) ? 'noChatBotOnMobile' : 'showChatBot',
    id: 'ze-snippet',
    src:
      'https://static.zdassets.com/ekr/snippet.js?key=aac69570-a454-4484-b769-02d662a1bea9',
    defer: true,
    async: true,
  },
  {
    propAddValue: 'showChatBot',
    type: 'text/javascript',
    innerHTML: `
      try {
        window.zESettings = {
          webWidget: {
            color: {
              theme: '#0e497b',
              launcher: '#1570bc', // This will also update the badge
              button: '#1570bc',
              resultLists: '#0e497b',
              header: '#0e497b',
              articleLinks: '#1570bc',
            },
            launcher: {
              label: {
                'en-US': 'Ask NM',
              },
            },
            answerBot: {
              avatar: {
                url: '${props.config.basePath}/template/assets/${props.config.nmxTemplateVersion}/images/chat-logo-v3.png',
                name: {
                  '*': 'Northwestern Mutual',
                },
              },
              title: {
                '*': "I'm the NM Answer Bot.",
              },
            },
            helpCenter: {
              originalArticleButton: false,
            },
          },
        };
      } catch (err) {
        console.error('Chatbot failed to load with error: ' + err);
      }
    `,
    defer: true,
    async: true,
  },
  {
    /* GOOGLE PLACES  */
    propAddValue: 'showGooglePlaces',
    type: 'text/javascript',
    defer: true,
    async: true,
    src: `https://maps.googleapis.com/maps/api/js?key=${props.config.public.googlePlacesApiKey}&libraries=geometry,places`,
  },
];
