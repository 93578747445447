import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import rgba from 'polished/lib/color/rgba';
import { inputStyles } from '../TextField/styles';

const SelectStyled = styled.select`
  ${inputStyles}
  ${({ theme, customTheme, value, hasError, isPcg }) => {
    if (customTheme === 'leads') {
      let borderColor = 'rgba(225, 225, 225, 0.8)'; // default
      if (hasError) {
        borderColor = theme.colors.system.lightRed;
      }
      return css`
        background-color: ${isPcg ? theme.colors.pcg.darkBlue5 : theme.colors.inputLeadVariantBG};
        color: ${value ? theme.colors.neutral.white : rgba(theme.colors.neutral.white, 0.8)};
        border: 1px solid ${borderColor};
        border-radius: ${rem(2)};
        height: ${rem(55)};
        font-weight: ${value ? 500 : 400};
        letter-spacing: ${rem(1)};
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        :hover {
          background-color: ${isPcg ? theme.colors.pcg.darkBlue4 : theme.colors.inputLeadVariantBGHover};
        }
        :focus {
          border: 1px solid ${theme.colors.neutral.white};
        }
        :-ms-expand {
          display: none;
        }
        :-ms-clear {
          display: none;
        }
        /** cross-brower fallback to change the BG color of the select dropdowns */
        /**  This affects IE, Edge, and Firefox */
        option {
          background-color: ${isPcg ? theme.colors.pcg.darkBlue5 : theme.colors.inputLeadVariantBG};
        }
      `;
    }
    return false;
  }}
`;

export default SelectStyled;
