import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { labelStyles } from '../Label/styles';

export const DivStyled = styled.div`
  ${labelStyles}
`;

export const FieldsetStyled = styled.fieldset`
  margin-top: ${rem(4)};
`;

export const RadioLabelStyled = styled.span`
  margin-right: ${rem(8)};
`;

export const RadioLabelGroupStyled = styled.label`
  ${({ index }) => css`
    ${index > 0 && 'margin-left: 1rem;'}
  `}
`;

// ====================
// Visual Picker styles
// ====================
export const VisualPickerContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VisualPickerItemContainerStyles = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VisualPickerOptionStyled = styled.div`
  display: flex;
  width: auto;
  margin-right: ${rem(16)};
  margin-bottom: ${rem(4)};
  cursor: pointer;

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    margin-left: ${rem(32)};
    align-self: center;
    /* opacity: 0; */ /**  set opacity: 0 to style a visual picker without the native radio button */
    cursor: pointer;
  }
`;

export const VisualPickerOptionContainerStyled = styled.div`
  ${({ theme }) => css`
    width: ${rem(120)};
    height: ${rem(50)};
    border: 1px solid ${theme.colors.neutral.gray29};
    border-radius: ${rem(4)};
    &:hover {
      box-shadow: ${theme.shadows.small};
    }

    input:focus ~ & {
      border: ${rem(2)} solid ${theme.colors.cta.blueActive};
    }

    input:checked ~ & {
      border: ${rem(2)} solid ${theme.colors.cta.blueActive};
      .nmx-visual-picker__label {
        color: ${theme.colors.cta.blueActive};
        font-weight: 700;
      }
    }
  `}
`;

export const VisualPickerOptionTextLabelStyled = styled.label`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    height: 100%;
    color: ${theme.colors.neutral.gray29};
    font-size: ${rem(18)};
    cursor: pointer;
  `}
`;

export const VisualPickerOptionTextStyled = styled.span`
  display: block;
  padding: ${rem(15)} 0 0 ${rem(52)};
`;
