import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SearchService from '@nmx/utils/dist/services/SearchService/Frontend';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import find from 'lodash/find';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import { newRelicJSError } from '@nmx/utils/dist/utilities/frontend/Analytics/new_relic_helper';
import { isEmpty } from '@nmx/utils/dist/utilities/validators';
import DynamicAgentCard from '../../../components/modules/DynamicAgentCard';
import { jsxIf } from '../utils/jsx.util';
import { navBodyScrollingHandler } from '../helpers/nav.header';

// TODO: this Component can be optimized in many places (hooks, refs, remove jsxIf, ErrorBoundaries, etc)

// elements found outside Component
let searchToggleOpenEl; let
  mobileNavContainer = null;

export class NMXSearchComponent extends Component {
  queryInput;

  constructor(props) {
    super(props);

    this.state = {
      query: Location.queryParams.q || '',
      didSearch: false,
      isSearching: false,
      results: undefined,
      reps: undefined,
      isOpen: props.isopen || Location.queryParams.searchIsOpen || false,
      mmBreakpoint: this.mmCheck(),
      isSafari: /^((?!chrome|android).)*safari/i.test(typeof navigator !== 'undefined' ? navigator.userAgent : ''),
    };

    // component refs
    this.searchResultsModule = React.createRef();
    this.searchFormContainer = React.createRef();
    this.searchToggleCloseEl = React.createRef();
    this.searchOverlayEl = React.createRef();
    this.searchClearButtonEl = React.createRef();
    this.searchContainerEl = React.createRef();

    // component listeners
    this.keyDownListener = throttle(this.keyDownListener.bind(this), 100);
    this.resizeListener = debounce(this.resizeListener.bind(this), 100);
  }

  keyDownListener(e) {
    const tabKeyCode = 9;
    const escapeKeyCode = 27;
    const enterKeyCode = 13;

    switch (e.keyCode) {
      case escapeKeyCode:
      // collapse search console on escape key press
        if (this.state.isOpen === true) {
          this.toggle();
        }
        break;
      case enterKeyCode:
        // toggle search console on enter key press
        if (document.activeElement === searchToggleOpenEl) {
          e.preventDefault();
          this.open();
        } else if (document.activeElement === this.searchToggleCloseEl) {
          e.preventDefault();
          this.close();
        }
        break;
      case tabKeyCode:
      // if console is open, focus on close button and trap focus in console\
        if (this.state.isOpen === true) {
        // accessibility tab ordering
          const thisSearchConsole = document.getElementById('nmx-search-container');
          thisSearchConsole.focus();
          const focusableEls = Array.prototype.slice.call(thisSearchConsole.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.no-query), [tabindex="0"]'));
          const firstFocusableEl = focusableEls[0];
          const lastFocusableEl = focusableEls[focusableEls.length - 1];
          // if console does not have focus, focus on console, otherwise, focus on first focusable ele
          if (find(focusableEls, document.activeElement) === undefined) {
            firstFocusableEl.focus();
          }

          if (e.shiftKey) {
          // reverse tab
            if (document.activeElement === firstFocusableEl) {
              e.preventDefault();
              lastFocusableEl.focus();
            }
          } else if (document.activeElement === lastFocusableEl) {
          // forward tab
            e.preventDefault();
            firstFocusableEl.focus();
          }
        }

        break;
      default:
        break;
    }
  }

  mmCheck = () => (typeof window !== 'undefined'
    ? window.matchMedia('(min-width: 768px)').matches
    : false);

  resizeListener() {
    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
      this.thisWindowHeight = window.innerHeight;

      if (this.searchResultsModule.current.classList.contains('has-results')) {
        if (this.shouldShowResults) {
          this.searchResultsModule.current.style.height = `${this.thisWindowHeight}px`; // TODO: this height should be the same as what is calculated in navBodyScrollingHandler
        }
      }

      // close search console when mobile breakpoint is reached (both ways) to minimize any performance conflicts
      if (this.mmCheck() !== this.state.mmBreakpoint) {
        this.setState({ mmBreakpoint: this.mmCheck() });
        this.close();
      }

      this.searchOverlayEl.current.style.height = `${this.thisWindowHeight}px`; // TODO: revisit overlay resizing
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
      searchToggleOpenEl = document.getElementById('nmx-nav-link-utility-search');
      mobileNavContainer = document.getElementById('nmx-mobile-nav-container');
      searchToggleOpenEl.addEventListener('click', this.toggle.bind(this));
      window.addEventListener('keydown', this.keyDownListener, true);
      window.addEventListener('resize', this.resizeListener, true);

      Location.addEventListener('changestate', (event) => {
        if (event.state && Object.prototype.hasOwnProperty.call(event.state, 'query')) {
          this.setState({ query: event.state.query });
          this.performSearch();
        }
      });

      if (!isEmpty(this.state.query)) {
        this.open();
        this.performSearch();
      }

      this.thisWindowHeight = window.innerHeight;
      this.searchOverlayEl.current.style.height = `${this.thisWindowHeight}px`;
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
      searchToggleOpenEl.removeEventListener('click', this.toggle.bind(this));
      window.removeEventListener('keydown', this.keyDownListener, true);
      window.removeEventListener('resize', this.resizeListener, true);

      Location.removeEventListener('changestate', (event) => {
        if (event.state && Object.prototype.hasOwnProperty.call(event.state, 'query')) {
          this.setState({ query: event.state.query });
          this.performSearch();
        }
      });
    }
  }

  componentDidUpdate() {
    if (typeof window !== 'undefined') {
      // browser-only code - react-static has some server side rendering for build process
      const thisWindowHeight = window.innerHeight;
      this.searchResultsModule.current.style.height = 'auto';
      if (this.shouldShowResults) {
        this.searchResultsModule.current.style.height = `${thisWindowHeight - this.searchFormContainer.current.offsetHeight - 50}px`; // window height - search form height - mobile fixed header height
        if (this.state.isSafari && this.state.mmBreakpoint) {
          mobileNavContainer.style.height = `${this.thisWindowHeight}px`;
          // mobileNavContainer.style.overflowY = `auto`;
        }
      }
    }
  }

  get isOpen() {
    return this.state.isOpen;
  }

  setState(updatedState = {}, callback = undefined) {
    Object.entries(updatedState).forEach(([key, value]) => {
      this.state[key] = value;
    });
    return super.setState(updatedState, callback);
  }

  open() {
    this.setState({ isOpen: true });
    document.body.classList.add('modal-is-open');
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({ event: 'site search opened' });
    if (this.queryInput) {
      setTimeout(() => {
        if (this.state.query !== '') {
          this.searchClearButtonEl.current.style.visibility = 'visible';
        } else {
          this.searchClearButtonEl.current.style.visibility = 'hidden';
        }
        this.queryInput.focus();
      }, 100);
    }
  }

  close() {
    this.setState({ isOpen: false });
    document.body.classList.remove('modal-is-open'); // TODO: addressed, but confirm this listener is not having additional global issues.
    if (this.queryInput) {
      this.queryInput.blur();
    }
    this.setState({
      results: undefined,
      didSearch: false,
      query: '',
      reps: undefined,
      isSearching: false,
    });
    Location.updateQueryParams({ q: '' }, { query: '' });
    if (this.mmCheck()) {
      searchToggleOpenEl.focus();
    } else {
      this.queryInput.focus();
      this.queryInput.classList.remove('has-value');
      this.searchToggleCloseEl.current.classList.remove('has-value');
    }
    if (this.state.mmBreakpoint) {
      if (this.state.isSafari) {
        // reset desktop settings (deactivateMobileNav())
        mobileNavContainer.style.height = '116px'; // TODO: use rems in Styled Components
        mobileNavContainer.style.overflowY = 'visible';
        // clearTimeout(safariSearchOpenTimer);
      }
    } else {
      // enable mobile menu scrolling
      navBodyScrollingHandler();
    }
  }

  toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  clearInput() {
    this.setState({ // TODO: this code is repeated a couple of times, probably can be consolidated
      results: undefined,
      didSearch: false,
      query: '',
      reps: undefined,
      isSearching: false,
    });
    Location.updateQueryParams({ q: '' }, { query: '' });
    this.searchClearButtonEl.current.style.visibility = 'hidden';
    this.queryInput.focus();
    if (!this.state.results) {
      this.queryInput.classList.remove('has-value');
      this.searchToggleCloseEl.current.classList.remove('has-value');
    }
    // enable mobile menu scrolling
    // mobileNavContainer.style.overflowY = 'auto'
    if (this.state.isSafari && this.state.mmBreakpoint) {
      // Safari reset search height
      mobileNavContainer.style.height = '116px'; // TODO: use rems in Styled Components
      mobileNavContainer.style.overflowY = 'visible';
    }
  }

  handleInputChange(event) {
    const target = event.target || event.srcElement || {};
    const query = target.value || '';

    this.setState({ query });

    if (query !== '') {
      this.searchClearButtonEl.current.style.visibility = 'visible';
      this.queryInput.classList.add('has-value');
      this.searchToggleCloseEl.current.classList.add('has-value');
    } else {
      this.searchClearButtonEl.current.style.visibility = 'hidden';
      if (!this.state.results) { // allow Cancel button to remain on mobile when search results visible but query has cleared
        this.queryInput.classList.remove('has-value');
        this.searchToggleCloseEl.current.classList.remove('has-value');
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    const { query } = this.state;
    const queryParams = {};

    if (!isEmpty(query)) {
      queryParams.q = encodeURIComponent(query);
    }
    Location.updateQueryParams(queryParams, { query });
  }

  calculateAdobeSearchResultsNumber() {
    if (this.shouldShowNoResults) {
      return 'zero';
    }

    if (this.shouldShowFRResults) {
      return '1';
    }

    return this.state.results.length;
  }

  performSearch() {
    // If the query was empty, then don't do anything and clear results
    if (isEmpty(this.state.query)) {
      return this.setState({
        query: '',
        results: undefined,
        reps: undefined,
        isSearching: false,
      });
    }

    this.setState({ isSearching: true });

    // Otherwise, perform a search
    Promise.all([
      SearchService.getSearchResults({ q: this.state.query }),
      SearchService.getFrs({
        fullName: this.state.query,
        'page[number]': 0,
        'page[size]': 20,
      }),
      SearchService.getTeams({
        name: this.state.query,
        isEnsemble: true,
      }),
    ])
      .then(([results, reps, ensembles]) => {
        this.setState({
          didSearch: true,
          results: results.data,
          reps: reps.data,
          ensembles: ensembles.data,
          isSearching: false,
        }, () => {
          window.adobeDataLayer = window.adobeDataLayer || [];
          window.adobeDataLayer.push({
            event: 'site search completed',
            'site-search': {
              term: this.state.query.toLowerCase(),
              'results-number': this.calculateAdobeSearchResultsNumber(),
              'results-type': this.shouldShowFRResults ? 'FR-card' : 'standard',
            },
          });
        });
      })
      .catch((err) => {
        newRelicJSError(err);
        console.error(err);
        this.setState({ isSearching: false });
      });
    return false;
  }

  get shouldShowLoading() {
    return this.state.isSearching;
  }

  get shouldShowSearchResults() {
    if (this.state.isSearching) {
      return false;
    }

    if (!this.state.results) {
      return false;
    }

    const numberOfWordsSearched = this.state.query.split(' ').length;
    const numberOfSearchResults = this.state.results.length;
    const numberOfRepResults = this.state.reps.length;
    const numberOfEnsembleResults = this.state.ensembles.length;

    // If 1 FR was returned and 1 search result when only searching with 1 word
    // we can assume that 1 search result is that FR's profile page.
    // In this case don't show the result because we'll show the FR card instead.
    if (numberOfWordsSearched === 1
       && numberOfRepResults === 1
       && numberOfEnsembleResults === 0
       && numberOfSearchResults === 1) {
      return false;
    }

    // If more than 1 word was searched like "Ben Feldman" and
    // only one FR is returned then don't show search results because
    // we'll show the FR card instead.
    if (numberOfWordsSearched > 1
      && numberOfRepResults === 1) {
      return false;
    }

    return this.state.results.length > 0;
  }

  get shouldShowFRResults() {
    if (this.state.isSearching) {
      return false;
    }

    if (!this.state.reps) {
      return false;
    }

    // Only show FR results if there is one FR and no ensembles returned.
    // Also, only show FR card if the user searched at least 2 words
    // because words like "ira" and "login" return a single FR and we
    // want to show all results instead of a single FR for that use case.
    // Also, show FR card if thats literally the only result returned.
    // Example: "beiser" will return 1 FR result and 1 search result so
    // we want to show the FR card because we don't show the search results in this use case.
    // Ensembles wanted the normal results to show when their ensemble was searched
    return this.state.reps.length === 1
     && this.state.ensembles.length === 0
     && (this.state.query.split(' ').length > 1 || this.state.results.length === 1);
  }

  get shouldShowResults() {
    if (this.state.isSearching) {
      return false;
    }

    if (!this.state.didSearch) {
      return false;
    }

    if (!this.state.mmBreakpoint) {
      // disable mobile menu scrolling if search results is active
      mobileNavContainer.style.overflowY = 'hidden';
    }

    return (this.shouldShowSearchResults || this.shouldShowFRResults);
  }

  get shouldShowNoResults() {
    if (this.state.isSearching) {
      return false;
    }

    if (!this.state.didSearch) {
      return false;
    }

    return !(this.shouldShowSearchResults || this.shouldShowFRResults);
  }

  resultsLinkClickedHandler = ({ resultsText, resultsUrl, resultsType }) => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: 'site search results clicked',
      'site-search': {
        term: this.state.query.toLowerCase(),
        'results-number': this.calculateAdobeSearchResultsNumber(),
        'results-type': resultsType,
        'results-text': resultsText,
        'results-href': resultsUrl,
      },
    });
  };

  render() {
    const resultsNumStyles = {
      padding: (this.mmCheck())
        ? '0'
        : '0 1rem', // TODO: we could probably use React Hooks for this, make a little more robust
    };

    return (
      <div className={classNames('nmx-search', { 'is-open': this.isOpen }, { 'has-results': this.state.results })}>
        <span className={classNames('nmx-search-overlay', { 'has-results': this.state.results })} ref={this.searchOverlayEl} onClick={this.close.bind(this)}></span>
        <div id='nmx-search-container' ref={this.searchContainerEl} className={classNames('nmx-container', 'nmx-container__search', { 'has-results': this.state.results })}>
          <div className='nmx-row'>
            <div className='nmx-col nmx-col-nested'>
              <div className='nmx-row'>
                <div id='nmx-search-form-container' className='nmx-col nmx-col-medium-10 nmx-col-medium--offset-1'>
                  <button id="nmx-search-toggle-close" className="nmx-button" ref={this.searchToggleCloseEl} onClick={this.close.bind(this)}>Cancel <span className='nmx-assistive-text'>and Close Search</span></button>
                  <form className='nmx-search-form' id='nmx-search-form' ref={this.searchFormContainer} onSubmit={this.handleSubmit.bind(this)} role='search' autoComplete='off'>
                    <legend>Search NorthwesternMutual.com</legend>
                    <label htmlFor='nmx-search-field' className='nmx-hide'>Search for specific advisors, products, services, articles, etc.</label>
                    <input
                      type='search'
                      role='searchbox'
                      placeholder='Search nm.com'
                      value={this.state.query}
                      onChange={this.handleInputChange.bind(this)}
                      id='nmx-search-field'
                      ref={(input) => this.queryInput = input} // TODO: reconfigure ref to follow other ref pattern
                    />
                    <button id='nmx-search-form-submit' className='nmx-button' type='submit' value='Search'>Search</button>
                    <button type='reset' className={classNames('nmx-icon nmx-icon-search-field-clear', { 'no-query': !this.state.query })} onClick={this.clearInput.bind(this)} ref={this.searchClearButtonEl}>Clear</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className='nmx-row'>
            <div className='nmx-col nmx-col-nested'>
              <div id='nmx-search-results' ref={this.searchResultsModule} className={classNames('nmx-search-results', { 'has-results': this.state.results })}>
                <div className='nmx-row'>
                  <div className='nmx-col nmx-col-medium-10 nmx-col-medium--offset-1'>

                    {jsxIf(this.shouldShowLoading, () => (
                      <div className='loader' />
                    ))}
                    {jsxIf(this.shouldShowNoResults, () => (
                      <p className='nmx-search-results__zero-matches-message'>Sorry, there were 0 results matching your search.</p>
                    ))}
                    {jsxIf(this.shouldShowFRResults, () => (
                      <Fragment>
                        <p style={resultsNumStyles}>Showing 1 Result for {this.state.query}</p>
                        <DynamicAgentCard
                          customName='advisor+search'
                          photoWidth={75}
                          agentObject={this.state.reps[0]}
                          campaign='NM.com Search'
                          source='NM.com'
                          topic='Find an Advisor Form - Search'
                          config={this.props.config}
                          searchAnalytics={{
                            searchTerm: this.state.query.toLowerCase(),
                            resultsNumber: this.calculateAdobeSearchResultsNumber(),
                          }} />
                      </Fragment>
                    ))}
                    {/*
                      <span className="is-small">Showing 1-{this.state.results.length} of {this.state.results.resultCount} Results</span>
                    */}
                    {jsxIf(this.shouldShowSearchResults, () => (
                      <ul id='nmx-search-results-list' className='nmx-search-results-list reduced' >
                        {this.state.results.map((result, index) => (
                          <li className='nmx-search-result-item' key={index}>
                            <a
                              href={result.url}
                              aria-label={result.title}
                              onClick={() => this.resultsLinkClickedHandler({
                                resultsText: result.title,
                                resultsUrl: result.url,
                                resultsType: (result.category || result.publishDate) ? 'article' : 'standard',
                              })}>
                              {jsxIf(result.category || result.publishDate, () => (
                                <div className='nmx-row'>
                                  <div className='nmx-col nmx-col-medium--offset-4 nmx-search-result-item-eyebrow'>
                                    {jsxIf(result.category, () => (
                                      <span className='nmx-search-result-item-eyebrow-category'>{result.category}</span>
                                    ))}
                                    {jsxIf(result.publishDate, () => (
                                      <Fragment>
                                        &#8211; <span className='nmx-search-result-item-eyebrow-date'>{result.publishDate}</span>
                                      </Fragment>
                                    ))}
                                  </div>
                                </div>
                              ))}
                              <div className='nmx-row'>
                                {jsxIf(result.thumbnail, () => (
                                  <div className='nmx-col nmx-col-xsmall-12 nmx-col-medium-4 nmx-search-result-item-image nmx-col-left-text-padding-right'>
                                    <picture>
                                      <img src={result.thumbnail} alt={result.title} />
                                    </picture>
                                  </div>
                                ))}
                                <div className={classNames('nmx-col nmx-col-xsmall-12 nmx-search-result-item-content', { 'nmx-col-medium-8': result.thumbnail })}>
                                  <h3>{result.title}</h3>
                                  <p>{result.excerpt}</p>
                                </div>
                              </div>
                            </a>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NMXSearchComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    public: PropTypes.shape({ googlePlacesApiKey: PropTypes.string.isRequired }),
    services: PropTypes.shape({
      serviceRequestApiBaseUrl: PropTypes.string,
      leadApiBaseUrl: PropTypes.string,
    }),
  }),
};

NMXSearchComponent.defaultProps = {
  config: {
    public: { googlePlacesApiKey: '<%=googlePlacesApiKey%>' },
    services: {
      serviceRequestApiBaseUrl: '<%=serviceRequestApiBaseUrl%>',
      leadApiBaseUrl: '<%=leadApiBaseUrl%>',
    },
  },
};

export default NMXSearchComponent;
