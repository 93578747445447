/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import GetMatchedIcon from '../../../icons/layout/GetMatched';

export const IconStyled = styled(GetMatchedIcon)`
  height: ${rem(65)};
  width: ${rem(300)};
  margin-bottom: ${rem(30)};
`;

const ellipsis = keyframes`
  0% {
    width: 0;
  }
  25% {
    width: ${rem(10)};
  }
  50% {
    width: ${rem(18)};
  }
  75% {
    width: ${rem(30)};
  }
  100% {
    width: 0;
  }
`;

export const SpanAnimatedEllipsisStyled = styled.span`
  :after {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    width: 0;
    animation: ${ellipsis} steps(1, end) 2s infinite;
    content: '...';
    vertical-align: bottom;
  }
`;
