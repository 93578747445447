import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Col } from '../../base/Grid';
import Box from '../../base/Box';
import Link from '../../base/Link';
// icon imports
import caratRight from '../../icons/utility/CaratRight';
import Typography from '../../base/Typography';

import {
  ColLinkContainerStyled,
  DivProductCalloutCardStyled,
  IconCardTitleStyled,
  LinkStyled,
  ProductCalloutRow,
  RowHeaderStyled,
  Styles,
  TypographyCardCopyStyled,
  TypographyHeaderStyled,
  WidowBlockStyled,
} from './styles';

// If the card is condensed, the button overlay will kick in to handle link functionality,
// and the button props will be applied to the CTA. (buttonId and buttonUrl)

export const ProductCalloutComponent = ({
  ariaLabel,
  bgVariant,
  cardId,
  className,
  condensed,
  config,
  contentBlock,
  copy,
  fixHeight,
  icon,
  linkId,
  linkText,
  linkUrl,
  footnote,
  title,
  withHeaderAccent,
}) => {
  const styles = Styles();
  const IconToRender = icon;
  let titleAsString;
  // if the title being passed in is a fragment/object
  if (typeof title === 'object') {
    // stringify the title, since it may be coming in as an object/fragment
    titleAsString = title.props.children.toString();
  } else {
    // if the title props is already a string, we will just use that.
    titleAsString = title;
  }

  // slice and dice the title string for proper injection into the condensed variant. This ultimately prevents the arrow adornment from wrapping on its own line.
  const titleWithoutLast2Words = titleAsString.split(' ').slice(0, -2).join(' ');
  const titleLastTwoWords = titleAsString.split(' ').splice(-2).join(' ');

  const ProductCalloutWrapper = ({ children }) => (condensed
    ? (
      <LinkStyled
        href={linkUrl}
        id={linkId}>
        {children}
      </LinkStyled>
    )
    : children
  );

  return (
    <ProductCalloutWrapper>
      <DivProductCalloutCardStyled
        bgVariant={bgVariant}
        className={className}
        id={cardId}
        condensed={condensed}
        fixHeight={fixHeight}>
        <ProductCalloutRow>
          <Col xsmall={12}>
            <RowHeaderStyled>
              <TypographyHeaderStyled
                component='h3'
                condensed={condensed}
                withHeaderAccent={withHeaderAccent}>
                {/* If not condensed, just render the title string, otherwise, use the split out version so we can properly append the arrow icon and prevent the icon wrapping as a widow */}
                {!condensed ? (
                  title
                ) : (
                  <>
                    {titleWithoutLast2Words} <WidowBlockStyled>{titleLastTwoWords} <IconCardTitleStyled className='icon-product-callout-arrow' /></WidowBlockStyled>
                  </>
                )}
              </TypographyHeaderStyled>
              {icon && !condensed
            && (
              <IconToRender style={styles.IconCardMainStyled} />
            )}
            </RowHeaderStyled>
            {/* Optional copy props for rendering simple para text */}
            {(copy && !condensed)
            && (
              <TypographyCardCopyStyled>
                {copy}
              </TypographyCardCopyStyled>
            )}
            {/* Optional content block props for rendering more complex values; e.g. an unordered list */}
            {(contentBlock && !condensed)
            && (
              <>
                {contentBlock}
              </>
            )
            }
          </Col>
        </ProductCalloutRow>
        {linkText && (
          <ProductCalloutRow
            condensed={condensed}
            className='nmx-no-print'>
            <ColLinkContainerStyled xsmall={12}>
              <Link
                id={linkId}
                variant='primary'
                href={linkUrl}
                ariaLabel={ariaLabel}
                config={config}
                endAdornment={caratRight}>
                {linkText}
              </Link>
            </ColLinkContainerStyled>
          </ProductCalloutRow>
        )}
        {/* TODO: footnote is used by FWM directly in product callout. Swap for backend service that can be rendered at bottom of page similar to NMCOM & Gelato. */}
        {footnote && (
          <Box sx={{ mt: 1 }}>
            <ProductCalloutRow>
              <Col>
                <Typography
                  component='small'
                  disableBottomPadding>
                  {footnote}
                </Typography>
              </Col>
            </ProductCalloutRow>
          </Box>
        )}
      </DivProductCalloutCardStyled>
    </ProductCalloutWrapper>
  );
};

ProductCalloutComponent.propTypes = {
  bgVariant: PropTypes.string,
  cardId: PropTypes.string.isRequired,
  /** optional additional className */
  className: PropTypes.string,
  condensed: PropTypes.bool,
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  contentBlock: PropTypes.any,
  copy: PropTypes.any,
  icon: PropTypes.any,
  linkId: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

ProductCalloutComponent.defaultProps = {
  bgVariant: 'secondary',
  condensed: false,
  config: {
    basePath: '<%=basePath%>',
    nmxTemplateVersion: '<%=nmxTemplateVersion%>',
  },
};

export default withTheme(ProductCalloutComponent);
