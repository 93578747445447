import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { Col, Container } from '../../base/Grid';
import Button from '../../base/Button';
import Link from '../../base/Link';

const mobileNavWidth = rem(320);
const isSafari = /^((?!chrome|android).)*safari/i.test(
  typeof navigator !== 'undefined'
    ? navigator.userAgent
    : '',
);

const mobileNavOpen = keyframes`
  0%,
  5% {
    transform: translateX(0);
  }
  75%,
  100% {
    transform: translateX(100%);
  }
`;

const mobileNavClose = keyframes`
  0%,
  5% {
    transform: translateX(${mobileNavWidth});
  }
  75%,
  100% {
    transform: translateX(0);
  }
`;

/** ============================================================
// nmx-header
// ============================================================ */
export const HeaderStyled = styled.header`
  ${({ theme, nmxTemplateVersion, basePath, headerStates, hasFwmNav, hasBreadcrumbsNavigation }) => {
    const largeUp = theme.largeUp();
    const largeDown = theme.largeDown();
    const smallDown = theme.smallDown();
    const smallUp = theme.smallUp();
    const large1200Up = theme.large1200Up();
    const custom2300Up = theme.custom2300Up();
    const mediumUp = theme.mediumUp();

    // height config
    let thisHeight = largeUp ? 115 : 50; // default header height
    const thisHeaderBottomBorderHeight = largeUp ? 1 : 0;
    let thisBreadcrumbsNavigationHeight = 0; // default breadcrumbsNav height
    if (hasBreadcrumbsNavigation) {
      thisBreadcrumbsNavigationHeight = mediumUp ? 53 : 40;
    }
    const thisInformationalBannerHeight = 0; // default informationalBanner height
    // if (showInformationalBanner) {
    //   thisInformationalBannerHeight = largeUp ? 64 : 50; // may vary based on variant // TODO: this is handled in InformationalBanner
    // }
    thisHeight = rem(thisHeight + thisHeaderBottomBorderHeight + thisBreadcrumbsNavigationHeight + thisInformationalBannerHeight); // add up header element heights

    return css`
      /** // is-overlayed only on desktop (with or without full header nav) */
      ${largeUp
        && headerStates.isOverlayed
        && css`
          height: auto;
          #nmx-mobile-nav-container {
            /** // using id for higher specificity */
            position: absolute;
            max-width: ${rem(2000)};
            height: ${rem(thisHeight + thisBreadcrumbsNavigationHeight + thisInformationalBannerHeight)}; /** TODO: use dynamic height */
            padding-right: 0;
            padding-left: 0;
            background-color: transparent;
            &::before {
              position: absolute;
              z-index: ${theme.zIndex.globalNavMain};
              top: 0;
              right: 0;
              left: 0;
              height: ${rem(thisHeight + thisBreadcrumbsNavigationHeight + thisInformationalBannerHeight)}; /** TODO: use dynamic height */
              border-bottom: 1px solid ${theme.colors.neutral.white};
              background: linear-gradient(rgba(#000, 0.8), rgba(#000, 0.2));
              content: '';
            }
          }
          .nmx-header-logo {
            /** // TODO: use dynamic template asset path */
            ${largeUp
              && css`
            /** // TODO: what is this doing here? test redundancy */
            z-index: ${theme.zIndex.globalNavMain};
            background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-white.svg'); /** TODO: encode svg // TODO: needs dynamic asset path treatment */
            &-container {
              z-index: ${theme.zIndex.globalNavLogo};
              background-color: transparent;
            }
          `}
          }
          .nmx-nav-primary {
            .nmx-nav-link {
              &-primary {
                color: ${theme.colors.neutral.white};
                &:hover,
                &:focus {
                  color: ${theme.colors.cta.yellow};
                }
                &-container {
                  background-color: transparent;
                }
              }
            }
          }
          &.nmx-informational-banner-active {
            margin-bottom: ${rem(-121)}; // TODO: this needs adjustment for stickyNav?
            #nmx-mobile-nav-container {
              position: relative;
            }
          }
        `}
      /** // end is-overlayed */

      /** // is-logo-only on desktop and mobile */
      ${headerStates.isHidden
        && css`
        #nmx-nav-primary,
        #nav-utility-container,
        #nmx-search-console,
        #nav-toggle,
        .nmx-login-button,
        #nmx-client-login {
          display: none;
        }


        #nmx-mobile-nav-container {
          &::before {
            display: none;
          }
        }
        ${largeDown
          && css`
            border-bottom: none;
            #nmx-mobile-nav-container {
              left: 0;
              overflow-y: auto;
            }
            #header-logo {
              left: 0;
              margin-left: ${rem(16)};
            }
          `}

        /** // has-logo-eyebrow header on desktop and mobile */
        ${headerStates.isLogoEyebrow
          && css`
            height: auto;

            #nmx-mobile-nav-container {
              position: relative;
              /** // TODO: can consolidate default styles with is-logo-only // using id for higher specificity */
              top: 0;
              /** TODO: if has-logo-eyebrow mode, don't set #nmx-mobile-nav-container height via javascript */
              height: ${hasFwmNav ? rem(70) : rem(50)} !important;

              .nmx-nav-primary-container {
                height: ${hasFwmNav ? rem(70) : rem(50)};
                margin-top: 0;
                .nmx-header-logo-container {
                  height: ${hasFwmNav ? rem(70) : rem(50)};
                  .nmx-header-logo {
                    ${hasFwmNav ? `
                      margin-left: ${rem(16)};
                      margin-top: ${smallDown ? 0 : rem(-5)};
                    ` : `
                      margin: ${rem(-5)} auto 0;
                      ${smallDown
                        && css`
                          margin-top: ${rem(5)};
                      `}
                    `}
                  }
                }
              }
            }

            ${headerStates.isLogoEyebrowLarge
              && css`
                #nmx-mobile-nav-container {
                  ${largeUp
                    && css`
                      /** TODO: if has-logo-eyebrow mode, don't set #nmx-mobile-nav-container height via javascript */
                      height: ${rem(100)} !important;
                      .nmx-header-logo-container {
                        width: auto;
                        height: ${rem(100)};
                        .nmx-header-logo {
                          width: ${rem(500)};
                          height: ${rem(60)};
                          margin-top: 0;
                        }
                      }
                    `}
                }
              `}
          `}
        /** // end has-logo-eyebrow */

        ${headerStates.isOverlayed
          && largeDown
          && css`
            /** // TODO: can consolidate with is-overlayed for full nav */
            #nmx-mobile-nav-container {
              position: relative;
              /** // using id for higher specificity */
              top: 0;
              height: ${rem(50)} !important;
              .nmx-nav-primary-container {
                height: ${rem(50)};
                margin-top: 0;
                .nmx-header-logo {
                  margin: ${rem(-5)} auto 0;
                  ${smallDown
                    && css`
                      margin-top: ${rem(5)};
                    `}
                }
              }
            }
          `}

        /** // auto-remove bottom border on logo-only */
        border-bottom: none;
      `}
      /** // end is-logo-only */

      /** // show utiltiy nav blue bg extension */
      #nmx-nav-link-fafa-link-container {
        &::after {
          ${largeUp
            && css`
              position: fixed;
              z-index: -1;
              top: 0;
              right: 0;
              display: block;
              width: 20%;
              height: ${rem(40)};
              background-color: ${theme.colors.primary.blue};
              content: '';
            `}
          /** to prevent blue bg extension from getting detached from utility nav, introduce reattachment at extremely large viewport widths  */
          ${custom2300Up
            && css`
              position: absolute;
              left: 95%;
              right: unset;
              width: 50%;
            `}
        }
      }
      height: ${thisHeight}; /** TODO: revisit - InformationalBanner currently set up to handle breadcrumbs + infobanner + header */
      background-color: ${theme.colors.neutral.white};
      ${headerStates.isWithoutBorder
        && css`
          border-bottom: none;
        `}

      .nmx-header-logo {
        position: relative;
        /** // TODO: most of these mobile styles will be consolidated once */
        top: 0;
        left: ${rem(-15)};
        display: flex;
        width: 100%;
        max-width: ${rem(180)};
        height: ${rem(30)}; /** TODO: 25% or 15px below 380px viewport width */
        align-self: center;
        margin: 0 auto;
        /** TODO: encode svg // TODO: needs dynamic asset path treatment */
        background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-navy.svg');
        background-position-x: center;
        background-repeat: no-repeat;
        background-size: contain;
        text-indent: ${rem(-9999)};
        ${smallUp
          && css`
            max-width: ${rem(250)};
          `}
        ${largeUp
          && css`
            left: 0;
            width: ${rem(250)};
            max-width: unset;
            margin: 0;
            background-position-x: 0;
            background-size: cover;
          `}
        ${large1200Up
          && css`
            margin-top: ${rem(-15)};
          `}

        &-container {
          &#{&} { /** TODO: use styled-components double-specificity here */
            position: fixed;
            z-index: ${theme.zIndex.globalNavOverlay};
            display: flex;
            width: 100%;
            height: ${rem(50)};
            background-color: ${theme.colors.neutral.white};
            ${largeDown
            && css`
              box-shadow: ${theme.shadows.small};
            `}
            /** //  if nmx-informational-banner-active mobile, set from JS */
            ${!largeUp
              && css`
                box-shadow: ${theme.shadows.small};
              `
}
            ${largeUp
              && css`
                position: absolute;
                z-index: ${theme.zIndex.globalNavLogo};
                left: 0;
                display: flex;
                width: ${rem(250)};
                max-width: unset;
                height: ${rem(40)};
                padding: 0;
                margin-left: ${rem(20)};
              `}
            ${large1200Up
              && css`
                position: relative;
                display: inline-flex;
                height: ${rem(75)};
                margin-left: ${rem(28)};
              `}
          }
        }
      }
      .nmx-container {
        max-width: none;
        padding: 0;
        margin: 0;
        background-color: ${theme.colors.neutral.white};

        &-inner {
          max-width: ${rem(1440)};
          margin: auto;
          ${largeUp
            && css`
              padding: 0 0.75rem; /** initial */
            `}
        }
      }
      /** // if nmx-informational-banner active // .nmx-header */
      /* &.nmx-informational-banner-active {
        .nmx-header-logo-container {
          top: 0;
        }
      } */
    }`;
  }}
`;

export const PrimaryHeaderContainerStyled = styled(Container)`
  ${({ theme, isSticky }) => {
    const largeUp = theme.largeUp();

    return css`
      box-shadow: ${theme.shadows.small};
      
      ${(largeUp && isSticky) && css`
        &#{&} {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          z-index: ${theme.zIndex.globalNavPrimary};
        }`}
    `;
  }}
`;

/** ============================================================
// nmx-nav-primary
// ============================================================ */
export const ColNavContainerStyled = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const large1200Up = theme.large1200Up();
    return css`
      height: ${rem(82)};
      padding: 0;
      text-align: center;
      ${largeUp
        && css`
          max-width: max-content;
          height: ${rem(40)};
          /** TODO: test 'inherit' in IE */
          padding: inherit;
          text-align: left;
        `}
      ${large1200Up
        && css`
          position: relative;
          max-width: unset;
          height: ${rem(75)};
          margin-top: ${rem(40)};
        `}
    `;
  }}
`;

export const NavPrimaryStyled = styled.nav`
  ${({ theme, nmxTemplateVersion, basePath }) => {
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();
    const largeDown = theme.largeDown();
    const large1200Up = theme.large1200Up();
    const custom768820 = theme.custom768820();
    return css`
      position: absolute;
      z-index: ${theme.zIndex.globalNavPrimary};
      width: 100%;
      border-top: 1px solid ${theme.colors.neutral.gray59};
      margin-top: ${rem(82)};

      ${largeUp
        && css`
          position: absolute;
          top: ${rem(40)};
          display: inline-block;
          width: auto;
          border-top: none;
          margin-top: 0;
        `}

      ${large1200Up
        && css`
          top: 0;
        `}

      .nmx-nav-link-primary {
        /** // TODO: look at organizing this better with .nmx-nav-link */
        position: relative;
        height: 100%;
        border-bottom: 1px solid ${theme.colors.neutral.gray59};
        color: ${theme.colors.primary.blue};
        font-weight: 500;
        letter-spacing: ${rem(3)};
        text-transform: uppercase;
        text-decoration: none;
        &::after {
          position: absolute;
          right: 5%;
          width: ${rem(15)};
          height: ${rem(15)};
          /** TODO: encode svg // TODO: needs dynamic asset path treatment */
          background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/icons/utility/chevron-right.svg');
          background-repeat: no-repeat;
          background-size: ${rem(10)};
          content: ' ';
          /** color to #1570bc (${theme.colors.cta.blue}) */
          filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
            brightness(100%) contrast(84%);
          ${largeUp
            && css`
              display: none;
              background: none;
              filter: none;
            `}
        }
        &:hover {
          text-decoration: none;
        }
        ${largeUp
          && css`
            z-index: ${theme.zIndex.globalNavPrimary};
            display: flex;
            align-items: center;
            border: none;
            margin: 0 ${rem(15)};
            &#nmx-nav-link-primary-who-we-are {
              margin-left: 0;
            }
          `}
        ${large1200Up
          && css`
            &#nmx-nav-link-primary-who-we-are {
              margin-left: ${rem(15)};
            }
          `}
        &-container {
          height: ${rem(60)};
          text-align: left;
          ${largeUp
            && css`
              display: inline-block !important;
              height: ${rem(75)};
            `}
        }

        &.is-active,
        &.has-focus,
        &.has-hover,
        /** // &[data-subnav-active=true], */
        &.subnav-is-active {
          color: ${theme.colors.cta.yellow};
          &::after {
            ${largeUp
              && css`
                position: absolute;
                bottom: ${rem(-1)};
                left: 0;
                display: block;
                width: 100%;
                height: ${rem(4)};
                margin-top: ${rem(13)};
                background: ${theme.colors.cta.yellow};
                content: '';
              `}
          }
        }

        /** subnav level1 */
        &-sub,
        &-sub-level2 {
          /** // subnav level2 */
          display: block;
          padding: 0;
          color: ${theme.colors.primary.blue};
          font-weight: 500;
          letter-spacing: ${rem(3)};
          text-transform: uppercase;
          /** // TODO: these could possibly be eventually built into some global menu defaults */
          &:focus,
          &:hover,
          &:active {
            color: ${theme.colors.primary.blue};
          }
          ${largeUp
            && css`
              padding: ${rem(10)} ${rem(16)};
              color: ${theme.colors.neutral.gray29};
            `}
        }
        &-sub {
          &.has-subnav-level2 {
            &::after {
              position: absolute;
              right: 5%;
              width: ${rem(15)};
              height: ${rem(15)};
              background-image: url('${basePath}/template/assets/${nmxTemplateVersion}/images/icons/utility/chevron-right.svg');
              background-repeat: no-repeat;
              content: ' ';
              filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
                brightness(100%) contrast(84%);
              ${largeUp
                && css`
                  display: none;
                `}
            }
          }
        }
        &-sub-level2 {
          display: inline-flex;
          align-items: center;
          text-align: right;
          &.has-icon {
            height: ${rem(42)} !important; /** TODO: work with proper styled components specificity to avoid !important */
            padding: ${rem(16)} ${rem(50)} ${rem(16)} ${rem(21)};
            padding-right: ${rem(50)};
            border: none !important; /** TODO: work with proper styled components specificity to avoid !important */
            margin-right: ${rem(36)};
            margin-left: ${rem(16)} !important; /** TODO: work with proper styled components specificity to avoid !important */
            background-color: ${theme.colors.neutral.gray95};
            text-align: left;
            ${largeUp
              && css`
                height: ${rem(30)} !important; /** TODO: work with proper styled components specificity to avoid !important */
                margin-right: 0;
                margin-left: ${rem(20)};
                text-align: right;
              `}
            @supports (background: -webkit-named-image(i)) {
              /** Safari fix */
              ${largeUp
                && css`
                  margin-right: ${rem(36)};
                `}
            }
            &.nmx-subnav-level2-item-life-insurance {
              .nmx-icon {
                ${largeUp
                  && css`
                    margin-right: ${rem(36)};
                    right: ${rem(10)};
                    @supports (background: -webkit-named-image(i)) {
                      margin-right: 0;
                      /** Safari fix */
                    }
                  `}
              }
            }
          }
          ${largeUp
            && css`
              padding: ${rem(5)} ${rem(10)};
              margin: ${rem(3)} 0 ${rem(3)} ${rem(30)};
              &.nmx-nav-link-primary-sub-level2 {
                /** // nested for higher specificity // TODO: can be cleaned up */
                font-weight: 400;
                white-space: nowrap;
              }
            `}
          .nmx-icon {
            position: absolute;
            right: 0;
            width: ${rem(55)};
            height: ${rem(55)};
            margin-right: ${rem(10)};
            margin-left: ${rem(10)};

            ${largeUp
              && css`
                width: ${rem(50)};
                height: ${rem(50)};
                /** safari fix */
                margin-top: 0;
                @media all and (-ms-high-contrast: none) {
                  right: 4rem;
                  /** // ie11 */
                  margin-top: ${rem(-15)};
                }
              `}
          }
        }
      }

      .nmx-subnav,
      .nmx-subnav-level2 {
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        position: absolute;
        z-index: ${theme.zIndex.globalNavPrimary};
        display: block;
        width: 100%;
        margin-left: -100%;
        animation: none;
        opacity: 1;
        transition: margin-left 0.15s ease-out;
        ${largeDown
          && css`
            top: 0;
          `}

        & > .nmx-nav-link-container {
          ${largeDown
            && css`
              height: ${rem(60)};
              background-color: ${theme.colors.neutral.white};
              .nmx-nav-link {
                &:not(.nmx-nav-link-primary-sub--first):not(.nmx-nav-link-primary-sub-level2--first):not(.nmx-nav-link-primary-sub--last):not(.nmx-nav-link-primary-sub-level2--last) {
                  border-bottom: 1px solid ${theme.colors.neutral.gray59};
                }
              }
            `}
          /** safari menu width fix */
          ${largeUp
            && css`
              width: max-content;
            `}
        }

        &.is-active {
          margin-left: 0;
        }
      }

      .nmx-subnav {
        ${largeUp
          && css`
            position: absolute;
            display: block;
            width: auto;
            padding: ${rem(16)};
            margin-top: ${rem(2)};
            margin-left: 0;
            @media all and (-ms-high-contrast: none) {
              /** // IE11 set base collapsed state */
              max-width: ${rem(420)};
              height: 0 !important;
              padding: 0;
            }

            background-color: ${theme.colors.neutral.white};
            opacity: 0;
            transform-origin: 20% -10%;
            transition: transform 0.15s ease, opacity 0.15s ease;
            transform: scale(0.1) translateY(-10%);
            box-shadow: 0 ${rem(4)} ${rem(16)} 0 rgb(0 0 0 / 10%);

            &.is-active {
              @media all and (-ms-high-contrast: none) {
                /** // IE11 visible nav state (no animation for you, IE11) */
                height: auto !important;
                padding: ${rem(16)};
              }

              opacity: 1;
              transform: scale(1) translateY(0);
            }
          `}

        ${(isSafari
          && largeUp)
          && css`
            /** // Safari animation and transitions failing // (sorry, no animation for you either, Safari (for now)) // TODO: revisit (JavaScript) menu animation in Styled Components when Header is converted over to nmx-template 2.0.x */
            opacity: 0 !important;
            transform: scale(0.1) translateY(-10%) !important;
            transition: none !important;
            &.is-active {
              opacity: 1 !important;
              transform: scale(1) translateY(0) !important;
            }
          `}

        /** // right alignment between medium and large for far-right (Life and Money) subnav */
        /* &-life-and-money {
          ${custom768820
            && css`
              right: 0;
            `}
        } */
        ${largeUp
          && css`
            margin-top: ${rem(1)};
          `}
      }

      .nmx-subnav-level2 {
        ${largeUp
          && css`
            position: relative;
            padding-right: ${rem(30)};
            margin: ${rem(3)} 0;
          `}
        .nmx-nav-link-mobile-subnav-overview-container {
          /** remove duplicate subnav-level2 mobile overview on desktop viewport widths using mobile/touchscreen devices // TODO: hook up "nmx-nav-link-mobile-subnav-level2-overview" and "nmx-nav-link-mobile-subnav-overview-container" */
          ${largeUp
            && css`
              display: none;
            `}
        }
      }
      @supports (background: -webkit-named-image(i)) {
        /** Safari fix */
        .nmx-subnav-level2 {
          /** safari subnav-level2 spacing */
          ${largeUp
            && css`
              padding-right: 0;
            `}
        }
      }
    }

    /** ============================================================
    nmx-nav-link
    ============================================================ */
    .nmx-nav-link-container {
      display: flex;
      align-items: center;
      background-color: ${theme.colors.neutral.white};
      ${largeUp
        && css`
          display: inherit;
          align-items: normal;
          padding: 0;
          border-bottom: none;
        `}

      .nmx-nav-link {
        width: 100%;
        height: 100%;

        ${largeDown
          && css`
            display: flex;
            align-items: center;
            margin: 0 ${rem(16)};
          `}

        ${largeUp
          && css`
            width: auto;
            height: 100%;
          `}
      }
    }
    .nmx-nav-link {
      &#{&} { /** TODO: use styled-components double-specificity here */
        height: auto;
        font-size: ${rem(12)};
        font-weight: 500;
        line-height: ${rem(18)};
        ${largeUp
          && css`
            height: 100%;
            font-size: ${rem(11)};
            line-height: ${rem(17)};
          `}
        ${xlargeUp
          && css`
            font-size: ${rem(13)};
            line-height: ${rem(20)};
          `}
      }
      &-mobile-back,
      &-mobile-level2-back {
        max-width: unset;
        border-color: ${theme.colors.neutral.white};
        color: ${theme.colors.primary.blue};
        font-weight: 500;
        text-transform: uppercase;
        &:focus,
        &:hover,
        &:active {
          border-color: ${theme.colors.neutral.white};
        }

        &::after {
          position: absolute;
          left: ${rem(16)};
          width: ${rem(30)};
          height: ${rem(18)};
          background-repeat: no-repeat;
          color: ${theme.colors.primary.blue};
          content: '←'; /** TODO: Switch out for ascii encoded version */
          font-size: ${rem(20)};
        }
        &-container {
          border-bottom: none;
          .nmx-nav-mobile-subnav-bg {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: ${theme.colors.blueLazyloadBg};
            opacity: 0.95;
          }
          ${largeUp
            && css`
              display: none;
            `}
        }
      }
      &-mobile-back {
        &#{&} { /** TODO: use styled-components double-specificity here */
          padding-left: 2rem;
        }
      }
      &-mobile-level2-back {
        &#{&} { /** TODO: use styled-components double-specificity here */
          padding-left: 2rem;
        }
      }
      &-mobile-subnav-overview {
        &#{&} { /** TODO: use styled-components double-specificity here */
          color: ${theme.colors.section.backgroundBlue};
          letter-spacing: ${rem(3)};
          text-transform: uppercase;
        }

        ${largeUp
          && css`
            display: none;
            &.is-active-desktop {
              display: block;
              padding: 1rem;
              color: ${theme.colors.neutral.gray29};
            }
            &-subnav-level2 {
              margin-left: 1.875rem;
              font-weight: 400;
            }
            &:not(.nmx-no-touch) & {
              display: block;
            }
          `}
      }
      &-mobile-primary-home {
        &#{&} { /** TODO: use styled-components double-specificity here */
          border-bottom: 1px solid ${theme.colors.neutral.gray59};
          background-color: ${theme.colors.neutral.white};
          color: ${theme.colors.section.backgroundBlue};
          text-transform: uppercase;
          text-decoration: none;
          letter-spacing: ${rem(3)};
          &:focus,
          &:hover,
          &:active {
            border-color: ${theme.colors.neutral.white};
          }
        }
        &-container {
          &#{&} { /** TODO: use styled-components double-specificity here */
            ${largeUp
              && css`
                display: none !important;
              `}
          }
        }
      }
    }
  `;
  }}
`;

/** ============================================================
// nmx-nav-utility
// ============================================================ */
export const NavUtilityStyled = styled.nav`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const largeDown = theme.largeDown();
    const xlargeUp = theme.xlargeUp();
    return css`
    z-index: ${theme.zIndex.globalNavMain};
    width: 100%;
    .nmx-nav-link-utility {
      color: ${theme.colors.neutral.white};
      text-decoration: none;
      ${largeUp
        && css`
          align-self: center;
          padding: ${rem(10)} ${rem(15)} ${rem(8)};
        `}
      &:hover {
        color: ${theme.colors.neutral.white};
        text-decoration: underline;
      }
      &-container {
        display: flex;
        height: ${rem(60)};
        align-items: center;
        border-bottom: 1px solid ${theme.colors.neutral.gray59};
        /** mobile? */
        /** // utility nav delimiter */
        background-color: ${theme.colors.section.backgroundBlue};
        &:nth-child(-n + 1) {
          &::after {
            ${largeUp
              && css`
                position: relative;
                color: ${theme.colors.neutral.white};
                content: '|';
                font-weight: 100;
              `}
          }
        }
        ${largeUp
          && css`
            display: inline-flex;
            height: ${rem(40)};
            padding: 0;
            border-bottom: none;
          `}
        &-mobile-button {
          ${largeDown
            && css`
              border: none;
              margin: ${rem(16)} 0;
              background: none;
            `}
        }
        .nmx-nav-link {
          height: auto;
          font-size: ${rem(15)};
          letter-spacing: 1px;
          font-weight: 500;
          line-height: ${rem(22)};
          ${largeUp
            && css`
              height: 100%;
              font-size: ${rem(15)};
              line-height: ${rem(22)};
            `}
          ${xlargeUp
            && css`
              font-size: ${rem(13)};
              line-height: ${rem(22)};
            `}
          ${largeDown
            && css`
              display: flex;
              width: 100%;
              height: 100%;
              align-items: center;
              padding: 0 ${rem(16)};
            `}
        }
      }
    }
    & > ul {
      display: block;
      ${largeUp
        && css`
          display: flex;
          justify-content: flex-end;
        `}
    }
    #nmx-nav-link-utility-fafa {
      ${largeDown
        && css`
          max-width: unset;
          justify-content: center;
          margin: ${rem(16)};
        `}
      ${largeUp
        && css`
          height: ${rem(30)};
          min-width: auto;
          max-width: unset;
          min-height: unset;
          padding-left: ${rem(10)};
          padding-right: ${rem(10)};
          color: ${theme.colors.neutral.black};
          font-weight: 400;
          &:hover {
            text-decoration: none;
          }
        `}
  }

  #nmx-nav-link-utility-claims {
    ${largeDown
      && css`
        max-width: unset;
        justify-content: center;
        margin: ${rem(16)};
        color: ${theme.colors.cta.blue};
      `}
  }
  `;
  }}
`;

export const ColNavUtilityFwmContainerStyled = styled(Col)`
  position: relative;
`;

export const FwmNavItemContainer = styled.ul`
${({ theme }) => css`
      position: absolute;
      right: 0;
      top: ${rem(-70)};
      display: flex;
      justify-content: flex-end;
      height: ${rem(70)};
      width: ${rem(275)};
      z-index: ${theme.zIndex.globalNavPrimary};
    `}
`;

export const FwmNavItem = styled.li`
${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      height: 100%;
      padding: 0 ${mediumUp ? `${rem(20)}` : `${rem(5)}`};
      margin-right: ${mediumUp ? `${rem(15)}` : `${rem(5)}`};
      display: flex;
      align-items: center;

      :last-child {
        margin-right: ${mediumUp ? '0' : `${rem(5)}`};
      }
  `;
  }}
`;

export const FwmNavLink = styled(Link)`
${({ theme }) => css`
  color: ${theme.colors.neutral.gray29};
  display: flex;
  align-items: center;
  `}
}
`;

export const ColNavUtilityContainerStyled = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const large1200Up = theme.large1200Up();
    const xlargeUp = theme.xlargeUp();
    return css`
      display: flex;
      width: 100%;
      padding: 0;
      margin-top: ${rem(360)};
      ${largeUp
        && css`
          position: relative;
          width: auto;
          padding-right: 0;
          margin-top: ${rem(-40)};
          float: right;

          /** // ie flexbox hack/fix */
          @media all and (-ms-high-contrast: none) {
            right: 0;
          }
        `}
      ${large1200Up
        && css`
          margin-top: ${rem(-115)};
        `}
      ${xlargeUp
        && css`
          padding: 0 ${rem(16)};
        `}
    `;
  }}
`;

/** ============================================================
// MOBILE NAV
// ============================================================ */
export const ButtonNavToggleStyled = styled(Button)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    return css`
      && {
        position: fixed;
        z-index: ${theme.zIndex.globalNavMain};
        top: 0;
        left: 0;
        width: ${rem(50)};
        min-width: unset;
        height: ${rem(49)};
        min-height: ${rem(49)};
        margin-left: ${mediumUp ? rem(10) : rem(6)};
        border: none;
        background: ${theme.colors.neutral.white};
      }

      .nav-toggle-bar,
      .nav-toggle-bar::after,
      .nav-toggle-bar::before {
        position: absolute;
        top: 50%;
        left: ${rem(10)};
        width: ${rem(30)};
        height: ${rem(2)};
        background-color: ${theme.colors.primary.blue};
        content: '';
        transform: translateY(-50%);
        transition: all 0.2s ease;
      }

      .nav-toggle-bar {
        margin-top: 0;
      }
      .nav-toggle-bar::after {
        margin-top: ${rem(8)};
        margin-left: ${rem(-10)};
      }
      .nav-toggle-bar::before {
        margin-top: ${rem(-8)};
        margin-left: ${rem(-10)};
      }

      &.is-active .nav-toggle-bar {
        background: transparent;
      }

      &.is-active .nav-toggle-bar::after,
      &.is-active .nav-toggle-bar::before {
        margin-top: 0;
        background: ${theme.colors.neutral.gray59};
      }

      &.is-active .nav-toggle-bar::after {
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &.is-active .nav-toggle-bar::before {
        margin-left: ${rem(-10)};
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      ${largeUp
        && css`
          display: none;
        `}
    `;
  }}
`;

export const ContainerMobileNavStyled = styled(Container)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      position: absolute;
      z-index: ${theme.zIndex.globalNavPrimary};
      top: ${rem(50)};
      left: -100%;
      display: block;
      width: 100%;

      animation: ${mobileNavClose} 0.15s both;
      background-color: ${theme.colors.neutral.white};
      overflow-y: auto;
      ${largeUp
        && css`
          && {
            position: relative;
            top: 0;
            left: auto;
            max-width: none;
            transition: none;
          }
        `}
      .nmx-row {
        ${largeUp
          && css`
            max-width: 90rem;
            margin: 0 auto;
          `}
      }
      body.is-mobile-active & {
        /** some sort of bug with styled components adding these styles to immediate parent of <header>, double up on class specificity to ensure only applying to this element */
        &.nmx-container-inner {
          position: fixed;
          animation: ${mobileNavOpen} 0.15s both;
        }
      }
      body.preload & {
        visibility: hidden;
      }
    `;
  }}
`;

/** ============================================================
// nmx-login-open-button
// ============================================================ */
export const LiLoginButtonContainer = styled.li`
  ${({ theme }) => {
    const largeUp = theme.largeUp(); return css`
      position: absolute;
      top: -50px;

      ${largeUp
        && css`
          position: static;
          top: 0;
        `}
    `;
  }}
`;

/** ============================================================
// SEARCH
// ============================================================ */
export const ButtonNavLinkUtilitySearchStyled = styled(Button)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const largeDown = theme.largeDown();
    if (largeDown) {
      return css`
        &&&& {
          display: none;
        }
      `;
    }
    return css`
      &&&& {
        display: flex;
        width: ${rem(75)};
        min-width: ${rem(75)};
        align-items: center;
        padding: 0;
        border: none;
        background-color: ${theme.colors.neutral.white};
        border-radius: 0;
        color: ${theme.colors.section.backgroundBlue};
        text-indent: ${rem(-9999)};
        ${largeUp
          && css`
            height: ${rem(75)};
            /** // ie flexbox hack/fix */
            @media all and (-ms-high-contrast: none) {
              position: absolute;
              top: 0;
              display: flex;
              align-items: center;
            }
          `}
        .nmx-icon-search {
          position: absolute;
          /** safari */
          right: 0;
          width: 100%;
          height: ${rem(30)};
          @media all and (-ms-high-contrast: none) {
            left: 0;
          }
        }
        &:hover {
          .nmx-icon-search {
            .icon-fill {
              fill: ${theme.colors.cta.yellow};
            }
          }
        }
      }
    `;
  }}
`;

export const LiNavLinkSearchContainerStyled = styled.li`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    const custom768810 = theme.custom768810();
    return css`
      &&&& {
        position: absolute;
        z-index: ${theme.zIndex.globalNavPrimary};
        top: 0;
        right: 0;
        width: ${rem(75)};
        height: max-content;
        padding: 0;
        border-bottom: none;
        ${largeUp
          && css`
            top: ${rem(40)};
          `}
        ${custom768810
          && css`
            width: ${rem(40)};
          `}
      }
    `;
  }}
`;

/** ============================================================
// MOBILE NAV OVERLAY
// ============================================================ */
export const DivMobileNavOverlayStyled = styled.div`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      position: fixed;
      z-index: ${theme.zIndex.globalNavOverlay};
      display: none;
      /** // TODO: nest inside container (.nmx-nav-primary) or keep near mobile nav styles? */
      width: 100%;
      height: 100%;
      background-color: ${theme.colors.neutral.black};
      opacity: 0.6;
      /** TODO: mobile nav base overlay needed with full-viewport-width menu? */
      /** body.is-mobile-active & {
        display: block;
      } */
      ${largeUp
        && css`
          display: none;
        `}
    `;
  }}
`;
