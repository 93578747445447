import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '../../base/Grid';
import Typography from '../../base/Typography';
import ErrorNotificationFillIcon from '../../icons/utility/ErrorNotificationFill';
import { Styles } from './styles';

export const ErrorComponent = ({ errorText, errorDescription }) => {
  const styles = Styles();
  return (
    <Container>
      <Row align="center">
        <Col xsmall={12}>
          <ErrorNotificationFillIcon style={styles.image} />
          <Typography variant="h3" align="center" disableBottomPadding styles={styles.errorText}>
            {errorText}
          </Typography>
          <Typography variant="p" align="center" disableBottomPadding>
            {errorDescription}
          </Typography>
        </Col>
      </Row>
    </Container>
  );
};

ErrorComponent.propTypes = {
  errorText: PropTypes.string,
  errorDescription: PropTypes.string,
};

ErrorComponent.defaultProps = {
  errorText: 'Unable to submit form.',
  errorDescription: 'Please try again later.',
};

export default ErrorComponent;
