import React from 'react';
import PropTypes from 'prop-types';

import DrawerCaretIcon from '../../icons/utility/DrawerCaret';

import {
  List,
  ListItem,
  LinkStyled,
} from './styles';

export const LinksListComponent = ({ items }) => (
  <List>
    {
      items.map((item) => (
        <ListItem key={`list-item-${item.href}`}>
          <LinkStyled href={item.href} target={item.target || '_self'} {...item.target === '_blank' && { rel: 'noopener' }}>{item.label} <DrawerCaretIcon /></LinkStyled>
        </ListItem>
      ))
    }
  </List>
);

LinksListComponent.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
  })).isRequired,
};

LinksListComponent.defaultProps = { };

export default LinksListComponent;
