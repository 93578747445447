import React from 'react';
import PropTypes from 'prop-types';
import Picture from '../../../../base/Picture';

const basePath = 'https://www.northwesternmutual.com';
const images = {
  default: {
    src: '/assets/images/frs/fr-2-desktop.jpg',
    mobileSrc: '/assets/images/frs/fr-2-mobile.jpg',
    mobileWebpSrc: '/assets/images/frs/fr-2-mobile.webp',
    desktopWebpSrc: '/assets/images/frs/fr-2-desktop.webp',
  },
  danNguyen: {
    src: '/assets/images/frs/dan-nguyen-desktop-v2.jpg',
    mobileSrc: '/assets/images/frs/dan-nguyen-mobile-v2.jpg',
    mobileWebpSrc: '/assets/images/frs/dan-nguyen-mobile-v2.webp',
    desktopWebpSrc: '/assets/images/frs/dan-nguyen-desktop-v2.webp',
  },
  leoTucker: {
    src: '/assets/images/frs/leo-tucker-desktop-v7.jpg',
    mobileSrc: '/assets/images/frs/leo-tucker-mobile-v6.jpg',
    mobileWebpSrc: '/assets/images/frs/leo-tucker-mobile-v6.webp',
    desktopWebpSrc: '/assets/images/frs/leo-tucker-desktop-v7.webp',
  },
  johnWhite: {
    src: '/assets/images/frs/john-white-desktop-v2.jpg',
    mobileSrc: '/assets/images/frs/john-white-mobile-v2.jpg',
    mobileWebpSrc: '/assets/images/frs/john-white-mobile-v2.webp',
    desktopWebpSrc: '/assets/images/frs/john-white-desktop-v2.webp',
  },
  trevorSmith: {
    src: '/assets/images/frs/trevor-smith-desktop-v2.jpg',
    mobileSrc: '/assets/images/frs/trevor-smith-mobile-v2.jpg',
    mobileWebpSrc: '/assets/images/frs/trevor-smith-mobile-v2.webp',
    desktopWebpSrc: '/assets/images/frs/trevor-smith-desktop-v2.webp',
  },
  kellerLindler: {
    src: '/assets/images/frs/keller-lindler-desktop-v2.jpg',
    mobileSrc: '/assets/images/frs/keller-lindler-mobile-v2.jpg',
    mobileWebpSrc: '/assets/images/frs/keller-lindler-mobile-v2.webp',
    desktopWebpSrc: '/assets/images/frs/keller-lindler-desktop-v2.webp',
  },
  holleeKier: {
    src: '/assets/images/frs/hollee-kier-desktop-v2.jpg',
    mobileSrc: '/assets/images/frs/hollee-kier-mobile-v2.jpg',
    mobileWebpSrc: '/assets/images/frs/hollee-kier-mobile-v2.webp',
    desktopWebpSrc: '/assets/images/frs/hollee-kier-desktop-v2.webp',
  },
};

const FrPicture = ({ defaultImage, alt, styles }) => <Picture
  src={`${basePath}${images[defaultImage].src}`}
  mobileSrc={`${basePath}${images[defaultImage].mobileSrc}`}
  mobileWebpSrc={`${basePath}${images[defaultImage].mobileWebpSrc}`}
  desktopWebpSrc={`${basePath}${images[defaultImage].desktopWebpSrc}`}
  alt={alt}
  styles={styles}
  style={{ display: 'block' }}
/>;

FrPicture.propTypes = {
  defaultImage: PropTypes.string,
  alt: PropTypes.string,
  styles: PropTypes.object,
};

FrPicture.defaultProps = { defaultImage: 'default' };

export default FrPicture;
