import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import './swiper.scss';
// template components
import Typography from '../../base/Typography';
import { Row } from '../../base/Grid';
import DynamicCallout from '../DynamicCallout';

// styles
import {
  ColArticleContainerStyled,
  ColDesktopCard,
  ColHeadlineStyled,
  ColSubtextStyled,
  SectionContainerStyled,
  SwiperCarouselContainerRow,
  SwiperCarouselSwiperCol,
  SwiperCarouselSwiperNavigation,
  SwiperCarouselSwiperNavigationLeftIcon,
  SwiperCarouselSwiperNavigationRightIcon,
  SwiperCarouselSwiperPagination,
  SwiperCarouselSwiperSlide,
  SwiperCarouselSwiperWrapper,
} from './styles';

export const ContentHubComponent = ({
  config,
  contentHubItems,
  headline,
  id,
  outGoingLink,
  sectionVariant,
  subText,
  disableLazyLoad,
}) => {
  const theme = useContext(ThemeContext);
  const mediumDown = theme.mediumDown();
  const [isMobile, setIsMobile] = useState(mediumDown);
  const [swiperLoaded, setSwiperLoaded] = useState(false);
  const swiperCarouselConfig = useRef();
  let cardList;
  const cardHeightsArray = [];
  let maxHeight;

  const CARDS = contentHubItems.reduce((items, item) => {
    let visualLink = '';
    let metadata = '';
    const entity = ((item || {}).entity || {});
    const title = entity.title || '';
    let tag = entity.entityBundle || '';
    const linkPath = ((entity.path || {}).alias || '').replace(/(^\/+)|(\/+$)/g, '').toString(); // remove leading and trailing slashes

    let imageName = '';

    if (tag === 'article_2') {
      if (entity.fieldFeaturedImage.entity.fieldMediaImage2) {
        imageName = entity.fieldFeaturedImage.entity.fieldMediaImage2.entity.uri.value;
      } else {
        imageName = entity.fieldFeaturedImage.entity.fieldImageAsset.entity.fieldMediaImage.entity.uri.value;
      }
    } else {
      imageName = entity.fieldImage.entity.fieldImageAsset.entity.fieldMediaImage.entity.uri.value || '';
    }

    if (tag === 'article' || tag === 'article_2' || tag === 'pillar_page') {
      visualLink = 'Learn more';
      if ((entity.fieldDatePublished || {}).value || '') {
      // This comment is for why we replace the dashes in the date with slashes.
      // When the date string follows the yyyy-mm-dd format, it's assumed to be
      // ISO 8601 with implicit UTC 00:00. When the string deviates from the format
      // (e.g. mm-dd-yyyy or slash instead of hyphen), it falls back to the looser
      // parser according to RFC 2822 which uses local time when the timezone is absent.
        metadata = new Date(((entity.fieldDatePublished || {}).value || '').replace(/-/g, '/')).toLocaleString('en-US', {
          month: 'long',
          day: '2-digit',
          year: 'numeric',
        });
      }
    } else if (tag === 'quiz') {
      visualLink = 'Take the quiz';
    } else if (tag === 'guide') {
      visualLink = 'Read the guide';
    } else if (tag === 'calculator') {
      visualLink = 'Calculate it';
    } else if (tag === 'video') {
      visualLink = 'Watch now';
    } else {
      visualLink = 'Learn more';
    }

    // pillar page is an internal term so we need to show this is guide instead
    if (tag === 'pillar_page') {
      tag = 'guide';
    } else if (tag === 'article_2') {
      tag = 'article';
    }

    // Don't add cards if we don't have these attributes
    if (title && tag && linkPath && imageName) {
      items.push(<DynamicCallout
        orientation='card'
        actionId={
          imageName.replace('s3://', '').split('.')[0]
        }
        image={`${config.mediaBasePath}/images/${imageName.replace('s3://', '')}`}
        title={title}
        visualLink={visualLink}
        url={`${config.basePath}${tag === 'calculator' ? '' : '/life-and-money'}/${linkPath}/`}
        tag={tag.replace('_', ' ')}
        flag='secondary'
        outGoingLink={outGoingLink}
        // assistiveText={title}
        metadata={metadata}
        disableLazyLoad={disableLazyLoad}
      />);
    }
    return items;
  }, []);

  const checkCardHeights = (cards) => {
    // Something wonky is going on with the computedHeight on load. Adding a delay
    // fixed this issue.
    setTimeout(() => {
      const allCards = cards;
      // Get the heights of all the cards
      for (let i = 0; i < allCards.length; i += 1) {
        // Loop through node list of cards and add their heights to the array
        cardHeightsArray.push(allCards[i].offsetHeight);
      }

      // Grab height value of the tallest card
      maxHeight = Math.max(...cardHeightsArray);
      for (let i = 0; i < allCards.length; i += 1) {
        // Loop through node list of cards and set their content area heights to match the tallest one.
        allCards[i].style.height = `${maxHeight}px`;
      }
    }, 100);
  };

  const parentRef = useCallback((node) => {
    // gather dynamic callout cards just within this module, targeting just the content container
    // of the card so we can set them all to the same height.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (node) {
      cardList = node.querySelectorAll('.js-dynamic-callout-card-content-container');
      checkCardHeights(cardList);
    }
  }, []);

  useEffect(() => {
    if (mediumDown !== isMobile) {
      setIsMobile(mediumDown);
    }
  }, [mediumDown]);

  useEffect(() => {
    /*
      Because we don't want swiper to bloat the bundle and these charts don't need to be ready on page load,
      we will render the script on mount and load the charts once ready.
    */
    const script = document.createElement('script');

    script.src = 'https://unpkg.com/swiper@6.7.0/swiper-bundle.min.js';
    script.integrity = 'sha384-Y1O5WrIEuc9IhTQYuX3AAtW4jNhdPW/kGCjE/AyulARrGXpDMJYi1WD6UIllTfJ0';
    script.crossOrigin = 'anonymous';
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
      setSwiperLoaded(true);
    };

    const link = document.createElement('link');

    link.href = 'https://unpkg.com/swiper@6.7.0/swiper-bundle.min.css';
    link.rel = 'stylesheet';
    link.integrity = 'sha384-hJBk1nqEF9yHIQHQ6cqCLBxzW+Eb4zV4S04M5kR/dG4EDiYTLEoBjGCKD3y5DDlK';
    link.crossOrigin = 'anonymous';

    document.body.appendChild(link);
  }, []);

  useEffect(() => {
    // init videos for YouTube API
    // TODO: build out YT players array to utilize YT API
    // TODO: Swiper bundle getting loaded on desktop, this should only get loaded on mobile, unloaded on desktop

    // Initiate Swiper (node slider/carousel library)
    if (swiperLoaded) {
      // eslint-disable-next-line no-unused-vars, no-undef
      const thisSwiperCarousel = new Swiper('.swiper-container', {
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,
        speed: 400,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
      });
    }
  }, [swiperLoaded]);

  return (
    <SectionContainerStyled
      id={id}
      className='nmx-no-print'
      variant={sectionVariant}
      ref={swiperCarouselConfig}
      moduleName='content-hub-module'
      moduleVariation='A'>
      <Row align='center'>
        <ColHeadlineStyled
          xsmall={12}
          subText={subText}>
          <Typography
            component='h2'
            align='center'
            disableBottomPadding>
            {headline}
          </Typography>
        </ColHeadlineStyled>
        {subText && (
          <ColSubtextStyled xsmall={12}>
            <Typography
              disableBottomPadding>
              {subText}
            </Typography>
          </ColSubtextStyled>
        )
        }
        {isMobile
          ? (
            <>
              <SwiperCarouselContainerRow className='swiper-container'>
                <SwiperCarouselSwiperWrapper className='nmx-section-thisSwiperCarousel-items swiper-wrapper'>
                  {CARDS.map((card, index) => (
                    <SwiperCarouselSwiperSlide
                      className='swiper-slide'
                      key={`desktop-content-hub-mobile-swiper-${index}`}>
                      <SwiperCarouselSwiperCol
                        xsmall={12}
                        small={10}
                        className='nmx-align-center'>
                        {card}
                      </SwiperCarouselSwiperCol>
                    </SwiperCarouselSwiperSlide>
                  ))}
                </SwiperCarouselSwiperWrapper>
                <SwiperCarouselSwiperNavigation variant='prev' className="swiper-button-prev">
                  <SwiperCarouselSwiperNavigationLeftIcon />
                </SwiperCarouselSwiperNavigation>
                <SwiperCarouselSwiperNavigation variant='next' className="swiper-button-next">
                  <SwiperCarouselSwiperNavigationRightIcon />
                </SwiperCarouselSwiperNavigation>
                <SwiperCarouselSwiperPagination className='swiper-pagination' />
              </SwiperCarouselContainerRow>
            </>
          )
          : (
            <ColArticleContainerStyled xsmall={12}>
              <div ref={parentRef}>
                <Row align='left'>
                  {CARDS.map((card, index) => (
                    <ColDesktopCard
                      xsmall={12}
                      small={6}
                      large={4}
                      key={`desktop-content-hub-card-${index}`}>
                      {card}
                    </ColDesktopCard>
                  ))}
                </Row>
              </div>
            </ColArticleContainerStyled>
          )}
      </Row>
    </SectionContainerStyled>
  );
};

ContentHubComponent.propTypes = {
  config: PropTypes.any,
  contentHubItems: PropTypes.any.isRequired,
  headline: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  /** optional outgoing link prop */
  outGoingLink: PropTypes.bool,
  sectionVariant: PropTypes.string.isRequired,
  subText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  disableLazyLoad: PropTypes.bool,
};

export default ContentHubComponent;
