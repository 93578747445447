import * as changeCase from 'change-case';
import { AnalyticsError } from '../errors';
import { getAdobeDataLayer, getPageLoadPath, push } from './_shared';
import { NAME_LEAD_FORM_EXPERIENCES, NAME_LEAD_FORM_SUBMIT_TYPE, NAME_PUSH_EVENTS } from '../constants';
import { TYPE_PUSH_EVENTS } from '../constants/_internal';
import { logger } from '../services';
import { file, validation } from '../utils';
/**
 * Returns the form type based on experience name
 * ---
 * These values' source of truth is the DART (Analytics team) Confluence page at:
 *
 *    https://confluence.nml.com/display/DART/Form+Mapping+Table
 */

var getFormType = function getFormType(experienceName) {
  var formType;

  switch (experienceName) {
    case NAME_LEAD_FORM_EXPERIENCES.QUIZ:
    case NAME_LEAD_FORM_EXPERIENCES.EMBEDDED_QUIZ:
      formType = 'quiz';
      break;

    case NAME_LEAD_FORM_EXPERIENCES.GENERAL:
    case NAME_LEAD_FORM_EXPERIENCES.OFFICE_SITES:
    case NAME_LEAD_FORM_EXPERIENCES.PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM:
      formType = 'simple';
      break;

    case NAME_LEAD_FORM_EXPERIENCES.EMBEDDED_FAFA:
      formType = 'step-by-step';
      break;

    case NAME_LEAD_FORM_EXPERIENCES.GATED_CONTENT:
      formType = 'gated content';
      break;

    case NAME_LEAD_FORM_EXPERIENCES.CALCULATOR:
      formType = 'calculator';
      break;

    default:
      if (typeof experienceName === 'string' && experienceName.startsWith(NAME_LEAD_FORM_EXPERIENCES.LANDING_PAGES_PREFIX, 0)) {
        formType = 'mad libs';
      } else {
        logger.error(new AnalyticsError("Your (FAFA) form experience of ".concat(experienceName, " is not recognized, and we couldn't get the corresponding form type. It may need to be added to the 'NAME_LEAD_FORM_EXPERIENCES' constant"), TYPE_PUSH_EVENTS.LEAD_FORM));
      }

  }

  return formType;
};
/**
 * Returns a value to help the Analytics team determine the order that a user went through the form
 * ---
 * For example, did they fill out their zip code first then their name, did they go bottom to top, etc.
 *
 * @param {string} eventName  See the constant, `NAME_PUSH_EVENTS`
 *
 * @see NAME_PUSH_EVENTS
 */


var getIncrementor = function getIncrementor(eventName) {
  var adobeDataLayer = getAdobeDataLayer();
  var incrementor;

  if (eventName === NAME_PUSH_EVENTS.FORM_LOAD || typeof adobeDataLayer.getState !== 'function') {
    incrementor = 0;
  } else {
    incrementor = adobeDataLayer.getState('form.incrementor') + 1;
  }

  return incrementor;
};
/**
 * Build out event data for FAFA form interactions
 *
 * For tracking interactions that are for any generic component that isn't the lead form, use `pushEventModule`.
 *
 * Remember! When passing param values, passing in an argument of `undefined` will result in the default value being
 * used. For example, the param of `submissionType` has a default argument/value of "lead". If you call this like:
 *
 *    pushEventForm({
 *      // required params
 *      submissionType: undefined,
 *    })
 *
 * ^ the value of `submissionType` will stay "lead". This is also true if you completely omit the param, since that is
 * synonymous with giving the param a value of `undefined`
 *
 *    pushEventForm({
 *      // required params
 *      // note the omission of `submissionType` param here
 *    })
 *
 * @param {Object} params
 *    To build out event data for FAFA forms
 * @param {string} params.eventName
 * @param {string} params.experience
 * @param {string} [params.customName=undefined]
 *    A custom name for the event. Since the `name` is normally based on the URL path, this helps detail forms that
 *    aren't inherently connected to a page URL. For example, it could be for a lead form that is part of a
 *    common header
 * @param {Object} [params.additionalVars={}]
 *    Extra fields (aka, Objects) that are sent with the event. These are specific to each event.
 *
 * @return void
 *
 * @see pushEventModule
 * @see NAME_LEAD_FORM_EXPERIENCES
 * @see NAME_LEAD_FORM_SUBMIT_TYPE
 */


var pushEventForm = function pushEventForm(_ref) {
  var eventName = _ref.eventName,
      experience = _ref.experience,
      _ref$customName = _ref.customName,
      customName = _ref$customName === void 0 ? undefined : _ref$customName,
      _ref$additionalVars = _ref.additionalVars,
      additionalVars = _ref$additionalVars === void 0 ? {} : _ref$additionalVars;

  // Not entirely necessary as clients can't call this function directly, but this is
  // helpful just for ourselves in case we forget to add a new event
  if (!file.getConstantsByPrefix(NAME_PUSH_EVENTS, 'FORM_').includes(eventName)) {
    logger.error(new AnalyticsError("The following form event is not valid, \"".concat(eventName, ". Check the 'NAME_PUSH_EVENTS' constant to see if it exists there\""), TYPE_PUSH_EVENTS.LEAD_FORM));
  }

  var eventData = Object.assign({}, additionalVars, {
    name: "Form: ".concat(getPageLoadPath(), " | ").concat(experience),
    experience: experience,
    incrementor: getIncrementor(eventName),
    // This conveniently doubles as a validator for the experiences name, since
    // the switch block in `getFormType` drops to an error
    'form-type': getFormType(experience)
  });

  if (customName) {
    eventData['custom-name'] = "Form: ".concat(customName, " | ").concat(experience);
  }

  push(eventName, {
    form: eventData
  });
};
/**
 * When a lead form first loads onto the page
 *
 * @param {Object} params
 * @param {string} params.experience
 *    Essentially, where this form is used. e.g., `'campaign'` OR `'office-site'` OR `'quiz'` OR `'calculator'` OR
 *    `'fafa'` OR other as needed
 * @param {string} [params.customName=undefined]
 *
 * @return void
 *
 * @see NAME_LEAD_FORM_EXPERIENCES
 */


var load = function load(_ref2) {
  var experience = _ref2.experience,
      _ref2$customName = _ref2.customName,
      customName = _ref2$customName === void 0 ? undefined : _ref2$customName;
  return pushEventForm({
    eventName: NAME_PUSH_EVENTS.FORM_LOAD,
    experience: experience,
    customName: customName
  });
};
/**
 * This should be executed for all interactions on any lead form other than start/complete. Action type/keys/values can
 * leverage the logic currently in place.
 *
 * @param {Object} params
 * @param {string} params.experience
 *    Essentially, where this form is used. e.g., `'campaign'` OR `'office-site'` OR `'quiz'` OR `'calculator'` OR
 *    `'fafa'` OR other as needed
 * @param {string} params.actionType
 *    The name of the input field, such as `'gender'`, `'last_name'`, etc. This value is automatically normalized to be
 *    snake_case, as values across other content is aggregated, and ideally, the field name here is the same across
 *    the entire org
 * @param {string} params.actionValues
 *    The user-provided value for the input field. This is auto-sanitized and changed to `'complete'` for PII fields.
 *    The list of PII fields used is set within this library.
 * @param {string} [params.customName=undefined]
 *    A custom name for the event. Since the `name` is normally based on the URL path, this helps detail forms that
 *    aren't inherently connected to a page URL. For example, it could be for a lead form that is part of a common
 *    header
 *
 * @return void
 *
 * @see modules.interact
 * @see NAME_LEAD_FORM_EXPERIENCES
 */


var interact = function interact(_ref3) {
  var experience = _ref3.experience,
      actionType = _ref3.actionType,
      actionValues = _ref3.actionValues,
      _ref3$customName = _ref3.customName,
      customName = _ref3$customName === void 0 ? undefined : _ref3$customName;

  if (!actionType || !actionValues) {
    logger.error(new AnalyticsError("When submitting a form interact event, you must set the 'actionType' & 'actionValues' params", TYPE_PUSH_EVENTS.LEAD_FORM));
  } // Strip PII  (like 'john' or '342-589-2399'), and normalize to param-case/kebab-case


  var actionTypeSanitized = changeCase.paramCase(actionType);
  var actionValuesSanitized = validation.isFieldNamePii(actionTypeSanitized) ? 'completed' : String(actionValues).substring(0, 255); // Instead of `document.querySelector('meta[name="app_name"]')` like in
  // the past, `action-keys` is now the same value as the `experience`
  // name, as it's essentially what it's used for
  //
  // i.e., `action-keys` is asking:
  //    "for this event, what's the name of the lead form?"

  var additionalVars = {
    'action-keys': experience,
    'action-type': actionTypeSanitized,
    'action-values': actionValuesSanitized
  };
  return pushEventForm({
    eventName: NAME_PUSH_EVENTS.FORM_INTERACT,
    experience: experience,
    customName: customName,
    additionalVars: additionalVars
  });
};
/**
 * To be fired whenever the lead form is submitted
 *
 * @param {Object} params
 * @param {string} params.experience
 *    Essentially, where this form is used. e.g., `'campaign'` OR `'office-site'` OR `'quiz'` OR `'calculator'` OR
 *    `'fafa'` OR other as needed
 * @param {string} [params.submissionType='lead']
 *    The type of lead submission this is. Most use cases will be the same, which is why this has a default. See the
 *    following constant for details on the different types: `NAME_LEAD_FORM_SUBMIT_TYPE`
 * @param {string} [params.customName=undefined]
 *    A custom name for the event. Since the `name` is normally based on the URL path, this helps detail forms that
 *    aren't inherently connected to a page URL. For example, it could be for a lead form that is part of a common
 *    header
 * @param {Object} [params.submissionData={}]
 *
 * @return void
 *
 * @see NAME_LEAD_FORM_SUBMIT_TYPE
 * @see NAME_LEAD_FORM_EXPERIENCES
 */


var complete = function complete(_ref4) {
  var experience = _ref4.experience,
      _ref4$customName = _ref4.customName,
      customName = _ref4$customName === void 0 ? undefined : _ref4$customName,
      _ref4$submissionType = _ref4.submissionType,
      submissionType = _ref4$submissionType === void 0 ? NAME_LEAD_FORM_SUBMIT_TYPE.LEAD : _ref4$submissionType,
      _ref4$submissionData = _ref4.submissionData,
      submissionData = _ref4$submissionData === void 0 ? {} : _ref4$submissionData;

  if (!Object.values(NAME_LEAD_FORM_SUBMIT_TYPE).includes(submissionType)) {
    logger.error(new AnalyticsError("When submitting a form complete event, you must set a valid submission type. See the 'NAME_LEAD_FORM_SUBMIT_TYPE' constant for valid values. Got: '".concat(submissionType, "'")));
  }

  var additionalVars = {
    'submission-type': submissionType,
    'submission-data': submissionData
  };
  return pushEventForm({
    eventName: NAME_PUSH_EVENTS.FORM_COMPLETE,
    experience: experience,
    customName: customName,
    additionalVars: additionalVars
  });
};
/**
 * To be fired whenever the lead form has an error
 *
 * @param {Object} params
 * @param {string} params.experience
 *    Essentially, where this form is used. e.g., `'campaign'` OR `'office-site'` OR `'quiz'` OR `'calculator'` OR
 *    `'fafa'` OR other as needed
 * @param {string} params.errorMsg
 *    The error message. This should be a descriptive string, and not be a full `Error` object
 * @param {string} [params.submissionType='lead']
 *    The type of lead submission this is. Most use cases will be the same, which is why this has a default. See the
 *    following constant for details on the different types: `NAME_LEAD_FORM_SUBMIT_TYPE`
 * @param {string} [params.customName=undefined]
 *    A custom name for the event. Since the `name` is normally based on the URL path, this helps detail forms that
 *    aren't inherently connected to a page URL. For example, it could be for a lead form that is part of a common
 *    header
 *
 * @return void
 *
 * @see NAME_LEAD_FORM_SUBMIT_TYPE
 * @see NAME_LEAD_FORM_EXPERIENCES
 */


var completeFailure = function completeFailure(_ref5) {
  var experience = _ref5.experience,
      _ref5$customName = _ref5.customName,
      customName = _ref5$customName === void 0 ? undefined : _ref5$customName,
      errorMsg = _ref5.errorMsg,
      _ref5$submissionType = _ref5.submissionType,
      submissionType = _ref5$submissionType === void 0 ? NAME_LEAD_FORM_SUBMIT_TYPE.LEAD : _ref5$submissionType;

  if (!Object.values(NAME_LEAD_FORM_SUBMIT_TYPE).includes(submissionType)) {
    logger.error(new AnalyticsError("When submitting a form complete-failure event, you must set a valid submission type. See the 'NAME_LEAD_FORM_SUBMIT_TYPE' constant for valid values. Got: '".concat(submissionType, "'")));
  }

  if (!errorMsg) {
    logger.error(new AnalyticsError('When submitting a form complete-failure event, you must set the error message'));
  }

  var additionalVars = {
    'submission-type': submissionType,
    'error-msg': errorMsg
  };
  return pushEventForm({
    eventName: NAME_PUSH_EVENTS.FORM_COMPLETE_FAILURE,
    experience: experience,
    customName: customName,
    additionalVars: additionalVars
  });
};

export { load, interact, complete, completeFailure };