import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../base/Typography';

export const ValidationErrorLabelStyled = styled(Typography)`
  display: block;
  padding-top: ${rem(3)};
`;

export const ValidationErrorLabelLeads = styled.div`
  position: relative;
  display: inline-block;
  padding: ${rem(5)} ${rem(10)};
  border-radius: ${rem(3)};
`;
