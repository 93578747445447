const transition = 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)';
// TODO: add the remainder of shadows.scss styles
const small = '0 2px 8px 0 rgba(0, 0, 0, 0.1)';
const medium = '0 0 10px rgba(0, 0, 0, 0.07), 0 2px 10px rgba(0, 0, 0, 0.1)';
const large = '0 8px 32px 0 rgba(0, 0, 0, 0.3)';
const inner = 'inset 0 -6px 6px 0 rgba(0, 0, 0, 0.1)';

export default {
  transition,
  small,
  medium,
  large,
  inner,
};
