import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// component imports
import Button from '../../base/Button';
import { Col } from '../../base/Grid';
import Link from '../../base/Link';
import Picture from '../../base/Picture';
import Typography from '../../base/Typography';

export const ButtonStyled = styled(Button)`
  ${({ theme }) => {
    const horizontalMargin = theme.mediumUp() ? 0 : 'auto';
    return css`
      margin-bottom: ${theme.mediumUp() ? rem(24) : rem(14)};
      margin-left: ${horizontalMargin};
      margin-right: ${horizontalMargin};
      ${!theme.mediumUp() && 'display: block;'}
    `;
  }}
}`;

export const ColContentStyled = styled(Col)`
  ${({ theme }) => {
    let paddingRight = 0;
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();

    if (xlargeUp) {
      paddingRight = rem(65);
    } else if (largeUp) {
      paddingRight = rem(40);
    } else if (mediumUp) {
      paddingRight = rem(30);
    }

    return css`
      text-align: left;
      padding-right: ${paddingRight};
    `;
  }}
}`;

export const ColDesktopStatCardContainer = styled(Col)`
  ${({ theme }) => css`
    ${theme.mediumDown() && 'display: none;'};
  `}
}`;

export const DivMobileStatCardContainer = styled.div`
  ${({ theme, buttonText, LinkText }) => {
    let paddingBottom = rem(32);
    if (!buttonText && !LinkText) {
      paddingBottom = 0;
    }
    return css`
      display: ${theme.mediumUp() ? 'none' : 'block'};
      padding-bottom: ${paddingBottom};
    `;
  }}
}`;

export const DivStatCardStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray99};
    padding: ${theme.mediumUp() ? rem(48) : rem(32)};
    margin: 0 ${theme.mediumUp() ? rem(10) : 0};
  `}
}`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => css`
    display: block;
    padding-bottom: 0;
    ${!theme.mediumUp() && 'text-align: center;'};
    @media print {
      display: none;
    }
  `}
`;

export const PictureCardImageStyled = styled(Picture)`
  padding-top: 0;
  padding-bottom: ${rem(16)};
  width: 60%;
  max-width: ${rem(150)};
  margin: 0 auto;
}`;

// Card header
export const TypographyCardHeaderStyled = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${rem(50)};
    line-height: ${rem(50)};
    font-weight: 300;
    text-align: center;
    color: ${theme.colors.primary.blue};
    overflow: hidden;
    padding-bottom: ${theme.mediumUp() ? rem(18) : rem(20)};
    :after {
      content: '';
      display: block;
      width: ${rem(48)};
      height: ${rem(3)};
      background-color: ${theme.colors.cta.yellow};
      margin: ${rem(18)} auto 0;
    }
    @media print {
      :after {
        display: none;
      }
    }
  `}
`;

// section header
export const TypographyContentHeaderStyled = styled(Typography)`
  ${({ theme }) => css`
    text-align: ${theme.mediumUp() ? 'left' : 'center'};
  `}
}`;
