import rem from 'polished/lib/helpers/rem';
import styled, { css } from 'styled-components';
import colors, { getColorForName } from '../../theme/colors';

export const BaseLink = styled.a`
  ${({ theme, variant, themeType }) => {
    const { darkBlue3 } = colors.pcg;

    const buttonTransition = '100ms';
    const buttonColorWhite = getColorForName('color-neutral-white');
    const buttonColorBlue = getColorForName('color-system-cta-blue');
    const buttonColorBlueHover = getColorForName('color-system-cta-hover-blue');
    const buttonColorBlueActive = getColorForName(
      'color-system-cta-active-blue',
    );
    const buttonColorYellow = getColorForName('color-system-cta-yellow');
    const buttonColorYellowHover = getColorForName(
      'color-system-cta-hover-yellow',
    );
    const buttonColorYellowActive = getColorForName(
      'color-system-cta-active-yellow',
    );
    const buttonColorBlueAccent = getColorForName('color-accent-blue');

    const getPrimaryStyles = () => css`
      color: ${colors.cta.blue};
      font-weight: 500;
      letter-spacing: 1px;
      text-decoration: none;

      &:hover {
        color: ${colors.cta.blueHover};
        text-decoration: underline;
      }

      &:active {
        color: ${colors.cta.blueActive};
      }

      &:focus {
        outline: auto 5px -webkit-focus-ring-color;
      }
    `;

    const getSecondaryStyles = () => css`
      &::after {
        display: inline-block;
        width: ${rem(8)};
        height: ${rem(11)};
        margin-top: ${rem(
    6,
  )}; /** TODO: use icon library for carat right, secondary (chevron icon) margin-top should be zero (0) to keep profile equal to primary variant, if margin is needed, apply to specific use-case */
        margin-left: ${rem(5)};
        background: transparent
          url('https://www.northwesternmutual.com/template/assets/2.12.0/images/icons/utility/carat-right.svg')
          no-repeat 50% 50%;
        content: ' ';
        filter: invert(25%) sepia(96%) saturate(7900%) hue-rotate(195deg)
          brightness(100%) contrast(84%); // color to #1570bc ($color-system-cta-blue)

        ${theme.smallUp() && `margin-top: ${rem(7)};`}
        /** TODO: secondary (chevron icon) margin-top should be zero (0) to keep profile equal to primary variant, if margin is needed, apply to specific use-case */
        ${theme.largeUp()
          && `
          width: ${rem(12)};
          height: ${rem(10)};
          margin-top: ${rem(8)};
        `}
        /** TODO: secondary (chevron icon) margin-top should be zero (0) to keep profile equal to primary variant, if margin is needed, apply to specific use-case */
        ${theme.xlargeUp()
          && `
          width: ${rem(15)};
          height: ${rem(12)};
          margin-top: ${rem(11)};
        `}
      }
      &:hover,
      &:focus {
        text-decoration: underline;
        color: ${theme.colors.cta.blueHover};
        &::after {
          background-color: transparent;
          filter: invert(19%) sepia(89%) saturate(1128%) hue-rotate(183deg)
            brightness(46%) contrast(93%); // color to $color-system-cta-hover-blue
        }
      }
    `;

    /** TODO: import BaseButton from '../Button/styles' */
    const getButtonPrimaryStyles = () => css`
      display: inline-flex; /** default <button> DOM element style is inline-block */
      @media print {
        display: none !important;
      }
      min-width: ${rem(200)};
      max-width: max-content;
      height: auto;
      min-height: ${rem(55)};
      padding: ${rem(11)} ${rem(25)} ${rem(9)};
      border: 1.5px solid ${buttonColorYellow};
      background-clip: padding-box; /** ff fix */
      background-color: ${buttonColorYellow};
      border-radius: ${rem(2)};
      color: ${buttonColorBlueAccent};
      cursor: pointer;
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: ${rem(1)};
      line-height: ${19 / 16};
      -webkit-tap-highlight-color: transparent;
      align-items: center;
      justify-content: center;
      transition: background-color ${buttonTransition} ease-out,
        box-shadow ${buttonTransition} ease-out,
        color ${buttonTransition} ease-out, border ${buttonTransition} ease-out;
      /** TODO: confirm autoprefixer handles this */
      user-select: none;

      /**  // TODO: reconfigure focus to allow native focus ring
      // &:focus { // TODO: complete and test focus ring build
      //   outline: auto 5px -webkit-focus-ring-color;
      // } */

      /** ff focus fix */
      &::-moz-focus-inner {
        padding: 0;
        border: none;
      }

      /** ff focus fix */
      /** FF Focus Ring takes into account inner text indented at -9999px, TODO: auto-set aria-label to "working" and make sure button height is retained when inner text is removed, will come back to this. */
      &:-moz-focusring {
        outline: 2px solid ${buttonColorBlueAccent};
        outline-offset: ${rem(2)};
      }

      /** base button states / .is-active is the JS alternative way for us to trigger the hover state on a button dynamically, if need be. */
      &:hover,
      &.is-active {
        color: ${buttonColorBlueAccent};
        border-color: ${buttonColorYellowHover};
        text-decoration: none;
        background-color: ${buttonColorYellowHover};
        box-shadow: ${theme.shadows.small};
      }

      &:focus {
        /** this will override the hover state when it's focused. */
        background-color: ${buttonColorYellowHover};
        /** overwrite nmcom styles */
        outline: auto 5px -webkit-focus-ring-color;
      }

      &:active {
        color: ${buttonColorBlueAccent};
        border-color: ${buttonColorYellowActive};
        background-color: ${buttonColorYellowActive};
        box-shadow: ${theme.shadows.small};
      }
    `;

    /** TODO: import BaseButton from '../Button/styles' */
    const getButtonSecondaryStyles = () => css`
      display: inline-flex; /** default <button> DOM element style is inline-block */
      @media print {
        display: none !important;
      }
      min-width: ${rem(200)};
      max-width: max-content;
      height: auto;
      min-height: ${rem(55)};
      padding: ${rem(11)} ${rem(25)} ${rem(9)};
      border: 1.5px solid ${buttonColorBlue};
      background-clip: padding-box; /** ff fix */
      background-color: ${buttonColorBlue};
      border-radius: ${rem(2)};
      color: ${buttonColorWhite};
      cursor: pointer;
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: ${rem(1)};
      line-height: ${19 / 16};
      -webkit-tap-highlight-color: transparent;
      align-items: center;
      justify-content: center;
      transition: background-color ${buttonTransition} ease-out,
        box-shadow ${buttonTransition} ease-out,
        color ${buttonTransition} ease-out, border ${buttonTransition} ease-out;
      /** TODO: confirm autoprefixer handles this */
      user-select: none;
      box-shadow: unset;

      /**  // TODO: reconfigure focus to allow native focus ring
      // &:focus { // TODO: complete and test focus ring build
      //   outline: auto 5px -webkit-focus-ring-color;
      // } */

      /** ff focus fix */
      &::-moz-focus-inner {
        padding: 0;
        border: none;
      }

      /** ff focus fix */
      /** FF Focus Ring takes into account inner text indented at -9999px, TODO: auto-set aria-label to "working" and make sure button height is retained when inner text is removed, will come back to this. */
      &:-moz-focusring {
        outline: 2px solid ${buttonColorBlueAccent};
        outline-offset: ${rem(2)};
      }

      /** base button states / .is-active is the JS alternative way for us to trigger the hover state on a button dynamically, if need be. */
      &:hover,
      &.is-active {
        color: ${buttonColorWhite};
        border-color: ${buttonColorBlueHover};
        text-decoration: none;
        background-color: ${buttonColorBlueHover};
      }

      &:focus {
        /** this will override the hover state when it's focused. */
        color: ${buttonColorWhite};
        border-color: ${buttonColorBlueHover};
        background-color: ${buttonColorBlueHover};
        /** overwrite nmcom styles */
        outline: auto 5px -webkit-focus-ring-color;
      }

      &:active {
        color: ${buttonColorWhite};
        border-color: ${buttonColorBlueActive};
        background-color: ${buttonColorBlueActive};
      }
    `;

    /** TODO: import BaseButton from '../Button/styles' */
    const getButtonTertiaryStyles = () => css`
      display: inline-flex; /** default <button> DOM element style is inline-block */
      @media print {
        display: none !important;
      }
      min-width: ${rem(200)};
      max-width: max-content;
      height: auto;
      min-height: ${rem(55)};
      padding: ${rem(11)} ${rem(25)} ${rem(9)};
      border: 1.5px solid ${buttonColorBlue};
      background-clip: padding-box; /** ff fix */
      background-color: transparent;
      border-radius: ${rem(2)};
      color: ${buttonColorBlue};
      cursor: pointer;
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: ${rem(1)};
      line-height: ${19 / 16};
      -webkit-tap-highlight-color: transparent;
      align-items: center;
      justify-content: center;
      transition: background-color ${buttonTransition} ease-out,
        box-shadow ${buttonTransition} ease-out,
        color ${buttonTransition} ease-out, border ${buttonTransition} ease-out;
      /** TODO: confirm autoprefixer handles this */
      user-select: none;
      box-shadow: unset;

      /**  // TODO: reconfigure focus to allow native focus ring
      // &:focus { // TODO: complete and test focus ring build
      //   outline: auto 5px -webkit-focus-ring-color;
      // } */

      /** ff focus fix */
      &::-moz-focus-inner {
        padding: 0;
        border: none;
      }

      /** ff focus fix */
      /** FF Focus Ring takes into account inner text indented at -9999px, TODO: auto-set aria-label to "working" and make sure button height is retained when inner text is removed, will come back to this. */
      &:-moz-focusring {
        outline: 2px solid ${buttonColorBlueAccent};
        outline-offset: ${rem(2)};
      }

      /** base button states / .is-active is the JS alternative way for us to trigger the hover state on a button dynamically, if need be. */
      &:hover,
      &.is-active {
        color: ${buttonColorBlueHover};
        border-color: ${buttonColorBlueHover};
        text-decoration: none;
        background-color: transparent;
      }

      &:focus {
        /** this will override the hover state when it's focused. */
        color: ${buttonColorBlueHover};
        border-color: ${buttonColorBlueHover};
        background-color: transparent;
        /** overwrite nmcom styles */
        outline: auto 5px -webkit-focus-ring-color;
      }

      &:active {
        color: ${buttonColorBlueActive};
        border-color: ${buttonColorBlueActive};
        background-color: transparent;
      }
    `;

    const getPcgStyles = () => `
      color: ${darkBlue3};
    `;

    return css`
      ${getPrimaryStyles()}
      ${variant === 'secondary' && getSecondaryStyles()}
      ${variant === 'buttonPrimary' && getButtonPrimaryStyles()}
      ${variant === 'buttonSecondary' && getButtonSecondaryStyles()}
      ${variant === 'buttonTertiary' && getButtonTertiaryStyles()}
      ${themeType === 'nmx-pcg' && getPcgStyles()}
    `;
  }}
`;

export const StartAdornmentContainerStyled = styled.span`
  margin-right: ${rem(10)};
`;

export const EndAdornmentContainerStyled = styled.span`
  margin-left: ${rem(10)};
`;
