import React from 'react';
import PropTypes from 'prop-types';

// import Illustrative Icons
import AppleWithStem from '../../icons/illustrative/AppleWithStem';
import Atom from '../../icons/illustrative/Atom';
import AwarenessRibbon from '../../icons/illustrative/AwarenessRibbon';
import BabyBuggy from '../../icons/illustrative/BabyBuggy';
import BarChartWithUpwardArrow from '../../icons/illustrative/BarChartWithUpwardArrow';
import BeachChairWithBorder from '../../icons/illustrative/BeachChairWithBorder';
import BeachChairWithSun from '../../icons/illustrative/BeachChairWithSun';
import BirthdayCake from '../../icons/illustrative/BirthdayCake';
import BlueprintWithHouse from '../../icons/illustrative/BlueprintWithHouse';
import BrainWithLightningBolt from '../../icons/illustrative/BrainWithLightningBolt';
import BookWithBookmark from '../../icons/illustrative/BookWithBookmark';
import Calendar from '../../icons/illustrative/Calendar';
import Camera from '../../icons/illustrative/Camera';
import CarMoving from '../../icons/illustrative/CarMoving';
import CertificateWithAwardRibbon from '../../icons/illustrative/CertificateWithAwardRibbon';
import CheckmarkWithBorder from '../../icons/illustrative/CheckmarkWithBorder';
import Cityscape from '../../icons/illustrative/Cityscape';
import ClipboardWithDollarSignPaper from '../../icons/illustrative/ClipboardWithDollarSignPaper';
import Clock from '../../icons/illustrative/Clock';
import CoffeeCupDisposable from '../../icons/illustrative/CoffeeCupDisposable';
import ComputerScreenWithMouseArrow from '../../icons/illustrative/ComputerScreenWithMouseArrow';
import CurvedArrowThroughXxo from '../../icons/illustrative/CurvedArrowThroughXxo';
import DialGauge from '../../icons/illustrative/DialGauge';
import DogWithCollar from '../../icons/illustrative/DogWithCollar';
import DollarSignRevolvingArrows from '../../icons/illustrative/DollarSignRevolvingArrows';
import DollarSignWithCircleAndMouseArrow from '../../icons/illustrative/DollarSignWithCircleAndMouseArrow';
import DollarSignWithFourArrowsPointingInward from '../../icons/illustrative/DollarSignWithFourArrowsPointingInward';
import Dress from '../../icons/illustrative/Dress';
import DrinkingGlassWithStrawAndLemonWheel from '../../icons/illustrative/DrinkingGlassWithStrawAndLemonWheel';
import EnvelopeMoving from '../../icons/illustrative/EnvelopeMoving';
import EnvelopeWithAtSymbolPaper from '../../icons/illustrative/EnvelopeWithAtSymbolPaper';
import EnvelopeWithPaperMoney from '../../icons/illustrative/EnvelopeWithPaperMoney';
import FamilyHeart from '../../icons/illustrative/FamilyHeart';
import FistHoldingPencil from '../../icons/illustrative/FistHoldingPencil';
import FiveConnectedNodes from '../../icons/illustrative/FiveConnectedNodes';
import FolderWithPaperMoney from '../../icons/illustrative/FolderWithPaperMoney';
import FourRomanColumnsYellowRoofDot from '../../icons/illustrative/FourRomanColumnsYellowRoofDot';
import FourRomanColumnsYellowRoofDotWithClock4OClock from '../../icons/illustrative/FourRomanColumnsYellowRoofDotWithClock4OClock';
import FourRomanColumnsYellowRoofDotWithClock12OClock from '../../icons/illustrative/FourRomanColumnsYellowRoofDotWithClock12OClock';
import GearAndGlobeConnectedWithOneWayArrows from '../../icons/illustrative/GearAndGlobeConnectedWithOneWayArrows';
import GraduationHatWithTassle from '../../icons/illustrative/GraduationHatWithTassle';
import GroupOfSixPeople from '../../icons/illustrative/GroupOfSixPeople';
import HandHoldingCreditCard from '../../icons/illustrative/HandHoldingCreditCard';
import HandWithThreeDollarSigns from '../../icons/illustrative/HandWithThreeDollarSigns';
import HandWithThreePlusSigns from '../../icons/illustrative/HandWithThreePlusSigns';
import Handshake from '../../icons/illustrative/Handshake';
import HeartWithCross from '../../icons/illustrative/HeartWithCross';
import HeartWithPulseOximeterWave from '../../icons/illustrative/HeartWithPulseOximeterWave';
import HomeSafeLocked from '../../icons/illustrative/HomeSafeLocked';
import Hourglass from '../../icons/illustrative/Hourglass';
import HouseWithCheckmarkShield from '../../icons/illustrative/HouseWithCheckmarkShield';
import HouseWithHammer from '../../icons/illustrative/HouseWithHammer';
import HouseWithPaperMoney from '../../icons/illustrative/HouseWithPaperMoney';
import House from '../../icons/illustrative/House';
import IdentificationBadge from '../../icons/illustrative/IdentificationBadge';
import InformationWordBubble from '../../icons/illustrative/InformationWordBubble';
import InnerOfficeEnvelopeFastenedWithString from '../../icons/illustrative/InnerOfficeEnvelopeFastenedWithString';
import Leaf from '../../icons/illustrative/Leaf';
import LifePreserverVest from '../../icons/illustrative/LifePreserverVest';
import Lightbulb from '../../icons/illustrative/Lightbulb';
import LineGraphUpwardsTrend from '../../icons/illustrative/LineGraphUpwardsTrend';
import MagnifyingGlassWithDollarSign from '../../icons/illustrative/MagnifyingGlassWithDollarSign';
import MagnifyingGlass from '../../icons/illustrative/MagnifyingGlass';
import MapPinInformational from '../../icons/illustrative/MapPinInformational';
import MapPin from '../../icons/illustrative/MapPin';
import MedalWithStar from '../../icons/illustrative/MedalWithStar';
import MicrophoneWithCable from '../../icons/illustrative/MicrophoneWithCable';
import MobileDeviceWithTwoWordBubbles from '../../icons/illustrative/MobileDeviceWithTwoWordBubbles';
import MoneyParachuteWithBorder from '../../icons/illustrative/MoneyParachuteWithBorder';
import MountaintopWithFlag from '../../icons/illustrative/MountaintopWithFlag';
import MobileDeviceWithWifiSymbol from '../../icons/illustrative/MobileDeviceWithWifiSymbol';
import OneArrowPointingUpToHorizontalLine from '../../icons/illustrative/OneArrowPointingUpToHorizontalLine';
import OneMapPinOneTaxPinConnectedWithCurvyDottedLines from '../../icons/illustrative/OneMapPinOneTaxPinConnectedWithCurvyDottedLine';
import OnePagePaperArticle from '../../icons/illustrative/OnePagePaperArticle';
import OnePersonOnChampionshipPodiumFirstPlace from '../../icons/illustrative/OnePersonOnChampionshipPodiumFirstPlace';
import OneTaxPinOneMapPinConnectedWithCurvyDottedLines from '../../icons/illustrative/OneTaxPinOneMapPinConnectedWithCurvyDottedLine';
import PadlockDisengaged from '../../icons/illustrative/PadlockDisengaged';
import PadlockEngaged from '../../icons/illustrative/PadlockEngaged';
import PaperMap from '../../icons/illustrative/PaperMap';
import PaperMoneyStack from '../../icons/illustrative/PaperMoneyStack';
import PaperShoppingBagEmpty from '../../icons/illustrative/PaperShoppingBagEmpty';
import PaperWithDeadFace from '../../icons/illustrative/PaperWithDeadFace';
import PaperWithDollarSignAndBarChart from '../../icons/illustrative/PaperWithDollarSignAndBarChart';
import PersonHoldingGroundedFlag from '../../icons/illustrative/PersonHoldingGroundedFlag';
import PersonOutwardArrows from '../../icons/illustrative/PersonOutwardArrows';
import PersonWithLightbulbWordBubble from '../../icons/illustrative/PersonWithLightbulbWordBubble';
import PersonWithPastGrowthHeightLines from '../../icons/illustrative/PersonWithPastGrowthHeightLines';
import PersonWithPlusCircle from '../../icons/illustrative/PersonWithPlusCircle';
import PersonWithXCircle from '../../icons/illustrative/PersonWithXCircle';
import Piggybank from '../../icons/illustrative/Piggybank';
import PottedPlantWithDollarSignCircle from '../../icons/illustrative/PottedPlantWithDollarSignCircle';
import PriceTagWithDollarSign from '../../icons/illustrative/PriceTagWithDollarSign';
import ProfileOutlineCheckmarkWithBorder from '../../icons/illustrative/ProfileOutlineCheckmarkWithBorder';
import ProjectorScreenWithPieChart from '../../icons/illustrative/ProjectorScreenWithPieChart';
import Quotation from '../../icons/illustrative/Quotation';
import RibbonWithCheckmark from '../../icons/illustrative/RibbonWithCheckmark';
import Rocket from '../../icons/illustrative/Rocket';
import SailboatOnWater from '../../icons/illustrative/SailboatOnWater';
import SettingsSlider from '../../icons/illustrative/SettingsSlider';
import ShieldWithClock180Arrow from '../../icons/illustrative/ShieldWithClock180Arrow';
import ShieldWithClock360Arrow from '../../icons/illustrative/ShieldWithClock360Arrow';
import ShieldWithCross from '../../icons/illustrative/ShieldWithCross';
import ShieldWithDollarSign from '../../icons/illustrative/ShieldWithDollarSign';
import ShieldWithGlobe2Arrows from '../../icons/illustrative/ShieldWithGlobe2Arrows';
import ShieldWithGlobe3Arrows from '../../icons/illustrative/ShieldWithGlobe3Arrows';
import ShieldWithHeartBorder from '../../icons/illustrative/ShieldWithHeartBorder';
import ShieldWithHeart from '../../icons/illustrative/ShieldWithHeart';
import ShieldWithKeyhole from '../../icons/illustrative/ShieldWithKeyhole';
import SquareWithPlusMinusMultiplyEqualQuadrants from '../../icons/illustrative/SquareWithPlusMinusMultiplyEqualQuadrants';
import StopwatchWithDollarSign from '../../icons/illustrative/StopwatchWithDollarSign';
import SubwayTrainOnTracks from '../../icons/illustrative/SubwayTrainOnTracks';
import SunsetOverWaterHorizon from '../../icons/illustrative/SunsetOverWaterHorizon';
import TelephoneWithOutgoingSound from '../../icons/illustrative/TelephoneWithOutgoingSound';
import ThreeConnectedNodes from '../../icons/illustrative/ThreeConnectedNodes';
import ThreePeopleConnectedByInnerLines from '../../icons/illustrative/ThreePeopleConnectedByInnerLines';
import ThreePeopleConnectedByOuterLines from '../../icons/illustrative/ThreePeopleConnectedByOuterLines';
import ThreePeopleWithDollarSign from '../../icons/illustrative/ThreePeopleWithDollarSign';
import ThumbsUp from '../../icons/illustrative/ThumbsUp';
import TwoInformationalWordBubbles from '../../icons/illustrative/TwoInformationalWordBubbles';
import TwoLeaves from '../../icons/illustrative/TwoLeaves';
import TwoMapPinsConnectedWithCurvyDottedLine from '../../icons/illustrative/TwoMapPinsConnectedWithCurvyDottedLine';
import TwoPapersWithDollarSign from '../../icons/illustrative/TwoPapersWithDollarSign';
import TwoPeopleOneWayArrow from '../../icons/illustrative/TwoPeopleOneWayArrow';
import TwoPeopleOneWordBubble from '../../icons/illustrative/TwoPeopleOneWordBubble';
import TwoPeopleTwoWayArrows from '../../icons/illustrative/TwoPeopleTwoWayArrows';
import TwoPeopleTwoWordBubbles from '../../icons/illustrative/TwoPeopleTwoWordBubbles';
import TwoPersonTournamentBracketWithTrophy from '../../icons/illustrative/TwoPersonTournamentBracketWithTrophy';
import TwoRevolvingArrowsOval from '../../icons/illustrative/TwoRevolvingArrowsOval';
import UmbrellaWithBorder from '../../icons/illustrative/UmbrellaWithBorder';
import Umbrella from '../../icons/illustrative/Umbrella';
import Wallet from '../../icons/illustrative/Wallet';
import WebPageWithDollarSign from '../../icons/illustrative/WebPageWithDollarSign';
import WomanWordBubbleLightbulb from '../../icons/illustrative/WomanWordBubbleLightbulb';

// import Illustrative Secondary Icons
import EnvelopeWithAtSymbolPaperSecondary from '../../icons/illustrative-secondary/EnvelopeWithAtSymbolPaperSecondary';
import FourRomanColumnsYellowRoofDotWithClock4OClockSecondary from '../../icons/illustrative-secondary/FourRomanColumnsYellowRoofDotWithClock4OClockSecondary';
import FourRomanColumnsYellowRoofDotWithClock12OClockSecondary from '../../icons/illustrative-secondary/FourRomanColumnsYellowRoofDotWithClock12OClockSecondary';
import LightbulbSecondary from '../../icons/illustrative-secondary/LightbulbSecondary';
import ShieldWithClockSecondary from '../../icons/illustrative-secondary/ShieldWithClockSecondary';
import ShieldWithPlusSignSecondary from '../../icons/illustrative-secondary/ShieldWithPlusSignSecondary';
import TelephoneWithOutgoingSoundSecondary from '../../icons/illustrative-secondary/TelephoneWithOutgoingSoundSecondary';
import ThumbsUpSecondary from '../../icons/illustrative-secondary/ThumbsUpSecondary';

// import LayoutIcons
import BeachChairWithUmbrella from '../../icons/layout/BeachChairWithUmbrella';
import FamilyShieldClouds from '../../icons/layout/FamilyShieldClouds';
import GetMatched from '../../icons/layout/GetMatched';
import PaperMoneyParachute from '../../icons/layout/PaperMoneyParachute';

// import Social Icons
import Facebook from '../../icons/social/Facebook';
import FacebookLightTheme from '../../icons/social/lightTheme/Facebook';
import FacebookLike from '../../icons/social/FacebookLike';
import Instagram from '../../icons/social/Instagram';
import Linkedin from '../../icons/social/Linkedin';
import LinkedinLightTheme from '../../icons/social/lightTheme/Linkedin';
import Pinterest from '../../icons/social/Pinterest';
import Twitter from '../../icons/social/Twitter'; // deprecated
import TwitterLightTheme from '../../icons/social/lightTheme/Twitter'; // deprecated
import Youtube from '../../icons/social/Youtube';
import X from '../../icons/social/X';

// import Utility Icons
import AlertStrokeIcon from '../../icons/utility/AlertStroke';
import ArrowRightIcon from '../../icons/utility/ArrowRight';
import BabyFace from '../../icons/utility/BabyFace';
import BoxWithArrowPointingOutwardIcon from '../../icons/utility/BoxWithArrowPointingOutward';
import Calculator from '../../icons/utility/Calculator';
import CaratRight from '../../icons/utility/CaratRight';
import Checkbook from '../../icons/utility/Checkbook';
import CheckmarkBoldIcon from '../../icons/utility/CheckmarkBold';
import ChevronDownIcon from '../../icons/utility/ChevronDown';
import ChevronLeftIcon from '../../icons/utility/ChevronLeft';
import ChevronLeftNarrowIcon from '../../icons/utility/ChevronLeftNarrow';
import ChevronRightIcon from '../../icons/utility/ChevronRight';
import ChevronRightNarrowIcon from '../../icons/utility/ChevronRightNarrow';
import ClockUtilityIcon from '../../icons/utility/ClockUtility';
import CloseIcon from '../../icons/utility/Close';
import Compass from '../../icons/utility/Compass';
import DrawerCaretIcon from '../../icons/utility/DrawerCaret';
import DrawerCaretFlippedIcon from '../../icons/utility/DrawerCaretFlipped';
import ErrorNotificationFillIcon from '../../icons/utility/ErrorNotificationFill';
import EyeIcon from '../../icons/utility/Eye';
import FaxIcon from '../../icons/utility/Fax';
import LightbulbUtility from '../../icons/utility/Lightbulb';
import MinusSignFill from '../../icons/utility/MinusSignFill';
// import MapPinUtility from '../../icons/utility/MapPinUtility';
import MoreInfoStrokeIcon from '../../icons/utility/MoreInfoStroke';
import PalmTreeIsland from '../../icons/utility/PalmTreeIsland';
import PaperFoldedCorner from '../../icons/utility/PaperFoldedCorner';
import Pdf from '../../icons/utility/Pdf';
import Phone from '../../icons/utility/Phone';
import PieChart from '../../icons/utility/PieChart';
import PlayMedia from '../../icons/utility/PlayMedia';
import PlusSignFill from '../../icons/utility/PlusSignFill';
import ProfileOutlineIcon from '../../icons/utility/ProfileOutline';
import QuotationMarkDoubleSquareUtility from '../../icons/utility/QuotationMarkDoubleSquare';
import RolledScroll from '../../icons/utility/RolledScroll';
import SearchIcon from '../../icons/utility/Search';
import ShieldWithCheckmark from '../../icons/utility/ShieldWithCheckmark';
import Star from '../../icons/utility/Star';

const IconComponent = ({ id, className, variant, ...otherProps }) => {
  switch (variant) {
  // illustrative
    case 'apple-with-stem':
      return <AppleWithStem className={className} {...otherProps} />;
    case 'atom':
      return <Atom className={className} {...otherProps} />;
    case 'awareness-ribbon':
      return <AwarenessRibbon className={className} {...otherProps} />;
    case 'baby-buggy':
      return <BabyBuggy className={className} {...otherProps} />;
    case 'bar-chart-with-upward-arrow':
      return <BarChartWithUpwardArrow className={className} {...otherProps} />;
    case 'beach-chair-with-border':
      return <BeachChairWithBorder className={className} {...otherProps} />;
    case 'beach-chair-with-sun':
      return <BeachChairWithSun className={className} {...otherProps} />;
    case 'birthday-cake':
      return <BirthdayCake className={className} {...otherProps} />;
    case 'blueprint-with-house':
      return <BlueprintWithHouse className={className} {...otherProps} />;
    case 'book-with-bookmark':
      return <BookWithBookmark className={className} {...otherProps} />;
    case 'brain-with-lightning-bolt':
      return <BrainWithLightningBolt className={className} {...otherProps} />;
    case 'calendar':
      return <Calendar className={className} {...otherProps} />;
    case 'camera':
      return <Camera className={className} {...otherProps} />;
    case 'car-moving':
      return <CarMoving className={className} {...otherProps} />;
    case 'certificate-with-award-ribbon':
      return <CertificateWithAwardRibbon className={className} {...otherProps} />;
    case 'checkmark-with-border':
      return <CheckmarkWithBorder className={className} {...otherProps} />;
    case 'cityscape':
      return <Cityscape className={className} {...otherProps} />;
    case 'clipboard-with-dollar-sign-paper':
      return <ClipboardWithDollarSignPaper className={className} {...otherProps} />;
    case 'clock':
      return <Clock className={className} {...otherProps} />;
    case 'coffee-cup-disposable':
      return <CoffeeCupDisposable className={className} {...otherProps} />;
    case 'computer-screen-with-mouse-arrow':
      return <ComputerScreenWithMouseArrow className={className} {...otherProps} />;
    case 'curved-arrow-through-xxo':
      return <CurvedArrowThroughXxo className={className} {...otherProps} />;
    case 'dial-gauge':
      return <DialGauge className={className} {...otherProps} />;
    case 'dog-with-collar':
      return <DogWithCollar className={className} {...otherProps} />;
    case 'dollar-sign-with-circle-and-mouse-arrow':
      return <DollarSignWithCircleAndMouseArrow className={className} {...otherProps} />;
    case 'dollar-sign-with-four-arrows-pointing-inward':
      return <DollarSignWithFourArrowsPointingInward className={className} {...otherProps} />;
    case 'dollar-sign-revolving-arrows':
      return <DollarSignRevolvingArrows className={className} {...otherProps} />;
    case 'dress':
      return <Dress className={className} {...otherProps} />;
    case 'drinking-glass-with-straw-and-lemon-wheel':
      return <DrinkingGlassWithStrawAndLemonWheel className={className} {...otherProps} />;
    case 'envelope-moving':
      return <EnvelopeMoving className={className} {...otherProps} />;
    case 'envelope-with-at-symbol-paper':
      return <EnvelopeWithAtSymbolPaper className={className} {...otherProps} />;
    case 'envelope-with-paper-money':
      return <EnvelopeWithPaperMoney className={className} {...otherProps} />;
    case 'family-heart':
      return <FamilyHeart className={className} {...otherProps} />;
    case 'fist-holding-pencil':
      return <FistHoldingPencil className={className} {...otherProps} />;
    case 'five-connected-nodes':
      return <FiveConnectedNodes className={className} {...otherProps} />;
    case 'folder-with-paper-money':
      return <FolderWithPaperMoney className={className} {...otherProps} />;
    case 'four-roman-columns-yellow-roof-dot':
      return <FourRomanColumnsYellowRoofDot className={className} {...otherProps} />;
    case 'four-roman-columns-yellow-roof-dot-with-clock-4-o-clock':
      return <FourRomanColumnsYellowRoofDotWithClock4OClock className={className} {...otherProps} />;
    case 'four-roman-columns-yellow-roof-dot-with-clock-12-o-clock':
      return <FourRomanColumnsYellowRoofDotWithClock12OClock className={className} {...otherProps} />;
    case 'gear-and-globe-connected-with-one-way-arrows':
      return <GearAndGlobeConnectedWithOneWayArrows className={className} {...otherProps} />;
    case 'graduation-hat-with-tassle':
      return <GraduationHatWithTassle className={className} {...otherProps} />;
    case 'group-of-six-people':
      return <GroupOfSixPeople className={className} {...otherProps} />;
    case 'hand-holding-credit-card':
      return <HandHoldingCreditCard className={className} {...otherProps} />;
    case 'hand-with-three-dollar-signs':
      return <HandWithThreeDollarSigns className={className} {...otherProps} />;
    case 'hand-with-three-plus-signs':
      return <HandWithThreePlusSigns className={className} {...otherProps} />;
    case 'handshake':
      return <Handshake className={className} {...otherProps} />;
    case 'heart-with-cross':
      return <HeartWithCross className={className} {...otherProps} />;
    case 'heart-with-pulse-oximeter-wave':
      return <HeartWithPulseOximeterWave className={className} {...otherProps} />;
    case 'home-safe-locked':
      return <HomeSafeLocked className={className} {...otherProps} />;
    case 'hourglass':
      return <Hourglass className={className} {...otherProps} />;
    case 'house-with-checkmark-shield':
      return <HouseWithCheckmarkShield className={className} {...otherProps} />;
    case 'house-with-hammer':
      return <HouseWithHammer className={className} {...otherProps} />;
    case 'house-with-paper-money':
      return <HouseWithPaperMoney className={className} {...otherProps} />;
    case 'house':
      return <House className={className} {...otherProps} />;
    case 'identification-badge':
      return <IdentificationBadge className={className} {...otherProps} />;
    case 'information-word-bubble':
      return <InformationWordBubble className={className} {...otherProps} />;
    case 'inner-office-envelope-fastened-with-string':
      return <InnerOfficeEnvelopeFastenedWithString className={className} {...otherProps} />;
    case 'leaf':
      return <Leaf className={className} {...otherProps} />;
    case 'life-preserver-vest':
      return <LifePreserverVest className={className} {...otherProps} />;
    case 'lightbulb':
      return <Lightbulb className={className} {...otherProps} />;
    case 'line-graph-upwards-trend':
      return <LineGraphUpwardsTrend className={className} {...otherProps} />;
    case 'magnifying-glass-with-dollar-sign':
      return <MagnifyingGlassWithDollarSign className={className} {...otherProps} />;
    case 'magnifying-glass':
      return <MagnifyingGlass className={className} {...otherProps} />;
    case 'map-pin-informational':
      return <MapPinInformational className={className} {...otherProps} />;
    case 'map-pin':
      return <MapPin className={className} {...otherProps} />;
    case 'medal-with-star':
      return <MedalWithStar className={className} {...otherProps} />;
    case 'microphone-with-cable':
      return <MicrophoneWithCable className={className} {...otherProps} />;
    case 'mobile-device-with-two-word-bubbles':
      return <MobileDeviceWithTwoWordBubbles className={className} {...otherProps} />;
    case 'money-parachute-with-border':
      return <MoneyParachuteWithBorder className={className} {...otherProps} />;
    case 'mountaintop-with-flag':
      return <MountaintopWithFlag className={className} {...otherProps} />;
    case 'mobile-device-with-wifi-symbol':
      return <MobileDeviceWithWifiSymbol className={className} {...otherProps} />;
    case 'one-arrow-pointing-up-to-horizontal-line':
      return <OneArrowPointingUpToHorizontalLine className={className} {...otherProps} />;
    case 'one-map-pin-one-tax-pin-connected-with-curvy-dotted-line':
      return <OneMapPinOneTaxPinConnectedWithCurvyDottedLines className={className} {...otherProps} />;
    case 'one-page-paper-article':
      return <OnePagePaperArticle className={className} {...otherProps} />;
    case 'one-person-on-championship-podium-first-place':
      return <OnePersonOnChampionshipPodiumFirstPlace className={className} {...otherProps} />;
    case 'one-tax-pin-one-map-pin-connected-with-curvy-dotted-line':
      return <OneTaxPinOneMapPinConnectedWithCurvyDottedLines className={className} {...otherProps} />;
    case 'padlock-disengaged':
      return <PadlockDisengaged className={className} {...otherProps} />;
    case 'padlock-engaged':
      return <PadlockEngaged className={className} {...otherProps} />;
    case 'paper-map':
      return <PaperMap className={className} {...otherProps} />;
    case 'paper-money-stack':
      return <PaperMoneyStack className={className} {...otherProps} />;
    case 'paper-shopping-bag-empty':
      return <PaperShoppingBagEmpty className={className} {...otherProps} />;
    case 'paper-with-dead-face':
      return <PaperWithDeadFace className={className} {...otherProps} />;
    case 'paper-with-dollar-sign-and-bar-chart':
      return <PaperWithDollarSignAndBarChart className={className} {...otherProps} />;
    case 'person-holding-grounded-flag':
      return <PersonHoldingGroundedFlag className={className} {...otherProps} />;
    case 'person-outward-arrows':
      return <PersonOutwardArrows className={className} {...otherProps} />;
    case 'person-with-lightbulb-word-bubble':
      return <PersonWithLightbulbWordBubble className={className} {...otherProps} />;
    case 'person-with-past-growth-height-lines':
      return <PersonWithPastGrowthHeightLines className={className} {...otherProps} />;
    case 'person-with-plus-circle':
      return <PersonWithPlusCircle className={className} {...otherProps} />;
    case 'person-with-x-circle':
      return <PersonWithXCircle className={className} {...otherProps} />;
    case 'piggybank':
      return <Piggybank className={className} {...otherProps} />;
    case 'potted-plant-with-dollar-sign-circle':
      return <PottedPlantWithDollarSignCircle className={className} {...otherProps} />;
    case 'price-tag-with-dollar-sign':
      return <PriceTagWithDollarSign className={className} {...otherProps} />;
    case 'profile-outline-checkmark-with-border':
      return <ProfileOutlineCheckmarkWithBorder className={className} {...otherProps} />;
    case 'projector-screen-with-pie-chart':
      return <ProjectorScreenWithPieChart className={className} {...otherProps} />;
    case 'quotation':
      return <Quotation className={className} {...otherProps} />;
    case 'ribbon-with-checkmark':
      return <RibbonWithCheckmark className={className} {...otherProps} />;
    case 'rocket':
      return <Rocket className={className} {...otherProps} />;
    case 'sailboat-on-water':
      return <SailboatOnWater className={className} {...otherProps} />;
    case 'settings-slider':
      return <SettingsSlider className={className} {...otherProps} />;
    case 'shield-with-clock-180-arrow':
      return <ShieldWithClock180Arrow className={className} {...otherProps} />;
    case 'shield-with-clock-360-arrow':
      return <ShieldWithClock360Arrow className={className} {...otherProps} />;
    case 'shield-with-cross':
      return <ShieldWithCross className={className} {...otherProps} />;
    case 'shield-with-dollar-sign':
      return <ShieldWithDollarSign className={className} {...otherProps} />;
    case 'shield-with-globe-2-arrows':
      return <ShieldWithGlobe2Arrows className={className} {...otherProps} />;
    case 'shield-with-globe-3-arrows':
      return <ShieldWithGlobe3Arrows className={className} {...otherProps} />;
    case 'shield-with-heart-border':
      return <ShieldWithHeartBorder className={className} {...otherProps} />;
    case 'shield-with-heart':
      return <ShieldWithHeart className={className} {...otherProps} />;
    case 'shield-with-keyhole':
      return <ShieldWithKeyhole className={className} {...otherProps} />;
    case 'square-with-plus-minus-multiply-equal-quadrants':
      return <SquareWithPlusMinusMultiplyEqualQuadrants className={className} {...otherProps} />;
    case 'stopwatch-with-dollar-sign':
      return <StopwatchWithDollarSign className={className} {...otherProps} />;
    case 'subway-train-on-tracks':
      return <SubwayTrainOnTracks className={className} {...otherProps} />;
    case 'sunset-over-water-horizon':
      return <SunsetOverWaterHorizon className={className} {...otherProps} />;
    case 'telephone-with-outgoing-sound':
      return <TelephoneWithOutgoingSound className={className} {...otherProps} />;
    case 'three-connected-nodes':
      return <ThreeConnectedNodes className={className} {...otherProps} />;
    case 'three-people-connected-by-inner-lines':
      return <ThreePeopleConnectedByInnerLines className={className} {...otherProps} />;
    case 'three-people-connected-by-outer-lines':
      return <ThreePeopleConnectedByOuterLines className={className} {...otherProps} />;
    case 'three-people-with-dollar-sign':
      return <ThreePeopleWithDollarSign className={className} {...otherProps} />;
    case 'thumbs-up':
      return <ThumbsUp className={className} {...otherProps} />;
    case 'two-informational-word-bubbles':
      return <TwoInformationalWordBubbles className={className} {...otherProps} />;
    case 'two-leaves':
      return <TwoLeaves className={className} {...otherProps} />;
    case 'two-map-pins-connected-with-curvy-dotted-line':
      return <TwoMapPinsConnectedWithCurvyDottedLine className={className} {...otherProps} />;
    case 'two-papers-with-dollar-sign':
      return <TwoPapersWithDollarSign className={className} {...otherProps} />;
    case 'two-people-one-way-arrow':
      return <TwoPeopleOneWayArrow className={className} {...otherProps} />;
    case 'two-people-one-word-bubble':
      return <TwoPeopleOneWordBubble className={className} {...otherProps} />;
    case 'two-people-two-way-arrows':
      return <TwoPeopleTwoWayArrows className={className} {...otherProps} />;
    case 'two-people-two-word-bubbles':
      return <TwoPeopleTwoWordBubbles className={className} {...otherProps} />;
    case 'two-person-tournament-bracket-with-trophy':
      return <TwoPersonTournamentBracketWithTrophy className={className} {...otherProps} />;
    case 'two-revolving-arrows-oval':
      return <TwoRevolvingArrowsOval className={className} {...otherProps} />;
    case 'umbrella-with-border':
      return <UmbrellaWithBorder className={className} {...otherProps} />;
    case 'umbrella':
      return <Umbrella className={className} {...otherProps} />;
    case 'wallet':
      return <Wallet className={className} {...otherProps} />;
    case 'web-page-with-dollar-sign':
      return <WebPageWithDollarSign className={className} {...otherProps} />;
    case 'woman-word-bubble-lightbulb':
      return <WomanWordBubbleLightbulb className={className} {...otherProps} />;

    // Illustrative Secondary
    case 'four-roman-columns-yellow-roof-dot-with-clock-4-o-clock-secondary':
      return <FourRomanColumnsYellowRoofDotWithClock4OClockSecondary className={className} {...otherProps} />;
    case 'four-roman-columns-yellow-roof-dot-with-clock-12-o-clock-secondary':
      return <FourRomanColumnsYellowRoofDotWithClock12OClockSecondary className={className} {...otherProps} />;
    case 'envelope-with-at-symbol-paper-secondary':
      return <EnvelopeWithAtSymbolPaperSecondary className={className} {...otherProps} />;
    case 'lightbulb-secondary':
      return <LightbulbSecondary className={className} {...otherProps} />;
    case 'shield-with-clock-360-arrow-secondary':
      return <ShieldWithClockSecondary className={className} {...otherProps} />;
    case 'shield-with-plus-sign-secondary':
      return <ShieldWithPlusSignSecondary className={className} {...otherProps} />;
    case 'telephone-with-outgoing-sound-secondary':
      return <TelephoneWithOutgoingSoundSecondary className={className} {...otherProps} />;
    case 'thumbs-up-secondary':
      return <ThumbsUpSecondary className={className} {...otherProps} />;

    // layout
    case 'beach-chair-with-umbrella':
      return <BeachChairWithUmbrella className={className} {...otherProps} />;
    case 'family-shield-clouds':
      return <FamilyShieldClouds className={className} {...otherProps} />;
    case 'get-matched':
      return <GetMatched className={className} {...otherProps} />;
    case 'paper-money-parachute':
      return <PaperMoneyParachute className={className} {...otherProps} />;

    // social
    case 'facebook':
      return <Facebook className={className} {...otherProps} />;
    case 'facebook-light-theme':
      return <FacebookLightTheme className={className} {...otherProps} />;
    case 'facebook-like':
      return <FacebookLike className={className} {...otherProps} />;
    case 'instagram':
      return <Instagram className={className} {...otherProps} />;
    case 'linkedin':
      return <Linkedin className={className} {...otherProps} />;
    case 'linkedin-light-theme':
      return <LinkedinLightTheme className={className} {...otherProps} />;
    case 'pinterest':
      return <Pinterest className={className} {...otherProps} />;
    case 'twitter':
      return <Twitter className={className} {...otherProps} />;
    case 'twitter-light-theme':
      return <TwitterLightTheme className={className} {...otherProps} />;
    case 'youtube':
      return <Youtube className={className} {...otherProps} />;
    case 'x':
      return <X className={className} {...otherProps} />;

    // utility
    case 'alert-stroke':
      return <AlertStrokeIcon className={className} {...otherProps} />;
    case 'baby-face':
      return <BabyFace className={className} {...otherProps} />;
    case 'plus-sign-fill':
      return <PlusSignFill className={className} {...otherProps} />;
    case 'arrow-right':
      return <ArrowRightIcon className={className} {...otherProps} />;
    case 'box-with-arrow-pointing-outward':
      return <BoxWithArrowPointingOutwardIcon className={className} {...otherProps} />;
    case 'calculator':
      return <Calculator className={className} {...otherProps} />;
    case 'carat-right':
      return <CaratRight className={className} {...otherProps} />;
    case 'checkbook':
      return <Checkbook className={className} {...otherProps} />;
    case 'checkmark-bold':
      return <CheckmarkBoldIcon className={className} {...otherProps} />;
    case 'chevron-down':
      return <ChevronDownIcon className={className} {...otherProps} />;
    case 'chevron-left':
      return <ChevronLeftIcon className={className} {...otherProps} />;
    case 'chevron-left-narrow':
      return <ChevronLeftNarrowIcon className={className} {...otherProps} />;
    case 'chevron-right':
      return <ChevronRightIcon className={className} {...otherProps} />;
    case 'chevron-right-narrow':
      return <ChevronRightNarrowIcon className={className} {...otherProps} />;
    case 'clock-utility':
      return <ClockUtilityIcon className={className} {...otherProps} />;
    case 'close':
      return <CloseIcon className={className} {...otherProps} />;
    case 'compass':
      return <Compass className={className} {...otherProps} />;
    case 'drawer-caret':
      return <DrawerCaretIcon className={className} {...otherProps} />;
    case 'drawer-caret-flipped':
      return <DrawerCaretFlippedIcon className={className} {...otherProps} />;
    case 'error-notification-fill':
      return <ErrorNotificationFillIcon className={className} {...otherProps} />;
    case 'eye':
      return <EyeIcon className={className} {...otherProps} />;
    case 'fax':
      return <FaxIcon className={className} {...otherProps} />;
    case 'lightbulb-utility':
      return <LightbulbUtility className={className} {...otherProps} />;
    case 'minus-sign-fill':
      return <MinusSignFill className={className} {...otherProps} />;
    // case 'map-pin-utility':
    // return <MapPinUtility className={className} {...otherProps} />;
    case 'more-info-stroke':
      return <MoreInfoStrokeIcon className={className} {...otherProps} />;
    case 'palm-tree-island':
      return <PalmTreeIsland className={className} {...otherProps} />;
    case 'paper-folded-corner':
      return <PaperFoldedCorner className={className} {...otherProps} />;
    case 'pdf':
      return <Pdf className={className} {...otherProps} />;
    case 'phone':
      return <Phone className={className} {...otherProps} />;
    case 'pie-chart':
      return <PieChart className={className} {...otherProps} />;
    case 'play-media':
      return <PlayMedia className={className} {...otherProps} />;
    case 'profile-outline':
      return <ProfileOutlineIcon className={className} {...otherProps} />;
    case 'quotation-mark-double-square-utility':
      return <QuotationMarkDoubleSquareUtility className={className} {...otherProps} />;
    case 'rolled-scroll':
      return <RolledScroll className={className} {...otherProps} />;
    case 'search':
      return <SearchIcon className={className} {...otherProps} />;
    case 'shield-with-checkmark':
      return <ShieldWithCheckmark className={className} {...otherProps} />;
    case 'star':
      return <Star className={className} {...otherProps} />;

    default:
      return <AlertStrokeIcon className={className} {...otherProps} />;
  }
};

IconComponent.propTypes = {
  /** Custom class name to add to Icon. */
  className: PropTypes.string,
  /** ID attribute for `Icon`. */
  id: PropTypes.string,
  /**  Determines the look and feel of the Icon */
  variant: PropTypes.oneOf([
    // illustrative
    'apple-with-stem',
    'awareness-ribbon',
    'atom',
    'baby-buggy',
    'bar-chart-with-upward-arrow',
    'beach-chair-with-border',
    'beach-chair-with-sun',
    'birthday-cake',
    'blueprint-with-house',
    'book-with-bookmark',
    'brain-with-lightning-bolt',
    'calendar',
    'camera',
    'car-moving',
    'certificate-with-award-ribbon',
    'checkmark-with-border',
    'cityscape',
    'clipboard-with-dollar-sign-paper',
    'clock',
    'coffee-cup-disposable',
    'computer-screen-with-mouse-arrow',
    'curved-arrow-through-xxo',
    'dial-gauge',
    'dog-with-collar',
    'dollar-sign-revolving-arrows',
    'dollar-sign-with-circle-and-mouse-arrow',
    'dollar-sign-with-four-arrows-pointing-inward',
    'dress',
    'drinking-glass-with-straw-and-lemon-wheel',
    'envelope-moving',
    'envelope-with-at-symbol-paper',
    'envelope-with-paper-money',
    'family-heart',
    'fist-holding-pencil',
    'five-connected-nodes',
    'folder-with-paper-money',
    'four-roman-columns-yellow-roof-dot',
    'gear-and-globe-connected-with-one-way-arrows',
    'graduation-hat-with-tassle',
    'group-of-six-people',
    'hand-holding-credit-card',
    'hand-with-three-dollar-signs',
    'hand-with-three-plus-signs',
    'handshake',
    'heart-with-cross',
    'heart-with-pulse-oximeter-wave',
    'home-safe-locked',
    'hourglass',
    'house-with-checkmark-shield',
    'house-with-hammer',
    'house-with-paper-money',
    'house',
    'identification-badge',
    'information-word-bubble',
    'inner-office-envelope-fastened-with-string',
    'leaf',
    'life-preserver-vest',
    'lightbulb',
    'line-graph-upwards-trend',
    'magnifying-glass-with-dollar-sign',
    'magnifying-glass',
    'map-pin-informational',
    'map-pin',
    'medal-with-star',
    'microphone-with-cable',
    'mobile-device-with-two-word-bubbles',
    'money-parachute-with-border',
    'mountaintop-with-flag',
    'mobile-device-with-wifi-symbol',
    'one-arrow-pointing-up-to-horizontal-line',
    'one-page-paper-article',
    'one-person-on-championship-podium-first-place',
    'padlock-disengaged',
    'padlock-engaged',
    'paper-map',
    'paper-money-stack',
    'paper-shopping-bag-empty',
    'paper-with-dead-face',
    'paper-with-dollar-sign-and-bar-chart',
    'person-holding-grounded-flag',
    'person-outward-arrows',
    'person-with-lightbulb-word-bubble',
    'person-with-past-growth-height-lines',
    'person-with-plus-circle',
    'person-with-x-circle',
    'piggybank',
    'potted-plant-with-dollar-sign-circle',
    'price-tag-with-dollar-sign',
    'profile-outline-checkmark-with-border',
    'projector-screen-with-pie-chart',
    'quotation',
    'ribbon-with-checkmark',
    'rocket',
    'sailboat-on-water',
    'settings-slider',
    'shield-with-clock-180-arrow',
    'shield-with-clock-360-arrow',
    'shield-with-cross',
    'shield-with-dollar-sign',
    'shield-with-globe-2-arrows',
    'shield-with-globe-3-arrows',
    'shield-with-heart-border',
    'shield-with-heart',
    'shield-with-keyhole',
    'square-with-plus-minus-multiply-equal-quadrants',
    'stopwatch-with-dollar-sign',
    'subway-train-on-tracks',
    'sunset-over-water-horizon',
    'telephone-with-outgoing-sound',
    'three-connected-nodes',
    'three-people-connected-by-inner-lines',
    'three-people-connected-by-outer-lines',
    'three-people-with-dollar-sign',
    'thumbs-up',
    'two-informational-word-bubbles',
    'two-leaves',
    'two-map-pins-connected-with-curvy-dotted-line',
    'two-papers-with-dollar-sign',
    'two-people-one-way-arrow',
    'two-people-one-word-bubble',
    'two-people-two-way-arrows',
    'two-people-two-word-bubbles',
    'two-person-tournament-bracket-with-trophy',
    'two-revolving-arrows-oval',
    'umbrella-with-border',
    'umbrella',
    'wallet',
    'web-page-with-dollar-sign',
    'woman-word-bubble-lightbulb',
    // Illustrative Secondary
    'envelope-with-at-symbol-paper-secondary',
    'lightbulb-secondary',
    'shield-with-clock-360-arrow-secondary',
    'shield-with-plus-sign-secondary',
    'telephone-with-outgoing-sound-secondary',
    'thumbs-up-secondary',
    // layout
    'family-shield-clouds',
    'get-matched',
    'paper-money-parachute',
    // social
    'facebook',
    'facebook-light-theme',
    'facebook-like',
    'instagram',
    'linkedin',
    'linkedin-light-theme',
    'pinterest',
    'twitter',
    'twitter-light-theme',
    'youtube',
    'x',
    // utility
    'alert-stroke',
    'arrow-right',
    'baby-face',
    'box-with-arrow-pointing-outward',
    'calculator',
    'carat-right',
    'checkbook',
    'checkmark-bold',
    'chevron-down',
    'chevron-left',
    'chevron-left-narrow',
    'chevron-right',
    'chevron-right-narrow',
    'clock-utility',
    'close',
    'compass',
    'drawer-caret',
    'drawer-caret-flipped',
    'error-notification-fill',
    'eye',
    'fax',
    'lightbulb-utility',
    'minus-sign-fill',
    // 'map-pin-utility',
    'more-info-stroke',
    'palm-tree-island',
    'pdf',
    'phone',
    'pie-chart',
    'play-media',
    'plus-sign-fill',
    'profile-outline',
    'quotation-mark-double-square-utility',
    'rolled-scroll',
    'search',
    'shield-with-checkmark',
    'star',
  ]),
};

// IconComponent.defaultProps = {
//   className: 'nmx-icon',
//   variant: 'alert-stroke',
// }

export default IconComponent;
