/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import Link from '../../base/Link';
import Typography from '../../base/Typography';

export const LinkStyled = styled(Link)`
${({ theme, sectionVariant }) => {
    const primarySection = sectionVariant === 'primary';
    return css`
      white-space: nowrap;
      ${primarySection && `color: ${theme.colors.neutral.white}`};
      :hover {
        ${primarySection && `color: ${theme.colors.neutral.gray87}`};
      }
  `;
  }
}`;

export const TypographyStyled = styled(Typography)`
  ${({ theme, disableBottomPadding }) => {
    let paddingBottom = theme.mediumUp() ? rem(16) : rem(12);
    if (disableBottomPadding) {
      paddingBottom = 0;
    }
    return css`
      padding-bottom: ${paddingBottom};
      text-align: ${theme.xlargeUp() ? 'center' : 'left'};
    `;
  }
}`;
