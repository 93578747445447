import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../../../base/Typography';

export const LabelGoalCardStyled = styled.label`
  ${({ theme }) => {
    let padding = `${rem(20)} ${rem(5)} ${rem(10)};`;
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      padding = `${rem(20)} 0 ${rem(10)};`;
    }

    return css`
      border: 1px solid ${theme.colors.accent.lightBlue2};
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      align-items: center;
      width: 100%;
      padding: ${padding};
      cursor: pointer;
      text-align: center;
      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.15);
      }
      &.is-active {
        background-color: ${theme.colors.neutral.white};
        .fafa-hero-goal-card-label-text {
          color: ${theme.colors.neutral.gray29};
        }
        /** dynamically change SVG icon colors */
        .dynamic-stroke {
          stroke: ${theme.colors.primary.blue};
        }
        .dynamic-fill {
          fill: ${theme.colors.primary.blue};
        }
      }
    `;
  }}
`;

export const InputRadioCardStyled = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
`;

export const DivGoalCardIconContainerStyled = styled.div`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();

    return css`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-bottom: ${rem(5)};
    height: ${mediumUp ? rem(50) : rem(40)};
    svg {
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  `;
  }
}`;

export const DivGoalCardTextContainerStyled = styled.div`
  ${({ theme }) => {
    let height = rem(45);
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();
    if (xlargeUp) {
      height = rem(30);
    } else if (largeUp) {
      height = rem(45);
    } else if (mediumUp) {
      height = rem(30);
    }

    return css`
      display: flex;
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: ${height};
    `;
  }}
`;

export const TypographyGoalCardStyled = styled(Typography)`
  ${({ theme }) => {
    let fontSize = rem(14);
    let lineHeight = rem(22);
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      fontSize = rem(15);
      lineHeight = rem(25);
    }

    return css`
      color: ${theme.colors.neutral.white};
      font-weight: 500;
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `;
  }}
`;
