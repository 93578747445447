import React from 'react';
import PropTypes from 'prop-types';

import {
  BaseImg,
  BasePicture,
} from './styles';
import breakpoints from '../../theme/breakpoints';

// Style variation for tertiary picture is TBD

export const PictureComponent = ({
  id,
  src,
  styles,
  mobileSrc,
  mobileWebpSrc,
  desktopWebpSrc,
  alt,
  variant,
  fullWidth,
  lazyLoad,
  ...rest
}) => {
  const tabletBp = `(max-width: ${breakpoints.values.medium}px)`; // TODO: mobile first

  const imageProps = {};
  const sourceProps = {};
  if (lazyLoad) {
    imageProps['data-src'] = src;
    imageProps.className = 'lazy';
    if (mobileWebpSrc) { sourceProps['data-srcset'] = mobileWebpSrc; }
    if (mobileSrc) { sourceProps['data-srcset'] = mobileSrc; }
    if (desktopWebpSrc) { sourceProps['data-srcset'] = desktopWebpSrc; }
  } else {
    imageProps.src = src;
    if (mobileWebpSrc) { sourceProps.srcSet = mobileWebpSrc; }
    if (mobileSrc) { sourceProps.srcSet = mobileSrc; }
    if (desktopWebpSrc) { sourceProps.srcSet = desktopWebpSrc; }
  }

  return (
  // TODO: Create a class and modifier(s) for nmx-picture in nmx-template. This is currently unsupported.
    <BasePicture
      id={id}
      {...rest}>
      {mobileWebpSrc && <source
        {...sourceProps}
        type="image/webp"
        media={tabletBp}
      />}
      {mobileSrc && <source
        {...sourceProps}
        media={tabletBp}
      />}
      {desktopWebpSrc && <source
        {...sourceProps}
        type="image/webp"
      />}
      <BaseImg variant={variant} fullWidth={fullWidth} style={styles || {}} alt={alt} {...imageProps}/>
    </BasePicture>
  );
};

PictureComponent.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  styles: PropTypes.object,
  id: PropTypes.string,
  src: PropTypes.any.isRequired,
  mobileSrc: PropTypes.any,
  mobileWebpSrc: PropTypes.any,
  desktopWebpSrc: PropTypes.any,
  alt: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  lazyLoad: PropTypes.bool,
};

PictureComponent.defaultProps = {
  variant: 'primary',
  lazyLoad: true,
};

export default PictureComponent;
