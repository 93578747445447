// systematically organize z-index hierarchy
// menu, tooltip, nav, nav-menu, overlay, modal, immersive-flow, side-slide, toasts, etc.
// there will be breathing room for layer mobility between UI components (toasts, modals, widgets, etc)
// for reference:
// Bootstrap: https://getbootstrap.com/docs/4.1/layout/overview/#z-index
// Material UI: https://next.material-ui.com/customization/z-index/

const menuIncrement = 10;
const toolTip = 20;
// toast/sticky banner
const toast = 200;
const stickyBanner = 210;
// global nav
const globalNav = 300;
const globalNavOverlay = globalNav + (menuIncrement * 1); // 310
const globalNavMain = globalNav + (menuIncrement * 2); // 320
const globalNavLogo = globalNav + (menuIncrement * 2) + 1; // 321
const globalNavPrimary = globalNav + (menuIncrement * 3); // 330
// info banner
const informationalBanner = 350;
// skip nav
const skipNav = 360;
// global search
const search = 400;
const searchOverlay = search + (menuIncrement * 1); // 410
const searchMain = search + (menuIncrement * 2); // 420
const searchMainResults = search + (menuIncrement * 2) + 1; // 421
const searchControls = search + (menuIncrement * 3); // 430
// recaptcha badge
const recaptchaBadge = 500;
// modal
const modal = 600;
const modalOverlay = modal + (menuIncrement * 1); // 610
const modalMain = modal + (menuIncrement * 2); // 620
// immersive flow
// const immersiveFlow = 1000;
// const sideSlide = 2000;

export default {
  globalNav,
  globalNavLogo,
  globalNavMain,
  globalNavOverlay,
  globalNavPrimary,
  // immersiveFlow,
  informationalBanner,
  modal,
  modalMain,
  modalOverlay,
  recaptchaBadge,
  search,
  searchControls,
  searchMain,
  searchMainResults,
  searchOverlay,
  // sideSlide,
  skipNav,
  stickyBanner,
  toast,
  toolTip,
};
