import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../form/TextField';

export const AgeInputComponent = React.forwardRef(({
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
  isPcg,
}, ref) => (
  <TextField
    id="in-page-lead-form-age-input"
    label='Age'
    ref={ref}
    name='age'
    value={value}
    error={error}
    errorMessage={errorMessage}
    onChange={onChange}
    onBlur={onBlur}
    required={true}
    customTheme={'leads'}
    maxLength={3}
    isPcg={isPcg} />
));

AgeInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default AgeInputComponent;
