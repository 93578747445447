import shade from 'polished/lib/color/shade';
import rgba from 'polished/lib/color/rgba';
import rem from 'polished/lib/helpers/rem';
import styled, { css } from 'styled-components';
import colors, { getColorForName } from '../../theme/colors';

const buttonTransition = '100ms';
const buttonColorWhite = getColorForName('color-neutral-white');
const buttonColorBlue = getColorForName('color-system-cta-blue');
const buttonColorBlueHover = getColorForName('color-system-cta-hover-blue');
const buttonColorBlueActive = getColorForName('color-system-cta-active-blue');
const buttonColorYellow = getColorForName('color-system-cta-yellow');
const buttonColorYellowHover = getColorForName('color-system-cta-hover-yellow');
const buttonColorYellowActive = getColorForName('color-system-cta-active-yellow');
const buttonColorBlueAccent = getColorForName('color-accent-blue');
const buttonColorDisabled = getColorForName('color-neutral-gray-41'); // what gray? #696969
const buttonColorDisabledBackground = '#d5d5d5'; // new color from design. Will be added to variables in 3.x
const colorNeutralBlack = getColorForName('color-neutral-black');

const BaseButton = styled.button`
  ${({ variant, disabled, theme, themeType }) => {
    const {
      gold1,
      gold2,
    } = colors.pcg;

    const getBaseButtonStyles = () => css`
      @media print {
        display: none !important;
      }
      min-width: ${rem(220)};
      max-width: max-content;
      height: auto;
      min-height: ${rem(55)};
      padding: ${rem(11)} ${rem(25)} ${rem(9)};
      border: 1.5px solid ${buttonColorYellow};
      background-clip: padding-box; /** ff fix */
      background-color: ${buttonColorYellow};
      border-radius: ${rem(2)};
      color: ${buttonColorBlueAccent};
      cursor: pointer;
      font-size: ${rem(16)};
      font-weight: 500;
      letter-spacing: ${rem(1)};
      line-height: ${19 / 16};
      -webkit-tap-highlight-color: transparent;
      text-align: center;
      transition: background-color ${buttonTransition} ease-out,
        box-shadow ${buttonTransition} ease-out, color ${buttonTransition} ease-out,
        border ${buttonTransition} ease-out;
        /** TODO: confirm autoprefixer handles this */
      user-select: none;

      /**  // TODO: reconfigure focus to allow native focus ring
      // &:focus { // TODO: complete and test focus ring build
      //   outline: auto 5px -webkit-focus-ring-color;
      // } */

      /** ff focus fix */
      &::-moz-focus-inner {
        padding: 0;
        border: none;
      }

      /** ff focus fix */
      /** FF Focus Ring takes into account inner text indented at -9999px, TODO: auto-set aria-label to "working" and make sure button height is retained when inner text is removed, will come back to this. */
      &:-moz-focusring {
        outline: 2px solid ${buttonColorBlueAccent};
        outline-offset: ${rem(2)};
      }

      /** base button states / .is-active is the JS alternative way for us to trigger the hover state on a button dynamically, if need be. */
      &:hover,
      &.is-active {
        border: 1.5px solid ${buttonColorYellowHover};
        /** // text-decoration: underline; // TODO: configure standardized hover state */
        background-color: ${buttonColorYellowHover};
        box-shadow: 0 2px 8px 0 ${rgba(colorNeutralBlack, 0.1)};
      }

      &:focus {
        /** this will override the hover state when it's focused. */
        background-color: ${buttonColorYellowHover};
        /** overwrite nmcom styles */
        outline: auto 5px -webkit-focus-ring-color;
      }

      &:active {
        border: 1.5px solid ${buttonColorYellowActive};
        background-color: ${buttonColorYellowActive};
        box-shadow: 0 2px 8px 0 ${rgba(colorNeutralBlack, 0.1)};
      }
    `;

    const getSecondaryStyles = () => css`
      border: 1.5px solid ${buttonColorBlue};
      background-color: ${buttonColorBlue};
      box-shadow: unset;
      color: ${buttonColorWhite};

      /** secondary button states */
      &:hover {
        border-color: ${buttonColorBlueHover};
        background-color: ${buttonColorBlueHover};
        box-shadow: unset;
      }

      &:active {
        border-color: ${buttonColorBlueActive};
        background-color: ${buttonColorBlueActive};
        box-shadow: unset;
      }

      &:focus {
        border-color: ${buttonColorBlueHover};
        background-color: ${buttonColorBlueHover};
        box-shadow: unset;
      }
    }
  `;

    const getTertiaryStyles = () => css`
      border: 1.5px solid ${buttonColorBlue};
      background-color: transparent;
      box-shadow: unset;
      color: ${buttonColorBlue};

      /** tertiary button states */
      &:hover,
      &.is-active {
        border-color: ${buttonColorBlueHover};
        background-color: transparent;
        box-shadow: unset;
        color: ${buttonColorBlueHover};
      }

      &:active {
        border-color: ${buttonColorBlueActive};
        background-color: transparent;
        box-shadow: unset;
        color: ${buttonColorBlueActive};
      }

      &:focus {
        border-color: ${buttonColorBlueHover};
        /** TODO: configure all tertiary button states */
        background-color: transparent;
        box-shadow: unset;
        color: ${buttonColorBlueHover};
      }
    `;

    const getQuarternaryStyles = () => css`
      border: none;
      background-color: transparent;
      box-shadow: unset;
      color: ${buttonColorBlue};

      /** quarternary button states */
      &:hover,
      &.is-active {
        border: none;
        background-color: transparent;
        box-shadow: unset;
        color: ${buttonColorBlueHover};
      }

      &:active {
        border: none;
        background-color: transparent;
        box-shadow: unset;
        color: ${buttonColorBlueActive};
      }

      &:focus {
        border: none;
        background-color: transparent;
        box-shadow: unset;
        /** TODO: configure all quarternary button states */
        color: ${buttonColorBlueHover};
      }
    `;

    const getDisabledStyles = () => css`
      border: none;
      background-color: ${buttonColorDisabledBackground};
      box-shadow: unset;
      color: ${buttonColorDisabled};
      cursor: default;
      /** disabled button states */
      &:hover,
      &.is-active,
      &:active,
      &:focus {
        border: none;
        background-color: ${buttonColorDisabledBackground};
        box-shadow: unset;
      }
    `;

    const getPcgRuleGradientStylesHover = () => css`
      background: rgb(189, 162, 99);
      background: linear-gradient(
        90deg,
        ${shade(0.05, gold1)} 0%,
        ${shade(0.05, gold2)} 50%,
        ${shade(0.05, gold1)}
      );
    `;

    const getPcgRuleGradientStyles = () => css`
      background: rgb(189, 162, 99);
      background: linear-gradient(
        90deg,
        ${gold1} 0%,
        ${gold2} 50%,
        ${gold1} 100%
      );
    `;

    const getPcgStyles = () => css`
      border: none;
      color: ${getColorForName('color-neutral-gray-29')};
      letter-spacing: ${rem(3)};
      text-transform: uppercase;
      ${getPcgRuleGradientStyles()}

      /** base button states */
      &:hover {
        border: none;
        ${getPcgRuleGradientStylesHover()}
      }

      &:focus {
        border: none;
      }

      &:active {
        border: none;
      }
    `;

    const getPcgDisabledStyles = () => css`
      border: none;
      color: ${buttonColorDisabled};
      letter-spacing: ${rem(3)};
      text-transform: uppercase;
      background-color: ${buttonColorDisabledBackground};
    `;

    return css`
      ${getBaseButtonStyles()}
      ${variant === 'secondary' && getSecondaryStyles()}
      ${variant === 'tertiary' && getTertiaryStyles()}
      ${variant === 'quarternary' && getQuarternaryStyles()}
      ${disabled && getDisabledStyles()}
      ${theme === 'nmx-pcg' && getPcgStyles()}
      ${(themeType === 'nmx-pcg' && !disabled) && getPcgStyles()} /** Adding additional themeType, since theme is a preconfigured keyword in styled components */
      ${(themeType === 'nmx-pcg' && disabled) && getPcgDisabledStyles()}
    `;
  }
}
`;

export default BaseButton;
