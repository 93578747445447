import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import './inline-tooltip.scss';

export const InlineTooltipComponent = ({
  buttonId,
  copy,
  isPcg,
  modalId,
}) => {
  const [modalDisplay, setModalDisplay] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const mmPhone = typeof window !== 'undefined' ? window.matchMedia('(max-width: 1023px)') : false;

  // Dynamically place modals for the tooltips based on location
  const placeModal = (posX, posY) => {
    const modalElem = document.getElementById(modalId);
    modalElem.style.top = `${posY}px`;

    // We ONLY want to set left adjustment for devices larger than phones
    if (!mmPhone.matches) {
      modalElem.style.left = `${posX}px`;
      modalElem.style['max-width'] = `${posX * 2}px`; // prevent overflow of modal off screen for mobile
    } else {
      modalElem.style.left = '50%';
    }
  };

  const closeModal = (event) => {
    if (event.key === 27) {
      closeModal();
    }
    // throttling this close modal function
    if (waiting) {
      return;
    }

    setModalDisplay(false);
    setWaiting(true);

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', closeModal);
      window.removeEventListener('scroll', closeModal);
      window.removeEventListener('keydown', closeModal);
    }

    setTimeout(() => {
      setWaiting(false);
    }, 300);
  };

  const openModal = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', closeModal);
      window.addEventListener('scroll', closeModal);
      window.addEventListener('keydown', closeModal);
    }

    const buttonElem = document.getElementById(buttonId);
    // Get tooltip button location
    const posX = buttonElem.getBoundingClientRect().left;
    const posY = buttonElem.getBoundingClientRect().top;

    placeModal(posX, posY);
    setModalDisplay(true);
  };

  return (
    <span className='nm-inline-tooltip'>
      <span className='nm-inline-tooltip__tooltip'>
        <button
          id={buttonId}
          className={`nm-inline-tooltip__tooltip-icon-button ${modalDisplay && 'is-active'} ${isPcg && 'nmx-pcg'}`}
          type='button'
          onClick={openModal}
          onFocus={openModal}
          onBlur={closeModal}
          onMouseEnter={openModal}
          onMouseLeave={closeModal}>
          ?
        </button>
      </span>
      <span
        id={modalId}
        className={`nm-inline-tooltip__modal-window ${modalDisplay && 'is-active'} ${isPcg && 'nmx-pcg'}`}>
        <span className={`nm-inline-tooltip__modal-window-close-btn ${isPcg && 'nmx-pcg'}`}>&#10005;</span>
        {copy}
      </span>
    </span>
  );
};

InlineTooltipComponent.propTypes = {
  copy: PropTypes.any.isRequired,
  buttonId: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
};

export default withTheme(InlineTooltipComponent);
