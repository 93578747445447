import React from 'react';

import { Row, Col } from '../../../base/Grid';

import { SectionStyled, CopyrightTypographyStyled, CopyrightSmallTypographyStyled } from './styles';

const CopyrightSection = ({ legalCopy, appendFooter }) => (
  <SectionStyled>
    <h4 className="nmx-assistive-text">Footer Copyright</h4>
    <Row align='center'>
      <Col xsmall={12} medium={6}>
        <CopyrightTypographyStyled component='p'>
          <CopyrightSmallTypographyStyled component='small'>
            &#42;Based on Northwestern Mutual internal data, not applicable exclusively to disability insurance products.
          </CopyrightSmallTypographyStyled>
        </CopyrightTypographyStyled>
        {legalCopy /* TODO: format needs checking/work, this was pulled in from nmx-static... still being used? */ ? (
          { legalCopy }
        ) : (
          <CopyrightTypographyStyled component='p'>
            <CopyrightSmallTypographyStyled component='small'>
              Copyright &#169; {new Date().getFullYear()} The Northwestern Mutual Life Insurance Company, Milwaukee, WI. All Rights Reserved. Northwestern Mutual is the marketing name for The Northwestern Mutual Life Insurance Company and its subsidiaries.
            </CopyrightSmallTypographyStyled>
          </CopyrightTypographyStyled>
        )}
      </Col>
      <Col xsmall={12} medium={6}>
        {appendFooter || <React.Fragment />}
        {/* TODO: format needs checking/work, this was pulled in from nmx-static... still being used? */}
      </Col>
    </Row>
  </SectionStyled>
);

export default CopyrightSection;
