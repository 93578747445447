import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../base/Link';
import DivSkipNavContainerStyled from './styles';

export const SkipNavComponent = ({ skipNavAside, skipNavFooter }) => (
  <DivSkipNavContainerStyled id="nmx-skip-nav">
    <Link className="nmx-nav-link" href="#main">
      Skip to main content
    </Link>
    {skipNavAside && (
      <Link className="nmx-nav-link" href="#aside">
        Skip to side content
      </Link>
    )}
    {skipNavFooter && (
      <Link className="nmx-nav-link" href="#footer">
        Skip to footer content
      </Link>
    )}
  </DivSkipNavContainerStyled>
);

SkipNavComponent.propTypes = {
  skipNavAside: PropTypes.bool.isRequired,
  skipNavFooter: PropTypes.bool.isRequired,
};

SkipNavComponent.defaultProps = {
  skipNavAside: false,
  skipNavFooter: false,
};

export default SkipNavComponent;
