import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Typography from '../../base/Typography';

export const DescriptionList = styled.dl`
  margin: 0 0 0 ${rem(24)};
  padding: 0 0 0 ${rem(8)};
`;

export const DescriptionListItem = styled.span`
  display: block;
  margin-bottom: ${rem(16)};
`;

export const DescriptionTerm = styled.dt`
  ${({ theme }) => css`
    display: inline;
    margin-right: ${rem(4)};
    list-style-type: disc;
    font-weight: 400;
    &::before {
      content: '';
      display: inline-block;
      height: ${rem(6)};
      width: ${rem(6)};
      margin-right: ${rem(8)};
      margin-bottom: ${rem(3)};
      border-radius: 50%;
      background-color: ${theme.colors.neutral.gray29};
    }
  `}
`;

export const DescriptionDetails = styled.dd`
  display: inline;
  margin: 0;
`;

export const TypographyStyled = styled(Typography)`
  display: inline;
`;
