import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import List from '../../../base/List';
import Typography from '../../../base/Typography';
import Facebook from '../../../icons/social/Facebook';
import Linkedin from '../../../icons/social/Linkedin';
import Instagram from '../../../icons/social/Instagram';
import Youtube from '../../../icons/social/Youtube';
import { getColorForName } from '../../../theme/colors';

// eslint-disable-next-line import/prefer-default-export
export const HeadlineStyled = styled(Typography)`
  ${({ theme }) => css`
    display: none;

    ${theme.mediumUp()
      && `
      display: block;
      margin-bottom: 0.5rem;
    `}
  `}
`;

export const ListStyled = styled(List)`
  ${({ theme }) => css`
    margin-top: ${rem(32)};
    text-align: center;

    ${theme.mediumUp()
      && `
        margin-top: 0;
        text-align: left;
      `}
  `}
`;

const iconStyles = () => css`
  .icon-fill {
    fill: ${getColorForName('color-neutral-gray-80')};
  }

  &:hover,
  &:focus {
    .icon-fill {
      fill: ${getColorForName('color-system-cta-hover-blue')};
    }
  }
`;

const getIconLinkStyles = () => css`
  display: flex;
  width: ${rem(30)};
  height: ${rem(30)};
  align-items: center;
  background-color: ${getColorForName('color-neutral-gray-80')};
  border-radius: 50%;
  &:hover,
  &:focus {
    background-color: ${getColorForName('color-system-cta-hover-blue')};
  }
  &:active {
    background-color: ${getColorForName('color-system-cta-hover-blue')};
  }
  .nmx-icon {
    width: 100%;
    .icon-fill {
      fill: ${getColorForName('color-neutral-white')};
    }
  }
`;

export const FacebookIconStyled = styled(Facebook)`
  ${iconStyles()}
`;
export const AStyledX = styled.a`
  ${getIconLinkStyles()}
`;
export const LinkedinIconStyled = styled(Linkedin)`
  ${iconStyles()}
`;
export const InstagramIconStyled = styled(Instagram)`
  ${iconStyles()}
`;
export const YoutubeIconStyled = styled(Youtube)`
  ${iconStyles()}
`;

export const ListItemStyled = styled.li`
  ${({ socialIcon }) => css`
    margin-right: ${rem(5)};
    ${socialIcon === 'x' && { verticalAlign: 'top' }};
  `}
`;
