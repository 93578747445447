import React from 'react';

import { FooterLogoStyled, FooterLogoContainerStyled } from './styles';

const FooterLogo = ({ isAmp, basePath, nmxTemplateVersion }) => (
  <FooterLogoContainerStyled component='h6'>
    <FooterLogoStyled nmxTemplateVersion={nmxTemplateVersion} basePath={basePath} href="/" id="footer-logo">
      Northwestern Mutual
      {/* TODO: clean up SVG markup (change "NW" to "nm" clean up IDs, descriptions, titles, etc.) */}
    </FooterLogoStyled>
    <meta
      itemProp="image"
      content={`${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-navy.svg`}
    />
    {/* amp requires us to use amp-img */}
    {isAmp
      ? (
        <amp-img
          className="nmx-print"
          width="332"
          height="40"
          src={`${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-navy.svg`}
          alt="Northwestern Mutual"
        />
      )
      : (
        <img
          className="nmx-print"
          src={`${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-navy.svg`}
          alt="Northwestern Mutual"
        />
      )}
  </FooterLogoContainerStyled>
);

export default FooterLogo;
