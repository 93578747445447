import styled, { css } from 'styled-components';

const BaseList = styled.ul`
  ${({ reduced, inline }) => css`
      ${reduced && `
        padding: 0;
        margin: 0;
        list-style: none;
      `}

      ${inline && `
        > * {
          display: inline-block;
        }     
      `}
    `}
`;

export default BaseList;
