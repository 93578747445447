import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import {
  BackgroundContainer,
  LinkButtonStyled,
  HeroHeadline,
  HeroProductH1,
  HeroSubheadline,
  HeroTextCol,
  HeroTextContainer,
  HeroTextRow,
  Section,
} from './styles';

const getImageForBreakpoint = (isMobile, isTablet, mobile, tablet, desktop) => {
  if (isMobile) {
    return mobile;
  }
  if (isTablet) {
    return tablet || desktop; // Tablet is not required, sometimes desktop is used as the tablet image
  }
  return desktop;
};

export const HeroSectionContainer = ({
  backgroundImage,
  backgroundImageMobile,
  backgroundImageMobileWebp,
  backgroundImageTablet,
  backgroundImageTabletWebp,
  backgroundImageWebp,
  className,
  ctaPrimary,
  ctaSecondary,
  headline,
  id,
  product,
  subHeadline,
}) => {
  const theme = useContext(ThemeContext);
  const mediumDown = theme.mediumDown();
  const largeDown = theme.largeDown();
  const [isMobile, setIsMobile] = useState(mediumDown);
  const [isTablet, setIsTablet] = useState(largeDown);
  const ctaButton1 = useRef(null);
  const ctaButton2 = useRef(null);

  const checkCtaPositions = () => {
    const { offsetTop: offsetTopCheck1 } = ctaButton1.current || {};
    const { offsetTop: offsetTopCheck2 } = ctaButton2.current || {};
    return offsetTopCheck1 !== offsetTopCheck2;
  };
  const [highResImage, setHighResImage] = useState(
    getImageForBreakpoint(
      mediumDown,
      largeDown,
      backgroundImageMobile,
      backgroundImageTablet,
      backgroundImage,
    ),
  );
  const [highResImageWebp, setHighResImageWebp] = useState(
    getImageForBreakpoint(
      mediumDown,
      largeDown,
      backgroundImageMobileWebp,
      backgroundImageTabletWebp,
      backgroundImageWebp,
    ),
  );
  const [ctaButtonsStacked, setCtaButtonsStacked] = useState(false);

  useEffect(() => {
    let didChange = false;
    if (mediumDown !== isMobile) {
      setIsMobile(mediumDown);
      didChange = true;
    }
    if (largeDown !== isTablet) {
      setIsTablet(largeDown);
      didChange = true;
    }
    if (didChange) {
      setHighResImageWebp(
        getImageForBreakpoint(
          mediumDown,
          largeDown,
          backgroundImageMobileWebp,
          backgroundImageTabletWebp,
          backgroundImageWebp,
        ),
      );
      setHighResImage(
        getImageForBreakpoint(
          mediumDown,
          largeDown,
          backgroundImageMobile,
          backgroundImageTablet,
          backgroundImage,
        ),
      );
      setCtaButtonsStacked(checkCtaPositions());
    }
  }, [mediumDown, largeDown]);

  return (
    <Section
      id={`section-${id}`}
      className={className}
      role='banner'
      data-module={`${`section-${id}`}:hero-module:A`}>
      <BackgroundContainer
        highResImageWebp={highResImageWebp}
        highResImage={highResImage}
        id={`background-image-${id}`}
      />
      <HeroTextContainer>
        <HeroTextRow>
          <HeroTextCol xsmall={12} medium={6}>
            <div>
              <HeroProductH1
                variant='p'
                component='h1'
                weight={400}
                id={`hero-product-name-${id}`}>
                {product}
              </HeroProductH1>
              <HeroHeadline
                variant='h1'
                component='h2'
                align='left'
                id={`hero-headline-${id}`}>
                {headline}
              </HeroHeadline>
              <HeroSubheadline
                component='p'
                align='left'
                id={`hero-subheadline-${id}`}>
                {subHeadline}
              </HeroSubheadline>
              {ctaPrimary && (
                <LinkButtonStyled
                  variant='buttonSecondary'
                  ariaLabel={ctaPrimary.ariaLabel}
                  href={ctaPrimary.link}
                  id={`${id}-cta-primary`}
                  ref={ctaButton1}>
                  {ctaPrimary.buttonText}
                </LinkButtonStyled>
              )}
              {ctaSecondary && (
                <LinkButtonStyled
                  applyTopPadding={ctaButtonsStacked}
                  ariaLabel={ctaSecondary.ariaLabel}
                  href={ctaSecondary.link}
                  id={`${id}-cta-secondary`}
                  ref={ctaButton2}
                  variant='buttonTertiary'>
                  {ctaSecondary.buttonText}
                </LinkButtonStyled>
              )}
            </div>
          </HeroTextCol>
        </HeroTextRow>
      </HeroTextContainer>
    </Section>
  );
};

HeroSectionContainer.propTypes = {
  id: PropTypes.string.isRequired,
  /** base64 encoded desktop 'fuzzy' image for initial display before full image renders */
  backgroundImageLowRes: PropTypes.any.isRequired,
  /** href or src of desktop image to load */
  backgroundImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  backgroundImageWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** base64 encoded mobile 'fuzzy' image for initial display before full image renders */
  backgroundImageTabletLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** href or src of mobile image to load */
  backgroundImageTablet: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  backgroundImageTabletWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** base64 encoded mobile 'fuzzy' image for initial display before full image renders */
  backgroundImageMobileLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** href or src of mobile image to load */
  backgroundImageMobile: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  backgroundImageMobileWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** optional additional className */
  className: PropTypes.string,
  product: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  subHeadline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** Props passed to primary CTA button - if object not provided, will now show button */
  ctaPrimary: PropTypes.shape({
    link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    ariaLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
  /** Props passed to secondary CTA button - if object not provided, will now show button */
  ctaSecondary: PropTypes.shape({
    link: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    buttonText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    ariaLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }),
};

HeroSectionContainer.defaultProps = {};

export default HeroSectionContainer;
