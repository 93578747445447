import React from 'react';
import PropTypes from 'prop-types';
import { InputStyled, TextAreaStyled } from './styles';
import Label from '../Label';
import ValidationErrorLabel from '../ValidationErrorLabel';

export const TextFieldComponent = React.forwardRef(({
  label,
  multiline,
  rows,
  error,
  errorMessage,
  required,
  id,
  isPcg,
  customTheme,
  inputType,
  placeholder,
  ...rest
}, ref) => (
  <Label
    label={label}
    required={required}
    customTheme={customTheme}
    htmlFor={id}
    inputType={inputType}
    ref={ref}>
    {
      !multiline
        ? <InputStyled
          aria-required={required}
          customTheme={customTheme}
          hasError={error}
          id={id}
          placeholder={placeholder}
          isPcg={isPcg}
          {...rest}
        />
        : <TextAreaStyled {...rest} rows={rows} />
    }
    <ValidationErrorLabel
      error={error}
      errorMessage={errorMessage}
      customTheme={customTheme}
    />
  </Label>
));

TextFieldComponent.propTypes = {
  /** The label placed on the text field */
  label: PropTypes.string.isRequired,
  /** Placeholder text when no value is provided */
  placeholder: PropTypes.string,
  /** Name applied to input or textarea */
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password', 'tel', 'email']).isRequired,
  value: PropTypes.any.isRequired,
  /** Specify a max character length for the text field */
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
  /** Should this be a textarea? */
  multiline: PropTypes.bool,
  /** Number of lines to show when multiline is true */
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  error: PropTypes.bool,
  /** Message to display when error is true */
  errorMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  /** Name of a custom theme to override the default styles */
  customTheme: PropTypes.oneOf(['', 'leads', '2.0']),
  /** Passing in inputType so select dropdowns with custom themes can have the checvron arrow */
  inputType: PropTypes.string,
};

TextFieldComponent.defaultProps = {
  type: 'text',
  value: '',
  onChange: () => {},
  onKeyUp: () => {},
  onBlur: () => {},
  multiline: false,
  rows: 5,
  disabled: false,
  error: false,
  errorMessage: 'Error',
  required: false,
  customTheme: '',
  inputType: 'text',
};

export default TextFieldComponent;
