import React from 'react';
import PropTypes from 'prop-types';
import { AssistiveText } from './styles';

// Centered quotes can ONLY have the Quotation icon for decoration
// Left aligned quotes can ONLY have the left border for decoration

export const AssistiveTextComponent = ({
  text,
  component,
}) => (
  <AssistiveText component={component}> {/* TODO: test print styles, may need to add .nmx-assistive-text class back in */}
    {text}
  </AssistiveText>
);

AssistiveTextComponent.propTypes = {
  text: PropTypes.string.isRequired,
  component: PropTypes.oneOf([
    'p',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'span',
    'div',
  ]),
};
AssistiveTextComponent.defaultProps = { component: 'p' };

export default AssistiveTextComponent;
