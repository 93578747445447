import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  ContainerStyled,
  ColStyled,
  DivBackgroundContainerStyled,
  DivHeaderContainerStyled,
  RowStyled,
  SectionStyled,
  TypographyHeaderStyled,
} from './styles';

export const FlexibleHeroComponent = React.forwardRef(({
  className,
  id,
  header,
  desktopImageJpg,
  desktopImageLowRes,
  desktopImageWebp,
  mobileImageJpg,
  mobileImageLowRes,
  mobileImageWebp,
  position,
}, ref) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Header positioning configuration. Center is default.
  let mediumColOffset = 3;
  if (position === 'left') {
    mediumColOffset = 0;
  }

  useEffect(() => {
    setImageLoaded(true);
  }, [desktopImageJpg, mobileImageJpg]);

  return (
    <SectionStyled
      id={`section-${id}`}
      className={className}
      role='banner'
      data-module={`${`section-${id}`}:flexible-hero-module:A`} // module name TBD
      ref={ref}>
      <DivBackgroundContainerStyled
        id={`background-image-${id}`}
        imageLoaded={imageLoaded}
        desktopImageJpg={desktopImageJpg}
        desktopImageLowRes={desktopImageLowRes}
        desktopImageWebp={desktopImageWebp}
        mobileImageJpg={mobileImageJpg}
        mobileImageLowRes={mobileImageLowRes}
        mobileImageWebp={mobileImageWebp}
      />
      <ContainerStyled>
        <RowStyled position={position}>
          <ColStyled
            xsmall={12}
            mediumOffset={mediumColOffset}
            medium={6}>
            <DivHeaderContainerStyled position={position}>
              <TypographyHeaderStyled
                component='h1'
                id={`flexible-hero-header-${id}`}
                position={position}>
                {header}
              </TypographyHeaderStyled>
            </DivHeaderContainerStyled>
          </ColStyled>
        </RowStyled>
      </ContainerStyled>
    </SectionStyled>
  );
});
FlexibleHeroComponent.propTypes = {
  id: PropTypes.string.isRequired,
  /** base64 encoded desktop 'fuzzy' image for initial display before full image renders */
  desktopImageLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** href or src of desktop image to load */
  desktopImageJpg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  desktopImageWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** base64 encoded mobile 'fuzzy' image for initial display before full image renders */
  mobileImageLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** href or src of mobile image to load */
  mobileImageJpg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  mobileImageWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** optional additional className */
  className: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  position: PropTypes.string,
};

FlexibleHeroComponent.defaultProps = { position: 'left' };

export default FlexibleHeroComponent;
