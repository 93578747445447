import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import Typography from '../../base/Typography';
import Link from '../../base/Link';

import {
  ArticleCardContainer,
  ArticleImageContainer,
  ArticleImage,
  ArticleContent,
  ActionContainer,
} from './styles';

export const ArticleCardComponent = ({ image, imageAlt, title, url }) => (
  <ArticleCardContainer>
    <ArticleImageContainer>
      <ArticleImage src={image} alt={imageAlt} />
    </ArticleImageContainer>
    <ArticleContent>
      <Typography weight={200} component="h4" variant="h5" disableBottomPadding>{title}</Typography>
    </ArticleContent>
    <ActionContainer>
      <Link
        id='storybook-link-id'
        variant="secondary"
        href={url}
        aria-label={`Read more ${title}`}>
        Read more
      </Link>
    </ActionContainer>
  </ArticleCardContainer>
);

ArticleCardComponent.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default withTheme(ArticleCardComponent);
