/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// components
import { Col, Row } from '../../base/Grid';
import Link from '../../base/Link';
import Typography from '../../base/Typography';
import WidowBlock from '../../base/WidowBlock';
// icons
import ArrowRightIcon from '../../icons/utility/ArrowRight';

// local function
const getComponentVariantBgColor = (theme, bgVariant) => {
  if (bgVariant === 'primary') {
    return theme.colors.neutral.white;
  }
  return theme.colors.neutral.gray99;
};

// start styled components
export const ColLinkContainerStyled = styled(Col)`
  margin-top: ${rem(24)};
`;

export const DivProductCalloutCardStyled = styled.div`
  ${({ theme, bgVariant, condensed, fixHeight }) => css`
    position: relative;
    background-color: ${getComponentVariantBgColor(theme, bgVariant)};
    color: ${theme.colors.neutral.gray29};
    height: ${(condensed && fixHeight) ? rem(90) : '100%'};
    overflow: hidden;
    ${condensed ? `padding: ${rem(24)} 0;` : `padding: ${rem(40)} 0;`};
    ${condensed && `
      display: flex;
      align-items: center;
    `}
    :hover {
      .icon-product-callout-arrow {
        transition: left 0.2s;
        left: ${rem(10)};
        margin-right: 0;
      }
    }
  `}
`;

export const IconCardTitleStyled = styled(ArrowRightIcon)`
 ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      transition: left 0.2s;
      margin-bottom: ${rem(2)};
      margin-left: ${rem(10)};
      left: 0;
      position: relative;
      margin-top: ${mediumUp ? rem(11) : rem(7)};
    `;
  }}
`;

// becomes the product callout wrapper if condensed variant
export const LinkStyled = styled(Link)`
  cursor: pointer;
  :hover {
    text-decoration: none;
  }
`;

export const ProductCalloutRow = styled(Row)`
  ${({ condensed }) => css`
    position: relative;
    padding: 0 ${rem(24)} 0 ${rem(16)};
    ${condensed && 'display:none;'}
  `}
`;

export const RowHeaderStyled = styled(Row)`
  justify-content: space-between;
  align-items: flex-start;
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => ({
  IconCardMainStyled: {
    height: rem(44),
    width: rem(44),
    position: 'absolute',
    right: '1rem',
    top: '-.5rem',
  },
});

export const TypographyCardCopyStyled = styled(Typography)`
  padding-bottom: 0;
  padding-right: ${rem(32)};
`;

export const TypographyHeaderStyled = styled(Typography)`
  ${({ theme, condensed, withHeaderAccent }) => {
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    let height = rem(23);
    let maxWidth = '85%';
    let paddingBottom = mediumUp ? rem(12) : rem(8);
    let decorationDisplay = 'none';

    if (largeUp) {
      height = rem(28);
      maxWidth = '80%';
    } else if (mediumUp) {
      height = rem(25);
      maxWidth = '75%';
    }

    if (condensed) {
      paddingBottom = 0;
      decorationDisplay = 'inline-block';
      maxWidth = '100%';
    }
    if (withHeaderAccent) {
      decorationDisplay = 'inline-block';
    }

    return css`
      padding-bottom: ${paddingBottom};
      max-width: ${maxWidth};
      :before {
        content: '';
        position: absolute;
        left: 0;
        display: ${decorationDisplay};
        width: ${rem(5)};
        height: ${height};
        background-color: ${theme.colors.cta.yellow};
        ${condensed && `
          top: 0;
          bottom: 0;
          margin-top: auto;
          margin-bottom: auto;
        `}
      }
    `;
  }}
`;

export const WidowBlockStyled = styled(WidowBlock)`
  display: inline;
  white-space: nowrap;
`;
