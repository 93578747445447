const DEFAULT_COLORS = {
  primary: { blue: '#0e497b' },
  accent: {
    blue: '#092d4d',
    lightBlue: '#83d3ee',
    lightBlue2: '#83d4f1', // TODO: this is barely a difference between "LightBlue 1", let's look at these closer before implementing
    lightBlue3: '#567fa2',
  }, // example: This is the font color for the values section // TODO: confirm these values after getting Sketch file
  accent2: { lightBlue: '#76c2ef' },
  hero: {
    background: '#667785', // TODO: rename/organize these once comp is finalized.
    textStroke: '#2e353a', // TODO: rename/organize these once comp is finalized.
  },
  section: { backgroundBlue: '#0e497b' },
  neutral: {
    white: '#fff',
    gray99: '#f7fafc', // GrayLight3 will be #f8fafc in new template version, #f7fafc will be decommissioned
    gray95: '#edf0f3',
    gray87: '#dfdfdf', // faq item background
    gray80: '#ccc',
    gray59: '#979797', // TODO: ECC: remove this gray and migrate to gray58
    gray58: '#949494', // gray 58% white - will be renaming these in the future (3:1 color contrast on white)
    gray54: '#8a8a8a',
    gray41: '#696969', // gray 41% white - will be renaming these in the future (4.6:1 color contrast on white)
    gray29: '#4a4a4a',
    gray23: '#3a3a3a',
    black: '#000',
  },
  cta: {
    blue: '#1570bc',
    blueHover: '#0e497b',
    blueActive: '#105fcc',
    yellow: '#ffb81c',
    yellowHover: '#cc8b00',
    yellowActive: '#FFB81C',
  },
  system: {
    cautionWarning: '#ffcc33',
    darkBlue: '#004ab3',
    negativeError: '#ff5242',
    darkRed: '#c12101',
    lightRed: '#F99B9A',
    positiveSuccess: '#00a84f',
    darkGreen: '#00803c',
  },
  pcg: {
    gold1: '#e1c88f',
    gold2: '#f2dfac',
    darkBlue1: '#0F1117',
    darkBlue2: '#0F2160',
    darkBlue3: '#00487c',
    darkBlue4: '#2f3d66', // < hex color was not chosen by design. May change.
    darkBlue5: '#26345f',
  },

  modal: { stroke: '#2071AA' }, // TODO: also duplicate in Sass files // TODO: confirm do we REALLY need to add another color so close to our other cta blu(s)? will confirm with creative.

  // Other
  blueLazyloadBg: '#667785',
  inputLeadVariantBG: '#205785',
  inputLeadVariantBGHover: '#285c89',

  // TODO: build in shade and highlight functions
};
export default DEFAULT_COLORS;

export const getColorForName = (color) => { // TODO: not certain, but this seems inefficient and open the doors to errors, there is another way to do this
  // Return the corresponding color, else return hex if a valid hex is provided
  // primary
  if (color === 'color-primary-nm-blue') return DEFAULT_COLORS.primary.blue;
  // accent
  if (color === 'color-accent-blue') return DEFAULT_COLORS.accent.blue;
  // hero
  if (color === 'color-hero-text-stroke') return DEFAULT_COLORS.hero.textStroke;
  // neutral
  if (color === 'color-neutral-white') return DEFAULT_COLORS.neutral.white;
  if (color === 'color-neutral-gray-95') return DEFAULT_COLORS.neutral.gray95;
  if (color === 'color-neutral-gray-80') return DEFAULT_COLORS.neutral.gray80;
  if (color === 'color-neutral-gray-58') return DEFAULT_COLORS.neutral.gray58;
  if (color === 'color-neutral-gray-41') return DEFAULT_COLORS.neutral.gray41;
  if (color === 'color-neutral-gray-29') return DEFAULT_COLORS.neutral.gray29;
  if (color === 'color-neutral-gray-23') return DEFAULT_COLORS.neutral.gray23;
  if (color === 'color-neutral-black') return DEFAULT_COLORS.neutral.black;
  // cta
  if (color === 'color-system-cta-blue') return DEFAULT_COLORS.cta.blue;
  if (color === 'color-system-cta-hover-blue') return DEFAULT_COLORS.cta.blueHover;
  if (color === 'color-system-cta-active-blue') return DEFAULT_COLORS.cta.blueActive;
  if (color === 'color-system-cta-yellow') return DEFAULT_COLORS.cta.yellow;
  if (color === 'color-system-cta-hover-yellow') return DEFAULT_COLORS.cta.yellowHover;
  if (color === 'color-system-cta-active-yellow') return DEFAULT_COLORS.cta.yellowActive;
  // system
  if (color === 'color-system-dark-blue') return DEFAULT_COLORS.system.darkBlue;
  if (color === 'color-system-dark-red') return DEFAULT_COLORS.system.darkRed;
  if (color === 'color-system-light-red') return DEFAULT_COLORS.system.lightRed;
  // if we get here, check for a hex value
  if (/^#([0-9A-F]{3}){1,2}$/i.test(color)) return color;
  // if we get here, an invalid color was passed in, we will return it anyway as to not break the page, but log error
  console.error(`Invalid color provided: ${color}, please use a valid defined color name or hex value.`);
  return color;
};

