import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Chip from '../../base/Chip/chip';
import SectionContainer from '../SectionContainer';
import Typography from '../../base/Typography';

export const ChipPersonalizationQuizStyled = styled(Chip)`
  margin: 0 ${rem(5)} ${rem(20)};
`;

export const SectionContainerStyled = styled(SectionContainer)`
  ${({ theme }) => {
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    // Note: Design requested that all the sections, at all breakpoints, be the same height for consistency.
    // This is contradictory to their request to have consistent spacing for all views/sections,
    // so we'll see what they say. But, I disagree with fixing the section heights like this, since it
    // doesn't allow for varying/changing content in the future.
    let height = rem(490);
    if (xlargeUp) {
      height = rem(310);
    } else if (largeUp) {
      height = rem(280);
    } else if (mediumUp) {
      height = rem(330);
    } else if (smallUp) {
      height = rem(475);
    }

    return css`
      && {
        min-height: ${height};
        padding-bottom: ${rem(40)};
      }

      @media print {
        display: none;
      }
    `;
  }}
`;

export const TypographyPersonalizationQuizHeadingStyled = styled(Typography)`
  ${({ hasSubheading }) => {
    let paddingBottom = rem(60);
    if (hasSubheading) {
      paddingBottom = rem(16);
    }
    return css`
      && {
        padding-bottom: ${paddingBottom};
      }
    `;
  }}
`;

export const TypographyPersonalizationQuizSubheadingStyled = styled(Typography)`
  && {
    padding-bottom: ${rem(40)};
  }
`;
