export const goals = [
  {
    name: 'Choose one',
    value: '',
    disabled: true,
  },
  {
    name: 'Buy a home',
    value: 'Buy a home',
    disabled: false,
  },
  {
    name: 'Save for college',
    value: 'Save for college',
    disabled: false,
  },
  {
    name: 'Save for retirement',
    value: 'Save for retirement',
    disabled: false,
  },
  {
    name: 'Protect my income',
    value: 'Protect my income',
    disabled: false,
  },
  {
    name: 'Plan for my growing family',
    value: 'Plan for my growing family',
    disabled: false,
  },
  {
    name: 'Protect my family',
    value: 'Protect my family',
    disabled: false,
  },
  {
    name: 'Grow my business',
    value: 'Grow my business',
    disabled: false,
  },
  {
    name: 'Grow my investments',
    value: 'Grow my investments',
    disabled: false,
  },
  {
    name: "I'm not sure",
    value: "I'm not sure",
    disabled: false,
  },
];

// income and wealth range values
export const incomes = [
  {
    name: 'Select a range',
    value: '',
    disabled: true,
  },
  {
    name: 'less than $40,000',
    value: 39999,
    disabled: false,
  },
  {
    name: '$40,001 - $75,000',
    value: 57500,
    disabled: false,
  },
  {
    name: '$75,001 - $100,000',
    value: 87500,
    disabled: false,
  },
  {
    name: '$100,001 - $150,000',
    value: 125000,
    disabled: false,
  },
  {
    name: '$150,001 - $200,000',
    value: 175000,
    disabled: false,
  },
  {
    name: '$200,001 - $300,000',
    value: 250000,
    disabled: false,
  },
  {
    name: 'more than $300,000',
    value: 300000,
    disabled: false,
  },
];
