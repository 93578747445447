/* eslint-disable max-len */
import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
// template components
import AssistiveText from '../../base/AssistiveText';

// styles
import {
  ColLeftStyled,
  ColRightStyled,
  LinkStyled,
  LIStyled,
  RowStyled,
  SectionContainerStyled,
  TypographyListItemStyled,
  TypographyStyled,
  ULStyled,
} from './styles';

export const RelatedLinksComponent = ({
  className,
  disableBottomPadding,
  id,
  linkItems,
  moduleName,
  moduleVariation,
  sectionVariant,
}) => (
  <SectionContainerStyled
    id={id}
    className={className}
    variant={sectionVariant}
    moduleName={moduleName}
    moduleVariation={moduleVariation}
    disableBottomPadding={disableBottomPadding}>
    <AssistiveText
      text='Related links'
      component='h2'/>
    <RowStyled align='center'>
      <ColLeftStyled
        xsmall={12}
        medium={3}
        mediumOffset={2}
        large={12}
        largeOffset={0}>
        <TypographyStyled weight={500}>
          Related links:
        </TypographyStyled>
      </ColLeftStyled>
      <ColRightStyled
        xsmall={12}
        medium={7}
        large={12}>
        <ULStyled>
          {
            linkItems.map((item, index) => (
              <LIStyled key={`related-links-${index}`}>
                <TypographyListItemStyled lastItem={index === (linkItems.length - 1)}>
                  <LinkStyled
                    href={item.url}
                    id={item.id}
                    {...(item.ariaLabel && { ariaLabel: item.ariaLabel })}
                    sectionVariant={sectionVariant}
                    {...(item.outGoingLink && {
                      target: '_blank',
                      rel: 'nofollow noopener noreferrer',
                    })}>
                    {item.text}
                  </LinkStyled>
                </TypographyListItemStyled>
              </LIStyled>
            ))
          }
        </ULStyled>
      </ColRightStyled>
    </RowStyled>
  </SectionContainerStyled>
);

RelatedLinksComponent.propTypes = {
  /** Optional custom class name */
  className: PropTypes.string,
  /** Disable the default bottom padding for section */
  disableBottomPadding: PropTypes.bool,
  /** section id */
  id: PropTypes.string.isRequired,
  /** The object containing the list of links you want to display */
  linkItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** optional moduleVariation */
  moduleVariation: PropTypes.string,
  /** section variant, which determines color styles */
  sectionVariant: PropTypes.any,
};

RelatedLinksComponent.defaultProps = {
  id: 'related-links',
  moduleName: 'related-links',
  moduleVariation: 'A',
  disableBottomPadding: false,
  disableTopPadding: false,
  sectionVariant: 'tertiary',
};

export default withTheme(RelatedLinksComponent);
