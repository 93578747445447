import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { BaseFont } from './styles';
import { getColorForName } from '../../theme/colors';

export const TypographyComponent = React.forwardRef(({
  align,
  ariaLabel,
  children,
  className,
  component,
  color,
  italic,
  nextElement,
  styles,
  uppercase,
  variant,
  weight,
  ...rest
}, ref) => {
  const classNames = {};
  let adjustedStyles = styles;

  classNames[`nmx-${variant}`] = variant;
  // classNames['nmx-uppercase'] = uppercase;
  // classNames['nmx-italic'] = italic;
  // classNames[`nmx-align-${align}`] = align && align !== 'inherit';
  classNames[className] = className;

  if (color) {
    adjustedStyles = {
      ...adjustedStyles,
      color: getColorForName(color),
    };
  }

  return (
    <BaseFont
      className={classnames(classNames, className)}
      component={component}
      variant={variant}
      nextElement={nextElement}
      as={component || 'p'}
      align={align}
      aria-label={ariaLabel}
      uppercase={uppercase}
      weight={weight}
      ref={ref}
      {...rest}
      style={adjustedStyles || {}}>
      {children}
    </BaseFont>
  );
});

TypographyComponent.propTypes = {
  /** Alignment for text component */
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  /** Optional ariaLabel, set to any to accommodate React.Fragment object, but should render as a string */
  ariaLabel: PropTypes.string,
  /** The inner content to be rendered */
  children: PropTypes.node.isRequired,
  /** Custom class name to add to Type. */
  className: PropTypes.string,
  /** Set of allowed colors */
  color: (props) => {
    if (
      !!props.color
      && ([
        'color-neutral-black',
        'color-neutral-white',
        'color-neutral-gray-58',
        'color-neutral-gray-41',
        'color-neutral-gray-29',
        'color-neutral-gray-23',
        'color-primary-nm-blue',
        'color-system-dark-red',
      ].indexOf(props.color) < 0
        && !/^#([0-9A-F]{3}){1,2}$/i.test(props.color))
    ) {
      throw new Error(
        `Invalid color provided to Typography component: ${props.color}, please use a valid color name or hex value.`,
      );
    }
  },
  /** what the DOM element rendered should be */
  component: PropTypes.oneOf([
    'address',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'small',
    'span',
    'time',
  ]),
  /** Disable the default bottom margin for variant */
  disableBottomPadding: PropTypes.bool,
  /** What styles should be applied to the tag */
  styles: PropTypes.object,
  /** convert typography innerText to upperCase */
  uppercase: PropTypes.bool,
  /** optional props to pass in the next element to set spacing for the typography */
  nextElement: PropTypes.string,
  /** What typography element */
  variant: PropTypes.oneOf([
    'p',
    'p2',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'small',
    'jumbo-type',
  ]),
  /** allows overriding of font-weight */
  weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700, 800, 900]), // TODO: name and export in a theme
};

TypographyComponent.defaultProps = {
  disableBottomPadding: false,
  className: null,
  styles: null,
  component: 'p',
  uppercase: false,
};

export default TypographyComponent;
