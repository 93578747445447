import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { BaseContainer, BaseRow, BaseCol } from './styles';

/* *****************
  CONTAINER
* **************** */

// TODO: Adjust logic so the nmx-container modifier only gets appended when needed
export const Container = React.forwardRef(({ id, styles, className, children }, ref) => (
  <BaseContainer
    id={id}
    className={classnames('nmx-container', className)}
    style={styles || {}}
    ref={ref}
  >
    {children}
  </BaseContainer>
));

Container.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Container.defaultProps = {
  className: null,
  styles: null,
  children: '',
};

/* *****************
  ROW
* **************** */

export const Row = React.forwardRef(({ id, className, styles, children, reverse, align }, ref) => (
  <BaseRow
    id={id}
    className={classnames('nmx-row', className)}
    style={styles || {}}
    align={align}
    reverse={reverse}
    ref={ref}
  >
    {children}
  </BaseRow>
));

Row.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
  /** Reverse the grid cols in this row? */
  reverse: PropTypes.bool,
  /** How to align the grid cols in the row */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  id: PropTypes.string,
};

Row.defaultProps = {
  className: null,
  styles: null,
  children: '',
  reverse: false,
  align: null,
};

/* *****************
  COL
* **************** */

export const Col = React.forwardRef(({
  align,
  className,
  styles,
  children,
  nested,
  nestedChild,
  paddingLeft,
  paddingRight,
  xsmall,
  small,
  medium,
  large,
  xlarge,
  xxlarge,
  xsmallOffset,
  smallOffset,
  mediumOffset,
  largeOffset,
  xlargeOffset,
  xxlargeOffset,
  id,
  ...otherProps
}, ref) => (
  <BaseCol
    id={id}
    align={align}
    nested={nested}
    nestedChild={nestedChild}
    className={classnames('nmx-col', className)}
    xsmall={xsmall} small={small} medium={medium} large={large} xlarge={xlarge} xxlarge={xxlarge}
    xsmallOffset={xsmallOffset}
    smallOffset={smallOffset}
    mediumOffset={mediumOffset}
    largeOffset={largeOffset}
    xlargeOffset={xlargeOffset}
    xxlargeOffset={xxlargeOffset}
    paddingLeft={paddingLeft}
    paddingRight={paddingRight}
    style={styles || {}}
    ref={ref}
    {...otherProps}
  >
    {children}
  </BaseCol>
));

Col.propTypes = {
  // TODO: provide description for these propTypes
  align: PropTypes.oneOf(['center']),
  className: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.node.isRequired,
  nested: PropTypes.bool,
  nestedChild: PropTypes.bool,
  paddingRight: PropTypes.bool,
  paddingLeft: PropTypes.bool,
  xsmall: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  small: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  medium: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  large: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xlarge: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xsmallOffset: PropTypes.number,
  smallOffset: PropTypes.number,
  mediumOffset: PropTypes.number,
  largeOffset: PropTypes.number,
  xlargeOffset: PropTypes.number,
  id: PropTypes.string,
};

Col.defaultProps = {
  className: null,
  styles: null,
  children: '',
  nested: false,
  nestedChild: false,
  xsmall: null,
  small: null,
  medium: null,
  large: null,
  xlarge: null,
  xxlarge: null,
  xsmallOffset: null,
  smallOffset: null,
  mediumOffset: null,
  largeOffset: null,
  xlargeOffset: null,
};
