import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const labelStyles = `
  font-weight: 300;
  letter-spacing: .0625rem;
`;

const LabelStyled = styled.label`
  ${labelStyles}
  ${({ theme, customTheme, inputType }) => {
    if (customTheme === 'leads') {
      // If leads theme, and is select input, add the custom chevron arrow
      if (inputType === 'select') {
        return css`
          color: ${theme.colors.neutral.white};
          ${theme.mediumDown() && 'width:100%;'}
          display: inline-block; /** Fix for firefox */
          position: relative;
          font-weight: 400;
          :after {
            content: '\\25c4'; /** Keep the escaping double backslash, or this unicode char will break */
            width: 0;
            height: 0;
            color: ${theme.colors.neutral.white};
            transform: rotate(270deg) scale(0.7, 1.9);
            right: ${rem(34)};
            top: ${rem(57)};
            padding: 0 0 ${rem(2)};
            position: absolute;
            pointer-events: none;
          }
        `;
      }
      // If custom themed input, but is not select
      return css`
        color: ${theme.colors.neutral.white};
        ${theme.mediumDown() && 'width:100%;'}
        font-weight: 400;
      `;
    }

    return false;
  }}
`;

export default LabelStyled;
