const mmMedium = typeof window !== 'undefined' && window.matchMedia('(min-width: 768px)');
const mmMLarge = typeof window !== 'undefined' && window.matchMedia('(min-width: 1024px)');

export function checkMobileDeviceWidth() {
  if (mmMedium.matches === false) {
    // mobile actions
    return 'mobile';
  }
  return 'desktop';
}

export function checkDesktopLargeDeviceWidth() {
  if (mmMLarge.matches === false) {
    // desktop < large actions
    return false;
  }
  return true;
}
