import React from 'react';
import { CanvasStyled } from './styles';

export const CanvasComponent = ({ children }) => (
  <CanvasStyled>
    {children}
  </CanvasStyled>
);

export default CanvasComponent;
