/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../base/Typography';

import {
  ULStyled,
  LIStyled,
  BulletStyled,
  NumberedStyled,
  IconStyled,
  TypographyListItemHeader,
} from './styles';

export const BulletedListComponent = ({
  bulletedListData,
  className,
  disableBottomPadding,
  bulletType,
}) => {
  useEffect(() => {
    // validate all bulletedListData passed in are correct
    if (bulletedListData.length === 0) {
      console.error(
        'Missing bulleted list data. At least one entry is required.',
      );
    }
  }, []);

  const ListDecorator = (index) => {
    if (bulletType === 'Checkmark') {
      return <IconStyled />;
    }

    if (bulletType === 'Bullet') {
      return <BulletStyled />;
    }

    if (bulletType === 'Numbered') {
      return <NumberedStyled index={index} />;
    }

    return false;
  };

  return (
    <ULStyled
      className={className}
      disableBottomPadding={disableBottomPadding}>
      {bulletedListData.map((item, index) => (
        <LIStyled key={index}>
          {ListDecorator(index)}
          {item.bulletedHeader && (
            <TypographyListItemHeader component='h5'>
              {item.bulletedHeader}
            </TypographyListItemHeader>
          )}
          {item.bulletedCopy && (
            <Typography
              disableBottomPadding={bulletedListData.length === (index + 1)} // disable bottom padding for last item
            >
              {item.bulletedCopy}
              {/* TODO: We probably don't need this superscript check if we can just pass in a Fragment (will be a breaking change) */}
              {item.sup && <sup>{item.sup}</sup>}
            </Typography>
          )}
        </LIStyled>
      ))}
    </ULStyled>
  );
};

BulletedListComponent.propTypes = {
  /** optional additional className */
  className: PropTypes.string,
  /** Array of accoladed to display in the section */
  bulletedListData: PropTypes.arrayOf(
    PropTypes.shape({
      /** The 'id' of the accolade wanting to display */
      bulletedHeader: PropTypes.any,
      /** The sup number corresponding with the footer reference, Required for bulletedListData needing source to cite */
      bulletedCopy: PropTypes.any,
    }),
  ).isRequired,
  bulletType: PropTypes.oneOf(['Checkmark', 'Bullet', 'Numbered']),
  /** Removes padding on bottom of list, used when list is at end of Section with NO CTA below, see special-needs-planning page */
  disableBottomPadding: PropTypes.bool,
};

BulletedListComponent.defaultProps = {
  disableBottomPadding: false,
  bulletType: 'Checkmark',
};

export default BulletedListComponent;
