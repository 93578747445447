import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Container, Row, Col } from '../../base/Grid';
import Typography from '../../base/Typography';
import Link from '../../base/Link';

const sharpenBg = keyframes`
  0% {
    -webkit-filter: blur(${rem(20)});
    filter: blur(${rem(20)});
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
`;

export const Section = styled.section`
  ${({ theme }) => css`
    overflow: hidden; /* for bg blur-up */
    padding-top: 0; /* for bg blur-up // overwrite global section padding */
    padding-bottom: 0; /* for bg blur-up // overwrite global section padding */
    position: relative;
    align-items: center;
    background-color: ${theme.colors.neutral.white};
    color: ${theme.colors.neutral.gray29};
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    text-align: center;
    padding: 0;
  `}
`;

export const BackgroundContainer = styled.div`
  ${({ theme, highResImageWebp, highResImage }) => css`
    position: ${theme.mediumDown() ? 'unset' : 'absolute'};
    top: 0;
    left: 0;
    width: 100%;
    min-height: ${rem(200)};
    height: ${theme.mediumDown() ? rem(200) : '100%'};
    background: ${theme.colors.blueLazyloadBg} no-repeat scroll 50% 20%;
    background-position: right 0;
    background-image: url(${highResImage}); /* TODO: Investigate possible warnings around undefined paths being sent to this styled component */
    background-image: url(${highResImageWebp || highResImage});
    background-position-y: 0%;
    background-size: cover;
    transform: scale(1);
    -webkit-animation: ${sharpenBg} 1s both;
    animation: ${sharpenBg} 1s both;
    @media print {
      && {
        display: none !important;
      }
    }
  `}
`;

export const HeroTextContainer = styled(Container)`
  z-index: 1;
  margin: 0;
  padding: 0;
  height: 100%;
`;

export const HeroTextRow = styled(Row)`
  height: 100%;
  text-align: left;
  justify-content: left;
`;

export const HeroTextCol = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    let padding = `${rem(48)} ${rem(16)}`;
    const between1200To2000 = theme.useMediaQuery(theme.breakpoints.between(1200, 4000),
      { defaultMatches: (theme.pageLoadWindowWidth >= 1200 && theme.pageLoadWindowWidth < 4000) });

    if (largeUp) {
      padding = rem(60);
    }
    return css`
      background-color: ${theme.colors.neutral.gray99};
      padding: ${padding};
      height: 100%;
      min-height: ${between1200To2000 ? rem(450) : 'unset'};
      display: flex;
      align-items: center;
    `;
  }}
`;

export const HeroProductH1 = styled(Typography)`
  ${({ theme }) => {
    let fontSize = rem(12);
    let lineHeight = rem(18);
    const paddingBottom = rem(16);

    if (theme.mediumUp()) {
      fontSize = rem(16);
      lineHeight = rem(22);
    }

    return css`
      && {
        font-family: 'Guardian Sans', 'Helvetica Neue', Helvetica, sans-serif;
        padding-bottom: ${paddingBottom};
        line-height: ${lineHeight};
        font-size: ${fontSize};
        letter-spacing: ${rem(2.5)};
        text-transform: uppercase;
      }
    `;
  }}
`;

export const HeroHeadline = styled(Typography)`
  ${({ theme }) => {
    let fontSize = rem(26);
    let lineHeight = rem(32);
    const paddingBottom = rem(16);
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();

    if (largeUp) {
      fontSize = rem(44);
      lineHeight = rem(52);
    } else if (mediumUp) {
      fontSize = rem(35);
      lineHeight = rem(41);
    }

    return css`
      font-family: 'Guardian Sans', 'Helvetica Neue', Helvetica, sans-serif;
      padding-bottom: ${paddingBottom};
      line-height: ${lineHeight};
      font-size: ${fontSize};
      ::after {
        display: block;
        width: ${rem(60)};
        padding-bottom: ${paddingBottom};
        border-bottom: ${rem(4)} solid ${theme.colors.cta.yellow};
        content: '';
      }
    `;
  }}
`;

export const HeroSubheadline = styled(Typography)`
  padding-bottom: ${rem(32)};
`;

export const LinkButtonStyled = styled(Link)`
  ${({ theme, applyTopPadding }) => {
    let buttonMinWidth = rem(150);
    const mediumToLarge = theme.mediumToLarge();
    const smallUp = theme.smallUp();

    if (mediumToLarge) {
      buttonMinWidth = rem(150);
    } else if (smallUp) {
      buttonMinWidth = rem(200);
    } else {
      buttonMinWidth = '100%'; // xsmall
    }

    return css`
      min-width: ${buttonMinWidth};
      ${applyTopPadding && `margin-top: ${rem(8)};`}
      :first-of-type {
        margin-right: ${rem(8)};
      }
    `;
  }}
`;
