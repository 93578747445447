import React from 'react';
import Typography from '../../../base/Typography';
import Colors from '../../../theme/colors';
import { Row, Col } from '../../../base/Grid';

import {
  ContainerStyled,
  IconStyled,
  LinkStyled,
  TypographyHeroConfirmationStyled,
} from './styles';

export const ThankYouModuleComponent = ({
  isPcg,
  userName,
  variant,
}) => {
  const hero = variant === 'hero'; // If 'hero' variant
  return (
    <ContainerStyled
      formvariant={variant}
      id='in-page-lead-thank-you-view'>
      {!isPcg && (
        <Row align='center'>
          <IconStyled formvariant={variant} />
        </Row>
      )}
      <Row align='center'>
        <Col align='center'>
          <TypographyHeroConfirmationStyled
            formvariant={variant}
            variant={(hero || isPcg) ? 'h2' : 'h4'}
            color={Colors.neutral.white}
            isPcg={isPcg}>
            Got it&#33; Thanks, {userName}.
          </TypographyHeroConfirmationStyled>
          {hero && (
            <Typography
              variant='h2'
              color={Colors.neutral.white}
              disableBottomPadding>
              We&#39;re excited to work with you.
            </Typography>
          )}
        </Col>
      </Row>
      {/* FOR EMBEDDED VARIANT (DEFAULT) */}
      {!hero && (
        <Row align='center'>
          <Col
            xsmall={12}
            medium={10}>
            <Typography
              weight={isPcg ? 400 : 300}
              color={Colors.neutral.white}
              nextElement='graphic'>
              We&#39;re excited to work with you. Your advisor will reach out soon to talk about your goals.
              Until then, check out&nbsp;
              <LinkStyled
                id='in-page-lead-form-guide-on-what-to-expect-link'
                href='/email-landing/prospect/get-the-most-from-your-first-advisor-meeting?utm_source=NMCOM&utm_medium=LandingPage&utm_term=na&utm_campaign=FAFA&utm_content=Let%27s_Get_Ready'
                variant='primary'>
                our guide on what you can expect
              </LinkStyled>
              &nbsp;from your first meeting and what to have handy.
            </Typography>
            {isPcg && (
              <Typography
                component='small'
                disableBottomPadding>
                We do our best to match you with an advisor based on what you told us. While unlikely, there are circumstances that could prevent us from matching you to an advisor at a Private Client Group firm. If this should happen, we&#39;ll still make sure to match you with an equally qualified advisor that will fit your needs.
              </Typography>
            )}
          </Col>
        </Row>
      )}
    </ContainerStyled>
  );
};

export default ThankYouModuleComponent;
