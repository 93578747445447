/** Created manually */
import React from 'react';
import classNames from 'classnames';

class Icon extends React.PureComponent {
  render() {
    const { className, svgRef, ...otherProps } = this.props;
    return (
      <svg version="1.1" height='100' width='100' viewBox="100 100 100 100" fill="currentColor" className={classNames('nmx-icon', className)} ref={svgRef} {...otherProps}><path d="M191.7 148.3c0-8.4-2.5-16.6-7.2-23.6s-11.3-12.4-19.1-15.7c-7.8-3.2-16.3-4.1-24.6-2.4-8.2 1.6-15.8 5.7-21.8 11.6-5.9 5.9-10 13.5-11.6 21.8-1.6 8.2-.8 16.8 2.4 24.6 3.2 7.8 8.7 14.4 15.7 19.1 7 4.7 15.2 7.2 23.6 7.2 0-5.6 1.1-11.1 3.2-16.3 2.1-5.2 5.3-9.8 9.2-13.8 3.9-3.9 8.6-7.1 13.8-9.2s10.9-3.3 16.4-3.3z" fill="#fc5742" className="icon-fill"/><path d="m146.1 157.1 20-18.5-2-2.2-20 18.5 2 2.2zm-12.8-10.8 10.8 10.8 2.1-2.1-10.8-10.8-2.1 2.1zm11.8 9.7-1.1 1.1 1 1 1.1-1-1-1.1z" fill="#fff"/><path d="M191.7 148.4c-5.6 0-11.1 1.1-16.3 3.2-5.2 2.1-9.8 5.3-13.8 9.2-3.9 3.9-7.1 8.6-9.2 13.8-2.1 5.2-3.2 10.7-3.2 16.3l42.5-42.1v-.4z" fill="#edf0f3"/></svg>
    );
  }
}

Icon.displayName = 'CircleWithCheckmarkIcon';

export default Icon;
