/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import Label from '../Label';
import SelectStyled from './styles';
import ValidationErrorLabel from '../ValidationErrorLabel';

export const SelectComponent = React.forwardRef(({
  label,
  name,
  value,
  onChange,
  error,
  errorMessage,
  disabled,
  children,
  required,
  customTheme,
  inputType,
  isPcg,
  ...rest
}, ref) => (
  <Label
    label={label}
    required={required}
    customTheme={customTheme}
    inputType={inputType}
    ref={ref}>
    <SelectStyled
      value={value}
      name={name}
      disabled={disabled}
      onChange={onChange}
      aria-required={required}
      customTheme={customTheme}
      hasError={error}
      isPcg={isPcg}
      {...rest}>
      {children}
    </SelectStyled>
    <ValidationErrorLabel
      error={error}
      errorMessage={errorMessage}
      customTheme={customTheme}
    />
  </Label>
));

SelectComponent.propTypes = {
  /** The label placed on the text field */
  label: PropTypes.string.isRequired,
  /** Name applied to input or textarea */
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  /** Is this input in an error state */
  error: PropTypes.bool,
  /** Message to display when error is true */
  errorMessage: PropTypes.string,
  /** The <option />'s for the select component */
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  customTheme: PropTypes.string,
  inputType: PropTypes.string,
};

SelectComponent.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  error: false,
  errorMessage: 'Error',
  children: '',
  required: false,
  customTheme: '',
  inputType: 'select',
};

export default SelectComponent;
