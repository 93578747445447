/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  DescriptionList,
  DescriptionListItem,
  DescriptionDetails,
  DescriptionTerm,
  TypographyStyled,
} from './styles';

export const DescriptionListComponent = ({ descriptionListData }) => {
  useEffect(() => {
    // validate all descriptionListData passed in are correct
    if (descriptionListData.length === 0) {
      console.error('Missing description list data. At least one entry is required.');
    }
  }, []);
  return (
    <DescriptionList>
      { descriptionListData.map((item, index) => (
        <DescriptionListItem key={`${item.descriptionTerm}-${index}`}>
          <DescriptionTerm>
            <TypographyStyled
              weight={400}>
              {item.descriptionTerm}
            </TypographyStyled>
          </DescriptionTerm>
          <DescriptionDetails>
            <TypographyStyled>
              {item.descriptionDetails}
              {item.sup && (<sup>{item.sup}</sup>)} {/* TODO: probably remove, looks like this was inherited from accolades */}
            </TypographyStyled>
          </DescriptionDetails>
        </DescriptionListItem>
      ))}
    </DescriptionList>
  );
};

DescriptionListComponent.propTypes = {
  /** Array of data to display in the description list */
  descriptionListData: PropTypes.arrayOf(PropTypes.shape({
    /** description term */
    descriptionTerm: PropTypes.any.isRequired,
    /** description details, will support React.Fragment (i.e. <sup>3</sup>) */
    descriptionDetails: PropTypes.any.isRequired,
  })).isRequired,
};

export default DescriptionListComponent;
