import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../../../form/Select';
import { goals } from '../../utilities/inPageLeadFormData';

export const GoalSelectInputComponent = React.forwardRef(({
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
  isPcg,
}, ref) => (
  <Select
    id='in-page-lead-form-goal-select'
    label="What's your biggest financial goal?"
    ref={ref}
    name='goal'
    value={value}
    error={error}
    errorMessage={errorMessage}
    onChange={onChange}
    onBlur={onBlur}
    options={goals}
    required={true}
    customTheme={'leads'}
    inputType='select'
    isPcg={isPcg}>
    {goals.map(
      (option) => <option
        key={option.name}
        value={option.value}
        disabled={option.disabled}>
        {option.name}
      </option>,
    )}
  </Select>
));

GoalSelectInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default GoalSelectInputComponent;
