import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

const getSectionVariantBackgroundColor = (theme, variant) => {
  if (variant === 'primary') {
    return theme.colors.primary.blue;
  }
  if (variant === 'secondary') {
    return theme.colors.neutral.gray95;
  }
  if (variant && variant === 'quarternary') {
    return theme.colors.neutral.gray99;
  }
  // If a hex color code is passed in, use that. (Design frequently uses several gray shades)
  if (variant && variant !== 'tertiary') {
    return variant;
  }
  // tertiary (Default)
  return theme.colors.neutral.white;
};

const getSectionVariantColor = (theme, variant) => {
  if (variant === 'primary') {
    return theme.colors.neutral.white;
  }
  // tertiary and secondary
  return theme.colors.neutral.gray29;
};

// eslint-disable-next-line import/prefer-default-export
export const GeneralSection = styled.section`
  ${({ theme, variant, disableBottomPadding, disableTopPadding }) => {
    const mediumUp = theme.mediumUp();
    let paddingBottom = rem(48);
    /* If a disableBottomPadding prop was provided, override the default */
    if (disableBottomPadding) {
      paddingBottom = 0;
    } else if (mediumUp) {
      paddingBottom = rem(60);
    }

    let paddingTop = rem(48);
    /* If a disableTopPadding prop was provided, override the default */
    if (disableTopPadding) {
      paddingTop = 0;
    } else if (mediumUp) {
      paddingTop = rem(60);
    }

    return css`
      padding-top: ${paddingTop};
      padding-bottom: ${paddingBottom};
      max-width: unset;
      background-color: ${getSectionVariantBackgroundColor(theme, variant)};
      color: ${getSectionVariantColor(theme, variant)};
    `;
  }}
`;
