import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import ArrowRightIcon from '../../icons/utility/ArrowRight';
import { Col } from '../../base/Grid';
import Typography from '../../base/Typography';
import Button from '../../base/Button';

export const DivDynamicCalloutCardStyled = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    transition: ${theme.shadows.transition};
    margin: 0 0 ${rem(32)};
    position: relative;
    overflow: visible;
    &:hover {
      box-shadow: ${theme.shadows.medium};
      text-decoration: none;
    }
  `}
`;

export const DivDynamicCalloutBannerStyled = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray99};
    overflow: hidden;
    transition: ${theme.shadows.transition};
    margin: 0 0 ${rem(32)};
    position: relative;
    &:hover {
      box-shadow: ${theme.shadows.medium};
      text-decoration: none;
    }
  `}
`;

export const DynamicCalloutCardContainer = styled.div`
  flex-direction: column;
  padding: ${rem(24)};
`;

export const MetadataStyled = styled(Typography)`
  display: block;
`;

export const ColImageContainerStyled = styled(Col)`
  margin: 0;
`;

export const ColContentContainerStyled = styled(Col)`
  margin: auto;
`;

export const DivContentContainerStyled = styled.div`
  ${({ theme }) => {
    let padding = '2rem';
    if (theme.largeUp()) {
      padding = '2rem 4rem';
    }

    return css`
      align-items: center;
      text-align: center;
      height: 100%;
      padding: ${padding};
    `;
  }}
`;

export const AnchorArticleContainerStyled = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

export const ArticleDynamicCalloutStyled = styled.article`
  ${({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.neutral.white};
  `}
`;

export const DivCardImageStyled = styled.div`
  ${({ theme, image }) => {
    const medium = theme.mediumUp();
    let height;
    if (medium) {
      height = '12.5rem';
    } else {
      height = '10rem';
    }

    return css`
      height: ${height};
      width: 100%;
      /* background-image: url(${image}); Removing because lazy load data-bg will populate this once scrolled to */
      background-size: cover;
      background-position: center;
    `;
  }}
`;

export const TypographyCardStyled = styled(Typography)`
  ${({ theme, hasLink }) => {
    const medium = theme.mediumUp();

    let paddingBottom;
    if (medium) {
      paddingBottom = '4rem';
    } else {
      paddingBottom = '3rem';
    }

    return css`
    && {
      padding-bottom:${hasLink ? paddingBottom : 0};
      `;
  }}
  }
`;

export const TypographyVisualLinkStyled = styled(Typography)`
  position: absolute;
  bottom: ${rem(16)};
  padding-bottom: ${rem(4)};
`;

export const IconVisualLinkStyled = styled(ArrowRightIcon)`
  ${({ theme }) => {
    const xsmall = theme.xsmallUp();
    const medium = theme.mediumUp();
    const xlarge = theme.xlargeUp();
    let bottom;

    if (xsmall) {
      bottom = 0;
    }
    if (medium) {
      bottom = '0.05rem';
    }
    if (xlarge) {
      bottom = '0.1rem';
    }

    return css`
      margin-left: ${rem(8)};
      margin-bottom: ${bottom};
    `;
  }}
`;

export const SpanArticleFlagStyled = styled.span`
  ${({ flagColor }) => css`
    position: absolute;
    top: ${rem(8)};
    left: ${rem(-8)};
    display: inline-flex;
    height: ${rem(25)};
    align-items: center;
    padding: ${rem(13)};
    background-color: ${flagColor};
    z-index: 1;
    border-radius: 0 0 1px 0;
    ::after {
      position: absolute;
      top: 0;
      right: ${rem(-12)};
      border-bottom: ${rem(26)} solid transparent;
      border-left: ${rem(12)} solid ${flagColor};
      content: '';
      @media print {
        display: none;
      }
    }
  `}
`;

export const TypographyArticleFlagStyled = styled(Typography)`
  && {
    font-size: ${rem(14)};
    text-transform: uppercase;
    letter-spacing: ${rem(2.5)};
    margin-top: ${rem(2)};
  }
`;

export const ButtonDynamicCalloutBannerStyled = styled(Button)`
  margin-top: ${rem(16)};
  text-transform: none;
`;

export const DivBannerImageContainerStyled = styled.div`
  ${({ theme, image }) => {
    let minHeight;

    if (theme.smallUp()) {
      minHeight = rem(265);
    } else {
      minHeight = rem(175);
    }

    return css`
      min-height: ${minHeight};
      height: 100%;
      width: 100%;
      background-image: url(${image});
      background-size: cover;
      background-position: center;
    `;
  }}
`;

export const ButtonDynamicCalloutOverlayStyled = styled.button`
    width: 100%;
    height: 100%;
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    &:hover {
      cursor: pointer;
    }
}`;
