import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from '../../base/Grid';
import Typography from '../../base/Typography';
import ProfileOutlineCheckmarkWithBorderIcon from '../../icons/illustrative/ProfileOutlineCheckmarkWithBorder';
import { Styles } from './styles';

export const ThankYouComponent = ({
  thankYouText,
  thankYouTextDescription,
}) => {
  const styles = Styles();
  return (
    <Container>
      <Row align="center">
        <Col xsmall={12} align="center" className={'nmx-align-center'}>
          <ProfileOutlineCheckmarkWithBorderIcon />
          <Typography variant="h3" align="center" disableBottomPadding styles={styles.thankYouText}>
            {thankYouText}
          </Typography>
          {!!thankYouTextDescription && (
            <Typography variant="p" weight="300" align="center" disableBottomPadding>
              {thankYouTextDescription}
            </Typography>
          )}
        </Col>
      </Row>
    </Container>
  );
};

ThankYouComponent.propTypes = {
  thankYouText: PropTypes.string,
  thankYouTextDescription: PropTypes.string,
};

ThankYouComponent.defaultProps = {
  thankYouText: 'Thank you.',
  thankYouTextDescription: null,
};

export default ThankYouComponent;
