import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from '@nmx/utils/dist/utilities/validators';
import { AgentCardWrapper, PhotoCol, StyledImg, FormCol } from './styles';
import Ensemble from './Ensemble';
import resolveFrPhoto from '../../../../assets/js/utils/resolveFrPhoto.util';
import { PHONE_MEDIA_QUERY } from '../../../../assets/js/constants/media_query.constants';
import AgentForm from '../../../forms/AgentForm';
import { Row, Col } from '../../../base/Grid';
import Button from '../../../base/Button';
import CheckronRightIcon from '../../../icons/utility/ChevronRight';
import Link from '../../../base/Link';
import Typography from '../../../base/Typography';
import sanitizePhone from '../../../../assets/js/helpers/sanitizePhone';

const AgentCard = ({
  applicationId,
  agentObject,
  config,
  customName,
  campaign,
  source,
  topic,
  photoWidth,
  searchAnalytics,
}) => {
  const theme = useContext(ThemeContext);
  const smallDown = theme.smallDown();
  const isMobile = smallDown;
  const [isExpanded, setIsExpanded] = useState(false);
  const rep = agentObject;
  const [typographyVariant, setTypographyVariant] = useState(
    isMobile
      ? 'h1'
      : 'h3',
  );

  useEffect(() => {
    setTypographyVariant(smallDown
      ? 'h1'
      : 'h3');
  }, [smallDown]);

  const getStandardizedTitle = () => {
    if (rep.type === 'MD') {
      return 'Managing Director';
    }

    return rep.title;
  };

  const getFormattedAddress = () => {
    const addressPieces = [];

    if (rep.street) addressPieces.push(rep.street);
    if (rep.building) addressPieces.push(rep.building);

    addressPieces.push(`\n${rep.city}, ${rep.state}`);

    if (rep.zipLastFour) {
      addressPieces.push(`${rep.zip}-${rep.zipLastFour}`);
    } else {
      addressPieces.push(rep.zip);
    }

    return addressPieces.join(' ');
  };

  const linkClickedHandler = (event) => {
    if (!isEmpty(searchAnalytics)) {
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: 'site search results clicked',
        'site-search': {
          term: searchAnalytics.searchTerm.toLowerCase(),
          'results-number': searchAnalytics.resultsNumber,
          'results-type': 'FR-card',
          'results-text': /\d/.test(event.currentTarget.innerText) ? 'phone number' : event.currentTarget.innerText.trim().toLowerCase(),
        },
      });
    }
  };

  const toggle = (event) => {
    setIsExpanded(!isExpanded);
    linkClickedHandler(event);
  };

  return (
    <AgentCardWrapper className="vcard" id="nmx-dynamic-fr-card">
      <Row className={isExpanded && 'is-expanded'}>
        <PhotoCol xsmall={12} small={4} medium={3} nestedChild>
          <Row>
            <Col xsmall={12} small={12} medium={12} nested>
              <StyledImg
                src={resolveFrPhoto(rep)}
                photoWidth={photoWidth}
                alt={rep.fullName}
                className="fr-photo photo"
              />
            </Col>
          </Row>
        </PhotoCol>
        <Col xsmall={12} small={8} medium={9} nestedChild>
          <Row>
            <Col
              xsmall={12}
              small={12}
              medium={6}
              nested
              styles={{ marginBottom: '1rem' }}>
              {/* TODO: parse out name for microformats "given-name", "family-name", etc */}
              <Typography
                className="fr-name n"
                component="h3"
                weight={300}
                variant={typographyVariant}
                styles={{ paddingBottom: '1rem' }}>
                <span className="fn">{rep.fullName}</span>
              </Typography>
              <Typography styles={{ letterSpacing: '1px' }} disableBottomPadding={true} component='span' weight={300}>{getStandardizedTitle()}</Typography>
              <Ensemble agentnumber={rep.id} config={config}></Ensemble>
              <ul style={{
                padding: '1rem 0',
                margin: '0',
                listStyle: 'none',
              }}>
                {rep.designations
                  && rep.designations.map((designation, index) => (
                    <li key={index} style={{ display: 'inline' }}>
                      <span>
                        {designation}
                        {index < rep.designations.length - 1 && ', '}
                      </span>
                    </li>
                  ))}
              </ul>
              <Link
                href="https://brokercheck.finra.org/"
                target="_blank"
                rel="nofollow noopener noreferrer"
                onClick={linkClickedHandler}>
                FINRA BrokerCheck
              </Link>
            </Col>
            <Col xsmall={12} small={12} medium={6} nested>
              {/* TODO: formatted address needs some love, needs microformats */}
              <Typography
                component="address"
                variant="p"
                styles={{ whiteSpace: 'pre-wrap' }}>
                {getFormattedAddress()}
              </Typography>
              {rep.selectedPhone1 && rep.selectedPhone1.Number && (
                <Link
                  href={`tel:${sanitizePhone(rep.selectedPhone1.Number)}`}
                  onClick={linkClickedHandler}>
                  {rep.selectedPhone1.Number}
                </Link>
              )}
              {/* if num1 and num2 exist, add delimiter */}
              {rep.selectedPhone1 && rep.selectedPhone1.Number && rep.selectedPhone2 && rep.selectedPhone2.Number && ' | '}
              {rep.selectedPhone2 && rep.selectedPhone2.Number && (
                <Link
                  href={`tel:${sanitizePhone(rep.selectedPhone2.Number)}`}
                  onClick={linkClickedHandler}>
                  {rep.selectedPhone2.Number}
                </Link>
              )}
              {/* if num2 and num3 exist, add delimiter */}
              {rep.selectedPhone2 && rep.selectedPhone2.Number && rep.selectedPhone3 && rep.selectedPhone3.Number && ' | '}
              {/* if num1 and num3 exist, but num2 does not exist add delimiter */}
              {rep.selectedPhone1 && rep.selectedPhone1.Number && !rep.selectedPhone2 && rep.selectedPhone3 && rep.selectedPhone3.Number && ' | '}
              {rep.selectedPhone3 && rep.selectedPhone3.Number && (
                <Link
                  href={`tel:${sanitizePhone(rep.selectedPhone3.Number)}`}
                  onClick={linkClickedHandler}>
                  {rep.selectedPhone3.Number}
                </Link>
              )}
              <ul
                className="fr-contact-links reduced"
                style={{
                  padding: '1rem 0',
                  margin: '0',
                  listStyle: 'none',
                }}>
                {rep.slug && (
                  <li className="fr-contact-link-container">
                    <Link
                      href={`/financial/advisor/${rep.slug}/`}
                      aria-label={`Link to ${rep.fullName} profile page`}
                      className="fr-contact-link"
                      onClick={linkClickedHandler}>
                      view my profile page
                    </Link>
                  </li>
                )}
              </ul>
              <Button
                variant="secondary"
                className="fr-contact-cta nmx-button--secondary" // adding legacy classes here to handle active state
                onClick={toggle}>
                Contact <CheckronRightIcon />
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {isExpanded && (
        <Row className={'fr-contact'} styles={{ marginTop: '2rem' }}>
          <Col
            xsmall={12}
            medium={5}
            nestedChild
            className={'fr-contact-more-info'}
            styles={{ paddingRight: '2rem' }}>
            {rep.briefBio && (
              <>
                <h5>About My Practice</h5>
                <p>{rep.briefBio}</p>
              </>
            )}
            <Typography
              component="h5"
              uppercase={true}
              disableBottomPadding={true}
              weight={300}>
              Location
            </Typography>
            <Link
              href={`https://maps.google.com/maps?q=loc:${rep.latitude},${rep.longitude}`}
              target="_blank"
              rel="nofollow noopener noreferrer">
              {/* can add width 100% here to have the map maximized */}
              <picture>
                <source
                  srcSet={`https://maps.googleapis.com/maps/api/staticmap?center=${rep.latitude},${rep.longitude}&size=250x414&markers=${rep.latitude},${rep.longitude}&maptype=roadmap&zoom=13&key=${config.public.googlePlacesApiKey}`}
                  media={PHONE_MEDIA_QUERY}
                />
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${rep.latitude},${rep.longitude}&size=534x282&markers=${rep.latitude},${rep.longitude}&maptype=roadmap&zoom=13&key=${config.public.googlePlacesApiKey}`}
                  alt="Google Map"
                />
              </picture>
            </Link>
          </Col>
          <FormCol xsmall={12} medium={7} className={'fr-contact-service'}>
            <AgentForm
              applicationId={applicationId}
              agentnumber={rep.id}
              config={config}
              customName={customName}
              isPcg={false}
              useRecaptcha={false}
              formLocation="search"
              campaign={campaign}
              source={source}
              topic={topic}
            />
          </FormCol>
        </Row>
      )}
    </AgentCardWrapper>
  );
};

AgentCard.propTypes = {
  /** application Id for the form */
  applicationId: PropTypes.string,
  photoWidth: PropTypes.number,
  config: PropTypes.shape({
    public: PropTypes.shape({ googlePlacesApiKey: PropTypes.string }),
    services: PropTypes.shape({
      leadApiBaseUrl: PropTypes.string,
      serviceRequestApiBaseUrl: PropTypes.string,
    }),
  }),
  customName: PropTypes.string,
  searchAnalytics: PropTypes.shape({
    searchTerm: PropTypes.string,
    resultsNumber: PropTypes.string,
  }),
};

AgentCard.defaultProps = {
  config: {
    public: { googlePlacesApiKey: '<%=googlePlacesApiKey%>' },
    services: {
      leadApiBaseUrl: '<%=leadApiBaseUrl%>',
      serviceRequestApiBaseUrl: '<%=serviceRequestApiBaseUrl%>',
    },
    searchAnalytics: {},
  },
};

export default AgentCard;
