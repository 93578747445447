import React from 'react';
import PropTypes from 'prop-types';

import {
  BaseLink,
  StartAdornmentContainerStyled,
  EndAdornmentContainerStyled,
} from './styles';

export const LinkComponent = React.forwardRef(({
  ariaLabel,
  children,
  className,
  config,
  endAdornment: RenderEndAdornment,
  href,
  id,
  startAdornment: RenderStartAdornment,
  themeType,
  variant,
  ...rest
}, ref) => (
  <BaseLink
    {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
    id={id}
    className={className}
    config={config}
    href={href}
    ref={ref}
    themeType={themeType}
    variant={variant}
    {...rest}>
    {RenderStartAdornment && (
      <StartAdornmentContainerStyled>
        <RenderStartAdornment />
      </StartAdornmentContainerStyled>
    )}
    {children}
    {RenderEndAdornment && (
      <EndAdornmentContainerStyled>
        <RenderEndAdornment />
      </EndAdornmentContainerStyled>
    )}
  </BaseLink>
));

LinkComponent.propTypes = {
  /** optional ariaLabel attribute */
  ariaLabel: PropTypes.string,
  /** required inner text */
  children: PropTypes.node.isRequired,
  /** optional additional className */
  className: PropTypes.string,
  /** allows for CSS asset path in styles */
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
  /** optional icon component that displays at the end of the link */
  endAdornment: PropTypes.func,
  /** required href path */
  href: PropTypes.string.isRequired,
  /** optional id attribute */
  id: PropTypes.string,
  /** optional icon component that displays at the start of the link */
  startAdornment: PropTypes.func,
  /** optional theme */
  themeType: PropTypes.oneOf(['nmx-pcg']),
  /** required variant, NOTE: buttonQuarternary not an option because that style is essentially a default Base Link */
  variant: PropTypes.oneOf(['primary', 'secondary', 'buttonPrimary', 'buttonSecondary', 'buttonTertiary']).isRequired,
};

LinkComponent.defaultProps = {
  children: '',
  config: {
    basePath: '<%=basePath%>',
    nmxTemplateVersion: '<%=nmxTemplateVersion%>',
  },
  variant: 'primary',
};

export default LinkComponent;
