/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Container, Row, Col } from '../../base/Grid';
import LightbulbSecondary from '../../icons/illustrative-secondary/LightbulbSecondary';
import Link from '../../base/Link';

export const StickyBannerContainer = styled(Container)`
  ${({ theme, isVisible, informationalBannerHeight }) => {
    const small601to768 = theme.useMediaQuery(
      theme.breakpoints.between(601, theme.breakpoints.values.medium),
      theme.breakpoints.defaultMatchBetween(
        601,
        theme.breakpoints.values.medium,
      ),
    );
    const small0to601 = theme.useMediaQuery(
      theme.breakpoints.between(0, 601),
      theme.breakpoints.defaultMatchBetween(0, 601),
    );
    return css`
      && {
        display: flex;
        position: fixed;
        left: ${small601to768 || theme.mediumUp() ? '50%' : '0'};
        top: ${informationalBannerHeight > 0 && small0to601
    ? `${informationalBannerHeight + 50 + 32}px` // 2rem (32px) + 50px (mobile menu height) + banner height
    : informationalBannerHeight <= 0 && small0to601
      ? rem('82px') // 2rem + 50px (mobile menu height)
      : 0};
        bottom: ${small601to768 || theme.mediumUp() ? rem(30) : 'unset'};
        width: ${small601to768 || theme.mediumUp()
    ? rem(600)
    : 'fill-available'};
        max-width: ${rem(600)};
        height: ${rem(90)};
        margin-left: ${isVisible && (small601to768 || theme.mediumUp())
    ? rem('-300px')
    : isVisible && small0to601
      ? '1rem'
      : rem('-600px')}; /** slide in from left mediumDown */
        margin-right: ${small601to768 || theme.mediumUp() ? 0 : rem(16)};
        margin-bottom: ${!small0to601 && !isVisible
    ? rem(-150)
    : 0}; /** slide in from bottom mediumUp */
        background-color: ${theme.colors.primary.blue};
        border: ${rem(1)} solid ${theme.colors.modal.stroke};
        box-shadow: ${theme.shadows.large};
        z-index: ${theme.zIndex.stickyBanner};
        transition: ${small601to768 || theme.mediumUp()
    ? 'margin-bottom 0.2s ease-out'
    : 'margin-left 0.2s ease-out'};
      }
    `;
  }}
`;

export const StickyBannerInnerRow = styled(Row)`
  && {
    width: 100%;
  }
`;

export const StickyBannerInnerCol = styled(Col)`
  ${({ theme, variant }) => {
    const small0to601 = theme.useMediaQuery(
      theme.breakpoints.between(0, 601),
      theme.breakpoints.defaultMatchBetween(0, 601),
    );
    return css`
      && {
        padding-left: ${small0to601 && variant === 'col-right' ? rem(16) : 0};
        padding-right: 0;
        display: inline-flex;
        align-items: center;
      }
    `;
  }}
`;

export const StickyBannerIcon = styled(LightbulbSecondary)`
  width: ${rem(57)};
  height: ${rem(57)};
`;

export const StickyBannerLink = styled(Link)`
  ${({ theme }) => {
    const small601to768 = theme.useMediaQuery(
      theme.breakpoints.between(601, theme.breakpoints.values.medium),
      theme.breakpoints.defaultMatchBetween(
        601,
        theme.breakpoints.values.medium,
      ),
    );
    return css`
      && {
        display: ${small601to768 || theme.mediumUp() ? 'inline' : 'block'};
        color: ${theme.colors.cta.yellow};
        font-size: ${small601to768 || theme.mediumUp() ? rem(16) : rem(14)};
        line-height: ${small601to768 || theme.mediumUp() ? rem(24) : rem(22)};

        &::after {
          /** TODO: add this in globally? needs testing */
          position: relative;
          top: ${rem(1)};
          width: ${small601to768 || theme.mediumUp() ? rem(14) : rem(12)};
          height: ${small601to768 || theme.mediumUp() ? rem(14) : rem(12)};
          filter: invert(66%) sepia(75%) saturate(646%) hue-rotate(15deg)
            brightness(104%) contrast(101%); /** $color-system-cta-yellow */ /** TODO: this would be nice in a theme */
        }
        &:hover {
          color: ${theme.colors.cta.yellowHover};
          filter: invert(48%) sepia(77%) saturate(1364%) hue-rotate(16deg)
            brightness(97%) contrast(101%); /** $color-system-cta-hover-yellow */ /** TODO: this would be nice in a theme */
        }
      }
    `;
  }}
`;

export const DismissButton = styled.button`
  ${({ theme }) => {
    const small601to768 = theme.useMediaQuery(
      theme.breakpoints.between(601, theme.breakpoints.values.medium),
      theme.breakpoints.defaultMatchBetween(
        601,
        theme.breakpoints.values.medium,
      ),
    );
    return css`
      position: absolute;
      right: ${rem(10)};
      top: ${small601to768 || theme.mediumUp() ? 'unset' : 0};
      width: ${small601to768 || theme.mediumUp() ? rem(20) : rem(16)};
      height: ${small601to768 || theme.mediumUp() ? rem(20) : rem(16)};
      margin-top: ${rem(10)};
      padding: 0;
      border: 0;
      background: transparent;
      cursor: pointer;
      ::before,
      ::after {
        content: '';
        width: ${rem(1)};
        height: 100%;
        background-color: ${theme.colors.neutral.white};
        transform: rotate(45deg) translateX(0);
        position: absolute;
        left: 50%;
        top: 0;
      }
      ::before {
        transform: rotate(-45deg) translateX(0);
      }
      :hover::before,
      :hover::after {
        background-color: ${theme.colors.neutral.gray54};
      }
    `;
  }}
`;
