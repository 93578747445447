import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../theme/colors';

import {
  ProgressStyled,
  DivProgressBarContainerStyled,
} from './styles';

export const ProgressBarComponent = ({
  fillPercentage,
  fillColor,
  bgColor,
}) => {
  const getColor = (color) => {
    switch (color) {
      case 'light-blue-2':
        return colors.accent.lightBlue2;
      case 'light-blue-3':
        return colors.accent.lightBlue3;
      case 'blue':
        return colors.primary.blue;
      case 'yellow':
        return colors.cta.yellow;
      case 'white':
        return colors.neutral.white;
      case 'pcg-gold-1':
        return colors.pcg.gold1;
      case 'neutral-gray-58':
        return colors.neutral.gray58;
      default:
      // return color value string (hex, rgb, etc)
        return color;
    }
  };

  return (
    <DivProgressBarContainerStyled className='nmx-no-print'>
      <ProgressStyled
        className='nmxc-progress-bar'
        value={fillPercentage}
        fillColor={getColor(fillColor)}
        bgColor={getColor(bgColor)}
        max='100'
      />
    </DivProgressBarContainerStyled>
  );
};
ProgressBarComponent.propTypes = {
  fillPercentage: PropTypes.number.isRequired,
  fillColor: PropTypes.string,
  bgColor: PropTypes.string,
};

ProgressBarComponent.defaultProps = {
  fillPercentage: 0,
  fillColor: 'light-blue-2',
  bgColor: 'light-blue-3',
};

export default ProgressBarComponent;
