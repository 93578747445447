import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Button from '../../../base/Button';
import Picture from '../../../base/Picture';
import Link from '../../../base/Link';
import Typography from '../../../base/Typography';
import { Col } from '../../../base/Grid';

export const HeaderSeparater = styled.span`
  font-size: 85%;
  font-weight: 300;
`;

export const TitleHeader = styled.span`
  font-size: 85%;
  font-weight: 300;
`;

export const FinraContainer = styled.div`
  margin-top: 1.5rem;
`;

export const BottomCtaContainer = styled.div`
  margin-top: 0.5rem;
`;

export const PhotoStyled = styled(Picture)`
  display: block;
`;

export const LinkStyled = styled(Link)`
  text-decoration: underline;
  text-underline-offset: ${rem(3)};
`;

export const ButtonStyled = styled(Button)`
  ${({ theme }) => css`
    ${(theme.mediumDown() || theme.largeUp())
      && `
    max-width: 100%;
    width: 100%;
      `}
    min-width: 14rem;
  `}
`;

export const CtaCol = styled(Col)`
  ${({ theme }) => css`
    ${theme.mediumDown()
      && `
      margin-top: ${rem(8)};
    `}
  `}
`;

export const TypographyWithIcon = styled(Typography)`
  ${({ icon, config }) => css`
  position: relative;
  ::before {
    position: absolute;
    top: ${rem(4)};
    left: ${rem(-41)};
    display: inline-block;
    width: ${rem(20)};
    height: ${rem(25)};
    padding-left: ${rem(40)};
    background-image: url('${config.basePath}/template/assets/${
  config.nmxTemplateVersion
}/images/icons/utility/${icon}.svg');
    filter: invert(31%) sepia(0%) saturate(0%) hue-rotate(213deg) brightness(91%) contrast(98%);
    background-repeat: no-repeat;
    content: ' ';
  }
`}
`;
