import styled, { css, ThemeContext } from 'styled-components';
import { useContext } from 'react';
import rem from 'polished/lib/helpers/rem';
// template components
import { Container, Col, Row } from '../../base/Grid';
import Link from '../../base/Link';
import Picture from '../../base/Picture';
import Typography from '../../base/Typography';

const getSectionVariantBgColor = (theme, variant) => {
  if (variant === 'primary') {
    return theme.colors.primary.blue;
  }
  if (variant === 'secondary') {
    return theme.colors.neutral.gray95;
  }
  // If a hex color code is passed in, use that. (Design frequently uses several gray shades)
  if (variant && variant !== 'tertiary') {
    return variant;
  }
  // tertiary (Default)
  return theme.colors.neutral.white;
};

const getComponentVariantBgColor = (theme, variant) => {
  if (variant === 'primary') {
    return theme.colors.primary.blue;
  }
  if (variant === 'secondary') {
    return 'transparent';
  }
  return theme.colors.neutral.gray99;
};

const getComponentVariantTextColor = (theme, variant) => {
  if (variant === 'primary') {
    return theme.colors.neutral.white;
  }
  return theme.colors.neutral.gray45;
};

// Loaded for the BG Image variant
export const BGImageContainerExperienceCalloutStyled = styled.div`
  ${({ theme, forFullWidthContainer, image, disableLazyLoad }) => {
    let width = '100%';
    let height = '100%';
    let position = 'relative';

    if (forFullWidthContainer && theme.largeUp()) {
      // if it IS fullWidth, set the BG image to the full screen orientation
      width = rem(460);
      position = 'absolute';
    }

    if (theme.mediumDown()) {
      height = rem(180);
    }

    return css`
      position: ${position};
      top: 0;
      left: 0;
      height: ${height};
      width: ${width};
      background-size: cover;
      background-position: center;
      ${disableLazyLoad && css`background-image: url(${image})`};
      display: flex;
      align-items: center;
      padding: 0;
    `;
  }}
`;

export const ButtonOverlayStyled = styled.button`
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  :hover {
    cursor: pointer;
  }
`;

export const ColExperienceCalloutStyled = styled(Col)`
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0;
`;

export const ComponentContainerExperienceCalloutStyled = styled.div`
  ${({ theme, variant, isFullWidthContainer, withHoverShadow }) => {
    let boxShadow = 'none';

    if (withHoverShadow) {
      boxShadow = theme.shadows.medium;
    }

    return css`
      ${!isFullWidthContainer && 'position:relative'};
      transition: ${theme.shadows.transition};
      background-color: ${getComponentVariantBgColor(theme, variant)};
      color: ${getComponentVariantTextColor(theme, variant)};
      &:hover {
        box-shadow: ${boxShadow};
        text-decoration: none;
        .button-experience-callout-styled {
          /** Inner button Hover styles */
          border-color: ${theme.colors.cta.blueHover};
          color: ${theme.colors.cta.blueHover};
        }
    `;
  }}
`;

export const ContainerExperienceCalloutStyled = styled(Container)`
  ${({ theme, variant, isFullWidthContainer, withBGImage }) => {
    let background = 'transparent';

    if (isFullWidthContainer || !theme.mediumUp()) {
      background = getComponentVariantBgColor(theme, variant);
    }

    return css`
      background-color: ${background};
      padding: 0;
      ${withBGImage && !isFullWidthContainer && 'position: relative;'}
    `;
  }}
`;

export const CTAContainerExperienceCalloutStyled = styled.div`
  ${({ theme, withBGImage }) => {
    let padding = `0 ${rem(32)} ${rem(48)}`;

    if (withBGImage || theme.mediumUp()) {
      padding = `${rem(48)} ${rem(32)}`;
    }

    return css`
      padding: ${padding};
      min-height: ${theme.mediumUp() ? rem(260) : 'unset'};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `;
  }}
`;

export const DescriptionCopyTypographyStyled = styled(Typography)`
  padding-bottom: ${rem(24)};
`;

export const ImageContainerExperienceCalloutStyled = styled.div`
  ${({ theme }) => {
    let padding = 0;
    const mediumDown = theme.mediumDown();
    const largeDown = theme.largeDown();

    if (mediumDown) {
      padding = `${rem(48)} ${rem(32)} ${rem(32)}`;
    } else if (largeDown) {
      padding = `0 0 0 ${rem(32)}`;
    }

    return css`
      position: relative;
      height: auto;
      width: 100%;
      display: flex;
      align-items: center;
      padding: ${padding};
      ${theme.largeUp() && 'justify-content: center;'}
    `;
  }}
`;

export const LinkExperienceCalloutStyled = styled(Link)`
  ${({ theme, variantOverride }) => {
    let color = theme.colors.cta.blue;
    let hoverColor = theme.colors.cta.blueHover;
    if (variantOverride === 'primary') {
      color = theme.colors.neutral.white;
      hoverColor = theme.colors.neutral.gray87;
    }
    return css`
      && {
        background-color: transparent;
        color: ${color};
        border-color: ${color};
        :hover {
          color: ${hoverColor};
          border-color: ${hoverColor};
        }
      }
    `;
  }}
`;

export const PictureExperienceCalloutStyled = styled(Picture)`
  && {
    height: ${rem(200)};
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const RowStyled = styled(Row)`
  position: relative;
}`;

export const SectionExperienceCalloutStyled = styled.section`
  && {
    ${({ theme, variant, hasFullWidthContainer, disableBottomPadding }) => {
    let thisMarginTop;
    let thisMarginBottom;
    let paddingHorizontal;
    const mediumUp = theme.mediumUp();
    const xlargeUp = theme.xlargeUp();

    if (hasFullWidthContainer) {
      thisMarginTop = 0;
      thisMarginBottom = 0;
      paddingHorizontal = 0;
    } else if (mediumUp) {
      thisMarginTop = rem(60);
      thisMarginBottom = disableBottomPadding ? 0 : rem(60);
    } else {
      thisMarginTop = rem(50);
      thisMarginBottom = disableBottomPadding ? 0 : rem(50);
    }

    if (!hasFullWidthContainer) {
      if (xlargeUp) {
        paddingHorizontal = 0;
      } else if (mediumUp) {
        paddingHorizontal = rem(25);
      } else {
        paddingHorizontal = rem(12);
      }
    }

    return css`
      position: relative;
      padding-left: ${paddingHorizontal};
      padding-right: ${paddingHorizontal};
      margin-top: ${thisMarginTop};
      margin-bottom: ${thisMarginBottom};
      max-width: unset;
      background-color: ${getSectionVariantBgColor(theme, variant)};
      @media print {
        display: none;
      }
    `;
  }}
  }
`;

export const TypographyTitleStyled = styled(Typography)`
  ${({ theme, hasCopy }) => {
    let bottomPadding;
    const mediumUp = theme.mediumUp();
    // if there is no text copy present beneath the header, change the bottom padding
    if (hasCopy) {
      if (mediumUp) {
        bottomPadding = rem(16);
      } else {
        bottomPadding = rem(12);
      }
    } else {
      bottomPadding = rem(24);
    }

    return css`
      padding-bottom: ${bottomPadding};
    `;
  }}
`;

/** Creates inline styles (to override stock Component Library styles) */
export const Styles = () => {
  const theme = useContext(ThemeContext);

  const hw = theme.largeUp() ? '80%' : '100%';
  return {
    IconExperienceCalloutStyled: {
      display: 'flex',
      width: hw,
      height: hw,
      maxHeight: rem(180),
      justifyContent: 'center',
      ...(theme.mediumUp() && { maxHeight: 'unset' }),
    },
  };
};
