/* eslint-disable import/prefer-default-export */
import rem from 'polished/lib/helpers/rem';
import styled, { css } from 'styled-components';

import {
  breakpointData,
  getColStyles,
  getNestedStyles,
  getNestedChildStyles,
  generateColOffsetStyles,
  generateColSizeStyles,
} from './helpers';

export const BaseContainer = styled.div`
  ${({ theme }) => css`
      width: 100%;
      max-width: ${rem(theme.breakpoints.values.large1200)};
      flex: 0 0 auto; /** ie fix */
      margin: auto;

      padding: 0 ${breakpointData.xsmall.outerGutterVal};
      ${theme.smallUp() && `padding: 0 ${breakpointData.small.outerGutterVal}`};
      ${theme.mediumUp() && `padding: 0 ${breakpointData.medium.outerGutterVal}`};
      ${theme.largeUp() && `padding: 0 ${breakpointData.large.outerGutterVal}`};
      ${theme.xlargeUp() && `padding: 0 ${breakpointData.xlarge.outerGutterVal}`};
      ${theme.xxlargeUp() && `padding: 0 ${breakpointData.xxlarge.outerGutterVal}`};
    `}
`;

export const BaseRow = styled.div`
  ${({ align, reverse }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    ${reverse && 'flex-direction: row-reverse;'}
    ${align === 'center' && 'justify-content: center;'}
    ${align === 'left' && 'align-self: flex-start;'}
    ${align === 'right' && 'justify-content: flex-end;'}
  `}
`;

export const BaseCol = styled.div`
  ${({
    align,
    theme,
    nested,
    nestedChild,
    xsmall,
    small,
    medium,
    large,
    xlarge,
    xxlarge,
    paddingLeft,
    paddingRight,
    xsmallOffset,
    smallOffset,
    mediumOffset,
    largeOffset,
    xlargeOffset,
    xxlargeOffset,
  }) => {
    const xsmallUp = theme.xsmallUp();
    const smallUp = theme.smallUp();
    const mediumUp = theme.mediumUp();
    const largeUp = theme.largeUp();
    const xlargeUp = theme.xlargeUp();
    const xxlargeUp = theme.xxlargeUp();
    return css`
    ${getColStyles('0 100%')}
    ${align === 'center' && 'text-align: center;'}

    padding: 0 ${rem(breakpointData.xsmall.gridGutterInPx)};
    ${smallUp && `padding: 0 ${rem(breakpointData.small.gridGutterInPx)}`};
    ${mediumUp && `padding: 0 ${rem(breakpointData.medium.gridGutterInPx)}`};
    ${largeUp && `padding: 0 ${rem(breakpointData.large.gridGutterInPx)}`};
    ${xlargeUp && `padding: 0 ${rem(breakpointData.xlarge.gridGutterInPx)}`};
    ${xxlargeUp && `padding: 0 ${rem(breakpointData.xxlarge.gridGutterInPx)}`};

    ${xsmallUp && generateColSizeStyles(xsmall)}
    ${smallUp && generateColSizeStyles(small)}
    ${mediumUp && generateColSizeStyles(medium)}
    ${largeUp && generateColSizeStyles(large)}
    ${xlargeUp && generateColSizeStyles(xlarge)}
    ${xxlargeUp && generateColSizeStyles(xxlarge)}

    ${xsmallUp && generateColOffsetStyles(xsmallOffset)}
    ${smallUp && generateColOffsetStyles(smallOffset)}
    ${mediumUp && generateColOffsetStyles(mediumOffset)}
    ${largeUp && generateColOffsetStyles(largeOffset)}
    ${xlargeUp && generateColOffsetStyles(xlargeOffset)}
    ${xxlargeUp && generateColOffsetStyles(xxlargeOffset)}

    ${mediumUp
      && css`
        ${paddingLeft
          && css`
            padding-left: 2rem;
          `}

        ${paddingRight
          && css`
            padding-right: 2rem;
          `}
      `}

    ${nested && getNestedStyles(theme)}
    ${nestedChild && getNestedChildStyles(theme)}
  `;
  }
}
`;
