import React from 'react';
import Typography from '../../../base/Typography';
import Colors from '../../../theme/colors';
import { Container, Row } from '../../../base/Grid';
import {
  IconStyled,
  SpanAnimatedEllipsisStyled,
} from './styles';

export const LoadingModuleComponent = ({ isPcg }) => (
  <Container>
    {!isPcg && (
      <Row align='center'>
        <IconStyled />
      </Row>
    )}
    <Row align='center'>
      <Typography
        component='p'
        variant='h3'
        color={Colors.neutral.white}>
        Just a moment
        <SpanAnimatedEllipsisStyled />
      </Typography>
    </Row>
  </Container>
);

export default LoadingModuleComponent;
