/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '../../base/Typography';

import {
  ValidationErrorLabelStyled,
  ValidationErrorLabelLeads,
} from './styles';

const ValidationErrorLabel = ({
  error,
  errorMessage,
  customTheme,
}) => {
  if (error) {
    if (customTheme === 'leads') {
      // Return LEADS variation of the error message
      return (
        <ValidationErrorLabelLeads>
          <Typography
            className='nmx-input-error-label'
            color='color-system-light-red'
            component='small'
            weight={500}>
            {errorMessage || 'Error'}
          </Typography>
        </ValidationErrorLabelLeads>
      );
    }
    // Return default version of the error message
    return (
      <ValidationErrorLabelStyled
        className='nmx-input-error-label'
        color='color-system-dark-red'
        component='small'
        weight={400}>
        {errorMessage || 'Error'}
      </ValidationErrorLabelStyled>
    );
  }
  return false;
};

ValidationErrorLabel.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  customTheme: PropTypes.string,
};

ValidationErrorLabel.defaultProps = {
  error: false,
  errorMessage: 'Error',
  customTheme: '',
};

export default ValidationErrorLabel;
