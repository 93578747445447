import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../../form/TextField';

export const LastNameInputComponent = React.forwardRef(({
  value,
  onChange,
  onBlur,
  error,
  errorMessage,
  isPcg,
}, ref) => (
  <TextField
    id='in-page-lead-form-last-name-input'
    label='Last name'
    ref={ref}
    name='lastName'
    value={value}
    error={error}
    errorMessage={errorMessage}
    onChange={onChange}
    onBlur={onBlur}
    required={true}
    customTheme={'leads'}
    maxLength={100}
    isPcg={isPcg} />
));

LastNameInputComponent.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default LastNameInputComponent;
