import React from 'react';
import InlineTooltip from '../../../modules/InlineTooltip';

import {
  DivHeaderInlineTooltipContainerStyled,
  DivInlineTooltipContainerStyled,
} from './styles';

// INPUT LABEL TOOLTIPS
// Adjust rightPos with a pixel value to position the tooltip icon/button
// this is where the leftPos value is breaking down.
export const GoalTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='We realize you have lots of aspirations, but for now, choose the one goal you&#39;d like to focus on the most. This will help us match you with a financial advisor who has the right expertise to help you reach your goal.'
      buttonId='tooltip-button-financial-goal'
      modalId='tooltip-modal-financial-goal'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const AgeTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='This will help us match you with an advisor who is generally at the same life stage as yourself.'
      buttonId='tooltip-button-age'
      modalId='tooltip-modal-age'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const ZipTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='This will help us match you with an advisor who lives in your area or who understands your hometown.'
      buttonId='tooltip-button-zip'
      modalId='tooltip-modal-zip'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const IncomeRangeTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='We&#39;re just looking for a range so we can match you with an advisor who has the right expertise for your income level.'
      buttonId='tooltip-button-income-range'
      modalId='tooltip-modal-income-range'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const WealthRangeTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='Add up the value of everything you own (home, cars, investments, savings, etc.) and subtract what you owe (any type of loan, credit card debt, etc.). That&#39;s your net worth.'
      buttonId='tooltip-button-wealth-range'
      modalId='tooltip-modal-wealth-range'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const FirstNameTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='Just so you know, your identity is safe with us.'
      buttonId='tooltip-button-first-name'
      modalId='tooltip-modal-first-name'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const LastNameTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='Just so you know, your identity is safe with us.'
      buttonId='tooltip-button-last-name'
      modalId='tooltip-modal-last-name'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const EmailTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='Don&#39;t worry, we&#39;ll never give out your contact information.'
      buttonId='tooltip-button-email'
      modalId='tooltip-modal-email'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

export const PhoneTooltip = (props) => (
  <DivInlineTooltipContainerStyled leftPos={props.leftPos}>
    <InlineTooltip
      copy='Don&#39;t worry, we&#39;ll never give out your contact information.'
      buttonId='tooltip-button-phone'
      modalId='tooltip-modal-phone'
      isPcg={props.isPcg} />
  </DivInlineTooltipContainerStyled>
);

// Hero variant Header tooltips /////////////
export const GoalHeaderTooltip = (props) => (
  <DivHeaderInlineTooltipContainerStyled>
    <InlineTooltip
      copy='We realize you have lots of aspirations, but for now, choose the one goal you&#39;d like to focus on the most. This will help us match you with a financial advisor who has the right expertise to help you reach your goal.'
      buttonId='tooltip-button-financial-goal-header'
      modalId='tooltip-modal-financial-goal-header'
      isPcg={props.isPcg} />
  </DivHeaderInlineTooltipContainerStyled>
);

export const DemographicInfoHeaderTooltip = (props) => (
  <DivHeaderInlineTooltipContainerStyled>
    <InlineTooltip
      copy='In case you&#39;re wondering, no, we won&#39;t share anything you share with anyone else.'
      buttonId='tooltip-button-demographic-info-header'
      modalId='tooltip-modal-demographic-info-header'
      isPcg={props.isPcg} />
  </DivHeaderInlineTooltipContainerStyled>
);

export const PersonalInfoHeaderTooltip = (props) => (
  <DivHeaderInlineTooltipContainerStyled>
    <InlineTooltip
      copy='Just so you know, your identity is safe with us.'
      buttonId='tooltip-button-personal-info-header'
      modalId='tooltip-modal-personal-info-header'
      isPcg={props.isPcg} />
  </DivHeaderInlineTooltipContainerStyled>
);

export const ContactInfoHeaderTooltip = (props) => (
  <DivHeaderInlineTooltipContainerStyled>
    <InlineTooltip
      copy='Don&#39;t worry, we&#39;ll never give out your contact information.'
      buttonId='tooltip-button-contact-info-header'
      modalId='tooltip-modal-contact-info-header'
      isPcg={props.isPcg} />
  </DivHeaderInlineTooltipContainerStyled>
);
