import React from 'react';
import PropTypes from 'prop-types';
import CopyrightSection from './CopyrightSection';
import DisclaimerSection from './DisclaimerSection';
import FooterLogo from './FooterLogo';
import NavLinks from './NavLinks';
import SocialLinks from './SocialLinks';
import { Row, Col, Container } from '../../base/Grid';

import { FooterSection, FafaCol, FafaLink, FafaTypography } from './styles';

export const FooterComponent = ({
  config,
  disableDisclaimerTopBorder,
  disclaimerFootnotes,
  customDisclaimers,
  isAmp,
  legalCopy,
  appendFooter,
  CustomFooter,
}) => {
  const { basePath, nmxTemplateVersion, nodeEnv } = config;

  return (
    // TODO: remove class "nmx-base" once nmx-template 1.0.x is implemented and "nmx-base" is added to body tag
    <footer id="footer">
      <Container>
        <h3 className="nmx-assistive-text">
          Find What You&#39;re Looking for at Northwestern Mutual
        </h3>
        {CustomFooter ? <CustomFooter />
          : <>
            <DisclaimerSection disclaimerFootnotes={disclaimerFootnotes} customDisclaimers={customDisclaimers} disableDisclaimerTopBorder={disableDisclaimerTopBorder} isAmp={isAmp} />
            <FooterSection style={{ paddingTop: '2rem' }}>
              <Row align='left'>
                <Col>
                  <FooterLogo isAmp={isAmp} basePath={basePath} nmxTemplateVersion={nmxTemplateVersion} />
                </Col>
              </Row>
              <Row>
                <Col nested xsmall={12} medium={8} large={5}>
                  <NavLinks basePath={basePath} nodeEnv={nodeEnv} />
                </Col>
                <Col xsmall={12} medium={4} large={3} className='nmx-no-print'>
                  <SocialLinks />
                </Col>
                <FafaCol xsmall={12} large={4}>
                  <FafaTypography component='h4' variant='h5' weight={300} disableBottomPadding={true}>
                    Over 6,400 Financial Advisors and Professionals Nationwide&#42;
                  </FafaTypography>
                  <FafaLink
                    href="/find-a-financial-advisor/"
                    variant='secondary'
                    className='nmx-no-print'
                    config={config}
                  >
                    Find an Advisor
                  </FafaLink>
                </FafaCol>
              </Row>
            </FooterSection>
            <CopyrightSection legalCopy={legalCopy} appendFooter={appendFooter} />
          </>
        }
      </Container>
    </footer>
  );
};

FooterComponent.propTypes = {
  /** Additional footer verbiage, this was pulled in from nmx-static // TODO: still being used? */
  appendFooter: PropTypes.any,
  /** Config vars used in footer */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
  }).isRequired,
  /** any page-specific, non-ordered footnotes */
  customDisclaimers: PropTypes.any,
  /** optional boolean to remove top border (when page last section has a background color other than white) */
  disableDisclaimerTopBorder: PropTypes.bool,
  /** page-specific, ordered footnotes used in conjunction with superscript numbering on the page */
  disclaimerFootnotes: PropTypes.any,
  /** optional isAmp boolean */
  isAmp: PropTypes.bool,
  /** legal copy in reference to footer verbiage (below disclaimer) */
  legalCopy: PropTypes.any,
  CustomFooter: PropTypes.func,
};

FooterComponent.defaultProps = {
  disableDisclaimerTopBorder: false,
  isAmp: false,
};

export default FooterComponent;
