/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import Link from '../../base/Link';
import SectionContainer from '../SectionContainer';
import Typography from '../../base/Typography';
import { Col, Row } from '../../base/Grid';

export const ColLeftStyled = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();

    return css`
      ${largeUp && `
        display: flex;
        flex: 0 0 auto;
        padding: 0;
      `}
    `;
  }
}`;

export const ColRightStyled = styled(Col)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      ${largeUp && `
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
        padding: 0;
      `}
    `;
  }
}`;

export const LinkStyled = styled(Link)`
  ${({ theme, sectionVariant }) => {
    const primarySection = sectionVariant === 'primary';
    return css`
      margin-right: ${rem(15)};
      margin-left: ${theme.largeUp() ? rem(15) : 0};
      ${primarySection && `color: ${theme.colors.neutral.white}`};
      :hover {
        ${primarySection && `color: ${theme.colors.neutral.gray87}`};
      }
  `;
  }
}`;

export const LIStyled = styled.li`
  ${({ theme }) => css`
    display: ${theme.largeUp() ? 'inline' : 'block'};
  `
}`;

export const RowStyled = styled(Row)`
  ${({ theme }) => {
    const largeUp = theme.largeUp();
    return css`
      ${largeUp && `
        display: flex;
        flex-wrap: unset;
        flex-direction: row;
      `}
    `;
  }
}`;

export const SectionContainerStyled = styled(SectionContainer)`
  ${({ theme }) => css`
    padding-top: ${theme.mediumUp() ? rem(48) : rem(32)};
  `
}`;

export const TypographyListItemStyled = styled(Typography)`
  ${({ theme, lastItem }) => {
    const largeUp = theme.largeUp();
    return css`
      padding-bottom: 0;
      ${largeUp && 'white-space: nowrap;'}
      display: inline-block;
      :after {
        ${(!lastItem && largeUp) && 'content: \' | \';'};
      }
    `;
  }
}`;

export const TypographyStyled = styled(Typography)`
  text-transform: uppercase;
`;

export const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
