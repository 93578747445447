import React from 'react';
import PropTypes from 'prop-types';
import { TypographyHeaderWithLineStyled } from './styles';

export const HeaderWithLineComponent = ({
  centeredLine,
  className,
  component,
  headerText,
}) => (
  <TypographyHeaderWithLineStyled
    className={className}
    component={component}
    variant='h6'
    centeredLine={centeredLine}>
    {headerText}
  </TypographyHeaderWithLineStyled>
);

HeaderWithLineComponent.propTypes = {
  centeredLine: PropTypes.bool,
  /** optional additional className */
  className: PropTypes.string,
  component: PropTypes.string,
  headerText: PropTypes.string.isRequired,
};

HeaderWithLineComponent.defaultProps = { component: 'h3' };

export default HeaderWithLineComponent;
