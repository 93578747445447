import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Typography from '../../base/Typography';

// styles
import {
  QuoteContainer,
  QuoteTypography,
  QuoteFigure,
  QuotationIconStyled,
  CitationTypographyStyled,
  SwiperCarouselSwiperWrapper, SwiperCarouselContainerRow, SwiperCarouselSwiperSlide, SwiperCarouselSwiperSlideRow, SwiperCarouselSwiperSlideCol, SwiperCarouselSwiperPagination, SwiperCarouselSwiperNavigation, SwiperCarouselSwiperNavigationLeftIcon, SwiperCarouselSwiperNavigationRightIcon,
} from './styles';
import './quote.scss';

// Left aligned quotes can ONLY have the left border for decoration

export const QuoteComponent = ({ 'data-static-config': staticConfig }) => {
  const [swiperLoaded, setSwiperLoaded] = useState(false);
  const swiperCarouselConfig = useRef();
  const [retrievedSwiperCarouselConfig, setRetrievedSwiperCarouselConfig] = useState(false); // triggers render to show banner

  useEffect(() => {
    /*
      Because we don't want swiper to bloat the bundle and these charts don't need to be ready on page load,
      we will render the script on mount and load the charts once ready.
    */
    const script = document.createElement('script');

    script.src = 'https://unpkg.com/swiper@6.7.0/swiper-bundle.min.js';
    script.integrity = 'sha384-Y1O5WrIEuc9IhTQYuX3AAtW4jNhdPW/kGCjE/AyulARrGXpDMJYi1WD6UIllTfJ0';
    script.crossOrigin = 'anonymous';
    script.async = true;

    document.body.appendChild(script);
    script.onload = () => {
      setSwiperLoaded(true);
    };

    const link = document.createElement('link');

    link.href = 'https://unpkg.com/swiper@6.7.0/swiper-bundle.min.css';
    link.rel = 'stylesheet';
    link.integrity = 'sha384-hJBk1nqEF9yHIQHQ6cqCLBxzW+Eb4zV4S04M5kR/dG4EDiYTLEoBjGCKD3y5DDlK';
    link.crossOrigin = 'anonymous';

    document.body.appendChild(link);
  }, []);

  useEffect(() => {
    // Initiate Swiper (node slider/carousel library)
    if (retrievedSwiperCarouselConfig && swiperLoaded) {
      const baseOpacity = 0.3;
      const swiperAutoplay = swiperCarouselConfig.current.autoplay
        ? { autoplay: { delay: swiperCarouselConfig.current.autoplayDelay } }
        : {};
      const SwiperContainer = document.querySelector('.swiper-container');

      // eslint-disable-next-line no-unused-vars, no-undef
      const thisSwiperCarousel = new Swiper(SwiperContainer, {
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,
        ...swiperAutoplay,
        speed: 600,
        loop: false, // disable continuous loop
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
        on: {
          init: (swiper) => {
            for (let i = 0; i < swiper.slides.length; i += 1) {
              // eslint-disable-next-line no-param-reassign
              swiper.slides[i].style.opacity = !i ? 1 : baseOpacity;
            }
          },
          transitionStart: (swiper) => {
            SwiperContainer.classList.add('transition');
            for (let i = 0; i < swiper.slides.length; i += 1) {
              // eslint-disable-next-line no-param-reassign
              swiper.slides[i].style.opacity = swiper.activeIndex === i ? 1 : baseOpacity;
            }
          },
          transitionEnd: () => {
            SwiperContainer.classList.remove('transition');
          },
          sliderMove: (swiper) => {
            const slideIndex = Math.floor(swiper.progress * (swiper.slides.length - 1));
            const progress = (swiper.progress * (swiper.slides.length - 1)) - slideIndex;
            if (typeof swiper.slides[slideIndex] !== 'undefined') {
              // eslint-disable-next-line no-param-reassign
              swiper.slides[slideIndex].style.opacity = 1 - ((1 - baseOpacity) * progress);
            }
            if (typeof swiper.slides[slideIndex + 1] !== 'undefined') {
              // eslint-disable-next-line no-param-reassign
              swiper.slides[slideIndex + 1].style.opacity = baseOpacity + ((1 - baseOpacity) * progress);
            }
          },
        },
      });
    }
  }, [retrievedSwiperCarouselConfig, swiperLoaded]);

  useEffect(() => {
    // used by storybook
    if (staticConfig && swiperLoaded) {
      swiperCarouselConfig.current = staticConfig.swiperCarousel;
      setRetrievedSwiperCarouselConfig(true);
    }
    // TODO: dynamicConfig here
  }, [swiperLoaded]);

  return (
    (retrievedSwiperCarouselConfig && (
      <>
        {/* assuming this component will live within a <section> element */}
        {swiperCarouselConfig.current.sectionHeading
          && <Typography component='h2' className='nmx-assistive-text'>{swiperCarouselConfig.current.sectionHeading}</Typography>}
        <QuotationIconStyled />
        <SwiperCarouselContainerRow {...swiperCarouselConfig.current.slides.length > 1 && { className: 'swiper-container' }}>
          <SwiperCarouselSwiperWrapper className='nmx-section-thisSwiperCarousel-items swiper-wrapper'>
            {swiperCarouselConfig.current.slides.map((swiperCarouselItem, index) => (
              <SwiperCarouselSwiperSlide className='swiper-slide' key={`${swiperCarouselItem}${index}`}>
                <SwiperCarouselSwiperSlideRow>
                  <SwiperCarouselSwiperSlideCol xsmall={8} medium={6} align={'center'}>
                    <QuoteFigure
                      style={{
                        textAlign: swiperCarouselItem.data.centered
                          ? 'center'
                          : 'left',
                      }}>
                      <QuoteContainer
                        hasBorder={swiperCarouselItem.data.centered
                          ? false
                          : swiperCarouselItem.data.hasBorder}
                        borderColor={swiperCarouselItem.data.borderColor}>
                        <QuoteTypography
                          color={swiperCarouselItem.data.color}
                          variant="h2"
                          disableBottomPadding
                          {...swiperCarouselItem.data.textProps}>
                          &#34;{swiperCarouselItem.data.text}&#34;
                        </QuoteTypography>
                      </QuoteContainer>
                      {(swiperCarouselItem.data.figcaption || swiperCarouselItem.data.cite) && (
                        <figcaption
                          style={{
                            textAlign: swiperCarouselItem.data.figcaptionCentered
                              ? 'center'
                              : 'left',
                          }}
                        >
                          <CitationTypographyStyled color={swiperCarouselItem.data.color} weight={400} disableBottomPadding>
                            {/*                                   em dash                */}
                            {swiperCarouselItem.data.figcaption && <span>{swiperCarouselItem.data.hasLeadingDash && '—'}{swiperCarouselItem.data.figcaption}</span>}
                            {swiperCarouselItem.data.figcaption && swiperCarouselItem.data.cite && <span>, </span>}
                            {swiperCarouselItem.data.cite && <cite>{swiperCarouselItem.data.cite}</cite>}
                          </CitationTypographyStyled>
                        </figcaption>
                      )}
                    </QuoteFigure>
                  </SwiperCarouselSwiperSlideCol>
                </SwiperCarouselSwiperSlideRow>
              </SwiperCarouselSwiperSlide>
            ))}
          </SwiperCarouselSwiperWrapper>
          {swiperCarouselConfig.current.slides.length > 1
          && <>
            <SwiperCarouselSwiperNavigation className="swiper-button-prev">
              <SwiperCarouselSwiperNavigationLeftIcon />
            </SwiperCarouselSwiperNavigation>
            <SwiperCarouselSwiperNavigation className="swiper-button-next">
              <SwiperCarouselSwiperNavigationRightIcon />
            </SwiperCarouselSwiperNavigation>
            <SwiperCarouselSwiperPagination className="swiper-pagination" />
          </>
          }
        </SwiperCarouselContainerRow>
      </>
    ))
  );
};

QuoteComponent.propTypes = {
  'data-static-config': PropTypes.object,
  'data-app-name': PropTypes.string,
  'data-dynamic-config-base-url': PropTypes.string,
};

export default QuoteComponent;
