import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { searchFactory } from '@nmx/utils/dist/services/SearchService/Frontend';
import Location from '@nmx/utils/dist/utilities/frontend/location';
import Session from '@nmx/utils/dist/utilities/frontend/session';
import AgentCard from './AgentCard';
import DefaultCard from './DefaultCard';
import EnsembleCard from './EnsembleCard';
import CrcCard from './CrcCard';
import Theme from '../../theme';

const DynamicAgentCardComponent = ({
  applicationId,
  agentCardTypeCallback,
  agentObject,
  backgroundColorCallback,
  checkCrc,
  customName,
  defaultCta,
  defaultHeadline,
  defaultImage,
  defaultText,
  defaultSecondaryText,
  config,
  campaign,
  removeDefaultCardForLeads,
  source,
  photoWidth,
  topic,
  searchAnalytics,
}) => {
  const [isEnsemble, setIsEnsemble] = useState(false);
  // if agentObject is passed in, it takes precedence over everything
  const [agentData, setAgentData] = useState(agentObject);
  const [ensembleData, setEnsembleData] = useState();
  const [crcData, setCrcData] = useState({});
  const [isCrcDataValid, setIsCrcDataValid] = useState(false);
  const SearchService = searchFactory({ baseURL: config.services.searchApiBaseUrl });

  const setCRCValues = () => {
    // Check for CRC value in query string
    const crcQueryParam = (Location.getQueryParam('crc') || '').substring(0, 4);
    if (crcQueryParam === 'true' || crcQueryParam === 'True') {
      const crc = true;
      const phone = Location.getQueryParam('no_phone');
      const city = Location.getQueryParam('no_city');

      setCrcData({
        crc,
        phone,
        city,
      });

      setIsCrcDataValid(crc && phone && city);
    }
  };

  const defaultCardNeedsToBeRemoved = () => {
    const localStorageVar = localStorage.getItem('leadConfirmedUser');
    const queryParam = (Location.getQueryParam('cta') || '').substring(0, 4);

    if (queryParam === 'none' || localStorageVar === 'true') {
      // If the user is a lead, set the localStorage var
      localStorage.setItem('leadConfirmedUser', true);

      return true;
    }

    return false;
  };

  useEffect(() => {
    // check special params to see if visitor is orphan
    setCRCValues();

    // if agentObject is passed in, it takes precedence over agentNumber from the Session
    const agentId = Session.get('agentNumber');

    // check for query params and ensure agentObject is undefined
    if (agentId && !agentObject) {
      SearchService.getTeams({
        agentNumber: agentId,
        isEnsemble: true,
      }).then((rsp) => {
        setIsEnsemble(rsp.data.length === 1);
        setEnsembleData(rsp.data[0]);
      });

      SearchService.getFrs({ agentNumber: agentId }).then((rsp) => {
        // rsp.data will be an [] if no data is returned
        setAgentData(rsp.data[0]);
      });
    }
  }, []);

  // default backgroundColor to white
  backgroundColorCallback(Theme.colors.neutral.white);

  if (isEnsemble && ensembleData) {
    agentCardTypeCallback('ensemble');
    return <EnsembleCard ensembleObject={ensembleData} />;
  }

  // Crcs are orphans, they don't have an agent anymore. So show the city and office phone number instead...
  if (checkCrc && isCrcDataValid) {
    backgroundColorCallback(Theme.colors.neutral.gray95);
    return <CrcCard city={crcData.city} phone={crcData.phone} />;
  }

  if (agentData) {
    agentCardTypeCallback('agent');
    return (
      <AgentCard
        applicationId={applicationId}
        key={agentData}
        photoWidth={photoWidth}
        agentObject={agentData}
        config={config}
        customName={customName}
        campaign={campaign}
        source={source}
        topic={topic}
        searchAnalytics={searchAnalytics}
      />
    );
  }

  /*
    we're about to show the DefaultCard, but HOLLLLLL UP.
    We may need this entire section to disappear.
    If business wants us to hide this for visitors meets certain criteria
  */
  if (removeDefaultCardForLeads && defaultCardNeedsToBeRemoved()) {
    return false;
  }

  backgroundColorCallback(Theme.colors.neutral.gray95);
  agentCardTypeCallback('default');
  return (
    <DefaultCard
      defaultImage={defaultImage}
      defaultText={defaultText}
      defaultSecondaryText={defaultSecondaryText}
      defaultHeadline={defaultHeadline}
      defaultCta={defaultCta}
    />
  );
};

DynamicAgentCardComponent.propTypes = {
  /** application Id for the form */
  applicationId: PropTypes.string,
  checkCrc: PropTypes.bool,
  customName: PropTypes.string,
  defaultHeadline: PropTypes.string,
  defaultImage: PropTypes.string,
  defaultText: PropTypes.string,
  defaultSecondaryText: PropTypes.string,
  defaultCta: PropTypes.string,
  removeDefaultCardForLeads: PropTypes.bool,
  photoWidth: PropTypes.number,
  campaign: PropTypes.string,
  source: PropTypes.string,
  topic: PropTypes.string,
  backgroundColorCallback: PropTypes.func,
  agentCardTypeCallback: PropTypes.func,
  agentObject: PropTypes.shape({
    id: PropTypes.string,
    agentNumber: PropTypes.string,
    fullName: PropTypes.string,
    type: PropTypes.string, // MP, MD, etc
    street: PropTypes.string,
    building: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    zipLastFour: PropTypes.string,
    designations: PropTypes.array,
    selectedPhone1: PropTypes.object,
    briefBio: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    photo: PropTypes.string,
    gender: PropTypes.string,
  }),
  config: PropTypes.shape({
    public: PropTypes.shape({ googlePlacesApiKey: PropTypes.string }),
    services: PropTypes.shape({
      serviceRequestApiBaseUrl: PropTypes.string,
      leadApiBaseUrl: PropTypes.string,
    }),
  }),
  searchAnalytics: PropTypes.shape({
    searchTerm: PropTypes.string,
    resultsNumber: PropTypes.string,
  }),
};

DynamicAgentCardComponent.defaultProps = {
  checkCrc: false,
  removeDefaultCardForLeads: false,
  backgroundColorCallback: () => { },
  agentCardTypeCallback: () => { },
  config: {
    public: { googlePlacesApiKey: '<%=googlePlacesApiKey%>' },
    services: {
      serviceRequestApiBaseUrl: '<%=serviceRequestApiBaseUrl%>',
      leadApiBaseUrl: '<%=leadApiBaseUrl%>',
    },
  },
  searchAnalytics: {},
};

export default DynamicAgentCardComponent;
