/** Created manually */
import React from 'react';
import classNames from 'classnames';

class Icon extends React.PureComponent {
  render() {
    const { className, svgRef, ...otherProps } = this.props;
    return (
      <svg version="1.1" height='100' width='100' viewBox="100 100 100 100" className={classNames('nmx-icon', className)} ref={svgRef} {...otherProps}><path d="M191.8 148.4c0-8.4-2.5-16.6-7.2-23.6s-11.3-12.4-19.1-15.7c-7.8-3.2-16.3-4.1-24.6-2.4-8.2 1.6-15.8 5.7-21.8 11.6-5.9 5.9-10 13.5-11.6 21.8-1.6 8.2-.8 16.8 2.4 24.6 3.2 7.8 8.7 14.4 15.7 19.1 7 4.7 15.2 7.2 23.6 7.2 0-5.6 1.1-11.1 3.2-16.3 2.1-5.2 5.3-9.8 9.2-13.8 3.9-3.9 8.6-7.1 13.8-9.2s10.8-3.3 16.4-3.3z" className="icon-fill" fill="#fc5742" />
        <path d="m139 144.9-6.8-5.1-.9 1.2 6.8 5.1.9-1.2zm7.4 5.6-7.4-5.6-.9 1.2 7.4 5.6.9-1.2zm16.1-10.1v5.1h1.5v-5.1h-1.5zm0-3.4v3.4h1.5V137h-1.5zm.8 4.1h6.8v-1.5h-6.8v1.5zm-24-.7v-13.5h-1.5v13.5h1.5zm0 5.1v-5.1h-1.5v5.1h1.5zm-7.5-4.4h6.8v-1.5h-6.8v1.5zm38.8 23.5-14-14.1-1.1 1.1 14 14.1 1.1-1.1zm-38.3 1.1 14.2-14.1-1.1-1.1-14.2 14.1 1.1 1.1zm19.1-11.5-5-3.8-.9 1.2 5 3.8.9-1.2zm18.2-14.4-19.1 14.4.9 1.2 19.1-14.4-.9-1.2zm-37.1 25.3v-24.8H131v24.8h1.5zm37.5-.7h-38.2v1.5H170v-1.5zm-.7-24v24.8h1.5v-24.8h-1.5zm-13.8-3.4v-10.1H154V137h1.5zm7.8-.7h-8.6v1.5h8.6v-1.5zm-9.2-8.9 8.6 10.1 1.1-1-8.6-10.1-1.1 1zm-15.6.2h16.2v-1.5h-16.2v1.5zm7.4 23.5.5-.6-.5.6zm5 3.7-.5.6.5.3.5-.3-.5-.6zm-19.1 10.3h-.8v.8h.8v-.8zm38.2 0v.8h.8v-.8h-.8zm0-24.7h.8v-.8h-.8v.8zm-38.2 0v-.8h-.8v.8h.8zm22.9-3.4h-.7v.8h.8v-.8zm8.6 0h.8v-.3l-.2-.2-.6.5zm-8.6-10.1.6-.5-.2-.3h-.3v.8zm-16.2 0v-.8h-.8v.8h.8z" fill="#fff"/><path d="M191.7 148.4c-5.6 0-11.1 1.1-16.3 3.2-5.2 2.1-9.8 5.3-13.8 9.2-3.9 3.9-7.1 8.6-9.2 13.8-2.1 5.2-3.2 10.7-3.2 16.3l42.5-42.1v-.4z" fill="#edf0f3" />
      </svg>
    );
  }
}

Icon.displayName = 'CircleWithEnvelopeIcon';

export default Icon;
