import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const inputStyles = `
  width: 100%;
  padding: ${rem(16)};
  margin-top: ${rem(8)};
  font-size: 100%;
  line-height: ${rem(18)};
`;

export const InputStyled = styled.input`
  ${inputStyles}
  ${({ theme, customTheme, hasError, isPcg }) => {
    // dark theme in page lead form
    if (customTheme === 'leads') {
      let borderColor = 'rgba(225, 225, 225, 0.8)'; // default
      if (hasError) {
        borderColor = theme.colors.system.lightRed;
      }

      return css`
        background-color: ${isPcg ? theme.colors.pcg.darkBlue5 : theme.colors.inputLeadVariantBG};
        color: ${theme.colors.neutral.white};
        border: 1px solid ${borderColor};
        border-radius: ${rem(2)};
        height: ${rem(55)};
        font-weight: 500;
        letter-spacing: ${rem(1)};
        :hover {
          background-color: ${isPcg ? theme.colors.pcg.darkBlue4 : theme.colors.inputLeadVariantBGHover};
        }
        :focus {
          border: 1px solid ${theme.colors.neutral.white};
        }
        ::placeholder {
          color: ${theme.colors.neutral.gray95};
          font-weight: 400;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus,
        :-webkit-autofill:active,
        :-webkit-autofill::first-line {
          -webkit-box-shadow: 0 0 0 1000px ${isPcg ? theme.colors.pcg.darkBlue5 : theme.colors.inputLeadVariantBG} inset;
          -webkit-text-fill-color: ${theme.colors.neutral.white};
        }
        option {
          background-color: ${isPcg ? theme.colors.pcg.darkBlue5 : theme.colors.inputLeadVariantBG};
        }
      `;
    }
    // error state for light theme inputs
    return css`
      ${hasError && `border: 1px solid ${theme.colors.system.darkRed}`};
    `;
  }}
`;

// TODO: Consider adding a LEADS themed textarea, but it's not needed yet.
export const TextAreaStyled = styled.textarea`
  ${inputStyles}
`;
