/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Col } from '../../../base/Grid';

const hasAdditionalDisclaimers = (disclaimerFootnotes, customDisclaimers) => (disclaimerFootnotes && disclaimerFootnotes.length > 0)
  || (customDisclaimers && customDisclaimers.length > 0);

export const GeneralDisclaimerColStyled = styled(Col)`
  ${({ disclaimerFootnotes, customDisclaimers, theme }) => css`
    padding-top: ${rem(32)};
    padding-bottom: ${rem(16)};
    column-count: ${theme.mediumUp() ? '2' : '1'};
    ${hasAdditionalDisclaimers(disclaimerFootnotes, customDisclaimers)
      && `
        border-top: 1px solid ${theme.colors.neutral.gray58};
      `}
  `}
`;
