import styled, { css } from 'styled-components';

export const BaseImg = styled.img`
  ${({ variant, fullWidth }) => css`
    display: block;
    ${variant === 'secondary' && 'border-radius: 50%;'}
    ${fullWidth ? 'width: 100%;' : 'width: auto;'}
  `}
`;

export const BasePicture = styled.picture`
  display: flex;
`;
