import React from 'react';
import Link from '../../../base/Link';
import { Row } from '../../../base/Grid';
import Typography from '../../../base/Typography';

import { GeneralDisclaimerColStyled } from './styles';

const GeneralDisclaimer = ({ disclaimerFootnotes, customDisclaimers, isAmp }) => (
  <Row align='center'>
    <GeneralDisclaimerColStyled
      disclaimerFootnotes={disclaimerFootnotes}
      customDisclaimers={customDisclaimers}
    >
      <h4 className="nmx-assistive-text">Northwestern Mutual General Disclaimer</h4>
      <Typography component='p'>
        <Typography component='small'>
          Northwestern Mutual is the marketing name for The Northwestern
          Mutual Life Insurance Company and its subsidiaries. Life and
          disability insurance, annuities, and life insurance with
          long&#45;term care benefits are issued by The Northwestern Mutual
          Life Insurance Company, Milwaukee, WI (NM). Long&#45;term care
          insurance is issued by Northwestern Long Term Care Insurance
          Company, Milwaukee, WI, (NLTC) a subsidiary of NM. Investment
          brokerage services are offered through{' '}
          <strong>Northwestern Mutual Investment Services, LLC (NMIS)</strong>{' '}
          a subsidiary of NM, broker&#45;dealer, registered investment
          advisor, and member{' '}
          <strong>
            <Link
              href="https://www.finra.org/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              FINRA
            </Link>
          </strong>{' '}
          and{' '}
          <strong>
            <Link
              href="https://www.sipc.org/"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              SIPC
            </Link>
          </strong>
          . Investment advisory and trust services are offered through
          Northwestern Mutual Wealth Management Company&#174; (NMWMC),
          Milwaukee, WI, a subsidiary of NM and a federal savings bank.
          Products and services referenced are offered and sold only by
          appropriately appointed and licensed entities and financial advisors
          and professionals.{' '}
          <strong>
            Not all products and services are available in all states. Not all
            Northwestern Mutual representatives are advisors. Only those
            representatives with &#34;Advisor&#34; in their title or who
            otherwise disclose their status as an advisor of NMWMC are
            credentialed as NMWMC representatives to provide investment
            advisory services.
          </strong>{' '}
          {isAmp && (
            <a
              href="https://www.google.com/policies/privacy/partners/"
              target="_blank"
              rel="nofollow noopener noreferrer"
              className="nmx-link">
              Google Privacy and Terms.
            </a>
          )}
        </Typography>
      </Typography>
    </GeneralDisclaimerColStyled>
  </Row>
);

export default GeneralDisclaimer;
