import React from 'react';
import PropTypes from 'prop-types';
import LabelStyled from './styles';

export const LabelComponent = React.forwardRef(({
  children,
  label,
  required,
  customTheme,
  inputType,
}, ref) => (
  <LabelStyled
    className='nmxc-label'
    inputType={inputType}
    customTheme={customTheme}>
    <span ref={ref} className='nmx-label'>
      {label}{required && <span className='nmx-label-asterisk-container' aria-hidden='true'>&#42;</span>}
    </span>
    {children}
  </LabelStyled>
));

LabelComponent.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  customTheme: PropTypes.string,
  inputType: PropTypes.string,
};

LabelComponent.defaultProps = {
  required: false,
  customTheme: '',
};

export default LabelComponent;
