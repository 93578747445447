/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// local components
import BreadcrumbsNavigation from './components/BreadcrumbsNavigation';
import SkipNav from './components/SkipNav';
import UserLoginModal from './components/UserLoginModal';
import UserLoginButton from './components/UserLoginButton';
import Search from './components/Search';
import InformationalBanner from '../../modules/InformationalBanner'; // TODO Refactor to work with new changes
import BeachChairWithBorderIcon from '../../icons/illustrative/BeachChairWithBorder';
import ShieldWithHeartBorderIcon from '../../icons/illustrative/ShieldWithHeartBorder';
import MoneyParachuteWithBorderIcon from '../../icons/illustrative/MoneyParachuteWithBorder';
import SearchIcon from '../../icons/utility/Search';
import PaperIcon from '../../icons/utility/PaperFoldedCorner';
import ProfileIcon from '../../icons/utility/ProfileOutline';
import Button from '../../base/Button';
import Link from '../../base/Link';
import AssistiveText from '../../base/AssistiveText';
import { Row, Col } from '../../base/Grid';

import { navScript } from '../../../assets/js/helpers/nav.header';

import {
  ButtonNavLinkUtilitySearchStyled,
  ButtonNavToggleStyled,
  ColNavContainerStyled,
  ColNavUtilityContainerStyled,
  ColNavUtilityFwmContainerStyled,
  ContainerMobileNavStyled,
  DivMobileNavOverlayStyled,
  FwmNavItem,
  FwmNavItemContainer,
  FwmNavLink,
  HeaderStyled,
  LiLoginButtonContainer,
  LiNavLinkSearchContainerStyled,
  NavPrimaryStyled,
  NavUtilityStyled,
  PrimaryHeaderContainerStyled,
} from './styles';

const FAFA_LINK_BUTTON_DEFAULT_PROPS = {
  role: 'menuitem',
  itemProp: 'url',
  className: 'nmx-nav-link nmx-nav-link-utility',
  href: '/find-a-financial-advisor/',
  id: 'nmx-nav-link-utility-fafa',
};

const CLAIMS_LINK_BUTTON_DEFAULT_PROPS = {
  role: 'menuitem',
  itemProp: 'url',
  className: 'nmx-nav-link nmx-nav-link-utility',
  href: '/claims/',
  id: 'nmx-nav-link-utility-claims',
};

export const HeaderComponent = React.memo(({
  breadcrumbsNavigationConfig,
  config,
  dynamicConfig,
  headerIsOverlayed,
  headerLogoEyebrow,
  headerLogoEyebrowLarge,
  headerWithoutBorder,
  hideHeader,
  showInformationalBanner,
  skipNavAside,
  skipNavFooter,
  staticInformationalBannerConfig,
  useFwmNav,
}) => {
  const { basePath, nmxTemplateVersion } = config;
  const theme = useContext(ThemeContext);
  const largeDown = theme.largeDown();
  const smallDown = theme.smallDown();
  const [isMobile, setIsMobile] = useState(largeDown);
  const isSticky = true; // [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    // once component is mounted, we can get the nav js on the page
    navScript(theme.pageLoadWindowWidth, theme.pageLoadWindowHeight);
    setTimeout(() => {
      // remove preload class so animations now happen on mobile nav toggle
      // annimation was triggering on page load, causing CLS score to jump
      // 150 millis to match animation time
      document.body.classList.remove('preload');
    }, 155);
  }, []);

  useEffect(() => {
    if (largeDown !== isMobile) {
      setIsMobile(largeDown);
    }
  }, [largeDown]);

  const headerStates = {
    isHidden: hideHeader,
    isOverlayed: headerIsOverlayed,
    isLogoEyebrow: hideHeader && headerLogoEyebrow,
    isLogoEyebrowLarge:
      hideHeader && headerLogoEyebrow && headerLogoEyebrowLarge,
    isWithoutBorder: headerWithoutBorder,
  };

  return (
    <>
      <HeaderStyled
        hasBreadcrumbsNavigation={breadcrumbsNavigationConfig}
        headerStates={headerStates}
        nmxTemplateVersion={nmxTemplateVersion}
        basePath={basePath}
        hasFwmNav={useFwmNav}
        id="nmx-header"
        className={classNames(
          'nmx-header',
          'nmx-base',
          { 'is-logo-only': headerStates.isHidden },
          { 'is-overlayed': headerStates.isOverlayed },
          { 'has-logo-eyebrow': headerStates.isLogoEyebrow },
          { 'has-logo-eyebrow-large': headerStates.isLogoEyebrowLarge },
          { 'without-border': headerStates.isWithoutBorder },
        )}
        showInformationalBanner={{ ...(showInformationalBanner && (!dynamicConfig || (dynamicConfig && dynamicConfig.banner && dynamicConfig.banner.isActive))) }}
      >
        <PrimaryHeaderContainerStyled isSticky={isSticky}>
          <SkipNav skipNavAside={skipNavAside} skipNavFooter={skipNavFooter} />
          {(showInformationalBanner && (!dynamicConfig || (dynamicConfig && dynamicConfig.banner && dynamicConfig.banner.isActive))) && (
            <div className="nmx-informational-banner">
              <InformationalBanner
                data-banner-id="nmx-informational-banner"
                data-app-name={config.appName}
                data-dynamic-config-base-url={config.dynamicConfigBaseUrl}
                data-static-config={(dynamicConfig && dynamicConfig.banner) ? { informationalBanner: dynamicConfig.banner } : null}
                {...staticInformationalBannerConfig}
              />
            </div>
          )}
          {!hideHeader && (
            <div className="nmx-header-mobile-container">
              {/* TODO: rename aria-label value to open/close */}
              <ButtonNavToggleStyled
                id="nav-toggle"
                className="nmx-button"
                aria-label="Toggle Mobile Menu">
                <span className="nav-toggle-bar"></span>
              </ButtonNavToggleStyled>
              <DivMobileNavOverlayStyled className="nmx-mobile-nav-overlay" />
            </div>
          )}
          <ContainerMobileNavStyled
            className="nmx-container-inner"
            id="nmx-mobile-nav-container"
            isSticky={isSticky}>
            <Row className="nmx-row">
              <ColNavContainerStyled className="nmx-col nmx-nav-primary-container">
                <h6
                  className="nmx-header-logo-container"
                  id="header-logo-heading">
                  <a className="nmx-header-logo" href="/" id="header-logo">
                    Northwestern Mutual
                    {/* TODO: clean up SVG markup (change "NW" to "nm" clean up IDs, descriptions, titles, etc.) */}
                  </a>
                  <meta
                    itemProp="image"
                    content={`${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-navy.svg`}
                  />
                  <img
                    src={`${basePath}/template/assets/${nmxTemplateVersion}/images/logo-horizontal-navy.svg`}
                    className="nmx-print"
                    alt="Northwestern Mutual"
                  />
                </h6>
                {/* remove non-applied DOM elements if logo-only header */}
                {!hideHeader && (
                  <NavPrimaryStyled
                    nmxTemplateVersion={nmxTemplateVersion}
                    basePath={basePath}
                    itemScope
                    itemType="https://schema.org/SiteNavigationElement"
                    className="nmx-nav-primary"
                    id="nmx-nav-primary">
                    <AssistiveText text="Primary Navigation" component="h6" />
                    <ul role="menubar" className="reduced">
                      <li
                        role="none"
                        className="nmx-nav-link-container nmx-nav-link-primary-container nmx-nav-link-mobile-primary-home-container">
                        <a
                          role="menuitem"
                          tabIndex="-1"
                          className="nmx-nav-link nmx-nav-link-mobile-primary-home"
                          href={`${basePath}/`}>
                          Home
                        </a>
                      </li>
                      <li
                        role="none"
                        itemProp="name"
                        className="nmx-nav-link-container nmx-nav-link-primary-container">
                        <a
                          role="menuitem"
                          itemProp="url"
                          aria-haspopup="true"
                          className="nmx-nav-link nmx-nav-link-primary"
                          href={`${basePath}/who-we-are/`}
                          id="nmx-nav-link-primary-who-we-are"
                          data-subnav-active="false"
                          aria-expanded="false">
                          About Us
                        </a>
                        <ul
                          role="menu"
                          className="reduced nmx-subnav nmx-subnav-who-we-are"
                          aria-label="About Us Submenu">
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-back-container">
                            <Button
                              id="button-mobile-sub-nav-back-to-main-menu"
                              role="menuitem"
                              variant="tertiary"
                              tabIndex="-1"
                              className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back">
                              Back to main menu
                              <span className="nmx-nav-mobile-subnav-bg"></span>
                            </Button>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                            <a
                              role="menuitem"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                              href={`${basePath}/who-we-are/`}>
                              About Us Overview
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/financial-advisor/"
                              id="nmx-nav-link-primary-sub-financial-advisor"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-who-we-are">
                              Working With an Advisor
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/financial-information/"
                              id="nmx-nav-link-primary-sub-our-financial-strength"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-who-we-are">
                              Our Financial Strength
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/who-we-are/sustainability-report/"
                              id="nmx-nav-link-primary-sub-sustainability-report-landing"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-who-we-are">
                              Sustainability Report
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        role="none"
                        itemProp="name"
                        className="nmx-nav-link-container nmx-nav-link-primary-container">
                        <a
                          role="menuitem"
                          itemProp="url"
                          aria-haspopup="true"
                          className="nmx-nav-link nmx-nav-link-primary"
                          href="/financial-planning/"
                          id="nmx-nav-link-primary-financial-planning"
                          data-subnav-active="false"
                          aria-expanded="false">
                          Financial Planning
                        </a>
                        <ul
                          role="menu"
                          className="reduced nmx-subnav nmx-subnav-financial-planning"
                          aria-label="Planning Services Submenu">
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-back-container">
                            <Button
                              id="button-sub-nav-back-to-main-menu"
                              role="menuitem"
                              variant="tertiary"
                              tabIndex="-1"
                              className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back">
                              Back to main menu
                              <span className="nmx-nav-mobile-subnav-bg"></span>
                            </Button>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                            <a
                              role="menuitem"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                              href={`${basePath}/financial-planning/`}>
                              Financial Planning Overview
                            </a>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-primary-sub-container">
                            <a
                              role="menuitem"
                              href="/retirement-planning/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning has-subnav-level2"
                              id="nmx-nav-link-primary-sub-retirement-planning"
                              aria-haspopup="true"
                              aria-expanded="false">
                              Retirement Planning
                            </a>
                            <ul
                              role="menu"
                              className="reduced nmx-subnav-level2 nmx-subnav-level2-retirement-planning"
                              aria-label="Retirement Planning Submenu">
                              <li
                                role="none"
                                className="nmx-nav-link-container nmx-nav-link-mobile-level2-back-container">
                                <Button
                                  id="button-sub-nav-back-to-financial-planning"
                                  variant="tertiary"
                                  role="menuitem"
                                  tabIndex="-1"
                                  className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub-level2--first nmx-nav-link-mobile-level2-back">
                                  Back to Planning Services
                                  <span className="nmx-nav-mobile-subnav-bg"></span>
                                </Button>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                                <a
                                  role="menuitem"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                                  href={`${basePath}/retirement-planning/`}>
                                  Retirement Planning Overview
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/retirement-calculator/"
                                  id="nmx-nav-link-primary-sub-level2-retirement-calculator"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-nav-link-primary-sub-level2--last nmx-subnav-level2-item-retirement-planning has-icon">
                                  Retirement Calculator{' '}
                                  <BeachChairWithBorderIcon />
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/college-savings-plans/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning"
                              id="nmx-nav-link-primary-sub-college-savings-plans">
                              College Savings Plans
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/private-wealth-management/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning"
                              id="nmx-nav-link-primary-sub-private-wealth-management">
                              Private Wealth Management
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/financial-planning/estate-planning/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning"
                              id="nmx-nav-link-primary-sub-estate-planning">
                              Estate Planning
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/long-term-care-planning/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-financial-planning"
                              id="nmx-nav-link-primary-sub-financial-planning-long-term-care">
                              Long&#45;Term Care
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/financial-planning/business-services/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-financial-planning"
                              id="nmx-nav-link-primary-sub-business-services">
                              Business Services
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        role="none"
                        itemProp="name"
                        className="nmx-nav-link-container nmx-nav-link-primary-container">
                        <a
                          role="menuitem"
                          itemProp="url"
                          aria-haspopup="true"
                          className="nmx-nav-link nmx-nav-link-primary"
                          href="/insurance/"
                          id="nmx-nav-link-primary-insurance"
                          data-subnav-active="false"
                          aria-expanded="false">
                          Insurance
                        </a>
                        <ul
                          role="menu"
                          className="reduced nmx-subnav nmx-subnav-insurance"
                          aria-label="Insurance Submenu">
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-back-container">
                            <Button
                              id="button-sub-nav-insurance-back-to-main-menu"
                              role="menuitem"
                              variant="tertiary"
                              tabIndex="-1"
                              className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back">
                              Back to main menu
                              <span className="nmx-nav-mobile-subnav-bg"></span>
                            </Button>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                            <a
                              role="menuitem"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                              href={`${basePath}/insurance/`}>
                              Insurance Overview
                            </a>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-primary-sub-container">
                            <a
                              role="menuitem"
                              href="/life-insurance/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-insurance has-subnav-level2"
                              id="nmx-nav-link-primary-sub-life-insurance"
                              aria-haspopup="true"
                              aria-expanded="false">
                              Life Insurance
                            </a>
                            <ul
                              role="menu"
                              className="reduced nmx-subnav-level2 nmx-subnav-level2-life-insurance"
                              aria-label="Life Insurance Submenu">
                              <li
                                role="none"
                                className="nmx-nav-link-container nmx-nav-link-mobile-level2-back-container">
                                <Button
                                  id="button-sub-nav-back-to-insurance"
                                  variant="tertiary"
                                  role="menuitem"
                                  tabIndex="-1"
                                  className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub-level2--first nmx-nav-link-mobile-level2-back">
                                  Back to Insurance
                                  <span className="nmx-nav-mobile-subnav-bg"></span>
                                </Button>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                                <a
                                  role="menuitem"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                                  href={`${basePath}/life-insurance/`}>
                                  Life Insurance Overview
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/life-insurance/whole-life-insurance/"
                                  id="nmx-nav-link-primary-sub-level2-whole-life-insurance"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance">
                                  Whole Life Insurance
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/life-insurance/universal-life-insurance/"
                                  id="nmx-nav-link-primary-sub-level2-universal-life-insurance"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance">
                                  Universal Life Insurance
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/life-insurance/variable-universal-life-insurance-vul/"
                                  id="nmx-nav-link-primary-sub-level2-variable-universal-life-insurance"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance">
                                  Variable Universal Life Insurance
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/life-insurance/term-life-insurance/"
                                  id="nmx-nav-link-primary-sub-level2-term-life-insurance"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-life-insurance">
                                  Term Life Insurance
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/life-insurance/life-insurance-calculator/"
                                  id="nmx-nav-link-primary-sub-level2-life-insurance-calculator"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-nav-link-primary-sub-level2--last nmx-subnav-level2-item-life-insurance has-icon">
                                  Life Insurance Calculator
                                  <ShieldWithHeartBorderIcon />
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-primary-sub-container">
                            <a
                              role="menuitem"
                              href="/disability-insurance/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-insurance has-subnav-level2"
                              id="nmx-nav-link-primary-sub-disability-insurance"
                              aria-haspopup="true"
                              aria-expanded="false">
                              Disability Insurance
                            </a>
                            <ul
                              role="menu"
                              className="reduced nmx-subnav-level2 nmx-subnav-level2-disability-insurance"
                              aria-label="Disability Insurance Submenu">
                              <li
                                role="none"
                                className="nmx-nav-link-container nmx-nav-link-mobile-level2-back-container">
                                <Button
                                  id="button-sub-nav-back-to-disability-insurance"
                                  variant="tertiary"
                                  role="menuitem"
                                  tabIndex="-1"
                                  className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub-level2--first nmx-nav-link-mobile-level2-back">
                                  Back to Insurance
                                  <span className="nmx-nav-mobile-subnav-bg"></span>
                                </Button>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                                <a
                                  role="menuitem"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                                  href={`${basePath}/disability-insurance/`}>
                                  Disability Insurance Overview
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/disability-insurance/"
                                  id="nmx-nav-link-primary-sub-level2-for-individuals"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-disability-insurance">
                                  For Individuals
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/disability-insurance/physicians-and-dentists-disability-insurance/"
                                  id="nmx-nav-link-primary-sub-level2-for-doctors-and-dentists"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-subnav-level2-item-disability-insurance">
                                  For Doctors and Dentists
                                </a>
                              </li>
                              <li
                                role="none"
                                className="nmx-nav-link-container">
                                <a
                                  role="menuitem"
                                  href="/disability-insurance/disability-insurance-calculator/"
                                  id="nmx-nav-link-primary-sub-level2-disability-insurance-calculator"
                                  tabIndex="-1"
                                  className="nmx-nav-link nmx-nav-link-primary-sub-level2 nmx-nav-link-primary-sub-level2--last nmx-subnav-level2-item-disability-insurance has-icon">
                                  Disability Insurance Calculator{' '}
                                  <MoneyParachuteWithBorderIcon />
                                </a>
                              </li>
                            </ul>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/long-term-care-planning/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-insurance"
                              id="nmx-nav-link-primary-sub-insurance-long-term-care">
                              Long&#45;Term Care
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/annuities/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-insurance"
                              id="nmx-nav-link-primary-sub-income-annuities">
                              Income Annuities
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        role="none"
                        itemProp="name"
                        className="nmx-nav-link-container nmx-nav-link-primary-container">
                        <a
                          role="menuitem"
                          itemProp="url"
                          aria-haspopup="true"
                          className="nmx-nav-link nmx-nav-link-primary"
                          href="/investments/"
                          id="nmx-nav-link-primary-investments"
                          data-subnav-active="false"
                          aria-expanded="false">
                          Investments
                        </a>
                        <ul
                          role="menu"
                          className="reduced nmx-subnav nmx-subnav-investments"
                          aria-label="Investments Submenu">
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-back-container">
                            <Button
                              id="button-sub-nav-back-to-investments"
                              role="menuitem"
                              variant="tertiary"
                              tabIndex="-1"
                              className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back">
                              Back to main menu
                              <span className="nmx-nav-mobile-subnav-bg"></span>
                            </Button>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                            <a
                              role="menuitem"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                              href={`${basePath}/investments/`}>
                              Investments Overview
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/investments/brokerage-accounts-services/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments"
                              id="nmx-nav-link-primary-sub-brokerage-accounts-services">
                              Brokerage Accounts &#38; Services
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/private-wealth-management/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments"
                              id="nmx-nav-link-primary-sub-investments-private-wealth-management">
                              Private Wealth Management
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/investments/investment-advisory-services/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments"
                              id="nmx-nav-link-primary-sub-investment-advisory-services">
                              Investment Advisory Services
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/investments/fixed-annuity-vs-variable-annuity/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments"
                              id="nmx-nav-link-primary-sub-fixed-annuity-vs-variable-annuity">
                              Fixed &#38; Variable Annuities
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/investments/private-client-services-wealth-management/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-investments"
                              id="nmx-nav-link-primary-sub-private-client-services-wealth-management">
                              Private Client Services
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/market-commentary/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub--last nmx-nav-link-primary-sub nmx-nav-link-primary-sub--last nmx-subnav-item-investments"
                              id="nmx-nav-link-primary-sub-investments-market-commentary">
                              Market Commentary
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li
                        role="none"
                        itemProp="name"
                        className="nmx-nav-link-container nmx-nav-link-primary-container">
                        <a
                          role="menuitem"
                          itemProp="url"
                          aria-haspopup="true"
                          className="nmx-nav-link nmx-nav-link-primary"
                          href="/life-and-money/"
                          id="nmx-nav-link-primary-life-and-money"
                          data-subnav-active="false"
                          aria-expanded="false">
                          Life &#38; Money
                        </a>
                        <ul
                          role="menu"
                          className="reduced nmx-subnav nmx-subnav-life-and-money"
                          aria-label="Life &#38; Money Submenu">
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-back-container">
                            <Button
                              id="button-sub-nav-back-to-life-and-money"
                              role="menuitem"
                              variant="tertiary"
                              tabIndex="-1"
                              className="nmx-button nmx-button--tertiary nmx-nav-link nmx-nav-link-primary-sub--first nmx-nav-link-mobile-back">
                              Back to main menu
                              <span className="nmx-nav-mobile-subnav-bg"></span>
                            </Button>
                          </li>
                          <li
                            role="none"
                            className="nmx-nav-link-container nmx-nav-link-mobile-subnav-overview-container">
                            <a
                              role="menuitem"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-mobile-subnav-overview"
                              href={`${basePath}/life-and-money/`}>
                              Life &#38; Money Overview
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/financial-planning/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money"
                              id="nmx-nav-link-primary-sub-financial-planning">
                              Financial Planning
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/investing/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money"
                              id="nmx-nav-link-primary-sub-investing">
                              Investing
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/insurance/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money"
                              id="nmx-nav-link-primary-sub-insurance">
                              Insurance
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/everyday-money/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money"
                              id="nmx-nav-link-primary-sub-everyday-money">
                              Everyday Money
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/family-and-work/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money"
                              id="nmx-nav-link-primary-sub-family-and-work">
                              Family &amp; Work
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/life-and-money/market-commentary/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money"
                              id="nmx-nav-link-primary-sub-life-and-money-market-commentary">
                              Market Commentary
                            </a>
                          </li>
                          <li role="none" className="nmx-nav-link-container">
                            <a
                              role="menuitem"
                              href="/podcast/"
                              tabIndex="-1"
                              className="nmx-nav-link nmx-nav-link-primary-sub nmx-subnav-item-life-and-money nmx-nav-link-primary-sub--last"
                              id="nmx-nav-link-primary-sub-life-and-money-podcast">
                              Podcast
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </NavPrimaryStyled>
                )}
              </ColNavContainerStyled>
            </Row>
            {/* remove non-applied DOM elements if logo-only header */}
            {/* {!hideHeader && <UtilityNavigation config={config} />} */}
            {useFwmNav && (
              <>
                <Row className="nmx-row nmx-row--align-right" style={{ height: '0' }}>
                  <ColNavUtilityFwmContainerStyled>
                    <FwmNavItemContainer className="reduced">
                      <FwmNavItem>
                        <FwmNavLink
                          {...CLAIMS_LINK_BUTTON_DEFAULT_PROPS}
                          style={{
                            color: '#4a4a4a',
                            display: 'flex',
                            'align-items': 'center',
                          }}
                        >
                          {!smallDown && <PaperIcon style={{ marginRight: '3px' }} fill="#4a4a4a" />}
                          <span>CLAIMS</span>
                        </FwmNavLink>
                      </FwmNavItem>
                      <FwmNavItem>
                        {!smallDown && <ProfileIcon height={20} width={20} />}
                        <UserLoginButton config={config} variant='fwm' />
                      </FwmNavItem>
                    </FwmNavItemContainer>
                  </ColNavUtilityFwmContainerStyled>
                </Row>
              </>
            )}
            {!hideHeader && (
              <>
                <Row className="nmx-row nmx-row--align-right">
                  <ColNavUtilityContainerStyled
                    className="nmx-col nmx-nav-utility-container"
                    id="nav-utility-container">
                    <NavUtilityStyled
                      itemScope
                      itemType="https://schema.org/SiteNavigationElement"
                      className="nmx-nav-utility"
                      id="nmx-nav-utility">
                      <AssistiveText text="Utility Navigation" component="h6" />
                      <ul role="menu" className="reduced">
                        <LiLoginButtonContainer
                          role="none"
                          itemProp="name"
                          className="nmx-nav-link-utility-container"
                          id="nmx-nav-link-login-button-container">
                          <div className="nmx-login-button">
                            <UserLoginButton config={config} />
                          </div>
                        </LiLoginButtonContainer>
                        <li
                          role="none"
                          itemProp="name"
                          className="nmx-nav-link-utility-container nmx-nav-link-utility-container-mobile-button">
                          {isMobile ? (
                            <Link
                              {...FAFA_LINK_BUTTON_DEFAULT_PROPS}
                              variant="buttonSecondary">
                              Find a Financial Advisor
                            </Link>
                          ) : (
                            <a {...CLAIMS_LINK_BUTTON_DEFAULT_PROPS}>Claims</a>
                          )}
                        </li>
                        <li
                          role="none"
                          itemProp="name"
                          className="nmx-nav-link-utility-container nmx-nav-link-utility-container-mobile-button"
                          id="nmx-nav-link-fafa-link-container">
                          {isMobile ? (
                            <Link
                              {...CLAIMS_LINK_BUTTON_DEFAULT_PROPS}
                              variant="buttonTertiary">
                              Claims
                            </Link>
                          ) : (
                            <Link {...FAFA_LINK_BUTTON_DEFAULT_PROPS}
                              variant="buttonPrimary">
                              Find a Financial Advisor
                            </Link>
                          )}
                        </li>
                        <LiNavLinkSearchContainerStyled
                          role="none"
                          itemProp="name"
                          className="nmx-nav-link-utility-container"
                          id="nmx-nav-link-search-container">
                          <ButtonNavLinkUtilitySearchStyled
                            role="menuitem"
                            className="nmx-button nmx-nav-link nmx-nav-link-utility"
                            id="nmx-nav-link-utility-search">
                            Search
                            <SearchIcon className="nmx-icon-search" />
                          </ButtonNavLinkUtilitySearchStyled>
                        </LiNavLinkSearchContainerStyled>
                      </ul>
                    </NavUtilityStyled>
                  </ColNavUtilityContainerStyled>
                </Row>
                <Row className="nmx-row">
                  <Col className="nmx-col">
                    <div id="nmx-search-console">
                      <Search id="nmx-search-console" config={config} pageLoadWindowHeight={theme.pageLoadWindowHeight} />
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </ContainerMobileNavStyled>
        </PrimaryHeaderContainerStyled>
        {breadcrumbsNavigationConfig
          && <BreadcrumbsNavigation
            config={breadcrumbsNavigationConfig}
            showInformationalBanner={{ ...(showInformationalBanner && (!dynamicConfig || (dynamicConfig && dynamicConfig.banner && dynamicConfig.banner.isActive))) }}
          />
        }
      </HeaderStyled>

      {/* remove non-applied DOM elements if logo-only header */}
      {(!hideHeader || useFwmNav) && <UserLoginModal config={config} />}
    </>
  );
});

HeaderComponent.propTypes = {
  /** Config vars used in footer */
  breadcrumbsNavigationConfig: PropTypes.object,
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    public: PropTypes.shape({
      googlePlacesApiKey: PropTypes.string,
      googleAnalyticsTrackingId: PropTypes.string,
    }).isRequired,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
      userLogoutUrls: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dynamicConfig: PropTypes.object,
  hideHeader: PropTypes.bool,
  headerIsOverlayed: PropTypes.bool,
  headerLogoEyebrow: PropTypes.bool,
  headerLogoEyebrowLarge: PropTypes.bool,
  headerWithoutBorder: PropTypes.bool,
  showInformationalBanner: PropTypes.bool,
  skipNavAside: PropTypes.bool,
  skipNavFooter: PropTypes.bool,
};

HeaderComponent.defaultProps = {
  hideHeader: false,
  headerIsOverlayed: false,
  headerWithoutBorder: false,
  showInformationalBanner: false,
  skipNavAside: false,
  skipNavFooter: false,
};

export default HeaderComponent;
