import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Session from '@nmx/utils/dist/utilities/frontend/session';
import { withTheme } from 'styled-components';
import Typography from '../../base/Typography';

// styles
import { StickyBannerContainer, StickyBannerInnerRow, StickyBannerInnerCol, StickyBannerIcon, StickyBannerLink, DismissButton } from './styles';
import './stickyBanner.scss';

export const StickyBannerModule = ({
  'data-app-name': appName = 'default',
  'data-static-config': staticConfig,
  'data-stickyBanner-id': stickyBannerId = 'nmx-sticky-banner',
}) => {
  // external els
  const nmxInformationalBanner = document.getElementById('nmx-informational-banner');

  // constants
  const SLIDE_IN_TIMER_COUNTDOWN = 10; // slide-in timer countdown (seconds)
  const DISMISSED_SESSION_KEY = `${appName}-dismiss-sticky-banner`;

  const bannerConfig = useRef();
  const [slideInCountdown, setSlideInCountdown] = useState(SLIDE_IN_TIMER_COUNTDOWN); // timer
  const [retrievedBannerConfig, setRetrievedBannerConfig] = useState(false); // triggers render to show banner
  const [stickyBannerDismissed, setStickyBannerDismissed] = useState(false);

  useEffect(() => {
    if (staticConfig) {
      // storybook/static config
      bannerConfig.current = staticConfig.stickyBanner;
      if (Session.get(DISMISSED_SESSION_KEY) !== undefined) {
        setStickyBannerDismissed(Session.get(DISMISSED_SESSION_KEY));
      }
      setRetrievedBannerConfig(true);
      return;
    }
    // hardcode config
    bannerConfig.current = {
      data: {
        icon: 'woman-word-bubble-lightbulb', // TODO: needs to be illustrative-secondary (dark theme)
        text: 'Get your free financial plan.',
        ctaLinkText: 'Connect with an advisor',
        ctaLinkUrl: '/find-a-financial-advisor/',
        ctaLinkAriaLabel: 'Find out more about receiving a free financial plan by connecting with an advisor',
      },
      isActive: true,
    }; // TODO: add dynamicConfig from nmx-brains?
    setRetrievedBannerConfig(true);
    if (Session.get(DISMISSED_SESSION_KEY) !== undefined) {
      setStickyBannerDismissed(Session.get(DISMISSED_SESSION_KEY));
    }
  }, []);

  useEffect(() => {
    // countdown timer hook

    // kill countdown timer when reaches 0
    if (!slideInCountdown) return;

    // save intervalId to clear the interval when the component re-renders
    const intervalId = setInterval(() => {
      setSlideInCountdown(slideInCountdown - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    // eslint-disable-next-line consistent-return
    return () => clearInterval(intervalId);
  }, [slideInCountdown]);

  function dismissStickyBanner() {
    setStickyBannerDismissed(true);
    Session.set(DISMISSED_SESSION_KEY, true);
  }

  return (
    (retrievedBannerConfig && bannerConfig.current.isActive === true && !stickyBannerDismissed) && (
      <StickyBannerContainer id={stickyBannerId} className='nmx-sticky-banner' isVisible={slideInCountdown === 0} informationalBannerHeight={nmxInformationalBanner && nmxInformationalBanner.clientHeight}>
        <StickyBannerInnerRow>
          <StickyBannerInnerCol xsmall={2}>
            <StickyBannerIcon variant={bannerConfig.current.data.icon} />
          </StickyBannerInnerCol>
          <StickyBannerInnerCol xsmall={10} variant='col-right'>
            <Typography variant='p' color={'color-neutral-white'} disableBottomPadding className='nmx-sticky-banner--text'>
              {bannerConfig.current.data.text}&nbsp;
              <StickyBannerLink
                id={'nmx-sticky-banner-link'}
                variant="secondary"
                className='nmx-link--stand-alone'
                href={bannerConfig.current.data.ctaLinkUrl}
                {...bannerConfig.current.data.ctaLinkAriaLabel !== '' && { ariaLabel: bannerConfig.current.data.ctaLinkAriaLabel }}
              >
                {bannerConfig.current.data.ctaLinkText}
              </StickyBannerLink>
            </Typography>
          </StickyBannerInnerCol>
        </StickyBannerInnerRow>
        <DismissButton onClick={dismissStickyBanner} aria-label="Dismiss Sticky Banner" />
      </StickyBannerContainer>
    )
  );
};

StickyBannerModule.propTypes = {
  'data-stickyBanner-id': PropTypes.string,
  'data-static-config': PropTypes.object,
  'data-app-name': PropTypes.string,
};

export default withTheme(StickyBannerModule);
