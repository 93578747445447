import React from 'react';
import PropTypes from 'prop-types';
import BaseList from './styles';

const ListComponent = ({ reduced, inline, children, ...rest }) => (
  <BaseList
    reduced={reduced}
    inline={inline}
    {...rest}>
    {children}
  </BaseList>
);

ListComponent.propTypes = {
  children: PropTypes.node.isRequired,
  reduced: PropTypes.bool,
  inline: PropTypes.bool,
};

ListComponent.defaultProps = {
  reduced: true,
  inline: false,
};

export default ListComponent;
