import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import ChevronLeftNarrowIcon from '../../icons/utility/ChevronLeftNarrow';
import ChevronRightNarrowIcon from '../../icons/utility/ChevronRightNarrow';
import QuotationIcon from '../../icons/illustrative/Quotation';
import { Row, Col } from '../../base/Grid';
import Typography from '../../base/Typography';

export const QuoteContainer = styled.blockquote`
  /* With the border */
  && {
    ${({ theme, borderColor, hasBorder }) => (hasBorder
    && css`
      border-left: ${rem('5px')}; solid ${borderColor};
      padding-left: ${theme.spacing(3).rem()};

    `)}
  }
  /* default */
  && {
      margin-left: 0;
      margin-right: 0;
      padding-bottom: ${rem(10)};
  }
`;

export const QuoteTypography = styled(Typography)`
  &&& {
    ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    return css`
      font-size: ${mediumUp ? rem(28) : rem(18)};
      line-height: ${mediumUp ? rem(40) : rem(24)};
      font-weight: 200;
      font-style: italic;
    `;
  }
}`;

export const QuoteFigure = styled.figure`
  ${({ theme }) => css`
      margin: 0;

      .icon-fill {
        fill: ${theme.colors.accent.lightBlue2}
      }
      @media print {
        .icon-fill {
          fill: ${theme.colors.neutral.black}
        }
      }
    `}
`;

export const CitationTypographyStyled = styled(Typography)`
  font-size: 1rem;
`;

export const QuotationIconStyled = styled(QuotationIcon)`
  ${({ theme }) => css`
    display: block;
    margin: 0 auto ${rem(8)};
    .icon-fill {
      fill: ${theme.colors.accent.lightBlue2}
    }
    @media print {
      .icon-fill {
        fill: ${theme.colors.neutral.black}
      }
    }
  `}
`;

export const SwiperCarouselContainerRow = styled(Row)`
  && {
    width: 100%;
    height: 100%;
    padding-bottom: ${rem(40)};
  }
`;

export const SwiperCarouselSwiperWrapper = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`;

export const SwiperCarouselSwiperSlide = styled.div`
  display: flex;
  justify-content: center;
`;

export const SwiperCarouselSwiperSlideRow = styled(Row)`
  width: 100%;
  justify-content: center;
`;

export const SwiperCarouselSwiperSlideCol = styled(Col)`
  ${({ theme }) => {
    let thisFlex = '0 75%';
    let thisMaxWidth = '75%';
    if (theme.mediumUp()) {
      thisFlex = '0 66.66666666666666%';
      thisMaxWidth = '66.66666666666666%';
    }
    return css`
      flex: ${thisFlex};
      max-width: ${thisMaxWidth};
    `;
  }}
`;

export const SwiperCarouselSwiperPagination = styled.div`
  &&& {
    width: 100%;
    bottom: ${rem(40)};
  }
`;

export const SwiperCarouselSwiperNavigation = styled.div`
  ${({ theme }) => css`
    && {
      display: block;
      width: ${rem(17)};
      height: ${theme.xsmallUp() ? rem(61) : '100%'};
      top: ${theme.xsmallUp() ? '45%' : 0};
      background-color: ${theme.xsmallUp() ? 'unset' : 'rgba(255,255,255,0.3)'};
      &::after {
        color: ${theme.colors.neutral.gray54};
        visibility: hidden;
      }
    }
  `}
`;

export const SwiperCarouselSwiperNavigationLeftIcon = styled(ChevronLeftNarrowIcon)`
  ${({ theme }) => css`
    .icon-stroke {
      fill: none; /** TODO: reexamine build-icons to handle svg polyline stroke and fill */
      stroke: ${theme.colors.neutral.gray54};
    }
    :hover {
      .icon-stroke {
        stroke: ${theme.colors.cta.blue};
      }
    }
  `}
`;

export const SwiperCarouselSwiperNavigationRightIcon = styled(ChevronRightNarrowIcon)`
  ${({ theme }) => css`
    .icon-stroke {
      fill: none; /** TODO: reexamine build-icons to handle svg polyline stroke and fill */
      stroke: ${theme.colors.neutral.gray54};
    }
    :hover {
      .icon-stroke {
        stroke: ${theme.colors.cta.blue};
      }
    }
  `}
`;
