import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Row } from '../../../base/Grid/grid';
import ProductResourceCallout from '../ProductResourceCallout/productResourceCallout';

import {
  ColProductResourcesStyled,
  ProductResourcesViewContainer,
  TypographyResourcesViewHeadingStyled,
} from './styles';

export const ProductResourcesViewComponent = ({
  heading,
  callouts,
  id,
}) => (
  <ProductResourcesViewContainer id={id}>
    <Row align='center'>
      <TypographyResourcesViewHeadingStyled
        component='h3'
        variant='h4'>
        {heading}
      </TypographyResourcesViewHeadingStyled>
    </Row>
    <Row align='center'>
      {
        callouts.map((callout) => (
          <ColProductResourcesStyled
            xsmall={12}
            medium={4}
            key={callout.linkId}>
            <ProductResourceCallout
              icon={callout.icon}
              text={callout.text}
              linkText={callout.linkText}
              linkPath={callout.linkPath}
              linkId={callout.linkId}
              ariaLabel={callout.ariaLabel}
            />
          </ColProductResourcesStyled>
        ))
      }
    </Row>
  </ProductResourcesViewContainer>
);

ProductResourcesViewComponent.propTypes = {
  callouts: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default withTheme(ProductResourcesViewComponent);
