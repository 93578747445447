/**
 * NAMES
 * ---
 * These are all values that get used directly in the `window.datalayer.{...}` function calls, generally in
 * props/fields labelled "name:", thus the naming of this file
 */

/**
 * The names of push events for the data layer
 * ---
 * These values are sent over to the Adobe Data Layer, so beware when changing them, just like all other constants
 * prefixed by `NAME_`
 */
var NAME_PUSH_EVENTS = {
  FORM_LOAD: 'form-load',
  FORM_INTERACT: 'form-interact',
  FORM_COMPLETE: 'form-complete',
  FORM_COMPLETE_FAILURE: 'form-complete-failure',
  // Generic page interaction
  PAGE_INTERACTION: 'page-interaction',
  QUIZ_LOAD: 'quiz-load',
  QUIZ_INTERACT: 'quiz-interact',
  QUIZ_COMPLETE: 'quiz-complete',
  QUIZ_RETAKE: 'quiz-retake',
  QUIZ_GO_BACK: 'quiz-go-back',
  QUIZ_CTA: 'quiz-cta',
  // Any calculator
  CALCULATOR_LOAD: 'calculator-load',
  CALCULATOR_INTERACTION: 'calculator-interact',
  CALCULATOR_COMPLETE: 'calculator-complete' // Finished the calculator experience. We've got some results

};
/**
 * Where the lead form is used
 * ---
 * For example, whether a lead form is embedded in a quiz or a landing page
 */

var NAME_LEAD_FORM_EXPERIENCES = {
  CALCULATOR: 'calculator',
  GATED_CONTENT: 'gated content',
  GENERAL: 'general',
  OFFICE_SITES: 'office sites',
  QUIZ: 'quiz',
  EMBEDDED_FAFA: 'embedded fafa',
  EMBEDDED_QUIZ: 'embedded quiz',
  LANDING_PAGES_PREFIX: 'landing pages-',
  PRE_LEAD_SUSPECT_EMAIL_CAPTURE_FORM: 'prelead email capture'
};
/**
 * What type of lead it is
 * ---
 * While most leads will likely be completely new customers with no existing business with NM, not all are like that
 */

var NAME_LEAD_FORM_SUBMIT_TYPE = {
  /*
   * Your basic type of submissions. Most submissions will be this type
   */
  LEAD: 'lead',

  /*
   * A lead form may be (visually) part of a financial rep's (FR)
   * profile (`AGENT_FORM`), for example, same <DIV />, callout
   * UI, etc.
   *
   * In these types of forms, there may be a selection UI that asks
   * whether the user has an "existing relationship" with the FR.
   * The lead becomes a service request when the user says yes
   */
  SERVICE_REQUEST: 'service-request',

  /**
   * pre lead email forms collecting emails for a mailing list.
   */
  PRE_LEAD_EMAIL: 'pre-lead-email-capture'
};
/**
 * Where a quiz is used
 * ---
 * For now, just determines whether a quiz is embedded or not
 */

var NAME_QUIZ_EXPERIENCES = {
  GENERAL: 'general',
  EMBEDDED: 'embedded'
};
/**
 * Quiz Types
 * ---
 * This should match 1:1 with the `quiz_type` dropdown in the Drupal CMS
 */

var NAME_QUIZ_VARIANT = {
  QUESTIONNAIRE: 'questionnaire',
  SCORED_QUIZ: 'scored_quiz'
};
export { NAME_LEAD_FORM_EXPERIENCES, NAME_LEAD_FORM_SUBMIT_TYPE, NAME_PUSH_EVENTS, NAME_QUIZ_EXPERIENCES, NAME_QUIZ_VARIANT };