/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Col } from '../../../base/Grid';

export const DisclaimerColStyled = styled(Col)`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};
`;

export const DisclaimerSectionStyled = styled.section`
  ${({ disableDisclaimerTopBorder, theme }) => css`
    && {
      margin-top: ${disableDisclaimerTopBorder ? 0 : rem(32)};
      padding-top: ${rem(16)};
      padding-bottom: ${rem(16)};
      /** disclaimer section will have top border unless disabled */
      border-top: ${disableDisclaimerTopBorder ? 'none' : `1px solid ${theme.colors.neutral.gray58}`};
      border-bottom: 1px solid ${theme.colors.neutral.gray58};
    }
  `}
`;

export const CustomDisclaimerColStyled = styled(Col)`
  ${({
    combinedCustomFootnotesCharacterCount,
    characterCountThreshold,
    theme,
  }) => {
    let thisColumnCount = 1;
    const mediumUp = theme.mediumUp();
    if (mediumUp && combinedCustomFootnotesCharacterCount > characterCountThreshold) {
      thisColumnCount = 2;
    }
    return css`
      && {
        padding-top: ${rem(16)};
        padding-bottom: ${rem(32)};
        column-count: ${thisColumnCount};
      }
    `;
  }}
`;
