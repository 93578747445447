/* eslint-disable react/self-closing-comp */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import xhr from 'xhr';
import { jsxIf } from '../utils/jsx.util';
import ProfileOutline from '../../../components/icons/utility/ProfileOutline';

export class NMLoginButtonComponent extends Component {
  constructor() {
    super();
    this.state = {
      windowOpen: true,
      iframesLoading: false,
    };

    // component refs
    this.loginButtonComponent = React.createRef();
  }

  componentDidMount() {
    xhr({
      method: 'GET',
      uri: this.props.config.services.userLogoutUrls,
    }, (err, resp, body) => {
      if (typeof body !== 'undefined') {
        this.setState({ userLogoutUrls: JSON.parse(body) });
      }
    });
  }

  // If the user is logged into any other NM services, clicking the login button will log them out.
  LogoutFrames = () => {
    if (this.state.userLogoutUrls) {
      const nmisLogout = this.state.userLogoutUrls.nmisLogoutUrl;
      // const urls = Object.values(this.state.userLogoutUrls.urls); // experimental feature Object.values unsupported in IE11
      const urls = Object.keys(this.state.userLogoutUrls.urls).map((item) => this.state.userLogoutUrls.urls[item]); // ie11 fix // TODO: revert after NM stops supporting IE11
      const randomizeUrl = `?${window.crypto.getRandomValues(new Uint32Array(1))}${window.crypto.getRandomValues(new Uint32Array(1))}`;
      const iframeTitle = 'Logout Window Content';
      urls.forEach((key, i) => {
        urls[i] += randomizeUrl;
      });

      return (
        <div className='nmx-logout-iframe-container'>
          {urls.map((url) => (
            <iframe key={url} src={url} title={iframeTitle} />
          ))}
          <iframe src={nmisLogout} title={iframeTitle} />
        </div>
      );
    }
    return false;
  };

  openModal = () => {
    this.setState({ iframesLoading: true });
    document.getElementById('nmx-client-login').classList.add('is-active');
    document.body.classList.add('modal-is-open');
    document.getElementById('nmx-client-login-username').focus(); // TODO: come back and reexamine which field gets focus (vs modal)

    // TODO: this login-button/login-component correlation can be streamlined...
    // all of the component logic should live in the same component as button
    // TODO: confirm Adobe tracking

    // Google analytics tracking
    window.gtag('event', 'send', {
      send_to: this.props.config.public.googleAnalyticsTrackingId,
      event_category: 'Log in Link',
      event_action: 'Click',
      event_label: 'Log in Link Click',
    });
  }

  addPixelImage = (src) => {
    const img = document.createElement('img');
    img.src = src;
    img.border = 0;
    document.body.appendChild(img);
  }

  render() {
    return (
      <Fragment>
        <div className='nm-login-button' ref={this.loginButtonComponent}>
          <button className='nmx-login-open-button nmx-nav-link nmx-nav-link-utility' id='nmx-login-open-button' aria-label='Open login modal' onClick={this.openModal}>
            <ProfileOutline />Log in
          </button>
          {jsxIf(this.state.iframesLoading, () => (
            <Fragment>{this.LogoutFrames()}</Fragment>
          ))}
        </div>
      </Fragment>
    );
  }
}

NMLoginButtonComponent.propTypes = {
  /** Config vars used for varying components */
  config: PropTypes.shape({
    public: PropTypes.shape({ googleAnalyticsTrackingId: PropTypes.string }).isRequired,
    services: PropTypes.shape({ userLogoutUrls: PropTypes.string.isRequired }).isRequired,
  }),
};

NMLoginButtonComponent.defaultProps = {
  config: {
    public: { googleAnalyticsTrackingId: '<%=googleAnalyticsTrackingId%>' },
    services: { userLogoutUrls: '<%=userLogoutUrls%>' },
  },
};

export default NMLoginButtonComponent;
