import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../base/Typography';
import { Col, Row } from '../../base/Grid';

export const DivDocumentLinkStyled = styled.div`
  ${({ theme }) => css`
    position: relative;
    min-height: ${theme.mediumUp() ? rem(100) : rem(90)};
    width: 100%;
    transition: ${theme.shadows.transition};
    background-color: ${theme.colors.neutral.gray99};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: ${rem(6)};
    &:hover {
      box-shadow: ${theme.shadows.medium};
      text-decoration: none;
      cursor: pointer;
    }
  `}
`;

export const RowStyled = styled(Row)`
  align-items: center;
  min-height: ${rem(70)};
  padding: ${rem(20)} 0;
`;

export const ColIconStyled = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: ${rem(70)};
  .nmx-icon {
    width: 100%;
    height: 100%;
  }
`;

export const ColPictureStyled = styled(Col)`
  ${({ theme }) => css`
    padding-left: ${theme.mediumUp() ? rem(20) : rem(15)};
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${rem(80)};
  `}
`;

export const DivImageContainerStyled = styled.div`
  ${({ bgImage }) => css`
    background-image: url('${bgImage}');
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
  `}
`;

export const ColLinkStyled = styled(Col)`
  display: flex;
  align-items: center;
  padding-left: 0;
`;

export const AnchorDocumentLinkStyled = styled.a`
  text-decoration: none;
`;

export const TypographyLinkTextStyled = styled(Typography)`
  ${({ theme }) => css`
    padding-top: ${rem(-2)};
    padding-right: ${rem(30)};
    padding-bottom: 0;
    margin-bottom: 0;
    color: ${theme.colors.neutral.gray29};
    &.hover-state-active {
      color: ${theme.colors.cta.blue};
    }
  `}
`;
