import styled, { css, keyframes } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
// template components
import { Container, Row, Col } from '../../base/Grid';
import Typography from '../../base/Typography';

// helper
const sharpenBg = keyframes`
  0% {
    -webkit-filter: blur(${rem(20)});
    filter: blur(${rem(20)});
  }
  100% {
    -webkit-filter: blur(0);
    filter: blur(0);
  }
`;

// start styled components
export const ColStyled = styled(Col)`
  ${({ theme }) => {
    const mediumDown = theme.mediumDown();
    return css`
      ${mediumDown && 'padding: 0;'};
    `;
  }
}`;

export const ContainerStyled = styled(Container)`
  ${({ theme }) => {
    const mediumUp = theme.mediumUp();
    const mediumDown = theme.mediumDown();
    return css`
      ${mediumDown && 'padding: 0;'};
      z-index: 1;
      margin: 0;
      ${mediumUp && 'height: 100%;'};
    `;
  }
}`;

export const DivBackgroundContainerStyled = styled.div`
  ${({
    desktopImageJpg,
    desktopImageLowRes,
    desktopImageWebp,
    imageLoaded,
    mobileImageJpg,
    mobileImageLowRes,
    mobileImageWebp,
    theme,
  }) => {
    const mediumUp = theme.mediumUp();
    const smallUp = theme.smallUp();
    const backgroundImageWebp = mediumUp ? desktopImageWebp : mobileImageWebp;
    let backgroundImageJpg;
    let bgImageHeight = rem(120);

    if (mediumUp) {
      bgImageHeight = '100%;';
    } else if (smallUp) {
      bgImageHeight = rem(160);
    }

    if (imageLoaded) {
      backgroundImageJpg = mediumUp ? desktopImageJpg : mobileImageJpg;
    } else {
      backgroundImageJpg = mediumUp ? desktopImageLowRes : mobileImageLowRes;
    }

    return css`
      ${mediumUp && `
        position: absolute;
        top: 0;
        left: 0;
      `};
      width: 100%;
      height: ${bgImageHeight};
      background: ${theme.colors.blueLazyloadBg} no-repeat scroll 50% 20%;
      background-position: center;
      background-image: url(${backgroundImageJpg});
      background-image: url(${backgroundImageWebp || backgroundImageJpg});
      background-size: cover;
      transform: scale(1);
      -webkit-animation: ${sharpenBg} 1s both;
      animation: ${sharpenBg} 1s both;
      @media print {
        && {
          display: none !important;
        }
      }
    `;
  }
}`;

// gray box
export const DivHeaderContainerStyled = styled.div`
  ${({ theme, position }) => {
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    let padding = `${rem(18)} ${rem(24)} ${rem(10)}}`;
    let marginBottom = 0;
    let justifyContent = 'center';

    if (largeUp) {
      padding = `${rem(26)} ${rem(48)} ${rem(16)}}`;
    }

    if (position === 'bottom') {
      if (largeUp) {
        marginBottom = rem(60);
      } else if (mediumUp) {
        marginBottom = rem(48);
      }
    }

    if (mediumUp && position === 'left') {
      justifyContent = 'flex-start';
    }

    return css`
      background-color: ${theme.colors.neutral.gray99};
      padding: ${padding};
      margin-bottom: ${marginBottom};
      height: ${largeUp ? 'auto' : '100%'};
      display: flex;
      align-items: center;
      justify-content: ${justifyContent};
    `;
  }}
`;

export const RowStyled = styled(Row)`
  ${({ theme, position }) => {
    const mediumUp = theme.mediumUp();
    let verticalPosition = 'flex-end';

    if (mediumUp) {
      if (position === 'left' || position === 'center') {
        verticalPosition = 'center';
      }
    }

    return css`
      height: 100%;
      text-align: left;
      justify-content: left;
      align-items: ${verticalPosition};
    `;
  }
}
`;

export const SectionStyled = styled.section`
  ${({ theme }) => {
    const xxlargeUp = theme.xxlargeUp();
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    let height = 'auto';

    if (xxlargeUp) {
      height = rem(660);
    } else if (xlargeUp) {
      height = rem(495);
    } else if (largeUp) {
      height = rem(350);
    } else if (mediumUp) {
      height = rem(265);
    }

    return css`
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      align-items: center;
      background-color: ${theme.colors.neutral.white};
      color: ${theme.colors.neutral.gray29};
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: ${height};
      text-align: center;
      padding: 0;
    `;
  }
}`;

export const TypographyHeaderStyled = styled(Typography)`
  ${({ theme, position }) => {
    const xlargeUp = theme.xlargeUp();
    const largeUp = theme.largeUp();
    const mediumUp = theme.mediumUp();
    let fontSize = rem(26);
    let lineHeight = rem(32);
    const paddingBottom = rem(16);
    let textAlign = 'center';
    let margin = '0 auto';

    if (xlargeUp) {
      fontSize = rem(44);
      lineHeight = rem(52);
    } else if (largeUp) {
      fontSize = rem(36);
      lineHeight = rem(36);
    } else if (mediumUp) {
      fontSize = rem(26);
      lineHeight = rem(26);
    }

    if (position === 'left' && mediumUp) {
      textAlign = 'left';
      margin = 0;
    }

    return css`
      && {
        font-family: 'Guardian Sans', 'Helvetica Neue', Helvetica, sans-serif;
        padding-bottom: ${paddingBottom};
        line-height: ${lineHeight};
        font-size: ${fontSize};
        text-align: ${textAlign};
        ::after {
          display: block;
          width: ${rem(60)};
          padding-bottom: ${paddingBottom};
          border-bottom: ${rem(4)} solid ${theme.colors.cta.yellow};
          content: '';
          text-align: ${textAlign};
          margin: ${margin};
        }
      }
    `;
  }}
`;
