import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import Link from '../../base/Link';

export const List = styled.ul`
  && {
    padding: 0;
    margin: 0;
    list-style: none;
  }
`;

export const ListItem = styled.li`
  && {
    ${({ theme }) => css`
      padding: ${rem(16)} 0;
      font-size: ${rem(14)};
      line-height: ${rem(22)};

      &:not(:last-child) {
        border-bottom: ${rem(1)} solid ${theme.colors.accent.lightBlue2};
      }

      ${theme.smallUp()
        && `
          font-size: ${rem(16)};
          line-height: ${rem(24)};
        `};

      ${theme.xlargeUp()
        && `
          font-size: ${rem(20)};
          line-height: ${rem(30)};
        `};

    `}
  }
`;

export const LinkStyled = styled(Link)`
  && {
    ${({ theme }) => css`
      display: inline-block;
      width: 100%;
      color: ${theme.colors.neutral.white};

      .nmx-icon {
        float: right;
        transform: rotate(270deg);
        margin-top: ${rem(8)};
        .icon-fill {
          fill: ${theme.colors.cta.yellow};
        }
      }

      &:hover {
        /** TODO: dark theme link (template 3.x.x) */
        color: ${theme.colors.neutral.white};
        text-decoration: underline;

        .nmx-icon {
          .icon-fill {
            fill: ${theme.colors.cta.yellowHover};
          }
        }
      }
    `}
  }
`;
