import React from 'react';
import Typography from '../../../base/Typography';
import { DivContainerStyled } from './styles';

export default class RecaptchaInFormBadgeComponent extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <DivContainerStyled className='safari-inflow-recaptcha-disclaimer'>
        <Typography disableBottomPadding><small>This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow noopener noreferrer"><small>Privacy Policy</small></a> and <a href="https://policies.google.com/terms" target="_blank" rel="nofollow noopener noreferrer"><small>Terms of Service</small></a> apply.</small></Typography>
      </DivContainerStyled>
    );
  }
}
