import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import jsRedirect from '../../../helpers/jsRedirect';
import { Col } from '../../base/Grid';
import AssistiveText from '../../base/AssistiveText';
import {
  BGImageContainerExperienceCalloutStyled,
  ButtonOverlayStyled,
  CTAContainerExperienceCalloutStyled,
  ColExperienceCalloutStyled,
  ComponentContainerExperienceCalloutStyled,
  ContainerExperienceCalloutStyled,
  DescriptionCopyTypographyStyled,
  ImageContainerExperienceCalloutStyled,
  LinkExperienceCalloutStyled,
  PictureExperienceCalloutStyled,
  RowStyled,
  SectionExperienceCalloutStyled,
  Styles,
  TypographyTitleStyled,
} from './styles';

export const ExperienceCalloutComponent = React.forwardRef(({
  actionId,
  ariaLabel,
  assistiveText,
  buttonText,
  className,
  copy,
  disableBottomPadding,
  disableLazyLoad,
  icon,
  id,
  image,
  imageAlt,
  isFullWidth,
  moduleName,
  title,
  url,
  variant,
  withBGImage,
  withHoverShadow,
}, ref) => {
  const styles = Styles();
  const IconToRender = icon;
  return (
    <SectionExperienceCalloutStyled
      id={id}
      variant={variant}
      className={className}
      hasFullWidthContainer={isFullWidth}
      data-module={`${id}:${moduleName}:A`}
      disableBottomPadding={disableBottomPadding}
      ref={ref}>
      <ContainerExperienceCalloutStyled
        variant={variant}
        isFullWidthContainer={isFullWidth}
        withBGImage={withBGImage}>
        <ComponentContainerExperienceCalloutStyled
          variant={variant}
          isFullWidthContainer={isFullWidth}
          withHoverShadow={withHoverShadow}>
          <RowStyled align="center">
            <ColExperienceCalloutStyled
              xsmall={12}
              medium={5}>
              {/* Background image */}
              {withBGImage ? (
                <BGImageContainerExperienceCalloutStyled
                  forFullWidthContainer={isFullWidth}
                  data-bg={`url(${image})`} /* If a non URL image is passed in, how should we handle setting the data-bg? */
                  className='nmx-experience-callout-image-container lazy'
                  image={image}
                  disableLazyLoad={disableLazyLoad} />
              ) : (
                <ImageContainerExperienceCalloutStyled
                  forFullWidthContainer={isFullWidth}>
                  {icon
                    ? (
                      <IconToRender style={styles.IconExperienceCalloutStyled} />
                    )
                    : (
                      <PictureExperienceCalloutStyled
                        src={image}
                        alt={imageAlt} />
                    )}
                </ImageContainerExperienceCalloutStyled>
              )}
            </ColExperienceCalloutStyled>
            <Col
              align='center'
              xsmall={12}
              medium={7}>
              <CTAContainerExperienceCalloutStyled withBGImage={withBGImage}>
                <TypographyTitleStyled
                  component='h2'
                  hasCopy={copy}
                  disableBottomPadding>
                  {title}
                </TypographyTitleStyled>
                {copy && (
                  <DescriptionCopyTypographyStyled
                    disableBottomPadding>
                    {copy}
                  </DescriptionCopyTypographyStyled>
                )}
                <LinkExperienceCalloutStyled
                  id={`${id}-button`}
                  className={(variant === 'tertiary' && !isFullWidth) && 'button-experience-callout-styled'}
                  variantOverride={variant}
                  variant='buttonTertiary'
                  ariaLabel={ariaLabel}
                  href={url}>
                  {buttonText}
                </LinkExperienceCalloutStyled>
              </CTAContainerExperienceCalloutStyled>
            </Col>
            {/* Clickable overlay, with drop shaodw, for the in-page variation */}
            {!isFullWidth && (
              <ButtonOverlayStyled
                id={actionId}
                role='link'
                data-tracking-id={actionId}
                aria-label={assistiveText}
                onClick={() => {
                  jsRedirect(url);
                }}>
                <AssistiveText
                  component='span'
                  text={assistiveText} />
              </ButtonOverlayStyled>
            )}
          </RowStyled>
        </ComponentContainerExperienceCalloutStyled>
      </ContainerExperienceCalloutStyled>
    </SectionExperienceCalloutStyled>
  );
});

ExperienceCalloutComponent.propTypes = {
  actionId: PropTypes.string,
  assistiveText: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  /** optional additional className */
  className: PropTypes.string,
  copy: PropTypes.string,
  /** optional disable bottom section padding (margin) */
  disableBottomPadding: PropTypes.bool,
  disableLazyLoad: PropTypes.bool,
  icon: PropTypes.any,
  id: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  imageAlt: PropTypes.string,
  isFullWidth: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  variant: PropTypes.string,
  withBGImage: PropTypes.bool,
  withHoverShadow: PropTypes.bool,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
};

ExperienceCalloutComponent.defaultProps = {
  disableBottomPadding: false,
  disableLazyLoad: false,
  buttonText: 'Get Started',
  url: 'https://www.google.com/',
  variant: 'tertiary',
  withBGImage: true,
};

export default withTheme(ExperienceCalloutComponent);
