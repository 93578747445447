import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Typography from '../../base/Typography';

export const CredentialContainer = styled.div`
  ${({ theme }) => {
    const smallUp = theme.smallUp();
    const mediumUp = theme.mediumUp();
    let margin = '1rem 0';
    if (mediumUp) {
      margin = '1rem 1.25rem';
    } else if (smallUp) {
      margin = '1rem 1.25rem 0';
    }
    return css`
      margin: ${margin};
      .nmx-credential-logo {
        height: 100%;
        max-height: ${rem(20)};
        &__ibd {
          max-height: ${rem(14)};
        }
      }
    `;
  }}
`;

export const CredentialHeadline = styled(Typography)`
  ${({ theme, sectionTheme }) => css`
    margin-bottom: ${rem(16)};
    font-size: ${theme.mediumUp() ? rem(64) : rem(60)};
    line-height: ${rem(50)};
    color: ${sectionTheme === 'light'
    ? theme.colors.primary.blue
    : theme.colors.neutral.white};
    && {
      overflow: visible; /** for wide headlines */
    }
    ::before {
      content: '';
      margin-right: ${rem(16)};
      display: inline-block;
      width: ${rem(3)};
      height: ${rem(46)};
      background-color: ${theme.colors.cta.yellow};
    }
    overflow: hidden;
    white-space: nowrap;
  `}
`;

export const DescriptionStyled = styled(Typography)`
  ${({ theme, sectionTheme, credential }) => css`
    ${!credential
      && css`
        padding-bottom: 0; /** remove extra padding here that is causing visually-apparent extra section padding */
      `}
    color: ${
  sectionTheme === 'light'
    ? theme.colors.neutral.gray29
    : theme.colors.neutral.white
};
  `}
`;

export const CredentialFooter = styled.footer`
  display: none;
`;
