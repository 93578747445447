import React from 'react';
import PropTypes from 'prop-types';

import { LegendStyled, FormStyled } from './styles';
import { Container } from '../../base/Grid';

export const FormComponent = React.forwardRef(({
  children,
  className,
  container,
  legend,
  ...rest
}, ref) => (
  <FormStyled
    ref={ref}
    className={className}
    {...rest}>
    { legend && <LegendStyled>{legend}</LegendStyled> }
    <Container>
      {children}
    </Container>
  </FormStyled>
));

FormComponent.propTypes = {
  legend: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired,
  // if the consuming component already has nmx-container, set this to false to prevent duplicating of classes
  container: PropTypes.bool,
};

FormComponent.defaultProps = {
  onSubmit: () => {},
  children: '',
  container: true,
};

export default FormComponent;
