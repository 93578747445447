import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Row } from '../../base/Grid/grid';
import ProductResourcesView from './ProductResourcesView/productResourcesView';

import {
  ChipPersonalizationQuizStyled,
  SectionContainerStyled,
  TypographyPersonalizationQuizHeadingStyled,
  TypographyPersonalizationQuizSubheadingStyled,
} from './styles';

export const PersonalizationQuizComponent = ({
  variant,
  heading,
  subheading,
  chips,
  views,
  quizContainerId,
  id,
}) => (
  <SectionContainerStyled
    id={id}
    variant={variant}>
    <div id={quizContainerId}>
      <Row align='center'>
        <TypographyPersonalizationQuizHeadingStyled
          component='h2'
          variant='h4'
          align='center'
          hasSubheading={subheading}>
          {heading}
        </TypographyPersonalizationQuizHeadingStyled>
      </Row>
      {subheading && (
        <Row align='center'>
          <TypographyPersonalizationQuizSubheadingStyled
            component='p'
            align='center'>
            {subheading}
          </TypographyPersonalizationQuizSubheadingStyled>
        </Row>)}
      <Row align='center'>
        {
          chips.map((chip) => (
            <ChipPersonalizationQuizStyled
              key={chip.id}
              id={chip.id}
              icon={chip.icon}
              text={chip.text}
              onClick={chip.onClick}
            />
          ))
        }
      </Row>
    </div>
    {
      views.map((view) => (
        <ProductResourcesView
          key={view.id}
          heading={view.heading}
          id={view.id}
          callouts={view.callouts}
        />
      ))
    }
  </SectionContainerStyled>
);

PersonalizationQuizComponent.propTypes = {
  chips: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  quizContainerId: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  views: PropTypes.array.isRequired,
};

export default withTheme(PersonalizationQuizComponent);
