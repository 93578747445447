import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Row } from '../../base/Grid';

const FormRowComponentStyled = styled(Row)`
  margin-bottom: ${rem(32)};
}
`;

export default FormRowComponentStyled; // TODO: this is not optimal - reform all of this
