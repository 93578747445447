/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';

import { Row, Col } from '../../base/Grid';
import Typography from '../../base/Typography';
import AssistiveText from '../../base/AssistiveText';
import AccoladesData from './accoladesData';
import {
  AccoladeContainer,
  AccoladeContainerBlockquote,
  AccoladeTitleTypography,
  AccoladeFooter,
} from './styles';

export const AccoladesComponent = ({
  accolades,
  accoladeTitleProps,
  accoladeDescriptionProps,
}) => {
  const theme = useContext(ThemeContext);
  const smallDown = theme.smallDown();
  const mediumDown = theme.mediumDown();
  const [isMobile, setIsMobile] = useState(smallDown);
  const [isTablet, setIsTablet] = useState(mediumDown);

  useEffect(() => {
    // validate all accolades passed in are correct
    if (accolades.length === 0) {
      console.error('Missing accolades. At least one accolade is required.');
    }
    for (let i = 0; i < accolades.length; i += 1) {
      if (accolades[i].accolade in AccoladesData) {
        // check if accolade requires sup
        if (
          AccoladesData[accolades[i].accolade]().blockquote
          && !accolades[i].sup
        ) {
          console.error(
            `${accolades[i]} accolade requires a sup to be passed in for footer reference.`,
          );
        }
      } else {
        // invalid accolade provided
        console.error(
          'Provided accolade identifier is invalid. Possible accodales are [financialStrengthRating, rankedInvestmentService, yearsOfService, dividend, rankedInsuranceCompany, policyholderRetention, moodysInvestorsService, AMBestCompany, fitchRatings, SPGlobalRatings, communityDonations, childhoodCancerDonations, childhoodCancerResearch, COVIDReliefDonations].',
        );
      }
    }
  }, []);

  useEffect(() => {
    if (smallDown !== isMobile) {
      setIsMobile(smallDown);
    }
    if (mediumDown !== isTablet) {
      setIsTablet(mediumDown);
    }
  }, [smallDown, mediumDown]);

  const mediumBreakpoint = 12 / accolades.length;
  const smallBreakpoint = accolades.length === 4
    ? 6
    : 12;
  return (
    <Row align="center">
      {accolades.map((accolade, index) => {
        // grab data for accolade
        const data = AccoladesData[accolade.accolade](accolade.sup);
        // grab the container, depends on blockquote
        const StyledAccoladeContainer = data.blockquote
          ? AccoladeContainerBlockquote
          : AccoladeContainer;
        return (
          <Col
            key={accolade.accolade}
            xsmall={12}
            small={smallBreakpoint}
            medium={mediumBreakpoint}>
            <StyledAccoladeContainer
              disableBottomPadding={
                (!isMobile && !isTablet) // desktop won't have stacked accolades (or should not) so no bottom padding needed
                || (index === accolades.length - 1 && isMobile) // mobile always stacks accolades in single col so it is safe to disable bottom padding on last index
                || (isTablet
                  && !isMobile
                  && accolades.length === 4
                  && (index === 3 || index === 2)) // if we have 4 accolades, we have a 2x2 grid/col on medium so need to disable padding on bottom 2
              }>
              <Typography component="h3" align="center" disableBottomPadding {...data.assistiveTitle && { ariaLabel: data.assistiveTitle }}>{/* assistiveTitle used by screen readers */}
                <AccoladeTitleTypography {...accoladeTitleProps}>
                  {data.title}
                </AccoladeTitleTypography>
                {/* AssistiveText Required for a comprehensive heading outline */}
                {data.assistiveTitleSupplement && (
                  <AssistiveText text={data.assistiveTitleSupplement} component='span' />
                )}
              </Typography>
              <Typography {...accoladeDescriptionProps} {...data.assistiveDescription && { ariaLabel: data.assistiveDescription }} disableBottomPadding>
                {data.description}
              </Typography>
              {data.footer && <AccoladeFooter>{data.footer}</AccoladeFooter>}
            </StyledAccoladeContainer>
          </Col>
        );
      })}
    </Row>
  );
};

AccoladesComponent.propTypes = {
  /** Array of accoladed to display in the section */
  accolades: PropTypes.arrayOf(
    PropTypes.shape({
      /** The 'id' of the accolade wanting to display */
      accolade: PropTypes.oneOf([
        'financialStrengthRating',
        'rankedInvestmentService',
        'yearsOfService',
        'dividend',
        'rankedInsuranceCompany',
        'policyholderRetention',
        'moodysInvestorsService',
        'AMBestCompany',
        'fitchRatings',
        'SPGlobalRatings',
        'communityDonations',
        'childhoodCancerDonations',
        'childhoodCancerResearch',
        'COVIDReliefDonations',
      ]).isRequired,
      /** The superscript symbol or number corresponding with the footer reference, Required for accolades needing source to cite */
      sup: PropTypes.any,
    }),
  ).isRequired,
  /** Override default typography props for the accolade titles */
  accoladeTitleProps: PropTypes.object,
  /** Override default typography props for the accolade descriptions */
  accoladeDescriptionProps: PropTypes.object,
};

AccoladesComponent.defaultProps = {
  containerStyles: '',
  headingProps: {
    component: 'h2',
    align: 'center',
    weight: 400,
  },
  accoladeTitleProps: {
    component: 'span',
    variant: 'jumbo-type',
    weight: 300,
  },
  accoladeDescriptionProps: {
    component: 'p',
    align: 'center',
  },
};

export default AccoladesComponent;
