/* eslint-disable max-len */
import React from 'react';
import { BlockSpan } from './styles';

export function renderSuperscript(sup) {
  if (sup) {
    // return superscript markup
    return <sup>{sup}</sup>;
  }
  return false;
}

export default {
  financialStrengthRating: (sup) => ({
    title: <>A&#43;&#43;</>,
    blockquote: true,
    assistiveTitle: 'A plus plus financial strength rating',
    assistiveTitleSupplement: ' financial strength rating',
    description: (
      <>
        AAA, Aaa, and AA&#43;&#8212;we&#39;ve earned the highest financial
        strength ratings awarded to life insurers from all four major rating
        agencies.{renderSuperscript(sup)}
      </>
    ),
    footer: (
      <>
        Awarded in the Insurance category: Life &amp; Health industry,{' '}
        <cite>Fortune&#174; Magazine&#39;s annual survey, 2017</cite>
      </>
    ),
  }),
  rankedInvestmentService: (sup) => ({
    title: <>Top 10</>,
    blockquote: true,
    assistiveTitle: 'Top ten ranked investment service',
    assistiveTitleSupplement: ' ranked investment service',
    description: (
      <>
        ranked among the Top U.S. Independent Investment Broker&#45;Dealers
        {renderSuperscript(sup)}
      </>
    ),
    footer: (
      <>
        Ranking for Northwestern Mutual Investment Services LLC,{' '}
        <cite>Financial Advisor magazine and InvestmentNews, May 2020</cite>
      </>
    ),
  }),
  yearsOfService: (sup) => ({
    title: <>160&#43;</>,
    blockquote: false,
    assistiveTitle:
      'Over one hundred sixty years strong through depressions, downturns, two world wars, and pandemics',
    assistiveTitleSupplement:
      ' years strong through depressions, downturns, two world wars, and pandemics',
    description: (
      <>
        years strong through depressions, downturns, two world wars, and
        pandemics{renderSuperscript(sup)}
      </>
    ),
    footer: null,
  }),
  dividend: (sup) => ({
    title: <>&#36;6.5B</>,
    blockquote: true,
    assistiveTitle:
      'Six point five billion dollars in dividends expected to be paid in 2022',
    assistiveTitleSupplement: ' in dividends expected to be paid in 2022',
    description: (
      <>in dividends expected to be paid in 2022{renderSuperscript(sup)}</>
    ),
    footer: <>Dividends are reviewed annually and are not guaranteed.</>,
  }),
  rankedInsuranceCompany: (sup) => ({
    title: <>No. 1</>,
    blockquote: true,
    assistiveTitle: 'Number one most trusted insurance company',
    assistiveTitleSupplement: ' most trusted insurance company',
    description: (
      <>
        Best Life Insurance Company ranked by NerdWallet, 2020
        {renderSuperscript(sup)}
      </>
    ),
    footer: (
      <>
        Ranking for Northwestern Mutual Investment Services LLC,{' '}
        <cite>
          &#169;2017&#8211;2020 and TM, NerdWallet, Inc. All Rights Reserved.
        </cite>
      </>
    ),
  }),
  trustedInsuranceCompany: (sup) => ({
    title: <>No. 1</>,
    blockquote: true,
    assistiveTitle: 'Number one overall ranked insurance company',
    assistiveTitleSupplement: ' overall ranked insurance company',
    description: (
      <>
        Most Trusted Financial Companies for Life Insurance ranked by
        Investor&#39;s Business Daily, 2021{renderSuperscript(sup)}
      </>
    ),
    footer: (
      <>
        Ranking for Northwestern Mutual{' '}
        <cite>2021, Investor&#39;s Business Daily&#174;</cite>
      </>
    ),
  }),

  policyholderRetention: (sup) => ({
    title: <>96&#37;</>,
    blockquote: true,
    assistiveTitle:
      'Ninety six percent of our policyowners stay with us year over year',
    description: (
      <>
        of our policyowners stay with us year over year{renderSuperscript(sup)}
      </>
    ),
    footer: (
      <>
        Statistic for Northwestern Mutual,{' '}
        <cite>based on Northwestern Mutual policyowner data.</cite>
      </>
    ),
  }),
  moodysInvestorsService: (sup) => ({
    title: <>Aaa</>,
    blockquote: true,
    description: (
      <>
        <BlockSpan uppercase={true}>Highest</BlockSpan>
        <BlockSpan>
          Moody&#39;s Investors Service{renderSuperscript(sup)}
        </BlockSpan>
        <BlockSpan>December 2021</BlockSpan>
      </>
    ),
    footer: (
      <>
        <cite>December 2021</cite>
      </>
    ),
  }),
  AMBestCompany: (sup) => ({
    title: <>A&#43;&#43;</>,
    blockquote: true,
    description: (
      <>
        <BlockSpan uppercase={true}>Highest</BlockSpan>
        <BlockSpan>
          A.M. Best Company<sup>{sup}</sup>
        </BlockSpan>
        <BlockSpan>May 2021</BlockSpan>
      </>
    ),
    footer: (
      <>
        <cite>May 2021</cite>
      </>
    ),
  }),
  fitchRatings: (sup) => ({
    title: <>AAA</>,
    blockquote: true,
    description: (
      <>
        <BlockSpan uppercase={true}>Highest</BlockSpan>
        <BlockSpan>
          Fitch Ratings<sup>{sup}</sup>
        </BlockSpan>
        <BlockSpan>December 2021</BlockSpan>
      </>
    ),
    footer: (
      <>
        <cite>December 2021</cite>
      </>
    ),
  }),
  SPGlobalRatings: (sup) => ({
    title: <>AA&#43;</>,
    blockquote: true,
    description: (
      <>
        <BlockSpan uppercase={true}>Second Highest</BlockSpan>
        <BlockSpan>
          S&#38;P Global Ratings<sup>{sup}</sup>
        </BlockSpan>
        <BlockSpan>July 2021</BlockSpan>
      </>
    ),
    footer: (
      <>
        <cite>July 2021</cite>
      </>
    ),
  }),
  communityDonations: () => ({
    title: <>&#36;445M&#43;</>,
    blockquote: false,
    description: <>given to our communities since 1992</>,
  }),
  childhoodCancerDonations: () => ({
    title: <>&#36;40M&#43;</>,
    blockquote: false,
    description: <>given to support the fight against childhood cancer</>,
  }),
  childhoodCancerResearch: () => ({
    title: <>500K&#43;</>,
    blockquote: false,
    description: <>hours of childhood cancer research funded</>,
  }),
  COVIDReliefDonations: () => ({
    title: <>&#36;2M&#43;</>,
    blockquote: false,
    description: <>In national COVID&#8211;19 relief</>,
  }),
};
