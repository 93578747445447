import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import BaseButton from './styles';

export const ButtonComponent = React.forwardRef(({
  ariaLabel,
  className,
  children,
  disabled,
  id,
  onClick,
  styles,
  theme,
  themeType,
  type,
  variant,
  ...rest
}, ref) => (
  <BaseButton
    {...ariaLabel && { 'aria-label': ariaLabel }} // NOTE: React implements exceptions to JSX, "aria-*" is one of them: https://reactjs.org/docs/accessibility.html
    className={classnames(
      'nmx-button',
      className,
    )}
    disabled={disabled}
    id={id}
    onClick={onClick}
    ref={ref}
    style={styles || {}}
    themeType={themeType}
    theme={theme}
    type={type}
    variant={variant}
    {...rest}>
    {children}
  </BaseButton>
));

ButtonComponent.propTypes = {
  /** Optional ariaLabel attribute, used for ADA to add more description to the Button (what will happen when clicked) */
  ariaLabel: PropTypes.string,
  /** Optional custom class name */
  className: PropTypes.string,
  /** Inner Text of button */
  children: PropTypes.node.isRequired,
  /** optional disabled prop */
  disabled: PropTypes.bool,
  /** Button id */
  id: PropTypes.string.isRequired,
  /** Optional onClick function */
  onClick: PropTypes.func,
  /** Optional custom styles */
  styles: PropTypes.object,
  /** Optional theme */
  theme: PropTypes.oneOf(['nmx-pcg']),
  /** Optional themeType = necessary because styled components use "theme" as a preconfigured keyword */
  themeType: PropTypes.oneOf(['nmx-pcg']),
  /** type: default button  */
  type: PropTypes.string,
  /** button variant */
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quarternary']).isRequired,
};

ButtonComponent.defaultProps = {
  children: '',
  className: null,
  disabled: false,
  styles: null,
  type: 'button',
  variant: 'primary',
};

export default ButtonComponent;
