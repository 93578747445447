/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import PropTypes from 'prop-types';

import FormRowComponentStyled from './styles';

export const FormRowComponent = ({ children }) => (
  <FormRowComponentStyled>
    {children}
  </FormRowComponentStyled>
);

FormRowComponent.propTypes = { children: PropTypes.node };

FormRowComponent.defaultProps = {};

export default FormRowComponent;
