import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import ThumbsUpIcon from '../../../icons/illustrative-secondary/ThumbsUpSecondary';
import Container from '../../../base/Grid/container';
import Link from '../../../base/Link';
import Typography from '../../../base/Typography';

export const ContainerStyled = styled(Container)`
  ${({ theme, formvariant }) => {
    const mediumUp = theme.mediumUp();
    if (formvariant === 'hero') {
      return css`
        margin-top: ${mediumUp ? rem(50) : rem(32)};
        margin-bottom: ${mediumUp ? rem(60) : rem(48)};
      `;
    }
    return false;
  }}
`;

export const IconStyled = styled(ThumbsUpIcon)`
  ${({ theme, formvariant }) => {
    let marginTop = 0;
    let iconWidth = rem(50);
    const xlargeUp = theme.xlargeUp();
    const mediumUp = theme.mediumUp();

    if (mediumUp) {
      marginTop = rem(-25);
    }

    if (formvariant === 'hero') {
      if (xlargeUp) {
        iconWidth = rem(60); // hero desktop icon width
      }
    }

    return css`
      width: ${iconWidth};
      margin-top: ${marginTop};
      margin-bottom: ${rem(10)};
    `;
  }}
`;

export const LinkStyled = styled(Link)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.white};
    text-decoration: underline;
    :hover,
    :active {
      color: ${theme.colors.neutral.gray80};
    }
  `}
`;

export const TypographyHeroConfirmationStyled = styled(Typography)`
  ${({ theme, formvariant, isPcg }) => {
    let padding = `0 ${rem(30)} ${rem(5)}`;
    const xlargeUp = theme.xlargeUp();
    if (xlargeUp) {
      padding = 0;
    }

    return css`
      ${formvariant === 'hero' && `padding: ${padding}`};
      text-align: center;
      ${isPcg && `
        text-transform: uppercase;
        letter-spacing: ${rem(4)};
        font-weight: 400;
        :after {
          content: '';
          display: block;
          height: ${rem(3)};
          width: ${rem(100)};
          margin: ${rem(10)} auto 0;
          background-color: ${theme.colors.pcg.gold1};
          background: linear-gradient(90deg, ${theme.colors.pcg.gold1} 0%, ${theme.colors.pcg.gold2} 50%, ${theme.colors.pcg.gold1} 100%);
        }
      `};
    `;
  }}
`;
