import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const ArticleCardContainer = styled.div`
  position: relative;
  text-align: center;
  min-height: ${rem(350)};
  height: 100%;
  background-color: #fff;
  border-radius: 2px;
`;

export const ArticleImageContainer = styled.div`
  ${({ theme }) => {
    const tablet = theme.mediumDown();
    return css`
      height: ${tablet ? '60%' : '50%'};
    `;
  }}
`;

export const ArticleImage = styled.img`
  position: relative;
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const ArticleContent = styled.div`
  padding: ${rem(20)};
`;

export const ActionContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: ${rem(20)};
  text-decoration: underline;
  line-height: ${rem(19)};
  font-size: ${rem(14)};
  letter-spacing: ${rem(0.45)};
`;
