/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Col } from '../../../base/Grid';

export const PhotoCol = styled(Col)`
  ${({ theme }) => {
    const mobile = theme.mediumDown();
    return css`
      margin-bottom: ${mobile ? '1.5rem' : 0};
    `;
  }}
`;
