import styled, { css } from 'styled-components';

import Typography from '../../base/Typography';

const getAccoladeContent = (theme, disableBottomPadding) => {
  const small = theme.smallUp();
  const medium = theme.mediumUp();
  let margin = '1rem 0';
  if (medium) {
    margin = '1rem 1.25rem';
  } else if (small) {
    margin = '1rem 1.25rem 0';
  }
  return css`
    margin: ${margin};
    margin-bottom: ${disableBottomPadding ? '0' : theme.spacing(8).rem()};
  `;
};

export const AccoladeContainer = styled.div`
  && {
    ${({ theme, disableBottomPadding }) => getAccoladeContent(theme, disableBottomPadding)}
  }
`;
export const AccoladeContainerBlockquote = styled.blockquote`
  && {
    ${({ theme, disableBottomPadding }) => getAccoladeContent(theme, disableBottomPadding)}
  }
`;

export const AccoladeTitleTypography = styled(Typography)`
  ${({ theme }) => css`
    white-space: nowrap;
    ::after {
      display: block;
      width: ${theme.spacing(15).rem()};
      padding-bottom: ${theme.spacing(4).rem()};
      border-bottom: ${theme.spacing(1).rem()} solid ${theme.colors.cta.yellow};
      margin: 0 auto ${theme.spacing(4).rem()};
      content: '';
    }
  `}
`;

export const AccoladeFooter = styled.footer`
  display: none;
`;

export const BlockSpan = styled.span`
  ${({ uppercase }) => css`
    display: block;

    ${uppercase && 'text-transform: uppercase;'}
  `}
`;
