import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../base/Grid';
import Button from '../../../base/Button';
import Link from '../../../base/Link';
import Typography from '../../../base/Typography';
import { PHONE_MEDIA_QUERY } from '../../../../assets/js/constants/media_query.constants';
import sanitizePhone from '../../../../assets/js/helpers/sanitizePhone';

const EnsembleCard = ({ ensembleObject }) => {
  const ensemble = ensembleObject;

  return (
    <Row align="center">
      <Col xsmall={12} small={4} medium={4} nestedChild>
        <Row align="center">
          <Col xsmall={12} small={12} medium={12} nested>
            <picture className='nm-ensemble-card--photo'>
              <source
                srcSet={ensemble.imageUrl || '/life-and-money/assets/images/ensemble-default-mobile.jpg'}
                media={PHONE_MEDIA_QUERY}
              />
              <source
                srcSet={ensemble.imageUrl || '/life-and-money/assets/images/ensemble-default.jpg'}
              />
              <img src={ensemble.imageUrl || '/life-and-money/assets/images/ensemble-default.jpg'} alt={ensemble.name} />
            </picture>
          </Col>
        </Row>
      </Col>
      <Col xsmall={12} small={8} medium={8} nestedChild>
        <Row>
          <Col xsmall={12} small={12} medium={6} styles={{ marginBottom: '1rem' }} nested>
            <div className='nm-rep-card--title' style={{ marginBottom: '1rem' }}>
              <h3 className='nm-rep-card--name'>{ensemble.name}</h3>
            </div>
            <div className='nm-rep-card--additional-info'>
              <Link href='https://brokercheck.finra.org/' target='_blank' rel='nofollow noopener noreferrer'>FINRA BrokerCheck</Link>
            </div>
          </Col>
          <Col xsmall={12} small={12} medium={6} nested>
            <Typography component='address' variant='p' styles={{ whiteSpace: 'pre-wrap' }}>
              {ensemble.street}. {ensemble.city}, {ensemble.state} {ensemble.zip}
            </Typography>
            {ensemble.primaryPhone && <Link href={`tel:${sanitizePhone(ensemble.primaryPhone)}`}>{ensemble.primaryPhone}</Link>}
            {ensemble.email && (
              <Row>
                <Button style={{ marginTop: '1rem' }} variant='secondary' role='link' onClick={() => window.location.replace(`mailto:${ensemble.email}`)}>Contact <img src='https://www.northwesternmutual.com/life-and-money/assets/images/chevron-right.svg' alt='icon for right arrow chevron' /></Button>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

EnsembleCard.propTypes = {
  ensembleObject: PropTypes.shape({
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    primaryPhone: PropTypes.string,
    email: PropTypes.string,
  }),
};

export default EnsembleCard;
