import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '../../../base/Grid';
import Typography from '../../../base/Typography';
import Link from '../../../base/Link';

const CrcCard = ({ city, phone }) => (
  <>
    <Row>
      <Col xsmall={12}>
        <Typography weight={200} align='center' component='h3' uppercase>{city}</Typography>
      </Col>
    </Row>
    <Row styles={{ textAlign: 'center' }}>
      <Col xsmall={12}>
        <Link href={`tel:${phone.replace(/-/g, '')}`}>{phone}</Link>
      </Col>
    </Row>
  </>
);

CrcCard.propTypes = {
  city: PropTypes.string,
  phone: PropTypes.string,
};

export default CrcCard;
