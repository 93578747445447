/* eslint-disable max-len */
import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
// template components
import AssistiveText from '../../base/AssistiveText';
import { Col, Row } from '../../base/Grid';
import SectionContainer from '../SectionContainer';
// styles
import {
  LinkStyled,
  TypographyStyled,
} from './styles';

export const BrokerDealerDisclosureComponent = ({
  className,
  disableBottomPadding,
  id,
  moduleName,
  moduleVariation,
  sectionVariant,
}) => (
  <SectionContainer
    id={id}
    className={className}
    variant={sectionVariant}
    moduleName={moduleName}
    moduleVariation={moduleVariation}
    disableBottomPadding={disableBottomPadding}>
    <AssistiveText
      text='Broker&#45;Dealer Disclosure'
      component='h2'/>
    <Row align='center'>
      <Col
        xsmall={12}
        medium={8}>
        <TypographyStyled variant='h3'>
          To learn more about Northwestern Mutual Investment Services, LLC and its financial professionals, visit <LinkStyled
            href='https://brokercheck.finra.org/'
            target='_blank'
            rel='nofollow noopener noreferrer'
            sectionVariant={sectionVariant}>FINRA BrokerCheck&#174;</LinkStyled>
        </TypographyStyled>
        <TypographyStyled disableBottomPadding>
          Visit our <LinkStyled
            href='/client-relationship-summary/'
            sectionVariant={sectionVariant}>Client Relationship Summaries</LinkStyled> (Form CRS) for information about our firms that provide brokerage and advisory services.
        </TypographyStyled>
      </Col>
    </Row>
  </SectionContainer>
);

BrokerDealerDisclosureComponent.propTypes = {
  /** Optional custom class name */
  className: PropTypes.string,
  /** Disable the default bottom padding for section */
  disableBottomPadding: PropTypes.bool,
  /** section id */
  id: PropTypes.string.isRequired,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** optional moduleVariation */
  moduleVariation: PropTypes.string,
  /** section variant, which determines color styles */
  sectionVariant: PropTypes.any,
};

BrokerDealerDisclosureComponent.defaultProps = {
  id: 'broker-dealer-disclosure',
  moduleName: 'broker-dealer-disclosure',
  moduleVariation: 'A',
  disableBottomPadding: false,
  sectionVariant: 'tertiary',
};

export default withTheme(BrokerDealerDisclosureComponent);
