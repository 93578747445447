import React from 'react';
import PropTypes from 'prop-types';
import { HeaderSeparater, TitleHeader, PhotoStyled, LinkStyled, BottomCtaContainer, FinraContainer, ButtonStyled, CtaCol, TypographyWithIcon } from './styles';
import { Row, Col } from '../../../base/Grid';
import Typography from '../../../base/Typography';
// import breakpoints from '../../../theme/breakpoints';
import sanitizePhone from '../../../../assets/js/helpers/sanitizePhone';
import jsRedirect from '../../../../helpers/jsRedirect';

const FinraLink = (
  <LinkStyled
    href='https://brokercheck.finra.org/'
    target='_blank'
    rel='nofollow noopener noreferrer'
  >
    FINRA BrokerCheck
  </LinkStyled>
);

const Cta = ({ slug, backgroundVariant, cta }) => (
  <ButtonStyled
    onClick={() => jsRedirect(slug)}
    variant={backgroundVariant === 'quarternary'
      ? 'secondary'
      : backgroundVariant}
  >
    {cta}
  </ButtonStyled>
);

const EntityCard = ({
  config,
  dataObject,
  backgroundVariant,
}) => {
  const {
    name,
    title,
    address,
    phone,
    slug,
    imgSrc,
  } = dataObject;

  const getFormattedAddress = () => {
    const addressPieces = [];

    if (address.street) addressPieces.push(address.street);
    if (address.building) addressPieces.push(address.building);

    addressPieces.push(`\n${address.city}, ${address.state}`);

    if (address.zipLastFour) {
      addressPieces.push(`${address.zip}-${address.zipLastFour}`);
    } else {
      addressPieces.push(address.zip);
    }

    return addressPieces.join(' ');
  };

  return (
    <Row>
      <Col xsmall={12} medium={3} large={3}>
        <PhotoStyled
          src={imgSrc}
          lazyLoad={false}
          alt={name}
          styles={{ width: '100%' }}
        />
        <Row styles={{ margin: '1.5rem 0' }}>
          <Col xsmall={0} medium={12} large={0} align='center'>
            <Typography>
              {FinraLink}
            </Typography>
          </Col>
        </Row>
      </Col>
      <Col xsmall={12} medium={9} large={6}>
        <Row>
          <Col xsmall={12}>
            <Typography component='h2'>{name} {title && <><HeaderSeparater>&#124;</HeaderSeparater> <TitleHeader>{title}</TitleHeader></>}</Typography>
          </Col>
        </Row>
        <div style={{ marginLeft: '2.5rem' }}>
          <Row>
            <Col xsmall={10} medium={11}>
              <TypographyWithIcon config={config} icon={'map-pin'} component='address' variant='p' styles={{ whiteSpace: 'pre-wrap' }}>{getFormattedAddress()}</TypographyWithIcon>
            </Col>
          </Row>
          <Row>
            <Col xsmall={10} medium={11}>
              <TypographyWithIcon config={config} icon={'phone'}>{phone && <LinkStyled href={`tel:${sanitizePhone(phone)}`}>{phone}</LinkStyled>}</TypographyWithIcon>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xsmall={0} medium={12} large={0}>
            <Cta cta={'Let\'s Connect'} slug={slug} backgroundVariant={backgroundVariant} />
          </Col>
        </Row>
      </Col>
      <CtaCol xsmall={12} medium={0} large={3}>
        <Row>
          <Col xsmall={12}>
            <Cta cta={'Let\'s Connect'} slug={slug} backgroundVariant={backgroundVariant} />
          </Col>
        </Row>
        <Row>
          <Col xsmall={12}>
            <BottomCtaContainer>
              <Cta cta={'View my profile page'} slug={slug} backgroundVariant='tertiary' />
            </BottomCtaContainer>
          </Col>
        </Row>
        <Row>
          <Col xsmall={12} align='center'>
            <FinraContainer>
              {FinraLink}
            </FinraContainer>
          </Col>
        </Row>
      </CtaCol>
    </Row>
  );
};

EntityCard.propTypes = {
  dataObject: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      building: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      zipLastFour: PropTypes.string,
    }),
    phone: PropTypes.string,
    slug: PropTypes.string,
    imgSrc: PropTypes.string,
  }),
  config: PropTypes.shape({
    basePath: PropTypes.string,
    nmxTemplateVersion: PropTypes.string,
  }),
};

EntityCard.defaultProps = {};

export default EntityCard;
